import React, { useState } from 'react';
import { Col, Row, Modal, InputNumber, message } from 'antd';
import { getfundManagerAssets, showSubAccountRules, getFirstRiskControl, createTemporaryRulesEasy } from '@/api/workbench';
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import { withRouter } from "react-router-dom";
import { useUnmount, useMount, useReactive } from 'ahooks';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { HOME_PRODUCT_NUMBER, autoCol, validateResponse } from '@/utils/utils';
import { saveStorage, isValidObj } from '@/utils/utils2';
import HomeCard from './Components/HomeCard';
import Achieve from './Components/Achieve';
import Alerts from './Components/Alerts';
import AstHeader from './Components/AstHeader';
import Traders from './Components/Traders';
import moment from 'moment';
import _ from 'lodash';
import './assets.scss'

const createResultTimeKey = (datas) => {
  let results = datas.map(n => 'beginDate' in n ? _.assign(n, { 'timekey': moment(_.get(n, 'beginDate')).diff(moment(), 'd') }) : n);
  return _.orderBy(results, ['timekey'], ['desc']);
}

let assets = null; let timer = null; let timer2 = null;
export default withRouter(function AssetsShow(props) {
  const mobxStore = useLocalObservable(() => store);
  const [assetss, setassetss] = useState({ currentProductResultList: [] });
  const [riskMessage] = useState([]);
  const [riskHour, setriskHour] = useState(0);
  const [riskValue, setriskValue] = useState(0);
  const [riskState, setriskState] = useState(false);
  const [dealDisabled, setdealDisabled] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [glbAssets] = useState(JSON.parse(JSON.stringify(mobxStore.assetsData)));
  const [userRole] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.userInfo)), 'role', ''));
  const isVisitor = userRole === 'visitor' ? true : false;
  const [homeResult, setHomeResult] = useState([]);
  const [allProducts, setAllProduct] = useState([]);
  const assetState = useReactive({ isShow: true });
  let isUnmont = false;

  useMount(() => {
    const isGlobalData = _.get(glbAssets, 'assets', null);
    // home 从登录或者点击左侧menu，路由状态会携带{from:'home'},则进行更新，否则使用缓存
    if (!!isGlobalData) {
      const curResult = _.get(glbAssets, 'assets.currentProductResultList', []);
      setassetss(_.get(glbAssets, 'assets'));
      setHomeResult(createResultTimeKey(curResult));
    }
    _gets();

    if (!isVisitor) {
      //【230926】账户改为mobx全局获取
      mobxStore._getProductAccount();
      timer = setTimeout(() => {
        setAllProduct(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
      }, 2500);
    }
    getRisk();
    mobxStore._isAllStockHoliday();
    mobxStore._showVirtualProduct();
    timer2 = setTimeout(() => {
      const status = _.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'status', '') === 'trading' ? true : false;
      if (status) {
        _intervalGet();
      }
    }, 2000);
    mobxStore.savePositionList([]); //返回首页清空持仓缓存数据
  });

  useUnmount(() => {
    isUnmont = true;
    assets && clearInterval(assets);
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
  });

  async function _gets() {
    let params = {
      isShow: assetState.isShow,
      pageNum: 1,
      pageSize: HOME_PRODUCT_NUMBER
    }
    let globalAssets = {};
    const res = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    if (validateResponse(res, isUnmont)) {
      if (res.data !== null && res.data.currentProductResultList.length !== 0) {
        res.data.currentProductResultList.map(item => {
          item.totalRevenueRate = parseFloat(item.totalRevenueRate).toFixed(2) + '%';
          item.totalRevenueRate_float = parseFloat(item.totalRevenueRate);
          item.dailyRevenueRate = parseFloat(item.dailyRevenueRate).toFixed(2) + '%';
          item.dailyRevenueRate_float = parseFloat(item.dailyRevenueRate);
          return item;
        })
        res.data.totalRevenueRate = parseFloat(res.data.totalRevenueRate).toFixed(2) + '%';
        res.data.dailyRevenueRate = parseFloat(res.data.dailyRevenueRate).toFixed(2) + '%';
      }
      const getAssets = _.get(res, 'data', {});
      const getResultList = _.get(getAssets, 'currentProductResultList', []);
      // const getInfoList = _.get(getAssets, 'pageInfo.list', []);
      if (_.size(getResultList) === 0 && params.isShow === true) {
        assetState.isShow = false;
        _gets();
        return;
      }
      // let newList = [];
      // getResultList.map(n => {
      //   let findex = _.findIndex(getInfoList, o => o.id === n.productId);
      //   if (findex !== -1) {
      //     newList.push(_.assign(n, { 'times': moment(_.get(getInfoList, `[${findex}].createTime`)).format(FORMATE) }));
      //   }
      // })
      const finalList = createResultTimeKey(getResultList);
      setassetss(getAssets);
      setHomeResult(finalList);
      globalAssets.assetArr = getAssets;
      globalAssets.assets = getAssets;
      mobxStore.changeAssetsData(globalAssets);
      mobxStore.changeAllProducts(finalList);
      mobxStore.saveAccountCache([]);
    }
  }

  async function _intervalGet() {
    assets = setInterval(
      async function () {
        if (document.getElementById("assetsShow") === null) {
          clearInterval(assets);
          return;
        }
        _gets();
      }, 30000);
  }
  async function getRisk() {
    const data = await getFirstRiskControl();
    if (validateResponse(data, isUnmont)) {
      riskMessage[0] = data.data;
      if (riskMessage[0] !== null) {
        const sendTime = moment(data.data.sendTime);
        const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
        setriskHour(nowTime.diff(sendTime, 'hours'));
        if (nowTime.diff(sendTime, 'minutes') > 30) {
          setisDisabled(true);
        }
        if (data.data.type === 'execute_risk_control') {
          setriskState(true);
        }
      }
    }
  }
  // 路由跳转
  function jumpRoute(path = '', state = {}) {
    saveStorage('1', path);
    let routeObj = { pathname: `/home/${path}` };
    if (isValidObj(state)) {
      _.set(routeObj, 'state', state);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    };
    props.history.push(routeObj);
  };

  async function createTemp() {
    if (isDisabled) {
      setdealDisabled(false);
      return;
    }
    const data = await createTemporaryRulesEasy({ subAccountId: riskMessage[0].subAccountId, alertLine: riskValue });
    if (data.code === '200') {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  }

  async function dealWith() {
    const data = await showSubAccountRules({ subAccountId: riskMessage[0].subAccountId });
    if (data.code === '200') {
      setriskValue(data.data.alertLine);
      setdealDisabled(true);
      if (isDisabled === true) {
        message.info("已超过30分钟，不可修改预警线");
      }
    } else {
      message.error(data.message);
    }
  }

  return (
    <div className="contentView" id="assetsShow">
      <Modal
        title="风控规则"
        visible={dealDisabled}
        onOk={createTemp}
        onCancel={() => { setdealDisabled(false) }}
      >
        <span style={{ marginRight: 20 }}>预警线:</span>
        <InputNumber min={0} value={riskValue} disabled={isDisabled} onChange={setriskValue} step={0.01} />
        <br /><br /><br />
        {isDisabled ? '' : <span style={{ color: 'red' }}>注意：此预警线设置后，两小时内有效，过期后自动恢复初始预警线，进入风控管理进行调整</span>}
      </Modal>

      <AstHeader
        role={userRole}
        assetss={assetss}
        onAdd={() => jumpRoute('addProduct')}
        onVirtualAdd={() => jumpRoute('addVirtuals')}
      />

      <br />

      <Row gutter={8}>
        <Col {...autoCol([16])} >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <HomeCard
                isVisitor={isVisitor}
                homeItem={homeResult}
                goProductDetails={(item) => jumpRoute('ProductDetails', { 'paramsData': item })}
                goSubAccount={() => jumpRoute('subAccount', { assetss })}
              />
            </Col>
            <Col span={24}>
              <Traders assetssArr={assetss} role={userRole} />
            </Col>
          </Row>
        </Col>
        <Col {...autoCol([8])} >
          <Row gutter={[0, 16]}>
            <Alerts
              riskState={riskState}
              riskHour={riskHour}
              role={userRole}
              riskMessage={riskMessage}
              goRisk={() => jumpRoute('fundManagerNewsRisk')}
              goNews={() => jumpRoute('fundManagerNews')}
              dealWith={dealWith}
            />
            <Achieve assetssArr={assetss} assetss={assetss} />
          </Row>

          {/* <div style={{ marginTop: 16 }}>

          </div> */}
        </Col>
      </Row>
    </div>
  )
})
import FutureScreening from '@/view/Home/AccountAdm/Futuresmanagements/FutureScreening';
import FutureTrading from '@/view/Home/AccountAdm/Futuresmanagements/FutureTrading';
import FutureAssets from '@/view/Home/AccountAdm/Futuresmanagements/FutureAssets';
import FundManagerStockPoll from '@/view/Home/StockAdm/StockScreening/FundManagerStockPoll';
import FundAdm from '@/view/Home/AccountAdm/FundManagerDesk/FundAdm';
import TraderAdm from '@/view/Home/AccountAdm/FundManagerDesk/TraderAdm/TraderAdm';
import RiskAdm from '@/view/Home/AccountAdm/FundManagerDesk/RiskAdm/RiskAdm';
import TransAnalyse from '@/view/Home/AccountAdm/AssetsShow/TransAnalyse';
// import UsStock from '@/view/Home/StockAdm/StockScreening/UsStock';
// import HkStock from '@/view/Home/StockAdm/StockScreening/HkStock';
// import FundPage from '@/view/Home/StockAdm/StockScreening/Fund';
import Futuresmanagements from '../view/Home/AccountAdm/Futuresmanagements/Futuresmanagements';
// import TraderMessageList from '@/view/Home/AccountAdm/TraderMessageList/TraderMessageList';
// import FundAssetConfig from '@/view/Home/AccountAdm/FundAssetConfig/FundAssetConfig';
import FundManagerListsDetails from '@/view/deprived/FundManagerListsDetails/FundManagerListsDetails';
// 基金经理新增
import ManagerListDetail from '@/view/deprived/ManagerListDetails';
// 支付体系
import Payment from '@/view/deprived/Payment/TraderCenter/Payment';
import PaySetting from '@/view/deprived/Payment/ManagerCenter/PaySetting';
import Withdrawal from '@/view/deprived/Payment/ManagerCenter/Withdrawal';
import WithdrawalForm from '@/view/deprived/Payment/ManagerCenter/WithdrawalForm';
//admin
import LevelPage from '@/view/deprived/Payment/LevelPage';
import NewLevelPage from '@/view/deprived/Payment/NewLevelPage';
import CheckBalance from '@/view/deprived/Payment/ManagerCenter/CheckBalance';
import OpenService from '@/view/deprived/Admin/OpenService';
import SubManager from '@/view/deprived/Admin/SubManager';
//积分管理
import RewardPage from '@/view/deprived/Reward/Reward';

const routeSubConfig = [
  // {
  //   path: "/home/fundAssetConfig",
  //   name: "手动调仓",
  //   component: FundAssetConfig,
  // },
  {
    path: "/home/mListsDetails",
    name: "基金经理子账户",
    component: ManagerListDetail,
  },
  // ————————————————————————————————————————————
  {
    path: "/home/fundManagerListsDetails",
    name: "经理查看子账户",
    component: FundManagerListsDetails,
  },

  {
    path: "/home/Futuresmanagements",
    name: "期货管理",
    component: Futuresmanagements,
  },
  // {
  //   path: "/home/traderMessageList",
  //   name: "交易员消息列表",
  //   component: TraderMessageList,
  // },
  {
    path: "/home/FundManagerStockPoll",
    name: "基金经理股票管理",
    component: FundManagerStockPoll,
  },
  {
    path: "/home/futureScreening",
    name: "期货池",
    component: FutureScreening,
  },
  {
    path: "/home/FundAdm",
    name: "基金经理资金管理",
    component: FundAdm,
  },
  {
    path: "/home/TraderAdm",
    name: "基金经理交易员管理",
    component: TraderAdm,
  },
  {
    path: "/home/RiskAdm",
    name: "基金经理风控管理",
    component: RiskAdm,
  },
  {
    path: "/home/TransAnalyse",
    name: "基金经理交易分析",
    component: TransAnalyse,
  },
  // {
  //   path: "/home/UsStock",
  //   name: "基金经理美股管理",
  //   component: UsStock,
  // },
  // {
  //   path: "/home/HkStock",
  //   name: "基金经理港股管理",
  //   component: HkStock,
  // },
  // {
  //   path: "/home/FundPage",
  //   name: "基金经理港股管理",
  //   component: FundPage,
  // },
  {
    path: "/home/FutureTrading",
    name: "基金经理港股管理",
    component: FutureTrading,
  },
  {
    path: "/home/futureAssets",
    name: "我的期货资产",
    component: FutureAssets,
  },
  // ________________支付体系_____________________ 
  {
    path: "/home/trader/payment",
    name: "服务支付",
    component: Payment,
  },
  {
    path: "/home/manager/paysetting",
    name: "支付设置",
    component: PaySetting,
  },
  {
    path: "/home/manager/withdrawal",
    name: "提现管理",
    component: Withdrawal,
  },
  {
    path: "/home/manager/withForm",
    name: "提现表单",
    component: WithdrawalForm,
  },
  // ________________admin_____________________ 
  {
    path: "/home/levelPage",
    name: "策略admin",
    component: LevelPage,
  },
  {
    path: "/home/newLevelPage",
    name: "新增策略admin",
    component: NewLevelPage,
  },
  {
    path: "/home/CheckBalance",
    name: "查询用户余额admin",
    component: CheckBalance,
  },
  {
    path: "/home/openService",
    name: "开通会员权限admin",
    component: OpenService,
  },
  {
    path: "/home/submanager",
    name: "分配基金经理",
    component: SubManager,
  },
  // ________________积分奖励_____________________ 
  {
    path: "/home/reward",
    name: "积分奖励",
    component: RewardPage,
  },
];

export default routeSubConfig;
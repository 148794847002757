import {
  RATE_KEYS_OBJ, DEFAULT_CARRY, STR_PLUS,
  calNets, calRateFormula, renderFloats, multiplication, accumulate
} from './chartsInfoUtils';
import { getAllPosition, showPositionReturn, showPositionSort } from '@/api/workbench';
import { message } from 'antd';
import { isValidArray, isValidNumber } from '@/utils/utils2';
import moment from 'moment';
import _ from 'lodash';
/*
  该utils文件为 ListDetails 和 ProductDetails文件中主要配置文件及提取出来的不需要state的function功能；
  账户层面页面和子账户拥有相同的逻辑和页面功能，可共用一个utils文件；
*/
const DATE_TYPE = {
  '1': 'TODAY',
  '2': 'ONE_WEEK',
  '3': 'ONE_MONTH',
  '4': 'THREE_MONTH',
  '5': 'SIX_MONTH',
  '6': 'ONE_YEAR',
  '7': 'THREE_YEAR',
  '9': 'ALL',
  '11': 'TODAY',
  '12': 'ALL',
}
const STRATEGY_TYPE = {
  '1': '股票策略',
  '2': '宏观策略',
  '3': '管理期货',
  '4': '事件驱动',
  '5': '相对价值',
  '6': '固定收益',
  '7': '组合策略',
  '8': '复合策略',
}
// 个股信号，生成markpoint
function handleSingnalItem(list, times, prices, type) {
  const conbindValueTxt = (t, i1, p) => (t === 'buy' ? '买入' : '卖出') + i1 + '手' + ' / ' + p;
  const itemObj = {
    buy: { color: 'darkred', size: 60, value: 'combine' },
    sell: { color: 'darkgreen', size: 60, value: 'combine' },
    buyIndex: { color: 'red', size: 40, value: '买' },
    sellIndex: { color: 'green', size: 40, value: '卖' }
  }
  let finalSig = [];
  if (_.size(list) > 0) {
    list.map(item => {
      if (times.indexOf(item[0]) !== -1) {
        //const getPrice = prices[times.indexOf(item[0])]
        const getPrice = item[2] ?? 0; // 修改为用avgPrice去显示；【原】k线中收盘价去显示
        let obj = {};
        obj.xAxis = type === 'buyIndex' || type === 'sellIndex' ? item : item[0];
        obj.yAxis = getPrice;
        obj.value = itemObj[type]['value'] === 'combine' ? conbindValueTxt(type, item[1], getPrice) : itemObj[type]['value'];
        obj.symbolSize = itemObj[type]['size'];
        obj.itemStyle = { color: itemObj[type]['color'] };
        if (type === 'sell' || type === 'sellIndex') {
          obj.symbolRotate = 180;
        }
        finalSig.push(obj);
      }
    })
  }
  return finalSig;
}

//原在chart图表加载时处理的数据，提取出来，在获取结束后处理，返回 dataObj 对象；
//包含多种收益率曲线及其他数据的计算及markpoint的涨停标记信息；
const renderKeyArray = (keys) => {
  let final = [];
  keys.map(k => {
    final = _.concat(final, _.get(RATE_KEYS_OBJ, k))
  });
  return final
}
function creatMainSeriseData(datas, tabs) {
  const getAnayDatas = _.get(datas, 'rateAnalysisList', []);
  const getPointList = _.get(datas, 'pointList', []);
  const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
  const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
  const getLimits = _.get(datas, 'limitResult', []); // 涨停票统计
  const getTimes = _.get(datas, 'timeList', []);
  const isRealtime = tabs === 'TODAY' ? true : false;
  const keyArrayHeadZero = _.concat(['pointList', 'pointRetreat'], renderKeyArray(RATE_KEYS_OBJ.key_zero)); // 需要判断头部非0的keys
  const keyArrayNoLine = renderKeyArray(['rdKeys', 'tdKeys', 'weekLimit', 'art', 'mainbKeys']); // 处理tooltip里面不需要线条展示，但是需要在formater里面处理显示的数据
  const keyArrayShow = renderKeyArray(RATE_KEYS_OBJ.key_show);// 显示收益率曲线的keys
  const keyArrayNoMulti = _.concat(RATE_KEYS_OBJ.bs) //买入卖出 ; 不进行连乘运算
  const keyArrayPlus = RATE_KEYS_OBJ.plus; // 近两日和涨停不进行累成的key

  let dataObj = {}; // 返回的data,object
  RATE_KEYS_OBJ.all.map(item => { //创建对应key的空值
    dataObj[item.key] = [];
  });
  let retreat = []; // 回撤数据
  let points = _.size(getPointList) > 0 ? getPointList.map(n => renderFloats(n, 1)) : [];
  let pointsNet = calNets(points); // 收益率先计算净值，然后按照计算回撤
  pointsNet.map((n, i) => {
    let cur = 0;
    if (i > 0) {
      const beforeMax = _.max(_.slice(pointsNet, 0, i + 1));
      cur = beforeMax ? _.round(((n - beforeMax) / beforeMax) * 100, DEFAULT_CARRY) : 0;
    }
    retreat.push(cur);
  });
  let dataTime = {}; // 时间+收益率的数据
  getTimes.map((t, i) => {
    _.set(dataTime, t, points[i]);
  });
  dataObj.pointList = points;
  dataObj.other = [] // 非图形数据，包含数量 及 平均收益率
  dataObj.pointRetreat = retreat;
  dataObj.pointsNetList = pointNetList.map((p, i) => {
    // 每日收益计算，(当日-前一日)/前一日
    return _.round(parseFloat(calRateFormula(p, i === 0 ? preNetVal : pointNetList[i - 1])), DEFAULT_CARRY)
  });
  dataObj.dataTime = dataTime;
  //rateAnalysisList里面数据分类提取
  _.size(getAnayDatas) > 0 && getAnayDatas.map((anay, index) => {
    let item = { 'time': getTimes[index] };
    // 实时不进行累计收益率计算
    keyArrayShow.map(keyname => {
      const is_plus = _.includes(keyname, STR_PLUS);
      const newKeyname = is_plus ? _.replace(keyname, STR_PLUS, '') : keyname;
      let anaVal = _.get(anay, newKeyname);
      if (keyname === 'tDownRate' || keyname === 'tDownRatePlus') { //自定义字段： 今仓非涨停=今仓-今仓涨停
        anaVal = _.get(anay, 'tRate') - _.get(anay, 'tRiseRate');
      }
      let val = renderFloats(anaVal, isRealtime ? 100 : 1, 6); //曲线预算增加1位进位，保留更大精度
      dataObj[keyname].push(val);
      item[keyname] = isRealtime ? val : renderFloats(val, 100, 6); // 将重新计算原始值保存在other里面
    });
    // 不显示图形的
    keyArrayNoLine.map(keyname => {
      if (keyname.indexOf('Rate') !== -1) { //Rate 都需要进行*100 处理
        item[keyname] = renderFloats(anay[keyname], 100);
      } else { // 数量的数据
        item[keyname] = anay[keyname];
      }
    });
    // 不进行累成的
    keyArrayNoMulti.map(keyname => {
      let val = renderFloats(anay[keyname], 100);
      dataObj[keyname].push(val);
      item[keyname] = val;
    });
    // 自定义字段，前仓涨停=涨停-今天-昨仓 ;
    item['preNum'] = _.get(item, 'riseNum') - _.get(item, 'tRiseNum') - _.get(item, 'yRiseNum');
    // item['riseMinusDown'] = _.round(_.get(item, 'rise') + _.get(item, 'down'), 4);
    dataObj.other.push(item);
  });
  // 除实时收益率外，第一位数据默认为 0；
  keyArrayHeadZero.map(keyname => {
    if (_.head(dataObj[keyname]) !== 0 && !isRealtime) {
      dataObj[keyname].unshift(0);
    } else {
      if (tabs !== 'TODAY') { //【临时处理】 修复部分首位非0的bug
        dataObj[keyname].unshift(0);
      }
    }
  });
  if (!isRealtime) {
    Object.keys(dataObj).map(keyname => {
      //【累成计算】 不是实时数据需要重新进行计算，并且将结果 *100 处理
      if (keyArrayShow.indexOf(keyname) !== -1 && !_.includes(keyname, STR_PLUS)) {
        dataObj[keyname] = multiplication(dataObj[keyname]);
      };
      // 累加计算，plus字段计算
      if (keyArrayPlus.indexOf(keyname) !== -1) {
        dataObj[keyname] = accumulate(dataObj[keyname], 100);
      };
    });
  };
  // 涨停票markpoint，增加limitMarks字段数据
  let markpoint = []; let fullMarks = []; let yArray = [];
  if (_.size(getLimits) > 0) {
    let stockCal = {};
    getLimits.map((l, i) => {
      const x_time = _.get(l, 'xAxis', '');
      const y_points = renderFloats(getPointList[i], 1);
      let mindex = _.findIndex(markpoint, o => o.xAxis === x_time)
      let pushObj = {
        name: l.name + i, value: l.name, xAxis: l.time, yAxis: y_points + 0.2, date: l.tradeDate,
        symbol: '', symbolSize: 0, label: { color: 'red', show: true }, stock: l.name,
        industry: l.industry, plate: l.plate, symbol_x: l.symbol, timeKey: moment().diff(moment(x_time), 'm')
      }
      fullMarks.push(_.cloneDeep(pushObj));
      yArray.push(y_points);
      if (stockCal[x_time] && mindex !== -1) { // 多只 symbol(数量n) 
        stockCal[x_time] = stockCal[x_time] + 1;
        markpoint[mindex].value = markpoint[mindex].stock + `(${stockCal[x_time]})`
      } else { // 单只最后markpoint上只显示symbol名称
        stockCal[x_time] = 1;
        markpoint.push(pushObj);
      }
    });
  }
  dataObj.limitMarks = markpoint;
  dataObj.fullMarks = fullMarks;
  return dataObj
}
//————————————————————————————交易记录-tradeChart——————————————————————————————
let nFifty = [];
let pFifty = [];
for (let index = -50; index < 51; index++) { // 生成-50到-1 1到50的数组
  if (index < 0) {
    nFifty.push(index);
  } else if (index > 0) {
    pFifty.push(index)
  }
}
const RANGE_ARRAY = [-50, -40, -30, -20, -10, -5, -1, -0.5, 0, 0.5, 1, 5, 10, 20, 30, 40, 50]; //【统计】 收益率区间范围array
const RANGE_ARRAY_EX = [- 5, -4, -3, -2, - 1, -0.8, -0.6, -0.4, -0.2, -0.1, 0, 0.1, 0.2, 0.4, 0.6, 0.8, 1, 2, 3, 4, 5]; // 【超额】
const RANGE_ARRAY_PLUS = _.concat(nFifty, [-0.5, 0, 0.5], pFifty); // 【统计+】基础上切分成1%为分隔的数组
const NEW_RANGE_ARRAY = {
  'ex': RANGE_ARRAY_EX,
  'range': RANGE_ARRAY,
  'range_plus': RANGE_ARRAY_PLUS
}
// 根据收益率值返回区间的名称，并作为分组的唯一标识；
const xnamePro = (v, type = 'range') => {
  let nIndex = NEW_RANGE_ARRAY[type].indexOf(v);
  const xnameObj = {
    '0': '平盘',
    '0.5': '0% ~ 1%',// 该统计算法因 0 区间的特殊性，%1分类是特殊处理显示
    '-0.5': '-1% ~ 0%',
    '-0.1': '负.1',// 0.1 和 0.5是自定义1-0之间的统计数字，作用是在1-0之前增增加特殊运算标识
    '0.1': '正.1',
  }
  const xnameVals = {
    '0': 0,
    '0.5': 1,// 该统计算法因 0 区间的特殊性，%1分类是特殊处理显示
    '-0.5': -1,
    '-0.1': -1,// 0.1 和 0.5是自定义1-0之间的统计数字，作用是在1-0之前增增加特殊运算标识
    '0.1': 1,
  }
  if (xnameObj[v]) { // 处理特殊字段
    return { 'xname': xnameObj[v], 'value': xnameVals[v] };
  } else {
    if (type === 'range_plus') {
      return { 'xname': String(NEW_RANGE_ARRAY[type][nIndex]), 'value': NEW_RANGE_ARRAY[type][nIndex] }; // string类型才可以点击是匹配name字段
    }
    if (v < 0) { //如果不是最大和最小，显示区间信息； 
      return {
        'xname': nIndex === 0 ? `<${v}%` : `${NEW_RANGE_ARRAY[type][nIndex - 1]}% ~ ${v}%`,
        'value': nIndex === 0 ? v : NEW_RANGE_ARRAY[type][nIndex - 1],
      }
    } else if (v > 0) {
      return {
        'xname': nIndex === (_.size(NEW_RANGE_ARRAY[type]) - 1) ? `>${v}%` : `${v}% ~ ${NEW_RANGE_ARRAY[type][nIndex + 1]}%`,
        'value': nIndex === (_.size(NEW_RANGE_ARRAY[type]) - 1) ? v : NEW_RANGE_ARRAY[type][nIndex + 1]
      }
    }
  }
}
// 查找收益率在RANGE中的index值,【合并两种同类算法】; 返回{idx:对应RANGE中index值,name：每条对应的分组名称}
const findRangeIndex = (v, type = 'range') => {
  const top = _.last(NEW_RANGE_ARRAY[type]);
  const breakNum = { 'ex': 0.1, 'range': 0.5, 'range_plus': 0.5 }; //间隔字符，特殊处理0周边的区间数，进行正负处理
  const gapNum = { 'ex': 0.2, 'range': 1, 'range_plus': 1 }; // 间隔数值
  const findZeroIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === 0);
  const findnOneIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === -breakNum[type]); // nagetive间隔数
  const findpOneIndex = _.findIndex(NEW_RANGE_ARRAY[type], o => o === breakNum[type]); // positive间隔数
  let idx = 0;
  if (v === 0) {
    idx = findZeroIndex; // 0 进行直接返回index值
  } else if (v < 0) {
    if (v <= -top) { // 最小值，返回第一位
      idx = 0
    } else if (v > -gapNum[type]) {//0 附近的间隔数，范返回负间隔数
      idx = findnOneIndex;
    } else {
      for (let index = 0; index < _.size(NEW_RANGE_ARRAY[type]); index++) {
        if (v <= NEW_RANGE_ARRAY[type][index]) {
          idx = index;
          break;
        }
      }
    }
  } else if (v > 0) {
    if (v >= top) { // 最大值，返回最后一位
      idx = _.size(NEW_RANGE_ARRAY[type]) - 1
    } else if (v < gapNum[type]) { // 正间隔数
      idx = findpOneIndex;
    } else { // 正数可以反向循环，实现小于<开口向左，大于开口向右，实现相反的分组逻辑
      for (let index = _.size(NEW_RANGE_ARRAY[type]) - 1; index > 0; index--) {
        if (v >= NEW_RANGE_ARRAY[type][index]) {
          idx = index;
          break;
        }
      }
    }
  }
  return { idx, name: _.get(xnamePro(NEW_RANGE_ARRAY[type][idx], type), 'xname') }
};

const renderDiv = (a, b, isRate = false, carry = 2) => a && b ? _.round(a / b, carry) + (isRate ? '%' : '') : 0;
// 计算y轴部分超出的值，max和markpoint，因y轴默认取最大值，有的时候会顶头不显示，所以需要计算额外部分；
const renderExtraSize = (size, type) => {
  const breakArray = [10, 20, 30, 50, 100, 200]; // 对比size大小的数组
  const sizeObj = { 'range': [1, 2, 3, 5, 8, 10], 'range_plus': [1, 2, 3, 5, 8, 10], 'ex': [3, 6, 8, 10, 12, 20], 'max': [8, 10, 14, 18, 22, 28] }; // 分类，另外增加的数值；
  const maxObj = { 'range': 20, 'range_plus': 20, 'max': 30 }; // 最大值
  let extraVal = 0;
  if (size > 200) { // 超过200直接创建最大值
    extraVal = size + maxObj[type];
  } else { // 遍历，取第一个size小于的数值条件作为结果
    for (let index = 0; index < _.size(breakArray); index++) {
      if (size < breakArray[index]) {
        extraVal = size + sizeObj[type][index];
        break;
      }
    }
  }
  return extraVal;
}
// 计算正收益及超额部分
const calExArray = (array) => {
  let final = 0; let finalSize = 0;
  if (array && _.size(array) > 0) {
    array.map(n => {
      final = final + n['tRate'];
      finalSize = finalSize + n['tSize'];
    })
  }
  return { rate: final, size: finalSize }
}

const TRADE_OPTION = {
  bar: {
    title: {
      text: '持仓收益率统计',
      left: 'center',
      subtext: '单位: 支'
    },
    tooltip: { trigger: 'item' },
    xAxis: {
      type: 'category',
      data: [],
      axisLabel: { interval: 0, rotate: 50 },
      axisLine: { show: false }
    },
    yAxis: { type: 'value' },
    series: [
      {
        name: '统计',
        type: 'bar',
        label: { show: true },
        data: [],
        markPoint: { data: [] }
      }
    ]
  },
  line: {
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value', splitNumber: 3 },
    series: [{
      name: '收益率',
      type: 'line',
      symbol: 'circle',
      lineStyle: { color: '#ffbb00' },
      itemStyle: { color: '#ffbb00' },
      data: [],
      markPoint: { data: [] }
    }],
    tooltip: { trigger: 'axis' }
  }
}

// 计算收益率与指数数据时间不匹配时，进行裁剪数据; 返回response完整的data对象
function sliceUnmatchTimes(resPoint, resIndex) {
  let tempResponse = _.cloneDeep(resPoint);
  let tempIdxResponse = _.cloneDeep(resIndex);
  const pointTimes = _.get(resPoint, 'timeList', []);
  const indexTimes = _.get(resIndex, 'timeList', []);
  if (_.head(pointTimes) !== _.head(indexTimes)) { // 第一位时间不匹配进行处理
    if (_.size(pointTimes) > _.size(indexTimes)) { // 收益率时间 > 指数，已指数为基准对收益率数据进行裁剪
      let findex = pointTimes.indexOf(_.head(indexTimes)); // 指数第一位对应收益率的index值
      if (findex > 0) {
        Object.keys(tempResponse).map(keyname => {
          if (_.isArray(tempResponse[keyname])) { // 对对象里每个值是数组的数据进行裁剪，findex开始到最后一位
            tempResponse[keyname] = _.slice(tempResponse[keyname], findex, _.size(tempResponse[keyname]));
          }
        });
        // 对preNetValue收盘净值进行赋值，findex-1就是前一日的净值
        _.set(tempResponse, 'preNetValue', _.get(resPoint, `netValueList[${findex - 1}]`));
      }
    } else { // 指数同理，指数数据>收益率数据，已收益率为基准对指数进行裁剪
      let pindex = indexTimes.indexOf(_.head(pointTimes));
      if (pindex > 0) {
        Object.keys(tempIdxResponse).map(keyname => {
          if (_.isArray(tempIdxResponse[keyname])) {
            tempIdxResponse[keyname] = _.slice(tempIdxResponse[keyname], pindex, _.size(tempIdxResponse[keyname]));
          }
        });
        // 处理前一日收盘价preClose = pindex-1 指数值
        _.set(tempIdxResponse, 'preClose', _.get(resIndex, `pointList[${pindex - 1}]`));
      }
    }
  }
  return {
    'indexs': tempIdxResponse,
    'points': tempResponse
  };
}

const renderOrderStatus = (text) => {
  const color_type = { '完成': 'green', '未完成': 'red' };
  return <span style={{ color: color_type[text] ?? 'blue' }}>{text}</span>
}
// 获取持仓方法，
async function _getPositions(infos) {
  const { productId, subAccountId, isProduct } = infos;
  const MS_FORMAT = 'YY-MM-DD HH:mm:ss:SSS'; const DAY_FORMAT = 'YYYY-MM-DD';
  const TODAY = moment().format(DAY_FORMAT);
  const openMoment = moment(TODAY + ' 09:00:00');
  let final = [];
  try {
    const res = isProduct ? await getAllPosition({ productId }) : await showPositionReturn({ subAccountId });
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      let newData = []; let plateMap = new Map(); let count = 1;
      let plateSingleMap = new Map();
      newData = isValidArray(getData) ? getData.map(n => {
        const getPlate = _.get(n, 'plate', null);
        const plateArr = _.split(getPlate, ','); // 板块拆分为数组
        let newPlateKey = 0;
        if (getPlate && !plateMap.has(getPlate)) {
          plateMap.set(getPlate, count);
          newPlateKey = count;
          count++;
        } else if (getPlate && plateMap.has(getPlate)) {
          newPlateKey = plateMap.get(getPlate);
        };
        // 多个板块逗号拆分，map内不重复的所有板块
        if (isValidArray(plateArr)) {
          plateArr.map(a => {
            if (!plateSingleMap.has(a)) {
              plateSingleMap.set(a, count);
            }
          })
        }
        const buyDate_moment = moment(_.get(n, 'buyDate', ''));
        return {
          ...n,
          'plateKey': newPlateKey,
          'plate_arr': plateArr,
          'formatDate': buyDate_moment.format(MS_FORMAT),
          'totalRevenue': _.round(_.get(n, 'totalRevenue', 0) * 100, 2),
          'formatDate_day': buyDate_moment.format(DAY_FORMAT), // 区分天
          'orderKey': openMoment.diff(buyDate_moment, 'millisecond'), // 排序用
          'speedIncrease': _.get(n, 'speedIncrease', 0) ?? 0,
        }
      }) : [];
      final = newData;
    }
  } catch (error) {
    message.info('获取持仓失败！');
    console.error(error);
  }
  return final;
}
// ALL = 成立以来，数据过多或者三年数据还没有，统一执行裁剪
const unmatch_types = ['ALL', 'THREE_YEAR'];
// 产品子账户处理指数及历史收益率返回数据；
function handleHistoryRate(res, resIdx, dateType) {
  let indexDatas = _.get(resIdx, 'data', {});
  let newTimeList = _.get(res, 'data.timeList', []);
  let newPoints = _.get(res, 'data', {});
  if (_.includes(unmatch_types, dateType)) { // 遇到ALL和三年长数，进行裁剪
    const newRes = sliceUnmatchTimes(newPoints, indexDatas);  // 传入收益率和指数的response.data对象
    newTimeList = _.get(newRes, 'points.timeList', []);// 重新赋值时间、收益率数据
    newPoints = _.get(newRes, 'points', {});
    indexDatas = _.get(newRes, 'indexs', {});
  }
  return {
    'timeList': newTimeList,
    'datas': newPoints,
    'newData': creatMainSeriseData(newPoints, dateType),
    'indexVal': indexDatas
  }
};
// 查找交易日期，返回实时或者其他周期
function initDateType(trades = [], defalut = '1') {
  const today = moment().format('YYYY-MM-DD');
  let dindex = _.findIndex(trades, o => o.date === today);
  return {
    'type': dindex !== -1 ? DATE_TYPE[defalut] : DATE_TYPE['2'],
    'tab': dindex !== -1 ? defalut : '2',
    'isTradeDay': dindex !== -1 ? true : false,
  }
}
// 两日持仓
async function _showPositionSortFunc(id, date, isProduct, isRev) {
  let params = { date };
  let isValidId = false;
  let sorts = [];
  _.set(params, isProduct ? 'productId' : 'subAccountId', id);
  isValidId = isValidNumber(id);
  if (!isValidId) {
    return
  }
  const res = await showPositionSort(params, isRev ? 'FC' : 'NOR')
  if (_.get(res, 'code', '') === '200') {
    sorts = _.get(res, 'data', {});
  };
  return sorts;
};

export {
  // 静态
  DATE_TYPE, STRATEGY_TYPE,
  TRADE_OPTION,
  NEW_RANGE_ARRAY,
  // 方法
  renderDiv,
  renderExtraSize,
  calExArray,
  findRangeIndex,
  xnamePro,
  handleSingnalItem,
  multiplication,
  renderOrderStatus,
  handleHistoryRate,
  initDateType,
  // 接口获取
  _getPositions,
  _showPositionSortFunc
}
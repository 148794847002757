import React from 'react';
import { Col, Row, Space } from 'antd';
import { autoCol } from '@/utils/utils';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';

const renderRound = (val, pos = 2) => val ? _.round(parseFloat(val), pos) : 0;
const AssetsHeader = ({ assets, pmData }) => {
  return <Row gutter={[8, 8]}>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'产品总资产'} value={_.get(assets, 'totalAssets', '')} isColor />
          <StatisticHeader title={'产品净值'} value={renderRound(_.get(pmData, 'netValue'), 4)} isColor />
        </Space>
      </div>
    </Col>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'总收益'} value={_.get(assets, 'totalRevenue', '')} isColor />
          <StatisticHeader title={'总收益率'} value={renderRound(_.get(assets, 'totalRevenueRate'), 2) + '%'} isColor isPrefix />
        </Space>
      </div>
    </Col>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'日收益'} value={_.get(assets, 'dailyRevenue', '')} isColor />
          <StatisticHeader title={'日收益率'} value={renderRound(_.get(assets, 'dailyRevenueRate'), 2) + '%'} isColor isPrefix />
        </Space>
      </div>
    </Col>
  </Row>
}

export default AssetsHeader;
import React from 'react';
import {
  Tabs, Row, Col, Select, Input, Space, Slider, Collapse, Table, Typography, Modal, message,
  Descriptions, Segmented, Button, Form, InputNumber, Tooltip, Switch, Divider
} from 'antd';
import { CaretRightOutlined, RollbackOutlined, SyncOutlined } from '@ant-design/icons';
import { createBasicColums, RateText } from '@/utils/utils';
import { isValidArray, isValidString, labelValues, simpleColumns } from '@/utils/utils2';
import { TAB_LIST, LIMIT_COL_LIST, PRE_DAY_CN } from './chartsInfoUtils';
import { INDEX_OPTIONS, PLATE_OPTIONS } from '@/utils/indexCode';
import { useReactive } from 'ahooks';
import _ from 'lodash';

const { Text, Paragraph, Title } = Typography;
const { TextArea } = Input;
// 指数选择
const NO_SHOW_TODAY = ['866001', '866006', '000985'];
const IndexSelect = ({ active = '', idv = null, size = 'small', onIndexChange }) => {
  const newIndexOptions = (active === 'TODAY' || active === '1') ? INDEX_OPTIONS.map(n => {
    let newItem = _.cloneDeep(n);
    if (_.includes(NO_SHOW_TODAY, n.value)) {
      _.set(newItem, 'disabled', true);
    }
    return newItem;
  }) : INDEX_OPTIONS;
  return <Select
    size={size}
    value={idv}
    style={{ width: size === 'normal' ? 160 : 120 }}
    onChange={onIndexChange}
    options={newIndexOptions}
  />
}
// 切换收益率时间周期组件
const MainRangeTabs = ({
  active = '',
  idv = '',
  plateVal = '',
  isManager = false,
  years = [],

  onTabChange,
  onIndexChange,
  onPlateChange
}) => {
  const tabComp = _.concat(TAB_LIST, years).map(n => <Tabs.TabPane key={n.key} tab={n.tab} />);
  const tabRightComp = <Space align='center'>
    {isManager && <Select
      placeholder='板块'
      bordered={false}
      size='small'
      mode='multiple'
      options={PLATE_OPTIONS}
      style={{ width: 175 }}
      value={plateVal}
      onChange={onPlateChange}
    />}
    <IndexSelect active={active} idv={idv} onIndexChange={onIndexChange} />
  </Space>;

  return <Row style={{ padding: '0px 4px' }}>
    <Col span={24}>
      <Tabs
        activeKey={active}
        onChange={onTabChange}
        tabPosition='top'
        size='small'
        tabBarExtraContent={{ right: tabRightComp }}
      >
        {tabComp}
      </Tabs>
    </Col>
  </Row>
}
// 滑动时间组件
const MainSlider = ({
  isNotToday = false,
  timeNameArray = [], // 名称数组
  timeArray = [], // index值数组
  sDisable = false,
  value = 0, // slider的值
  svalue = 0, // slider index的值
  empComp = <></>,
  isFull = false,

  onSliderChange,
  onSliderAfterChange,
  onReset
}) => {
  const formatter = (value) => timeNameArray[value] || 'none';
  const firstdate = _.get(timeNameArray, `[1]`, '');
  const start = _.get(timeNameArray, `[${value[0]}]`, '');
  const end = _.get(timeNameArray, `[${value[1]}]`, '');
  const max = _.last(timeArray) || 0;
  const textProps = { strong: true, style: { fontSize: 13 } };
  // start会等于前一交易日，所以符合sliderValue=0及中文相同，则显示数组1位的有效日期
  if (isNotToday) {
    return (
      <Row align='middle'>
        <Col span={6}>
          <Text {...textProps}>开始日期: {value[0] === 0 && start === PRE_DAY_CN ? firstdate : start}</Text>
          <div style={{ height: 15, width: 15, cursor: 'pointer', marginRight: 4, float: 'right' }} onClick={onReset}>
            {isFull ? <></> : <RollbackOutlined style={{ color: '#9f9f9f' }} />}
          </div>
        </Col>
        <Col span={12}>
          <Slider
            min={0}
            max={max}
            disabled={sDisable}
            range={{ draggableTrack: true }}
            tipFormatter={formatter}
            value={svalue}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Text {...textProps}>结束日期: {end}</Text>
        </Col>
      </Row>
    )
  } else {
    return empComp
  }
}

const MainLimitTable = ({ datas = [] }) => {
  const columns = [...createBasicColums(LIMIT_COL_LIST, [1, 'strong'])];

  return isValidArray(datas) ? <>
    <br />
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="site-collapse-custom-collapse"
    >
      <Collapse.Panel header="涨停票" key="1" className="site-collapse-custom-panel">
        <Table
          rowKey="name"
          dataSource={datas}
          columns={columns}
          bordered
          size='small'
          pagination={{ defaultPageSize: 5 }}
        />
      </Collapse.Panel>
    </Collapse>
  </> : <></>
}
// 图表切换竖向tab 组件
const ChartTabs = ({
  active = '',
  options = [],
  onTabChange
}) => {
  function renderLabel(label) {
    return _.size(label) > 2 ? label.substring(0, 2) + '...' : label;
  };

  return <div className='aidxContent'>
    {options.map(n => <div key={n.value}
      className={active === n.value ? 'aidxTab-active' : 'aidxTab'}
      onClick={() => {
        if (active !== n.value) {
          onTabChange(n.value);
        };
      }}
    >
      <Tooltip title={n.label} placement='right'>
        {renderLabel(n.label)}
      </Tooltip>
    </div>)}
  </div>;
};
// 笔记modal组件
const NoteModal = ({ isShow, validDate, onCancel, onConfirm }) => {
  const modalState = useReactive({ note: '', date: '' });

  // useEffect(() => {
  //   console.log('modal打开')
  // }, [isShow]);

  const newDateOptions = isValidArray(validDate) ? validDate.map(n => labelValues([n, n])) : [];

  return <Modal
    visible={isShow}
    width={780}
    maskClosable={false}
    title={'笔记内容'}
    onCancel={() => {
      onCancel();
      modalState.date = '';
      modalState.note = '';
    }}
    onOk={() => {
      if (!isValidString(modalState.date)) {
        message.info('请选择备注日期！');
        return
      }
      if (!isValidString(modalState.note)) {
        message.info('请填写备注内容！');
        return
      }
      onConfirm({
        date: modalState.date,
        remark: modalState.note,
      });
    }}
  >
    <Select
      placeholder='日期' style={{ width: '100%', marginBottom: 16 }} options={newDateOptions}
      value={modalState.date}
      onChange={v => modalState.date = v}
    />
    <TextArea
      showCount
      maxLength={100}
      value={modalState.note}
      style={{ height: 120, marginBottom: 12 }}
      onChange={(e) => modalState.note = e.target.value}
      placeholder="填写内容"
    />
  </Modal>
}
// 月度超额统计; 新增周及区间信息的显示
const EXTRA_OPTIONS = [labelValues(['区间', 'ranges']), labelValues(['季度', 'season']), labelValues(['月度', 'months']), labelValues(['周度', 'weeks'])];
const CEXTRA = { 'day': '日', 'weeks': '周', 'months': '月', 'season': '季' };
const ExtraMonthTable = ({
  isShow = false,
  isExtra = false,
  isOverlap = false,
  noExtra = false,
  extras = {},
  chrtabSize = 0,

  onTypeChange,
  onExtraChange,
  onOverlapChange
}) => {
  const extraState = useReactive({ type: 'ranges' });
  const newExtra = _.get(extras, extraState.type, {});
  // 对ctype的key进行处理，与数据字段相同；
  const newCtype = extraState.type === 'ranges' ? 'day' : extraState.type;
  const otherRateNum = chrtabSize - 3;
  return isShow ? <>
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <Row >
          <Col span={16}>
            <Space size='small'>
              <Segmented
                size='small'
                options={EXTRA_OPTIONS}
                value={extraState.type}
                onChange={v => {
                  extraState.type = v;
                  onTypeChange('type', v);
                }}
              />
              <Divider type='vertical' />
              {!noExtra && <Switch
                checkedChildren="超额"
                unCheckedChildren="超额"
                checked={isExtra}
                onChange={onExtraChange}
              />}
            </Space>
          </Col>
          {extraState.type !== 'ranges' && !noExtra && <Col span={8} style={{ textAlign: 'right' }}>
            <Text type='secondary'>其他收益率: {otherRateNum} 个</Text>
            <Divider type='vertical' />
            <Switch
              disabled={otherRateNum < 2}
              checkedChildren="叠加"
              unCheckedChildren="叠加"
              checked={isOverlap}
              onChange={onOverlapChange}
            />
          </Col>}
        </Row>
      </Col>
      <Col span={16}>
        <div style={{ maxHeight: 345, overflowY: 'auto' }}>
          <Descriptions size='small' bordered column={3}>
            {_.keys(newExtra).map((keyname, i) => {
              let finalLabel = '';
              if (extraState.type === 'month') {
                const split_month = _.split(keyname, '-');
                const year = split_month[0]; const mon = parseInt(split_month[1]);
                finalLabel = mon === 1 ? `${year}-${mon}月` : `${mon}月`;
              } else {
                finalLabel = keyname;
              }
              return <Descriptions.Item key={'mon' + i} label={finalLabel}>
                {extraState.type === 'ranges' ?
                  <Text>{newExtra[keyname]}</Text>
                  : <RateText rate={newExtra[keyname]} carry={2} />}
              </Descriptions.Item>
            })}
          </Descriptions>
        </div>
      </Col>
      <Col span={8}>
        <Descriptions size='small' bordered column={1}>
          <Descriptions.Item label={'区间'}>
            <Text strong>{_.get(extras, `cal.${newCtype}.size`, '')}</Text>
            <Text>个交易{CEXTRA[newCtype]}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={'平均'}>
            <RateText rate={_.get(extras, `cal.${newCtype}.avg`, '')} carry={4} />
          </Descriptions.Item>
          <Descriptions.Item label={'胜率'}>
            <RateText rate={_.get(extras, `cal.${newCtype}.psize`, '')} carry={2} />
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  </> : <></>
};
// 今仓昨仓统计drawer ; 【bug】<Drawer> 组件无法使用，open=true无弹出层
const positionKeys = ['今仓', '昨仓', '前仓'];
const PositionDrawer = ({ visible = false, datas = {}, onClose }) => {
  return <Modal
    title={null} width={820} style={{ top: 20 }} footer={null}
    mask={false} maskClosable={false} bodyStyle={{ height: 350, overflowY: 'auto' }}
    visible={visible}
    onCancel={onClose}
  >
    <Typography>
      {positionKeys.map(n => <Paragraph key={'para' + n}>
        <Title level={5}>{n}</Title>
        {isValidArray(_.get(datas, n, [])) ? _.get(datas, n, []).map((stock, i) => <Text key={stock + i} keyboard>{stock}</Text>) : '无'}
      </Paragraph>)}
    </Typography>
  </Modal>
}

// 持仓概况
const BriefTable = ({ show = false, loading = false, datas = [], onSync }) => {
  const columns = [
    simpleColumns(['股票', 'stockName']),
    {
      ...simpleColumns(['涨跌幅', 'pctChg']),
      sorter: (a, b) => a.pctChg - b.pctChg,
      render: (text) => <RateText rate={text} />
    },
    {
      ...simpleColumns(['收益率', 'totalRevenue']),
      sorter: (a, b) => a.totalRevenue - b.totalRevenue,
      render: (text) => <RateText rate={text} />
    },
    {
      ...simpleColumns(['持仓比', 'totalMarketRate']),
      sorter: (a, b) => a.totalMarketRate - b.totalMarketRate,
      render: (text) => _.round(text, 2) + '%'
    },
  ];

  return show ? <Table
    rowKey="stockName"
    dataSource={datas}
    columns={columns}
    loading={loading}
    footer={() => <Row>
      <Col span={12}><Text strong>
        仓位比例:
        {isValidArray(datas) ? _.chain(datas).map(n => n.totalMarketRate).sum().round(2).value() : '0'}
        %
      </Text></Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button
          icon={<SyncOutlined />} type='text' size='small'
          onClick={onSync}
        />
      </Col>
    </Row>}
    bordered={false}
    scroll={{ y: 345 }}
    size='small'
    pagination={false}
  /> : <></>
};

// 渲染FormItem的props内容
const message_type = { 1: '输入', 2: '选择' };
function renderFormProps(name, key, isRequired, type) {
  let newProps = { label: name, name: key }
  if (isRequired) {
    _.set(newProps, 'rules', [{ required: true, message: `请${message_type[type]}正确的${name}` }]);
  }
  return newProps;
}

// 收益率实际减去差异的配置form
const RateDiffModal = ({
  isShow = false,

  onCancel,
  onConfirm
}) => {
  const [formDiff] = Form.useForm();

  return <Modal
    visible={isShow}
    width={710}
    maskClosable={false}
    onCancel={onCancel}
    onOk={() => {
      formDiff
        .validateFields()
        .then((values) => {
          //console.log(values);
          onConfirm(values);
        })
        .catch((info) => { console.log('Validate Failed:', info) });
    }}
  >
    <div style={{ height: 35 }}></div>
    <Form
      form={formDiff}
      layout="horizontal"
      name="modal_diff_rate"
      initialValues={{ 'diff': 0.2 }}
    >
      <Form.Item {...renderFormProps('差异比例', 'diff', true, 1)}>
        <InputNumber step={0.1} min={0} addonAfter="%" />
      </Form.Item>
    </Form>
  </Modal>
}


export {
  MainRangeTabs,
  MainSlider,
  MainLimitTable,
  ChartTabs,
  IndexSelect,
  NoteModal,
  ExtraMonthTable,
  PositionDrawer,
  BriefTable,
  RateDiffModal
}
import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';

export default function EchartsInfo9(props) {

    const [option, setoption] = useState({
        xAxis: [{}],
        yAxis: [{}],
        series: [
            {
                name: '收益率',
                data: [],
                type: 'line',
                symbol: 'none',
                smooth: true,
                yAxisIndex: 0,
            }
        ],
        tooltip: {
            show:true,
            trigger:'axis',	
            axisPointer: {
                type: 'cross',
                lineStyle: {
                    type : 'dashed',
                    width : 1
                }
            },
            padding:5,
        },
        legend: {
            type: 'scroll',
            data: ['收益率'],
            selectedMode:'multiple',
        },
    });

    useEffect(() => {
        let myChart = props.myChart;
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose();//销毁
        }

        myChart = echarts.init(document.getElementById('echart9'));
        myChart.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });

        let newOption = option;
        if (props.chartsXData9) {
            if(props.hasBar === true){
                newOption.xAxis = [
                    {
                        data: props.chartsXData9,
                        type:'category',							
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                          show:false
                        },
                        axisLine:{
                            onZero:false,
                        },
                        axisLable:{
                            hideOverLap:true,
                        }
                    }
                ];
                newOption.yAxis = [
                    {
                        type:'value',
                        name:'权益',
                        position: 'left',
                        axisLine:{
                            show:true, 
                            symbol:['none', 'arrow'],
                            symbolSize:[8, 8],
                            symbolOffset:7,
                        },
                        splitArea: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        // min:0,
                        // max:1,
                    },
                    {
                        type:'value',
                        name:'资金使用率',
                        position: 'right',
                        axisLine:{
                            show:true, 
                            symbol:['none', 'arrow'],
                            symbolSize:[8, 8],
                            symbolOffset:7,
                        },
                        splitArea: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            formatter: '{value} %'
                        },
                        min: 0,
                        max: 100,
                        interval: 10, 
                    }
                ];
                newOption.series= [
                    {
                        name:'权益曲线',
                        type: 'bar',
                        data: props.barData
                    },
                    {
                        name:'资金使用率',
                        type: 'line',
                        data: props.lineData,
                        yAxisIndex: 1,
                        // symbol: 'none',
                    },
                ];
                // newOption.legend.data=['权益曲线','资金使用率'];
            }else{
                newOption.xAxis = [
                    {
                        data: props.chartsXData9,
                        type: 'category',
                        splitLine: {
                            show: false
                        },
                        boundaryGap: false,
                        axisTick: {
                            show:false
                        },
                        axisLine:{
                            onZero:false,
                        },
                        axisLable:{
                            hideOverLap:true,
                        }
                    }
                ];
                newOption.yAxis = [
                    {
                        type:'value',
                        name:'收益率(%)',
                        position: 'left',
                        axisLine:{
                            show:true, 
                            symbol:['none', 'arrow'],
                            symbolSize:[8, 8],
                            symbolOffset:7,
                        },
                        splitArea: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        },
                        // min:0,
                        // max:1,
                    },
                ];
                newOption.series= [
                    {
                        name: '收益率',
                        data: props.chartsData9Left,
                        type: 'line',
                        symbol: 'none',
                        smooth: true,
                        yAxisIndex: 0,
                    }
                ];
                // newOption.legend.data=['权益曲线','资金使用率'];
            }
            // newOption.yAxis[0].min = Math.floor(Math.min.apply(Math,props.chartsData9Left)*0.99);
            // newOption.yAxis[0].max = Math.ceil(Math.max.apply(Math,props.chartsData9Left)*1.01);
        }
        setoption(newOption);
        myChart.setOption(newOption, true);
        myChart.hideLoading();
        myChart.resize();
    }, [props, option])
   

    return (
        <div
            id="echart9"
            style={{
                width: '100%',
                height: props.height ? props.height : 600
            }}
        />
    )
}
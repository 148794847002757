import React from 'react';
import { Col, Row } from 'antd';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';

const TotalComp = ({ accountData }) => {
    const container_col = { xxl: 4, xl: 4, lg: 4, md: 8, sm: 12, flex: 'auto' };
    const renderDaliyRate = _.round(parseFloat(_.get(accountData, 'dailyRevenueRate', 0)), 2) + '%'
    return (
        <div className='assetCard'>
            <Row gutter={[24, 16]} >
                <Col {...container_col}>
                    <StatisticHeader title={'总资产'} value={_.get(accountData, 'totalAssets', '')} />
                </Col>
                <Col {...container_col}>
                    <StatisticHeader title={'总市值'} value={_.get(accountData, 'positionsAssets', '')} />
                </Col>
                <Col {...container_col}>
                    <StatisticHeader title={'可用资金'} value={_.get(accountData, 'totalBalance', '')} />
                </Col>
                <Col {...container_col}>
                    <StatisticHeader title={'总收益'} value={_.get(accountData, 'totalRevenue', '')} isColor />
                </Col>
                <Col {...container_col}>
                    <StatisticHeader title={'日收益'} value={_.get(accountData, 'dailyRevenue', '')} isColor isPrefix />
                </Col>
                <Col {...container_col}>
                    <StatisticHeader title={'日收益率'} value={renderDaliyRate} isColor isPrefix />
                </Col>
            </Row>
        </div>
    )
}

export default TotalComp;
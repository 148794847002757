import React, { useState } from 'react';
import {
  Col, Row, List, Slider, Button, Input, Modal, message, InputNumber, Tooltip, Typography,
  Cascader, Space, Card, Descriptions, Segmented
} from 'antd';
import { getTransBalanceFlow, postCashTransfer } from '@/api/workbench';
import { useMount } from 'ahooks';
import _ from 'lodash';

const { Text } = Typography;

const FundTransfer = ({ options, onListUpdate }) => {
  const [cascaderValue, setcascaderValue] = useState([]);
  const [fundList, setFundList] = useState([]);
  const [sliderValue, setsliderValue] = useState(0); // 进度条
  const [sliderValueBottom, setsliderValueBottom] = useState(0); // 进度条
  const [sliderFunds, setsliderFunds] = useState(0); // 资金划转证券账户总资产
  const [sliderSubAccount, setsliderSubAccount] = useState(0); // 子账户总资产
  const [firstValue, setfirstValue] = useState(0);
  const [secondValue, setsecondValue] = useState(0);
  const [casItems, setCasItems] = useState([]);

  useMount(() => {
    _getInfo();
  });

  async function _getInfo(id) {
    const res = await getTransBalanceFlow({ subAccountId: id ? id : null });
    setFundList(_.get(res, 'data', []));
  }

  async function handleChange(value) {
    if (value.length === 0) {
      return;
    }
    if (value.length === 3) {
      // 处理资金划转功能
      let casVal = findCascaderValue(value);
      setsliderValue(0);
      setsliderValueBottom(0);
      setsliderFunds(_.get(casVal, '[1].securitiesBalance', 0));
      setsliderSubAccount(_.get(casVal, '[2].subAccountBalance', 0));
      setCasItems(casVal);
      _getInfo(_.get(casVal, '[2].subAccountId', 0));
    } else {
      message.error('该证券账户下暂无子账户！');
    }
  }

  //子账户划转到证券账户
  async function _assetsTransferBottom() {
    if (_.size(casItems) !== 3) {
      message.info('请选择正确账户！');
      return
    }
    // 要划转的资金总量 产品 证券账户 子账户
    let num = secondValue;
    let product = _.get(casItems, '[0]', {});
    let account = _.get(casItems, '[1]', {});
    let subAccount = _.get(casItems, '[2]', {});
    if (num === 0) return message.error('交易资金量不能为0');
    Modal.confirm({
      title: '是否执行划转资金量的操作！',
      content: `${subAccount.subAccountName} -- ${account.securitiesAccountName} 划转资金 ${num}`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        let params = {
          productId: product.productId,
          securitiesAccountId: account.securitiesAccountId,
          subAccountId: subAccount.subAccountId,
          balance: num,
          direction: 1
        }
        const data = await postCashTransfer(params);
        if (data.code === '200') {
          onListUpdate();
          setcascaderValue([]);
          setsliderFunds(0);
          setsliderSubAccount(0);
          message.success('划转成功');
        } else {
          message.error(data.message);
        }
      }
    });

  }

  // 点击确认资金划转
  async function _assetsTransfer() {
    if (_.size(casItems) !== 3) {
      message.info('请选择正确账户！');
      return
    }
    // 要划转的资金总量 产品 证券账户 子账户
    let num = firstValue;
    let product = _.get(casItems, '[0]', {});
    let account = _.get(casItems, '[1]', {});
    let subAccount = _.get(casItems, '[2]', {});
    if (num === 0) return message.error('交易资金量不能为0');
    Modal.confirm({
      title: '是否执行划转资金量的操作！',
      content: `${account.securitiesAccountName} -- ${subAccount.subAccountName} 划转资金 ${num}`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        let params = {
          productId: product.productId,
          securitiesAccountId: account.securitiesAccountId,
          subAccountId: subAccount.subAccountId,
          balance: num,
          direction: '2'
        }
        const data = await postCashTransfer(params);
        if (data.code === '200') {
          onListUpdate();
          setcascaderValue([]);
          setsliderFunds(0);
          setsliderSubAccount(0);
          message.success('划转成功');
        } else {
          message.error(data.message);
        }
      }
    });
  }
  // 根据varray返回的层级数，保存每一层选择的对象，返回size=3的数据
  const valuekeys = ['productId', 'securitiesAccountId', 'subAccountId'];
  function findCascaderValue(varray) {
    const fastIndex = (array, key, val) => {
      let fidx = _.findIndex(array, o => o[key] === val);
      return array[fidx];
    };
    let final = [{}, {}, {}]
    let counter = 0;
    varray.map(v => {
      final[counter] = fastIndex(
        counter === 0 ? options : _.get(final, `[${counter - 1}].children`, []),
        valuekeys[counter],
        v
      );
      counter++;
    });
    return final;
  }

  return (
    <>
      <Row style={{ marginBottom: 8 }} align='middle'>
        <Col span={14}>
          <div style={{ backgroundColor: '#f5f5f5', padding: 4, borderRadius: 4, width: 365 }}>
            <Cascader
              placeholder='账户选择'
              bordered={false}
              options={options}
              value={cascaderValue}
              onChange={(v) => {
                handleChange(v);
                setcascaderValue(v);
              }}
              style={{ width: 365 }}
            />
          </div>
        </Col>
        <Col span={10} style={{ textAlign: 'center' }}>
          {_.size(casItems) === 3
            ? <Segmented defaultValue={_.get(casItems, '[2].label')} options={[_.get(casItems, '[2].label'), '全部消息']} onChange={v => {
              _getInfo(v === '全部消息' ? null : _.get(casItems, '[2].value'));
            }} />
            : <Text>全部消息</Text>}
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginBottom: 26 }}>
        <Col span={14}>
          <Card bodyStyle={{ padding: '16px 12px 16px 12px' }} style={{ marginBottom: 16 }}>
            <Descriptions title='证券账户到子账户' bordered column={2}>
              <Descriptions.Item label="证券账户总资金" span={2}>
                <Text style={{ marginLeft: 5, marginRight: 5, color: 'green' }} strong>
                  {_.round(Math.floor(sliderFunds * 10000) / 10000, 2)}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="子账户划转前">
                <Text strong>{_.round(sliderSubAccount, 2)}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="子账户划转后">
                <Text strong>{_.round(sliderSubAccount + (sliderFunds * (sliderValue / 100)), 2)}</Text>
              </Descriptions.Item>
            </Descriptions>
            <div style={{ padding: 6, backgroundColor: '#f5f5f5', borderRadius: 4, marginTop: 12 }}>
              <Row align='middle' justify='center'>
                <Col span={6} style={{ textAlign: 'center' }}><Text>划转资金:</Text></Col>
                <Col span={12}>
                  <Space>
                    <Slider
                      style={{ width: 200 }}
                      onChange={(value) => { setsliderValue(value); setfirstValue(sliderFunds * (value / 100)) }}
                      value={typeof sliderValue === 'number' ? sliderValue : 0}
                    />
                    <span>{sliderValue + '%'}</span>
                  </Space>
                </Col>
                <Col span={6}>
                  <Tooltip title={firstValue}>
                    <InputNumber min={0} value={firstValue} onChange={e => { setfirstValue(e) }} />
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: 'center', marginTop: 22 }}>
              <Button type="primary" onClick={_assetsTransfer}>提交</Button>
            </div>
          </Card>

          <Card bodyStyle={{ padding: '16px 12px 16px 12px' }}>
            <Descriptions title='子账户到证券账户' bordered column={2}>
              <Descriptions.Item label="证券账户总资金" span={2}>
                <Text style={{ marginLeft: 5, marginRight: 5, color: 'green' }} strong>{sliderSubAccount}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="证券划转前">
                <Text strong>{_.round(sliderFunds, 2)}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="证券划转后">
                <Text strong>{_.round(sliderFunds + (sliderSubAccount * (sliderValueBottom / 100)), 2)}</Text>
              </Descriptions.Item>
            </Descriptions>
            <div style={{ padding: 6, backgroundColor: '#f5f5f5', borderRadius: 4, marginTop: 12 }}>
              <Row align='middle'>
                <Col span={6} style={{ textAlign: 'center' }}><Text>划转资金:</Text></Col>
                <Col span={12}>
                  <Space>
                    <Slider
                      style={{ width: 200 }}
                      onChange={(value) => { setsliderValueBottom(value); setsecondValue(sliderSubAccount * (value / 100)) }}
                      value={typeof sliderValueBottom === 'number' ? sliderValueBottom : 0}
                    />
                    <span>{sliderValueBottom + '%'}</span>
                  </Space>
                </Col>
                <Col span={6}>
                  <Tooltip title={secondValue}>
                    <InputNumber min={0} value={secondValue} onChange={e => { setsecondValue(e) }} />
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <div style={{ textAlign: 'center', marginTop: 22 }}>
              <Button type="primary" onClick={_assetsTransferBottom}>提交</Button>
            </div>
          </Card>
        </Col>
        <Col span={10}>
          <List
            itemLayout="horizontal"
            dataSource={fundList}
            size='small'
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
            }}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  description={<Input.TextArea
                    bordered={false}
                    value={item}
                    autoSize={true}
                  />}
                />
              </List.Item>
            )}
          />
        </Col>

      </Row>
    </>
  )
}

export default FundTransfer;
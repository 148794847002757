import React, { Component } from 'react';
import {
    Row,
    Col,
    Space,
    Segmented,
    PageHeader,
    Button,
    Typography,
    Cascader,
    Spin,
    Tabs,
    message,
    Divider
} from 'antd';
import { FormOutlined, ReloadOutlined, SaveOutlined, AccountBookOutlined } from '@ant-design/icons'
import { observer, inject } from 'mobx-react';
import { getProductDetailss } from '@/api/details';
import { getfundManagerAssets } from '@/api/workbench';
import { backtestAll, backtestSingle, showOption } from '@/api/strategy';
import { autoCol } from '@/utils/utils';
import SingleList from './Components/SingleList';
import BackTable from './Components/BackTable';
// import BackCharts from './Components/BackCharts';
import BackParams from './Components/BackParams';
import _ from 'lodash';
import './testback.scss'

const { Text, Title } = Typography;
const SEG_OPTION = [{ label: '账户', value: 1, icon: <AccountBookOutlined /> }, { label: '已存', value: 2, icon: <SaveOutlined /> }];
@inject('store')
@observer
class Testback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: [],
            subId: 0,
            pageInfo: { page: 1, total: 0 },
            backList: [],
            listLoading: false,
            backTests: [],
            formTest: {},
            dLoading: false,
            tabKey: 'record',
            updateCount: 0,
            currentItem: {},
            segValue: SEG_OPTION[0].value,
            optionList: [],
        }
        this.productss = JSON.parse(JSON.stringify(this.props.store.products))
    }

    componentDidMount() {
        // 全局产品数据；空获取，有则直接初始option
        if (!this.productss || _.size(this.productss) === 0) {
            this._getfundManagerAssets();
        } else {
            this.initOptions(this.productss);
        }

        this._showOption();
        // 返回如有备份数据直接赋值state
        const getBackup = JSON.parse(JSON.stringify(this.props.store.backTestBackup));
        // console.log('跳转备份', getBackup);
        if (_.size(getBackup) > 0) {
            this.setState({
                backList: _.get(getBackup, 'bkl', []),
                pageInfo: _.get(getBackup, 'pgi', {}),
                backTests: _.get(getBackup, 'bkt', []),
                subId: _.get(getBackup, 'sid', 0),
                currentItem: _.get(getBackup, 'curr', {}),
                updateCount: this.state.updateCount + 1
            }, () => {
                this._backtestAll();
            })
        }
    }

    // componentDidUpdate(prevProps) {
    // }
    _getfundManagerAssets = async () => {
        const data = await getfundManagerAssets();
        if (_.get(data, 'code', '') === '200') {
            if (data.data !== null && data.data.currentProductResultList.length !== 0) {
                data.data.currentProductResultList.map(item => {
                    item.totalRevenueRate = parseFloat(item.totalRevenueRate).toFixed(2) + '%';
                    item.dailyRevenueRate = parseFloat(item.dailyRevenueRate).toFixed(2) + '%';
                    return item;
                })
                data.data.totalRevenueRate = parseFloat(data.data.totalRevenueRate).toFixed(2) + '%';
                data.data.dailyRevenueRate = parseFloat(data.data.dailyRevenueRate).toFixed(2) + '%';
            }
            const newAssets = _.get(data, 'data', []);
            this.initOptions(newAssets);
        }
    }
    // 获取所有回测记录
    _backtestAll = async (page) => {
        const { subId, pageInfo } = this.state;
        if (subId) {
            this.setState({ listLoading: true });
            let params = {
                subAccountId: subId,
                page: page ? page : pageInfo.page,
                pageSize: 5,
            }
            const res = await backtestAll(params);
            if (_.get(res, 'code', '') === '200') {
                this.setState({
                    backList: _.get(res, 'data.backTestUnitList', []),
                    pageInfo: { page: _.get(res, 'data.page', 1), total: _.get(res, 'data.totalPage', 1) },
                    listLoading: false
                })
            } else {
                this.setState({ listLoading: false });
            }
        }
    }
    // 单项回测数据
    _backtestSingle = async (id) => {
        if (id) {
            this.setState({ dLoading: true });
            let params = {
                backTestUnitId: id,
            }
            const res = await backtestSingle(params);
            if (_.get(res, 'code', '') === '200') {
                const getUnits = _.get(res, 'data.stockBackTestResult.stockBackTestUnits', []);
                this.setState({
                    backTests: getUnits,
                    formTest: _.get(res, 'data.stockBackTestSampleForm', {}),
                    dLoading: false,
                    updateCount: this.state.updateCount + 1
                })
            } else {
                message.info(_.get(res, 'message', 'warning'));
                this.setState({ dLoading: false });
            }
        }
    }
    // 已回测过的数据
    _showOption = async () => {
        this.setState({ listLoading: true });
        const res = await showOption();
        if (_.get(res, 'code', '') === '200') {
            this.setState({
                optionList: _.get(res, 'data', []),
                listLoading: false
            })
        }
    }
    // ——————产品option功能——————
    initOptions = (assetDatas) => {
        const getList = _.get(assetDatas, 'currentProductResultList', []);
        if (_.size(getList) > 0) {
            let newOption = getList.map(n => {
                return { label: _.get(n, 'productName', ''), value: _.get(n, 'productId', 0), isLeaf: false, }
            });
            this.setState({ options: newOption });
        }
    }

    onChange = (value, selectedOptions) => {
        if (_.size(value) > 1) {
            this.setState({
                subId: _.last(value),
            }, () => {
                this._backtestAll();
            })
        }
    };

    loadData = async (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        if (targetOption.value) {
            const res = await getProductDetailss({ params: { productId: targetOption.value } });
            if (_.get(res, 'code', '') === '200') {
                const getAccount = _.get(res, 'data.securitiesAccounts', []);
                if (_.size(getAccount) > 0) {
                    let itemArray = [];
                    getAccount.map(acc => {
                        const getSubAccount = _.get(acc, 'subAccountList', [])
                        if (_.size(getSubAccount) > 0) {
                            getSubAccount.map(s => {
                                itemArray.push({
                                    label: _.get(s, 'name', ''),
                                    value: _.get(s, 'id'),
                                    isLeaf: true,
                                })
                            })
                        }
                    })
                    targetOption.children = itemArray;
                } else {
                    targetOption.loading = false;
                }
            }
        }
        this.setState({ options: [...this.state.options] })
    }
    // 跳转TestForm，赋值路由state
    handleLink = (type, option) => {
        const { subId, formTest, backList, pageInfo, backTests, options, currentItem } = this.state;
        let finalState = {
            form: { subAccountId: subId },
            // 备份，在TechRules表单页进行全局备份，goBack返回时重新赋值给state
            bkl: backList,
            pgi: pageInfo,
            bkt: backTests,
            sid: subId,
            opn: options,
            curr: currentItem
        };
        if (type === 'params') {
            finalState.form = { subAccountId: subId, ...formTest }
        } else if (type === 'option') {
            finalState.option = option;
        }
        this.props.history.push({
            pathname: '/home/backform',
            state: finalState
        });
    }

    render() {
        const { subId, options, pageInfo, backList, listLoading, backTests, dLoading, tabKey, updateCount,
            formTest, currentItem, segValue, optionList
        } = this.state;
        const currentTitle = _.get(currentItem, 'id') ? `回测-${_.get(currentItem, 'id', '')}` : ''
        return (
            <div className='common_page_container'>
                <PageHeader
                    title={'回测'}
                    style={{ marginBottom: 5 }}
                >
                </PageHeader>

                <div className='controlContainer'>
                    <Row style={{ marginBottom: 18 }} justify='space-between'>
                        <Col {...autoCol([6])}>
                            {/* <Title level={5}>查询</Title> */}
                            <Space>
                                <Text>账户：</Text>
                                <Cascader
                                    options={options}
                                    loadData={this.loadData}
                                    onChange={this.onChange}
                                    changeOnSelect
                                    style={{ width: 200 }}
                                />
                            </Space>
                        </Col>
                        <Col {...autoCol([10])} style={{ textAlign: 'center' }}>
                            <Space>
                                <Segmented
                                    options={SEG_OPTION}
                                    value={segValue}
                                    onChange={(v) => {
                                        this.setState({ segValue: v })
                                    }} />
                                <Button
                                    type='text'
                                    icon={<ReloadOutlined />}
                                    shape='circle'
                                    size='small'
                                    onClick={() => this._backtestAll()}
                                    disabled={subId ? false : true}
                                />
                            </Space>
                        </Col>
                        <Col {...autoCol([6])} style={{ textAlign: 'right' }}>
                            <Button
                                icon={<FormOutlined />}
                                onClick={() => this.handleLink()}
                                disabled={subId ? false : true}
                            >
                                创建回测
                            </Button>
                        </Col>
                    </Row>

                    <Row gutter={[16, 24]} justify='space-between' style={{ minHeight: 390 }}>
                        <Col {...autoCol([24])}>
                            <SingleList
                                type={segValue}
                                sid={subId}
                                singleList={backList}
                                opnList={optionList}
                                page={pageInfo}
                                loading={listLoading}
                                pageChange={(page) => this._backtestAll(page)}
                                onDetail={(item, type) => {
                                    if (type === 1) {
                                        this._backtestSingle(item.id);
                                        this.setState({ currentItem: item })
                                    } else {
                                        this.handleLink('option', item)
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <Divider />
                <div className='bottomContainer'>
                    <Tabs
                        activeKey={tabKey}
                        type="card"
                        onChange={(key) => this.setState({ tabKey: key })}
                        tabBarExtraContent={{
                            right: <Space>
                                <Spin spinning={dLoading} />
                                <Text strong>{currentTitle}</Text>
                            </Space>
                        }}
                    >
                        <Tabs.TabPane tab={'记录'} key='record'>
                            <BackTable datas={backTests} updateCount={updateCount} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={'参数'} key='params'>
                            <BackParams details={formTest} id={subId} onUpdate={this._showOption} />
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab={'收益率'} key='charts'>
                                <BackCharts updateCount={updateCount} datas={backTests} />
                            </Tabs.TabPane> */}
                    </Tabs>
                </div>
            </div>

        )
    }
}

export default Testback;
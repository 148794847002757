const INDEX_CODE = {
  '上证50': "000016",
  '上证指数': "000001",
  '中证500': "000905",
  '中证1000': "000852",
  '创业板指': "399006",
  '沪深300': "000300",
  '深证成指': "399001",
  '默认指数': "123456",
  '科创板': "999996",
  '创业板': "999997",
  '普通深市': "999998",
  '普通沪市': "999999",
}

// const index_code_keys = { base: '基础', plate: '板块' };
const INDEX_CODE_2 = {
  base: {
    '上证50': "000016",
    '沪深300': "000300",
    '中证500': "000905",
    '中证1000': "000852",
    '中证2000': "932000",
    '中证全指': "000985",
    '东财小市值': '800006',
    '米筐小市值': '866001',
    '米筐微盘股': '866006',
    '国证2000': "399303",
    '上证指数': "000001",
    '深证成指': '399001',
    '创业板指': '399006',
    '科创50': '000688',
  },
  plate: {
    "环保": "888701",
    "电力设备": "888702",
    "商贸零售": "888703",
    "社会服务": "888704",
    "综合": "888705",
    "汽车": "888706",
    "石油石化": "888707",
    "煤炭": "888708",
    "钢铁": "888709",
    "房地产": "888710",
    "纺织服饰": "888711",
    "通信": "888712",
    "交通运输": "888713",
    "轻工制造": "888714",
    "基础化工": "888715",
    "食品饮料": "888716",
    "非银金融": "888717",
    "农林牧渔": "888718",
    "有色金属": "888719",
    "建筑材料": "888720",
    "美容护理": "888721",
    "建筑装饰": "888722",
    "医药生物": "888723",
    "公用事业": "888724",
    "家用电器": "888725",
    "电子": "888726",
    "国防军工": "888727",
    "机械设备": "888728",
    "传媒": "888729",
    "计算机": "888730",
    "银行": "888731",
    "ETF": "888732",
  }
};
let INDEX_OPTIONS = []; // 分组options使用
let INDEX_CODE_NAME = {}; // 通过指数名称找到代码
let INDEX_CODE_VALUE = {}; // 通过代码找到名称
let PLATE_OPTIONS = [];
Object.keys(INDEX_CODE_2).map((cname) => {
  Object.keys(INDEX_CODE_2[cname]).map((keyname, kindex) => {
    INDEX_CODE_NAME[keyname] = INDEX_CODE_2[cname][keyname];
    INDEX_CODE_VALUE[INDEX_CODE_2[cname][keyname]] = keyname;
    if (cname === 'base') {
      INDEX_OPTIONS[kindex] = { label: keyname, value: INDEX_CODE_2[cname][keyname] }
    } else if (cname === 'plate') {
      PLATE_OPTIONS[kindex] = { label: keyname, value: INDEX_CODE_2[cname][keyname] }
    }
  })
});
// 默认中证1000；
const DEFAUL_INDEX_CODE = '000852';

export {
  INDEX_CODE_NAME, INDEX_OPTIONS,
  INDEX_CODE_VALUE, INDEX_CODE,
  PLATE_OPTIONS,
  DEFAUL_INDEX_CODE,
};
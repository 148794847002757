import React, { useState, useEffect } from 'react';
import { Select,Table,Button,Card,Row,Spin,Input } from 'antd';
import './Futuresmanagements.scss'
import {showFuturesPoolFutures } from '@/api/workbench';
import moment from 'moment';

export default function Futuresmanagements(props) {
    const { Option } = Select;
    const [listData, setlistData] = useState([]);
    const [futurePoolListIndex, setfuturePoolListIndex] = useState(0);
    const [futurePoolList, setfuturePoolList] = useState([]);
    const [subAccountInfo] = useState(props.location.state.subAccountInfo);
    const { Search } = Input;
    const [spinning, setspinning] = useState(false);

    const futureColumns = [
        {
            title: '名称',
            align: 'center',
            dataIndex: 'name',
            filterDropdown: () => {
                return (
                    <Search placeholder="请输入期货名称" allowClear enterButton onSearch={onSearch}/>
                );
            },
        },
        {
            title: '代码',
            align: 'center',
            dataIndex: 'code',
            render: (text) => {
                return (text.indexOf('.')===-1?text:text.substring(0,text.indexOf('.')))
            },
        },
        {
            title: '交易所简称',
            align: 'center',
            dataIndex: 'code',
            render: (text) => {
                return (text.indexOf('.')===-1?text:text.substring(text.indexOf('.')+1))
            },
        },
        {
            title: 'k线周期',
            align: 'center',
            dataIndex: 'kline',
            sorter: (a, b) => Number(a.kline) - Number(b.kline),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: 'K线数量',
            align: 'center',
            dataIndex: 'diffnumber',
            sorter: (a, b) => Number(a.diffnumber) - Number(b.diffnumber),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '波动率倍数',
            align: 'center',
            dataIndex: 'stdmultiple',
            sorter: (a, b) => Number(a.stdmultiple) - Number(b.stdmultiple),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益回撤比',
            align: 'center',
            dataIndex: 'incomedrawdown',
            sorter: (a, b) => Number(a.incomedrawdown) - Number(b.incomedrawdown),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益率',
            align: 'center',
            dataIndex: 'incomerate',
            sorter: (a, b) => Number(a.incomerate) - Number(b.incomerate),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '开始时间',
            align: 'center',
            dataIndex: 'startDate',
            sorter: (a, b) => {
                let aTime = new Date(a.startDate).getTime();
                let bTime = new Date(b.startDate).getTime();
                return aTime - bTime;
            },
            sortDirections:['descend','ascend'],
            defaultSortOrder:'descend',
            render: (text) => (
                <>{text === null?'--':moment(text).format('YYYY-MM-DD')} </>
            )
        },
        {
            title: '结束时间',
            align: 'center',
            dataIndex: 'endDate',
            sorter: (a, b) => {
                let aTime = new Date(a.startDate).getTime();
                let bTime = new Date(b.startDate).getTime();
                return aTime - bTime;
            },
            sortDirections:['descend','ascend'],
            defaultSortOrder:'descend',
            render: (text) => (
                <>{text === null?'--':moment(text).format('YYYY-MM-DD')} </>
            )
        },
        {
            title: '操作',
            render: (record) => (
                <>
                    <Button type="primary" shape="round" onClick={()=>{
                        document.documentElement.scrollTop = document.body.scrollTop =0;
                        props.history.push({
                            pathname: '/home/FutureTrading',
                            state: { 
                                subAccountInfo: subAccountInfo, 
                                record: record
                            }
                        });
                    }}>买入</Button>
                </>
            )
        },
    ]
    useEffect(() => {
        async function showFuturesPoll(){
            const data = await showFuturesPoolFutures({accountType:'trader',subAccountId:subAccountInfo.subAccountId});
            if(data.code === '200'){
                setlistData(data.data);
                if(data.data !== null && data.data.length !== 0){
                    const obj = data.data[0].futuresPoolFuturesContractList;
                    let arr = [];
                    if(data.data[0].futuresPoolFuturesContractList !== null && data.data[0].futuresPoolFuturesContractList.length !== 0){
                        for(let i in obj){
                            arr = arr.concat(obj[i]);
                        }
                    }
                    setfuturePoolList(arr);
                }else{
                    setfuturePoolListIndex(null);
                    setfuturePoolList([]);
                }
            }   
        }
        showFuturesPoll();
    }, [])

    async function onSearch(value){
        if(value === ''){
            let brr = [];
            if(listData[futurePoolListIndex].futuresPoolFuturesContractList !== null){
                let obj = listData[futurePoolListIndex].futuresPoolFuturesContractList;
                for(let i in obj){
                    brr = brr.concat(obj[i]);
                }
            }
            setfuturePoolList(brr);
        }else{
            var reg = new RegExp(value);
            var arr = [];
            setspinning(true);
            if(futurePoolList.length !== 0){
                futurePoolList.map(item => {
                    if(reg.test(item.name)){
                        arr.push(item);
                    }
                    return item;
                })
                setfuturePoolList(arr);
            }
            setspinning(false);
        }
    }
    function changeIndex(e) {
        setfuturePoolListIndex(e);
        let arr = [];
        if(listData[e].futuresPoolFuturesContractList !== null){
            let obj = listData[e].futuresPoolFuturesContractList;
            for(let i in obj){
                arr = arr.concat(obj[i]);
            }
        }
        setfuturePoolList(arr);
    }
   
    return (
        <div className="contentView">
            <Spin spinning={spinning}>
                <Card style={{ marginTop: 20 }}>
                    <Card>
                        <Row>
                            <h2>我的期货池</h2>
                            <Select style={{marginLeft:20,width:180}} onChange={changeIndex} defaultActiveFirstOption='true' value={futurePoolListIndex}>
                                {
                                    listData.length !== 0 && listData.map((item, index) => {
                                        return (
                                            <Option
                                                key={item.futuresPoolId}
                                                value={index}
                                            >
                                                {item.futuresPoolName}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>        
                        </Row>
                    </Card>
                    <Table
                        rowKey="id"
                        dataSource={futurePoolList}
                        columns={futureColumns}
                        bordered
                        pagination={{
                            defaultPageSize: 10,
                        }}
                    />
                </Card>
            </Spin>
        </div>
    )
}
import React, { useState } from 'react';
import { getAllStocks } from '@/api/strategy';
import {
  AutoComplete,
  Spin,
  message,
  Typography,
  Space,
} from 'antd';
import { isEmptyStringV2 } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
let timer = null;

const StockSearch = ({ width = 200, size = 'middle', isTitle = true, titleTxt = '搜索股票: ', onStockChange }) => {
  const [stockOption, setStockOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stValue, setStValue] = useState('');
  async function _getAllStocks(txt) {
    if (isEmptyStringV2(txt)) {
      return
    }

    try {
      setLoading(true);
      const data = await getAllStocks(txt);
      if (_.get(data, 'code', '') === '200') {
        const newStockData = _.get(data, 'data', []).map(n => { return { label: n.stockName, value: n.symbol, ...n } });
        setStockOption(newStockData);
      } else {
        message.error('获取出错！')
      }
      setLoading(false);
    } catch (error) {
      message.error('获取出错！')
    }
  }

  function onSearch(searchText) {
    setStValue(searchText);
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      _getAllStocks(searchText)
    }, 1200);
  }

  function onSelect(data) {
    let fIndex = _.findIndex(stockOption, o => o.value === data);
    if (fIndex !== -1) {
      onStockChange(stockOption[fIndex]);
    }
  }

  return (
    <Space>
      {isTitle && <Text>{titleTxt}</Text>}
      <AutoComplete
        size={size}
        options={stockOption}
        style={{ width: width }}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder="输入股票代码或名称"
        value={stValue}
      />
      <Spin spinning={loading} size='small' />
    </Space>
  )
}

export default StockSearch;
import React, { useState, memo } from 'react';
import { Row, Col, Card, Typography, Button, Space, Modal, message, Badge, Empty } from 'antd';
import { excuteRiskControl, getFirstWithoutRiskControl } from '@/api/workbench';
import { ExclamationCircleOutlined, AlertFilled, BulbFilled, EllipsisOutlined } from '@ant-design/icons';
import { useMount, useUnmount } from 'ahooks';
import { validateResponse } from '@/utils/utils';
// import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;

const Alerts = ({ riskState, riskMessage, role, goRisk, goNews, dealWith }) => {
  const [normalMessage] = useState([]);
  let isUnmont = false;

  useMount(() => {
    getNormal();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function getNormal() {
    const data = await getFirstWithoutRiskControl();
    if (validateResponse(data, isUnmont)) {
      if (data.data !== null) {
        normalMessage[0] = data.data;
        // const sendTime = moment(data.data.sendTime);
        // const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
        // setnormalHour(nowTime.diff(sendTime, 'hours'));
      } else {
        normalMessage[0] = '';
      }
    }
  }

  async function perform() {
    Modal.confirm({
      title: '是否确认执行预警操作？',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await excuteRiskControl({ subAccountId: riskMessage[0].subAccountId, lineType: '1' });
        if (data.code === '200') {
          if (data.message === 'subAccount already alerts') {
            message.success("已执行操作，该交易员账户已处于预警期");
            return;
          }
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      }
    });
  }
  const newRisk = _.size(riskMessage) > 0 ? _.head(riskMessage) : null;
  const newNormal = _.size(normalMessage) > 0 ? _.head(normalMessage) : null;
  return (
    <>
      <Col span={24}>
        <Card>
          <Row align='middle'>
            <Col span={12}><span className='messageTitle'>风控信息</span></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="text" onClick={goRisk} icon={<EllipsisOutlined />} />
            </Col>
          </Row>
          {newRisk ? <Badge.Ribbon text='New'>
            <div className='messageDiv'>
              <div className='msg_title'>
                <Space>
                  <AlertFilled />
                  <Text>{_.get(newRisk, 'sender')}</Text>
                </Space>
              </div>
              <Paragraph type='secondary'>
                {_.get(newRisk, 'content', '')}
              </Paragraph>
              {
                riskState &&
                <Row justify="end">
                  <Button type="link" disabled={role === 'visitor' ? true : false} onClick={perform}>执行</Button>
                  <Button type="link" disabled={role === 'visitor' ? true : false} onClick={dealWith}>处理</Button>
                </Row>
              }
            </div>
          </Badge.Ribbon> : <Empty />}
        </Card>
      </Col>

      <Col span={24}>
        <Card>
          <Row align='middle'>
            <Col span={12}><span className='messageTitle'>消息</span></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="text" onClick={goNews} icon={<EllipsisOutlined />} />
            </Col>
          </Row>
          {newNormal ? <Badge.Ribbon text='New'>
            <div className='messageDiv'>
              <div className='msg_title'>
                <Space>
                  <BulbFilled />
                  <Text>{_.get(newNormal, 'sender')}</Text>
                </Space>
              </div>
              <Paragraph type='secondary'>
                {_.get(newNormal, 'content', '')}
              </Paragraph>
            </div>
          </Badge.Ribbon> : <Empty />}
        </Card>
      </Col>
    </>
  )
}

export default memo(Alerts);
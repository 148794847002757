import React, { useState } from 'react';
import { Card, Col, Row, Button, Table, Space, message, Checkbox, Popconfirm } from 'antd';
import { LeftOutlined, RightCircleOutlined, DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { useUnmount, useMount, useReactive, useInterval } from 'ahooks';
import { getfundManagerAssets, restProductShow, updateProductShow } from '@/api/workbench';
import { deleteProductCode, getUserPhone, deleteVerifyPhone, deleteProduct } from '@/api/auto';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { autoCol, colWidth, singleColumns, RateText, HOME_PRODUCT_NUMBER } from '@/utils/utils';
import { saveStorage, getStorage, isValidString } from '@/utils/utils2';
import PhoneVerify from '@/view/common/PhoneVerify';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';

const sub_storage_key = 'sub_acc';
const INTERVAL_NUM = 60000;
const createColumnsRate = (keyarray = [], type = '') => {
  const props_type = { 'noprefix': { noPrefix: true }, 'rest': { restStyle: { fontWeight: 400, color: 'black' } } };
  let newCol = singleColumns(keyarray);
  let rateProps = {};
  if (type === 'noprefix') {
    rateProps = _.assign(rateProps, props_type['noprefix']);
  }
  if (type === 'noprefix_rest') {
    rateProps = _.assign(rateProps, props_type['noprefix'], props_type['rest']);
  }
  if (type !== '') {
    newCol.render = (text, record) => <RateText rate={text} rType='replace' {...rateProps} />
  }
  return newCol;
}

export default function SubAccount(props) {
  const mobxStore = useLocalObservable(() => store);
  const [listData] = useState(JSON.parse(JSON.stringify(mobxStore.assetsAlls)));
  const [filterList, setFilterList] = useState(listData);
  const [assetss] = useState(props.location.state.assetss);
  const [tradeTime] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'status', '') === 'trading' ? true : false);
  const [allassets, setallassets] = useState({});
  const [itemVal, setItemVal] = useState({});
  const [userPhone, setuserPhone] = useState(null);
  const [phoneVisible, setphoneVisible] = useState(false);
  const [vCount, setvCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isHomeArray, setIsHomeArray] = useState([]);
  const [inter, setInter] = useState(undefined);
  const pageState = useReactive({
    page: 1, pageSize: 10, total: 0
  });

  const columns = [
    createColumnsRate(['名称', 'productName', 125]),
    createColumnsRate(['总资产', 'totalAssets', 135]),
    createColumnsRate(['总收益', 'totalRevenue', 135], 'noprefix'),
    createColumnsRate(['总收益率', 'totalRevenueRate', 90], 'normal'),
    createColumnsRate(['日收益', 'dailyRevenue', 115], 'noprefix'),
    createColumnsRate(['日收益率', 'dailyRevenueRate', 90], 'normal'),
    createColumnsRate(['总市值', 'positionsAssets', 120], 'noprefix_rest'),
    createColumnsRate(['可用资金', 'productBalance', 120], 'noprefix_rest'),
    {
      ...singleColumns(['是否显示在首页', 'isHome', 100]),
      render: (text, record) => {
        const pid = _.get(record, 'productId');
        const isCheck = isHomeArray.indexOf(pid) !== -1 ? true : false
        return <Checkbox
          checked={isCheck}
          onChange={(e) => { //  =17 时不可再添加产品 ，最后一个是更多卡片按钮
            if (_.size(isHomeArray) > (HOME_PRODUCT_NUMBER - 2) && e.target.checked === true) {
              message.info('已达到首页显示推荐大小！')
              return;
            }
            _updateProductShow({
              productId: pid,
              isShow: e.target.checked
            });
            if (e.target.checked) {
              setIsHomeArray(_.uniq([...isHomeArray, pid]));
            } else {
              let temp = _.cloneDeep(isHomeArray);
              _.pullAll(temp, [pid]);
              setIsHomeArray(temp);
            }
          }}
        />
      }
    },
    {
      ...singleColumns(['操作', 'option', 200]),
      fixed: 'right',
      render: (text, record, index) => (
        <Space size='small'>
          <Button type="link" icon={<RightCircleOutlined />} onClick={() => _goProductDetails(record)}>查看</Button>
          <Button type="link" danger icon={<DeleteOutlined />} onClick={() => {
            setphoneVisible(true);
            setItemVal(record);
            setvCount(vCount + 1)
          }}>删除</Button>
        </Space>
      )
    },
  ];

  useMount(() => {
    // 获取存储的分页信息
    const storagePage = getStorage(sub_storage_key);
    if (isValidString(storagePage)) {
      pageState.page = parseInt(storagePage);
    }

    _getPhone();
    setallassets(assetss);
    assetss.dataType = 'TODAY';

    if (tradeTime) {
      setInter(INTERVAL_NUM);
    }
    _getAsset(true);
  });

  useUnmount(() => {
    setInter(undefined);
  });

  useInterval(() => {
    _getAsset(false);
  }, inter);

  async function _getPhone() {
    let userInfo = JSON.parse(JSON.stringify(mobxStore.userInfo));
    const data = await getUserPhone({ params: { email: userInfo.email } });
    if (data.code === '200') {
      setuserPhone(data.data.phone);
    }
  }

  async function _getAsset(isLoading) {
    let params = {
      isShow: false,
      pageNum: pageState.page,
      pageSize: pageState.pageSize
    }
    if (isLoading) {
      setLoading(true);
    }
    const res = await getfundManagerAssets(params);
    if (_.get(res, 'code', '') === '200') {
      res.data.totalRevenueRate = parseFloat(res.data.totalRevenueRate).toFixed(2) + '%';
      res.data.dailyRevenueRate = parseFloat(res.data.dailyRevenueRate).toFixed(2) + '%';
      const getList = _.get(res, 'data.currentProductResultList', []);
      const getInfoList = _.get(res, 'data.pageInfo.list', []);
      setallassets(res.data);
      setFilterList(getList);
      setIsHomeArray(_.chain(getInfoList).filter(o => o.isShow === 1).map('id').value());
      pageState.total = _.get(res, 'data.pageInfo.total', 0);
    }
    setLoading(false);
  }

  async function _restProductShow() {
    const res = await restProductShow();
    if (_.get(res, 'code', '') === '200') {
      message.success(_.get(res, 'message', '重置成功！'));
      setIsHomeArray([]);
    }
  }

  async function _updateProductShow(pms) {
    const res = await updateProductShow(pms);
    if (_.get(res, 'code', '') !== '200') {
      message.warning(_.get(res, 'message', '更新失败!'));
    }
  }

  async function _phoneVerify(valuesCode) {
    // console.log(111, valuesCode);
    const data = await deleteVerifyPhone({ account: valuesCode.phone, verifyCode: valuesCode.phoneCode });
    if (data.code === '200') {
      setphoneVisible(false);
      let params = {
        productId: itemVal.productId,
        phone: valuesCode.phone,
        code: valuesCode.phoneCode,
      }
      const res = await deleteProduct(params);
      if (res.code === '200') {
        message.success("删除成功！");
        _getAsset();
      } else {
        message.error(res.message);
      }
    }
  }

  function _goProductDetails(item) {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/ProductDetails',
      state: { paramsData: item }
    });
  }

  const headerList = [
    { title: '总资产', key: 'totalAssets' },
    { title: '总收益', key: 'totalRevenue' },
    { title: '日收益', key: 'dailyRevenue' },
  ];
  return (
    <>
      <div className="contentView" id='subAccount'>
        <Row gutter={[8, 8]}>
          {headerList.map(n => <Col key={n.key} {...autoCol([8])}>
            <div className='colorCardBasic colorCardFund'>
              <StatisticHeader title={n.title} value={_.get(allassets, n.key, '')} isColor />
            </div>
          </Col>)}
        </Row>
        <br />
        <Card>
          <Row style={{ marginBottom: 12 }} align='middle' justify='center'>
            <Col span={12}>
              <Button size='small' icon={<LeftOutlined />} onClick={() => {
                saveStorage(sub_storage_key, '1'); // 只有点击返回将分页重置为 1 ;
                props.history?.goBack();
              }}>返回</Button>
            </Col>
            <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
              <Popconfirm
                title="重置产品显示"
                description="是否重置首页产品卡片的显示?"
                onConfirm={() => _restProductShow()}
                onCancel={() => { }}
                okText="是"
                cancelText="否"
              >

                <Button type='link' icon={<RedoOutlined />} size='small'>重置产品显示</Button>
              </Popconfirm>
            </Col>
          </Row>
          <Table
            rowKey="productId"
            dataSource={filterList}
            columns={columns}
            bordered
            scroll={{ x: colWidth(columns) }}
            loading={loading}
            pagination={{
              showSizeChanger: false,
              current: pageState.page,
              pageSize: pageState.pageSize,
              total: pageState.total,
              onChange: (e) => {
                pageState.page = e;
                saveStorage(sub_storage_key, String(e));
                _getAsset(true);
              },
            }}
          />
        </Card>
        <br />
      </div>

      <PhoneVerify
        vCount={vCount}
        visible={phoneVisible}
        phoneHandleCancel={() => setphoneVisible(false)}
        gobackValues={_phoneVerify}
        getCode={deleteProductCode}
        userPhone={userPhone}
      />
    </>

  )
}
import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Table, Alert } from 'antd';
import { showFutureAssetsAndRevenue, showAllSubAccountCal } from '@/api/stock';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import _ from 'lodash';


export default function TradersDesk(props, { history }) {
    const mobxStore = useLocalObservable(() => store);
    const [routeUserInfo] = useState(_.get(mobxStore, 'userInfo', {}));
    const [accountList, setaccountList] = useState([]);
    const [accountData, setaccountData] = useState({});

    const columns = [
        {
            title: '账户名称',
            dataIndex: 'subAccountName',
        },
        {
            title: '账户类别',
            dataIndex: 'subAccountType',
            render: (text) => {
                return (text === '1' ? <span>股票</span> : text === '2' ? <span>期货</span> : <span>基金</span>)
            }
        },
        {
            title: '证券账户',
            dataIndex: 'securitiesAccountName',
        },
        {
            title: '基金产品',
            dataIndex: 'productName',
        },
        {
            title: '总资产',
            dataIndex: 'totalAsset',
        },
        {
            title: '可用资金',
            dataIndex: 'balance',
        },
        {
            title: '总收益',
            dataIndex: 'totalRevenue',
            render: (text) => {
                return (parseFloat(text) > 0 ? <span style={{ color: 'red' }}>{text}</span> : parseFloat(text) < 0 ? <span style={{ color: 'green' }}>{text}</span> : <span>{text}</span>)
            }
        },
        {
            title: '日收益',
            dataIndex: 'dailyRevenue',
            render: (text) => {
                return (parseFloat(text) > 0 ? <span style={{ color: 'red' }}>{text}</span> : parseFloat(text) < 0 ? <span style={{ color: 'green' }}>{text}</span> : <span>{text}</span>)
            }
        },
        {
            title: '操作',
            render: (record) => {
                return (
                    <Button type="link" onClick={() => _seeSubAccount(record)}>查看</Button>
                )
            }
        },
    ]

    var _get
    useEffect(() => {
        async function _getData() {
            const data = await showFutureAssetsAndRevenue();
            if (data.code === '200') {
                setaccountData(data.data);
            }
            const data1 = await showAllSubAccountCal();
            if (data1.code === '200') {
                setaccountList(data1.data);
            }
        }
        _getData();
        _get = setInterval(() => {
            showFutureAssetsAndRevenue().then((data) => {
                if (data.code === '200') {
                    setaccountData(data.data);
                }
            });
            showAllSubAccountCal().then((data1) => {
                if (data1.code === '200') {
                    setaccountList(data1.data);
                }
            })
        }, 10000)
        return () => { clearInterval(_get); return; }
    }, []);

    function _seeSubAccount(record) {
        props.history.push({
            pathname: '/home/fundManagerListsDetails',
            state: { subAccountInfo: record }
        });

    }
    const desText = _.get(routeUserInfo, 'role', '') === 'trader' ? '关联' : '管理'

    return (
        <div className="contentView" >
            {
                accountList === null &&
                <Alert
                    message="提醒"
                    description={`请联系基金经理，${desText}交易子账户`}
                    type="warning"
                    showIcon
                    closable
                />
            }
            <Card>
                <Row gutter={24} >
                    <Col span={7}>
                        <Card title="总资产" bordered={true} className="headCard">
                            <span className="headCardTetx" ><h3 className="headCardTetx">{accountData !== null ? accountData.totalAssets : 0}</h3></span>
                            <Row>
                                <div className="headCardT">
                                    <span className="colorGrey">可用资金</span>
                                    <h3 className="headCardTetx">{accountData !== null ? accountData.totalBalance : 0}</h3>
                                </div>
                                <div className="headCardT" style={{ marginLeft: 30 }}>
                                    <span className="colorGrey">保证金</span>
                                    <h3 className="headCardTetx">{accountData !== null ? accountData.frozenBalance : 0}</h3>
                                </div>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card bordered={true} className="headCard">
                            <div className="headCardT">
                                <span className="colorGrey">总收益</span>
                                <h3 className="headCardTetx">{accountData === null || parseFloat(accountData.totalRevenue) === 0 ? 0 : parseFloat(accountData.totalRevenue) > 0 ?
                                    <span style={{ color: 'red' }}>{accountData.totalRevenue}</span> : <span style={{ color: 'green' }}>{accountData.totalRevenue}</span>}</h3>
                            </div>
                            <div className="headCardB" >
                                <span className="colorGrey">总收益率</span>
                                <h3 className="headCardTetx">{accountData === null || parseFloat(accountData.totalRevenueRate) === 0 ? '0%' : parseFloat(accountData.totalRevenueRate) > 0 ?
                                    <span style={{ color: 'red' }}>{parseFloat(accountData.totalRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(accountData.totalRevenueRate * 100).toFixed(2) + '%'}</span>}</h3>
                            </div>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card bordered={true} className="headCard">
                            <div className="headCardT">
                                <span className="colorGrey">日收益</span>
                                <h3 className="headCardTetx">{accountData === null || parseFloat(accountData.dailyRevenue) === 0 ? 0 : parseFloat(accountData.dailyRevenue) > 0 ?
                                    <span style={{ color: 'red' }}>{accountData.dailyRevenue}</span> : <span style={{ color: 'green' }}>{accountData.dailyRevenue}</span>}</h3>
                            </div>
                            <div className="headCardB">
                                <span className="colorGrey">日收益率</span>
                                <h3 className="headCardTetx">{accountData === null || parseFloat(accountData.dailyRevenueRate) === 0 ? '0%' : parseFloat(accountData.dailyRevenueRate) > 0 ?
                                    <span style={{ color: 'red' }}>{parseFloat(accountData.dailyRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(accountData.dailyRevenueRate * 100).toFixed(2) + '%'}</span>}</h3>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <br /><br /><br />
                <Table
                    rowKey="subAccountId"
                    dataSource={accountList}
                    columns={columns}
                    bordered
                    pagination={{
                        defaultPageSize: 5,
                    }}
                />
            </Card>
        </div>
    )
}
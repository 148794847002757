import React, { } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { routeConfig } from '@/router/route.config';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';

export default function Guards(props) {
    const mobxStore = useLocalObservable(() => store);

    return (
        <Observer>
            {() => (
                <Router>
                    <Switch>
                        {
                            routeConfig.map(item => {
                                return <Route key={item} exact={item.exact} path={item.path} component={item.component} />
                            })
                        }
                        {/* <Redirect to='/home' /> */}
                        {
                            mobxStore.userLogin ?
                                <Redirect to='/home' />
                                :
                                <Redirect to='/login' />
                        }
                    </Switch>
                </Router>
            )}
        </Observer>
    )
}
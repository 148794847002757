import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, message } from 'antd';
import { getTradingContractOfNotExpired,addFuturesContractToPool } from '@/api/workbench';
import moment from 'moment';

export default function Screen(props) {
    const [visible, setvisible] = useState(false);
    const [preseVisible, setpreseVisible] = useState(false);
    const [listData, setlistData] = useState([]);
    const [addListData, setaddListData] = useState([]);
    const [num, setnum] = useState(0);

    const columns = [
        {
            title: '期货类型',
            dataIndex: 'type',
        },
        {
            title: '期货名称',
            dataIndex: 'displayName',
        },
        {
            title: '期货代码',
            dataIndex: 'code1',
        },
        {
            title: '交易所简称',
            dataIndex: 'dealer',
        },
        {
            title: '开始时间',
            dataIndex: 'startDate',
            render: (text)=>{
                return (moment(text).format('YYYY-MM-DD'))
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endDate',
            render: (text)=>{
                return (moment(text).format('YYYY-MM-DD'))
            }
        },
        {
            title: '操作',
            render: (text, record, index) => (
                <>
                    {
                        record.isAdd ?
                            <Button type="primary" shape="round" danger onClick={() => _actFilterStocks(num+index)}>取消</Button>
                            :
                            <Button type="primary" shape="round" onClick={() => _actFilterStocks(num+index)}>添加</Button>
                    }
                </>
            )
        },
    ]
    const addColumns = [
        {
            title: '期货类型',
            dataIndex: 'type',
        },
        {
            title: '期货名称',
            dataIndex: 'displayName',
        },
        {
            title: '期货代码',
            dataIndex: 'code',
        },
        {
            title: '交易所简称',
            dataIndex: 'dealer',
        },
        {
            title: '开始时间',
            dataIndex: 'startDate',
            render: (text)=>{
                return (moment(text).format('YYYY-MM-DD'))
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endDate',
            render: (text)=>{
                return (moment(text).format('YYYY-MM-DD'))
            }
        },
    ]

    useEffect(() => {
        
    }, [])

    function handleCancel() {
        setvisible(false);
        setaddListData([]);
        setlistData([]);
    }
    // 操作进期货池保存的列表
    function _actFilterStocks(index) {
        const arr = JSON.parse(JSON.stringify(listData));
        if(!arr[index].isAdd){
            arr[index].isAdd = true;
        }else{
            arr[index].isAdd = !arr[index].isAdd;
        }
        setlistData(arr);
    }
    // 点击确认保存期货池 
    function _holdStockPool() {
        const arr = JSON.parse(JSON.stringify(listData));
        let newArr = [];
        for (let item of arr) {
            if (item.isAdd) {
                newArr.push(item);
            }
        }
        if (newArr.length === 0) return message.error('请添加要保存的期货');
        setaddListData(newArr);
        setpreseVisible(true);
    }
   
    async function _preseName() {
        let arr = [];
        if(addListData.length !== 0){
            addListData.map(item => {
                var b = {};
                b.name = item.displayName;
                b.code = item.code1+'.'+item.dealer;
                b.startDate = moment(item.startDate).format('YYYY-MM-DD');
                b.endDate = moment(item.endDate).format('YYYY-MM-DD');
                arr.push(b);
                return item;
            })
        }
        const data = await addFuturesContractToPool({ type:1,futuresPoolId: props.futurePoolInfos.futuresPoolId, futuresPoolFutures: arr });
        if (data.code === '200') {
            message.success('添加成功');
            setpreseVisible(false);
            handleCancel();
            props._goBack();
        } else {
            message.error(data.message);
        }
    }
    function _open() {
        if (!props.futurePoolInfos) {
            return message.error('请选择期货池');
        }
        async function _get() {
            const data = await getTradingContractOfNotExpired();
            const obj = data.data;
            let arr = [];
            for(let i in obj){
                arr = arr.concat(obj[i]);
            }
            setlistData(arr);
        }
        _get();
        setvisible(true);
    }
    
    return (
        <>
            <Button
                size="large"
                type="primary"
                onClick={_open}
                style={{marginRight:20}}
                // disabled={props.ban}
            >添加期货</Button>

            <Modal
                title="期货池名称"
                okText="保存"
                visible={preseVisible}
                width={800}
                onCancel={() => { setpreseVisible(false) }}
                onOk={_preseName}
            >
                <Table
                    rowKey={(record,index) => {return index}}
                    style={{ marginTop: 20 }}
                    dataSource={addListData}
                    columns={addColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 20,
                    }}
                />

            </Modal>
            <Modal
                title="期货池"
                okText="保存"
                width={1000}
                visible={visible}
                onCancel={handleCancel}
                onOk={_holdStockPool}
            >
                <Table
                    rowKey={(record,index) => {return index}}
                    style={{ marginTop: 10 }}
                    dataSource={listData}
                    columns={columns}
                    bordered
                    pagination={{
                        defaultPageSize: 10,
                        onChange: (current, pageSize) => {setnum((current-1)*pageSize)}
                    }}
                />  
            </Modal>
        </>
    )
}
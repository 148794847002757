import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Select,
  InputNumber,
  Space,
  Typography
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography

const ExchangeModal = ({ visible, maxPoint, list, onCancel, onConfirm }) => {
  const [point, setPoint] = useState(0);
  const [serviceIndex, setServiceIndex] = useState(null);

  function confirmExchange() {
    let params = {
      costPoint: point || 0,
      serviceIndex: serviceIndex,
    }
    confirm({
      title: '是否确定兑换积分？',
      icon: <ExclamationCircleOutlined />,
      content: ``,
      onOk: () => {
        onConfirm(params);
      },
      onCancel: () => { }
    })
  }

  return (
    <Modal
      title={'积分兑换'}
      visible={visible}
      onCancel={() => onCancel()}
      onOk={() => confirmExchange()}
    >
      <Row>
        <Col span={24}>
          <Space size='large'>
            <span>所需积分</span>
            <Text strong style={{ fontSize: 19 }}>{point}</Text>
            {/* <InputNumber
              min={0}
              // max={maxPoint} 
              step={1}
              value={point}
              style={{ width: 220 }}
              status={point > parseInt(maxPoint) ? 'error' : null}
              onChange={(v) => {
                setPoint(v);
              }}
            /> */}
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <Space size='large'>
            <span>选择服务</span>
            <Select
              style={{ width: 220 }}
              value={serviceIndex}
              onChange={(value) => {
                setServiceIndex(value)
                setPoint(_.get(list, `[${value}].point`, 0))
              }}
            >
              {list && list.map((item, index) => {
                return <Option value={index} key={index}>{_.get(item, 'name', '')}</Option>
              })}
            </Select>
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}

export default ExchangeModal;
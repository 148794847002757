import React, { useState } from 'react';
import { Card, Col, Row, Descriptions, Tooltip, Space, Button, Typography } from 'antd';
import { EllipsisOutlined, ProjectFilled, SortAscendingOutlined } from '@ant-design/icons';
import { autoCol, RateText, renderUpDownRate2, PRODUCT_STRATEGY_TYPE } from '@/utils/utils';
import { useReactive } from 'ahooks';
import _ from 'lodash';

const { Text } = Typography;
const SORT_KEY = {
  'total': 'totalRevenueRate_float',
  'day': 'dailyRevenueRate_float',
  // 'avg': 'avgExcess',
  // 'timekey': 'timekey',
};
const SORT_CHANGE_OBJ = { 'up': 'down', 'down': 'up' };// 改变升降序时，需要赋值相反的状态
const SORT_NAME = {
  'total': '总',
  'day': '今',
  // 'avg': '超额',
  // 'timekey': '日期',
}
// 首页卡片展示
const HomeCard = ({ homeItem, isVisitor, goProductDetails, goSubAccount }) => {
  const [orderItem, setOrderItem] = useState([]);
  const [isOrder, setIsOrder] = useState(false);
  const sortState = useReactive({
    'totalRevenueRate_float': 'down',
    'dailyRevenueRate_float': 'down',
    // 'avgExcess': 'down',
    // 'timekey': 'down',
    'sortType': '',
  });
  //console.log(homeItem)
  const sortRate = (key) => {
    const sort_full_key = SORT_KEY[key];
    const curSortType = _.get(sortState, sort_full_key);
    sortState[sort_full_key] = SORT_CHANGE_OBJ[curSortType];
    sortState.sortType = key;
    setOrderItem(_.orderBy(homeItem, [sort_full_key], [sortState[sort_full_key] === 'up' ? 'desc' : 'asc']));
    setIsOrder(true);
  };

  const renderSortColor = (type) => {
    const isSortType = sortState[SORT_KEY[type]]
    let updownColor = isSortType === 'up' ? 'red' : 'green';
    return isOrder && type === sortState.sortType ? updownColor : 'black';
  }

  const contentBtnProps = { size: 'small', type: 'text' };
  const newHomeItem = isOrder ? orderItem : homeItem;
  return (
    <Card style={{ minHeight: 508 }} bodyStyle={{ padding: '36px 16px 16px 16px' }}>
      <Row wrap={true} gutter={[8, 8]}>
        {
          _.size(newHomeItem) > 0
          &&
          newHomeItem.map(item => {
            const getTotalRevenue = _.get(item, 'totalRevenueRate', 0);
            const totalIconColor = renderUpDownRate2(getTotalRevenue);
            return (
              <Col key={item.productId} {...autoCol([8])}>
                <div
                  className='astsCard'
                  onClick={() => goProductDetails(item)}
                >
                  <Descriptions
                    title={<Text style={{ whiteSpace: 'pre-wrap' }}>{item.productName}</Text>}
                    extra={<ProjectFilled style={{ fontSize: 18, color: totalIconColor.color }} />}
                    column={{ ...autoCol([2, 1, 1]) }}
                    size='small'
                  >
                    <Descriptions.Item label={<Text type='secondary'>{'总资产'}</Text>}>
                      <Text strong>{_.get(item, 'totalAssets', '')}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type='secondary'>{'最新净值'}</Text>}>
                      <RateText rate={_.get(item, 'netValue', '')} noPrefix carry={4}/>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type='secondary'>{'总收益率'}</Text>}>
                      <RateText rate={_.get(item, 'totalRevenueRate', null)} />
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type='secondary'>{'今日'}</Text>}>
                      <RateText rate={_.get(item, 'dailyRevenueRate', null)} />
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type='secondary'>{'开始时间'}</Text>} span={2}>
                      <Text strong>{_.get(item, 'beginDate', '')}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type='secondary'>{'产品交易类型'}</Text>} span={2}>
                      <Text strong>{PRODUCT_STRATEGY_TYPE[_.get(item, 'strategyType', 1)]}</Text>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>
            )
          })
        }
        {!isVisitor && <Col {...autoCol([8])}>
          <div
            className='astsCard_empty'
            onClick={goSubAccount}
          >
            <Text type='secondary'>更多</Text>
          </div>
        </Col>}

        {_.size(homeItem) > 0 && <div className='moreIcon'>
          <Space>
            <Space size='small'>
              {_.keys(SORT_KEY).map((keyname, i) => <Button key={keyname + i} {...contentBtnProps}
                icon={<SortAscendingOutlined style={{ color: renderSortColor(keyname) }} />}
                onClick={() => sortRate(keyname)}>
                {SORT_NAME[keyname]}{sortState[SORT_KEY[keyname]] === 'up' ? '升序' : '降序'}
              </Button>)}
              <Button {...contentBtnProps} disabled={!isOrder} danger onClick={() => {
                setIsOrder(false);
                sortState.sortType = '';
              }}>
                重置
              </Button>
            </Space>
            <div style={{ cursor: 'pointer' }} onClick={goSubAccount}>
              <Tooltip title='查看更多'>
                <EllipsisOutlined />
              </Tooltip>
            </div>
          </Space>
        </div>}
      </Row>
    </Card>
  );
}

export default HomeCard;
import axios from '@/utils/axios';

/**
 *  获取所有策略
 */
export function showAllStrategies(data) {
  return axios.get(global.api + '/trader/control/showAllStrategies', data);
}
/**
 *  指标展示
 */
export function showIndicator(data) {
  return axios.post(global.api + '/trader/control/showIndicator', data);
}
/**
 *  保存自定义策略
 */
export function addCustomPolicy(data) {
  return axios.post(global.api + '/trader/control/addCustomPolicy', data);
}
/**
 *  展示子账户策略
 */
export function showSubAccountStrategy(data) {
  return axios.post(global.api + '/trader/control/showSubAccountStrategy', data);
}
/**
 *  分钟K线展示
 */
export function getKLineDetail(data) {
  return axios.post(global.api + '/stock/kLineDetail', data);
}
export function isAllStockHoliday(params) {
  return axios.post(global.api + '/trader/control/isAllStockHoliday', params);
}
/**
 *  回测
 */
export function indexBackTest(params) {
  return axios.post(global.api + '/trader/control/indexBackTest', params);
}
/**
 *  回测历史记录，及回测结果
 */
export function showBackTestHistory(params) {
  return axios.post(global.api + '/trader/control/showBackTestHistory', params);
}
export function showBackTestHistoryResult(params) {
  return axios.post(global.api + '/trader/control/showBackTestHistoryResult', params);
}
/**
 *  指数k线
 */
export function indexKlineDetail(params) {
  return axios.post(global.api + '/stock/indexKlineDetail', params);
}
export function indexCode() {
  return axios.get(global.api + '/stock/indexCode');
}
//获取股票分红信息
export function getDividend(params) {
  return axios.post(global.api + '/stock/getDividend', params);
}
/**
 *  admin 策略池部分
 */
export function getAllStocks(txt) {
  return axios.get(global.api + '/stock/getAllStocks?search=' + txt);
}
export function showPublicStrategies() {
  return axios.get(global.api + '/strategy/showPublicStrategies');
}
export function showAllStrategiesPools() {
  return axios.get(global.api + '/strategy/showAllStrategiesPools');
}
export function showUserStrategies() {
  return axios.get(global.api + '/strategy/showUserStrategies');
}
export function showPoolStrategies(params) {
  return axios.post(global.api + '/strategy/showPoolStrategies', params);
}
export function createPublicStrategy(params) {
  return axios.post(global.api + '/strategy/createPublicStrategy', params);
}
export function createStrategy(params) {
  return axios.post(global.api + '/strategy/createStrategy', params);
}
export function addStrategies(params) {
  return axios.post(global.api + '/strategy/addStrategies', params);
}
export function approveUserStrategies(params) {
  return axios.post(global.api + '/strategy/approveUserStrategies', params);
}
export function getAvailableStrategies(params) {
  return axios.post(global.api + '/strategy/getAvailableStrategies', params);
}
export function removeStrategies(params) {
  return axios.post(global.api + '/strategy/removeStrategies', params);
}
export function deleteStrategies(params) {
  return axios.post(global.api + '/strategy/deleteStrategies', params);
}
export function showALLApproveStrategies() {
  return axios.get(global.api + '/strategy/showALLApproveStrategies');
}
export function strategiesOnline(params) {
  return axios.post(global.api + '/system/admin/strategiesOnline', params);
}
export function openPermission(params) {
  return axios.post(global.api + '/strategy/openPermission', params);
}
export function updateStrategiesVisible(params) {
  return axios.post(global.api + '/strategy/updateStrategiesVisible', params);
}
export function previewSpecific(id) {
  return axios.get(global.api + '/system/admin/previewSpecific?customStrategiesId=' + id);
}
export function showStrategiesStocks(id) {
  return axios.get(global.api + '/system/admin/showStrategiesStocks?customStrategiesId=' + id);
}
export function setBellwetherStock(params) {
  return axios.post(global.api + '/system/admin/setBellwetherStock', params);
}
export function deleteStrategiesStock(params) {
  return axios.post(global.api + '/system/admin/deleteStrategiesStock', params);
}
export function NewStockOnline(params) {
  return axios.post(global.api + '/system/admin/NewStockOnline', params);
}
export function showRealTimeRate(params) {
  return axios.post(global.api + '/strategy/showRealTimeRate', params);
}
//全市场回测
export function indexBackTestNoLimit(params) {
  return axios.post(global.api + '/system/admin/indexBackTestNoLimit', params);
}
export function showIndexBackTestResult(params) {
  return axios.post(global.api + '/system/admin/showIndexBackTestResult', params);
}
export function exportBackTestResult(params) {
  return axios.post(global.api + '/system/admin/exportBackTestResult', params);
}
// 用户获取自己的策略
export function showCurrentUserStrategies() {
  return axios.get(global.api + '/strategy/showCurrentUserStrategies');
}
// 交易员获取自己的策略
export function showUserCreateStrategies() {
  return axios.get(global.api + '/strategy/showUserCreateStrategies');
}
export function deleteUserStrategies(params) {
  return axios.post(global.api + '/strategy/deleteUserStrategies', params);
}
export function updateUserStrategies(params) {
  return axios.post(global.api + '/strategy/updateUserStrategies', params);
}
// 策略市场
export function strategiesPreview() {
  return axios.get(global.api + '/openStrategy/preview');
}
export function showDurationRates(params) {
  return axios.post(global.api + '/openStrategy/showDurationRates', params);
}

/**
 *  20221122 回测部分
 */
export function backtestCreate(params) {
  return axios.post(global.api + '/backtest/create', params);
}
export function backtestAll(params) {
  return axios.post(global.api + '/backtest/all', params);
}
export function backtestSingle(params) {
  return axios.post(global.api + '/backtest/single', params);
}
export function createOption(params) {
  return axios.post(global.api + '/backtest/createOption', params);
}
export function updateOption(params) {
  return axios.post(global.api + '/backtest/updateOption', params);
}
export function showOption() {
  return axios.get(global.api + '/backtest/showOption');
}
import React, { useState, useEffect } from 'react';
import {
    message,
    PageHeader,
    Input,
    InputNumber,
    Form,
    Button,
    DatePicker,
    Divider,
    notification,
    Space,
} from 'antd';
import { backtestCreate, updateOption } from '@/api/strategy';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { RedoOutlined, CheckOutlined, SaveOutlined } from '@ant-design/icons'
import moment from 'moment';
import _ from 'lodash';
import './testback.scss'

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const form_list = [
    { name: '开始时间', key: 'beginDate', type: '1' },
    { name: '结束时间', key: 'endDate', type: '1' },
    { title: "基础条件" },
    { name: '初始资金', key: 'originAssets', type: '2' },
    { name: '每股买入多少元', key: 'perMoney', type: '2' },
    { name: '一分钟成交量大于昨日平均成交量的x倍', key: 'totalVolumeComparePreAvgVolume', type: '2' },
    { name: '一分钟成交量大于近5日平均成交量的最大值的x倍', key: 'totalVolumeComparePre5DaysMaxAvgVolume', type: '2' },
    { name: '一分钟涨跌幅限制x(小于)', key: 'tempPctChgLimit', type: '2' },
    { name: '当前涨跌幅限制x(大于)', key: 'totalPctChgLimit', type: '2' },
    { name: '昨日涨跌幅限制x(小于)', key: 'prePctChgLimit', type: '2' },
    { name: '前五日最低价到收盘价涨跌幅限制x(小于)', key: 'pre5DaysMaxPctChgLimit', type: '2' },
    { name: '今日开盘涨跌幅限制(小于)', key: 'todayOpenPctChgLimit', type: '2' },
    { title: "条件1" },
    { name: '一分钟涨跌幅限制x(大于)', key: 'tempPctChgOption1X', type: '2' },
    { name: '一分钟成交量与昨日最大值比X(大于)', key: 'preMaxVolumeOption1X', type: '2' },
    { name: '一分钟成交量与近5日最大值的最大值比X(大于)', key: 'pre5DaysMaxMaxVolumeOption1X', type: '2' },
    { title: "条件2" },
    { name: '分钟成交量与昨日平均值比Y(大于)', key: 'preAvgVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与昨日最大值比Y(大于)', key: 'preMaxVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与近5日平均值的最大值比Y(大于)', key: 'pre5DaysMaxAvgVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与近5日最大值的最大值比Y(大于)', key: 'pre5DaysMaxMaxVolumeOption2Y', type: '2' },
    { name: '一分钟涨跌幅限制（大于)', key: 'tempPctChgOption2Y', type: '2' },
    { title: "条件3" },
    { name: '分钟成交量与昨日平均值比Z(大于)', key: 'preAvgVolumeOption3Z', type: '2' },
    { name: '一分钟成交量与昨日最大值比Z(大于)', key: 'pre5DaysMaxMaxVolumeOption3Z', type: '2' },
    { name: '一分钟涨跌幅限制(大于)', key: 'tempPctChgOption3Z', type: '2' },
    { name: '备注', key: 'remark', type: '3' },
]

const TestForm = (props) => {
    const mobxStore = useLocalObservable(() => store);
    const [form] = Form.useForm();
    const [isOption, setIsOption] = useState(false);
    const [optionVals, setOptionVals] = useState({});
    const [accid, setAccid] = useState(0);

    useEffect(() => {
        //props.location.state
        const stateValue = _.get(props, 'location.state', null)
        const getFormState = _.get(props, 'location.state.form', null)
        const getId = _.get(getFormState, 'subAccountId', null);
        const getOne = _.get(getFormState, 'beginDate', null); // 是否有表单的数据
        const getOption = _.get(stateValue, 'option', null)
        setAccid(getId);
        if (!getId) {
            message.error('未发现id！！')
        }
        if (getOne) {
            form.setFieldsValue({
                ...getFormState,
                beginDate: _.get(getFormState, 'beginDate', null) ? moment(getFormState.beginDate) : '',
                endDate: _.get(getFormState, 'endDate', null) ? moment(getFormState.endDate) : '',
            })
        }
        if (getOption) {
            const getOptionForms = _.get(getOption, 'option', {});
            form.setFieldsValue({
                ...getOptionForms,
                beginDate: _.get(getOptionForms, 'beginDate', null) ? moment(getOptionForms.beginDate) : '',
                endDate: _.get(getOptionForms, 'endDate', null) ? moment(getOptionForms.endDate) : '',
            })
            setIsOption(true);
            setOptionVals(getOption);
        }
        if (stateValue) { // 将路由内的state赋值给全局
            mobxStore.changeTestBackup(stateValue);
        }
        return () => { return; }
    }, [])

    const onFinish = async () => {
        const values = await form.validateFields();
        let params = {
            subAccountId: accid,
            ...values,
            beginDate: _.get(values, 'beginDate', null) ? moment(values.beginDate).format('YYYY-MM-DD') : '',
            endDate: _.get(values, 'endDate', null) ? moment(values.endDate).format('YYYY-MM-DD') : '',
        }
        // console.log(isEdit ? '更新' : '新增', params);
        if (isOption) {
            _updateOption(params);
        } else {
            _backtestCreate(params)
        }
    };

    const onReset = () => {
        form.resetFields();
    };
    // 创建新回测
    async function _backtestCreate(pam) {
        const res = await backtestCreate(pam);
        if (_.get(res, 'code', '') === '200') {
            notification.info({
                message: `执行成功！id=${_.get(res, 'data.id', '0')}`,
                description: '执行时间将持续几分钟，请届时刷新数据页面即可查看详情',
                onClose: () => props.history.goBack()
            })
        }
    }
    // 更新回测
    async function _updateOption(pam) {
        const getId = _.get(optionVals, 'id', null);
        if (getId) {
            let params = {
                ...optionVals,
                optionId: getId,
                option: pam,
                remark: _.get(pam, 'remark', '')
            }
            const res = await updateOption(params);
            if (_.get(res, 'code', '') === '200') {
                message.success('已保存！');
                props.history.goBack();
            }
        }
    }

    function renderFormType(type) {
        if (type === '1') {
            return <DatePicker format="YYYY-MM-DD" />
        } else if (type === '2') {
            return <InputNumber />
        } else if (type === '3') {
            return <Input.TextArea allowClear />
        }
    }

    return (
        <div className='common_page_container'>
            <PageHeader
                title={'参数配置'}
                style={{ marginBottom: 10 }}
                onBack={() => props.history.goBack()}
            >
            </PageHeader>

            <Form {...layout}
                form={form}
                name="new-level"
                size='small'
                onFinish={onFinish}
                initialValues={{}}
            >
                {form_list.map((item, i) => {
                    const isTitle = _.get(item, 'title', null);
                    if (isTitle) {
                        return <Divider>{_.get(item, 'title', '')}</Divider>
                    } else {
                        return <Form.Item
                            key={item.key + i}
                            name={_.get(item, 'key', '')}
                            label={_.get(item, 'name', '')}
                            rules={[{ required: true }]}
                        >
                            {renderFormType(item.type)}
                        </Form.Item>
                    }
                })}

                <Form.Item {...tailLayout}>
                    <Space>
                        {!isOption && <Button type="primary" icon={<CheckOutlined />} disabled={accid ? false : true} onClick={onFinish}>
                            创建
                        </Button>}
                        {isOption && <Button type="primary" icon={<SaveOutlined />} disabled={accid ? false : true} onClick={onFinish}>
                            修改并保存
                        </Button>}
                        <Button htmlType="button" type='link' onClick={onReset} icon={<RedoOutlined />} style={{ marginLeft: 8 }}>
                            重置
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}

export default TestForm;
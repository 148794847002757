import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  PageHeader,
  InputNumber,
  Modal,
  Card,
  Button,
  Select,
  Space,
  Input,
  Table,
  message
} from 'antd';
import { findUser, openService } from '@/api/Adm';
import { showSubscriptionServices } from '@/api/userSystem';
import _ from 'lodash';

const { Search } = Input;
const { Option } = Select;

const _validateResponse = (res, empType) => {
  if (_.get(res, 'code') === '200') {
    return _.get(res, 'data', empType || null)
  } else {
    message.info('获取失败！');
    return empType || null
  }
}

const OpenService = (props) => {
  const [subsData, setSubsData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [typeVal, setTypeVal] = useState(null);
  const [count, setCount] = useState(1);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    _getSubsription();

    return () => { return; }
  }, [props.location.state])

  async function _openService() {
    const findLevelidIndex = _.findIndex(subsData, o => o.levelId === typeVal);
    if (findLevelidIndex !== -1) {
      let params = {
        userIds: selectRowKey,
        type: subsData[findLevelidIndex].type,
        levelId: typeVal,
        count: count,
      }
      const res = await openService(params);
      if (_.get(res, 'code') === '200') {
        message.success(_.get(res, 'message', '更新成功!'));
        setVisible(false);
        clearValue();
      }
    }

  }

  function clearValue() {
    setCount(1);
    setTypeVal(null);
  }

  async function _findUser(val) {
    setLoading(true);
    const res = await findUser(val || searchVal);
    if (_.get(res, 'code') === '200') {
      setLoading(false)
      setUserList(_.get(res, 'data', []))
    }
  }

  async function _getSubsription() {
    const subRes = await showSubscriptionServices();
    setSubsData(_validateResponse(subRes, []))
  }

  const columns = [
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '名称',
      dataIndex: 'account',
      key: 'account',
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys) => {
      setSelectRowKey(selectedRowKeys)
    },
    // getCheckboxProps: (record) => ({
    //   disabled: _.findIndex(disList, o => o.id === record.id) !== -1,
    // }),
  };

  return (
    <>
      <PageHeader
        title={'开放用户服务'}
        style={{ border: ' 1px solid rgb(235, 237, 240)', backgroundColor: 'white', marginBottom: 10 }}
      >
        <div style={{ width: 280, marginBottom: 8 }}>
          <Search
            placeholder="手机号，账户名称 搜索用户信息"
            allowClear
            onSearch={(val) => {
              _findUser(val);
              setSearchVal(val)
            }}
          />
        </div>
      </PageHeader>
      <div style={{ margin: 10 }}>
        <Card title='查询结果' extra={
          <Button
            type='primary'
            disabled={_.size(selectRowKey) === 0 ? true : false}
            onClick={() => {
              setVisible(true)
            }}>
            确认开通
          </Button>
        }>
          <Table
            columns={columns}
            dataSource={userList}
            rowKey={'id'}
            loading={loading}
            rowSelection={rowSelection}
          />
        </Card>
      </div>

      <Modal
        title={'参数'}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => _openService()}
      >
        <Space>
          <span>服务类型:</span>
          <Select
            value={typeVal}
            style={{ width: 150 }}
            onChange={(val) => setTypeVal(val)}>
            {_.size(subsData) > 0 && subsData.map((item, index) => (
              <Option key={index} value={item.levelId}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Space>
        <br /><br />
        <Space>
          <span>开通时长（月）:</span>
          <InputNumber min={1} val={count} onChange={(val) => setCount(val)} />
        </Space>
      </Modal>
    </>
  )
}

export default OpenService;
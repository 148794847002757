import React, { useState } from 'react';
import { Button, message, Modal, Row, Table } from 'antd';
import { MinusOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { delFuturesOnFuturesPool,showFuturesPoolFutures } from '@/api/workbench';

export default function StockPoolList(props) {
    const [visible, setvisible] = useState(false);
    const [futureList, setfutureList] = useState([]);

    const columns = [
        {
            title: '名称',
            align: 'center',
            dataIndex: 'name',
        },
        {
            title: '代码',
            align: 'center',
            dataIndex: 'code',
        },
        {
            title: '类型',
            align: 'center',
            dataIndex: 'typeName',
        },
        {
            title: 'k线周期',
            align: 'center',
            dataIndex: 'kline',
            sorter: (a, b) => Number(a.kline) - Number(b.kline),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: 'K线数量',
            align: 'center',
            dataIndex: 'diffnumber',
            sorter: (a, b) => Number(a.diffnumber) - Number(b.diffnumber),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '波动率倍数',
            align: 'center',
            dataIndex: 'stdmultiple',
            sorter: (a, b) => Number(a.stdmultiple) - Number(b.stdmultiple),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益回撤比',
            align: 'center',
            dataIndex: 'incomedrawdown',
            sorter: (a, b) => Number(a.incomedrawdown) - Number(b.incomedrawdown),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '收益率',
            align: 'center',
            dataIndex: 'incomerate',
            sorter: (a, b) => Number(a.incomerate) - Number(b.incomerate),
            render: (text) => (
                <>{text === null?'--':text} </>
            )
        },
        {
            title: '操作',
            render: (text, record, index) => (
                <>
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        shape="round"
                        danger
                        onClick={() => _delfilterFutures(record)}
                    />
                </>
            )
        },
    ]

    function _open() {
        if (!props.futurePoolInfos) {
            return message.error('请选择期货池');
        }
        const obj = props.futurePoolInfos.futuresPoolFuturesContractList;
        let arr = [];
        for(let i in obj){
            arr = arr.concat(obj[i]);
        }
        setfutureList(arr);
        setvisible(true);
    }
    async function updateTable(){
        const data = await showFuturesPoolFutures({accountType:'fundManager' });
        if(data.code === '200'){
            if(data.data !== null){
                data.data.map(item => {
                    if(item.futuresPoolId === props.futurePoolInfos.futuresPoolId){
                        let arr =[];
                        if(item.futuresPoolFuturesContractList !== null){
                            let obj = item.futuresPoolFuturesContractList;
                            for(let i in obj){
                                arr = arr.concat(obj[i]);
                            }
                            setfutureList(arr);
                        }
                    }
                    return item;
                })
            }
        }  
    }
    async function _delfilterFutures(record) {
        let arr = [];
        arr.push(record.id);
        const data = await delFuturesOnFuturesPool({ futuresPoolId: props.futurePoolInfos.futuresPoolId, ids: arr });
        if(data.code === '200'){
            message.success('删除成功');
            props._goBack();
            updateTable();
        }else{
            message.error(data.message);
        }
    }
    async function _delFuturePool() {
        Modal.confirm({
            title: '是否清空该期货池！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let arr = [];
                if(futureList.length !== 0){
                    futureList.map(item => {
                        arr.push(item.id);
                        return item;
                    })
                }
                const data = await delFuturesOnFuturesPool({ futuresPoolId: props.futurePoolInfos.futuresPoolId, ids: arr });
                if (data.code === '200') {
                    message.success('删除成功');
                    setvisible(false);
                    props._goBack();
                } else {
                    message.error(data.message);
                }
            }
        });
    }

    return (
        <>
            <Button
                size="large"
                type="primary"
                className="span"
                onClick={_open}
                // disabled={props.ban}
            >
                查看期货池
            </Button>

            <Modal
                title="期货池列表详情"
                width={1000}
                visible={visible}
                onCancel={() => setvisible(false)}
                footer={null}
            >
                <div>
                    <Row align="middle">
                        <h2>{props.futurePoolInfos && props.futurePoolInfos.futuresPoolName}</h2>
                        <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            icon={<MinusOutlined />}
                            style={{ margin: '0 20px 0 50px' }}
                            onClick={_delFuturePool}
                        />
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    style={{ marginTop: 20 }}
                    dataSource={futureList}
                    columns={columns}
                    bordered
                    pagination={{
                        defaultPageSize: 30,
                    }}
                />
            </Modal>
        </>
    )
}
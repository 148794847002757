import React from 'react';
import { Statistic } from 'antd';
import { renderValueColor, renderValuePrefix } from './utils';
import _ from 'lodash';


const StatisticHeader = ({ title = '', value = 0, isColor = false, isPrefix = false, fSize = 0, stProps = {} }) => {
  let renderStyle = { fontSize: 24, fontWeight: '600' }
  let sProps = { ...stProps }
  if (fSize) {
    renderStyle.fontSize = fSize;
  }
  if (isColor) {
    renderStyle.color = renderValueColor(value, 'replace');
  }
  if (isPrefix) {
    sProps.prefix = renderValuePrefix(value);
  }

  return <Statistic
    title={title}
    value={value}
    valueStyle={renderStyle}
    {...sProps}
  />
}

export default StatisticHeader;
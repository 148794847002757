import React, { useState } from 'react';
import { message, Tabs, PageHeader } from 'antd';
import MainRateCharts from '@/view/common/MainRateCharts';
import DisplayCharts from '@/view/common/DisplayCharts';
import ContributionCharts from './Components/ContributionCharts';
import CompareCharts from './Components/CompareCharts';
import TransCharts from './Components/TransCharts';
import SignalCharts from './Components/SignalCharts';
import CompareChartsTwo from '@/view/common/CompareChartsTwo';
import {
  showSubAccountData, showSubAccountHistory, getHoldingStocksInfo,
  showAllSubAccountHistory, showManagerAssets, showIndexKline
} from '@/api/workbench';
import { checkUserExpire, showSubAccountStrategy, showAutoTradeDetail } from '@/api/userSystem';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useInterval, useMount, useUnmount, useReactive } from 'ahooks';
import { DATE_TYPE, handleHistoryRate, initDateType } from '@/view/common/Components/list_util';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import { validateResponse } from '@/utils/utils';
import { isValidArray, isMarketTrading } from '@/utils/utils2';
import TradeRecord from './TradeRecord';
import CalModal from './Components/CalModal';
import SubAccountTable from './Components/SubAccountTable';
import AutoRecordModal from './Components/AutoRecordModal';
import PositionTable from './Components/PositionTable';
import AssetsHeader from './Components/AssetsHeader';
import KRateCharts from '@/view/common/KRateCharts';
import ReviewCharts from './Components/ReviewCharts';
import { RecentTabs, DetailTabsIcon } from '../ProductDetails/de_widget';
import moment from 'moment';
import _ from 'lodash';
import './list.scss';

message.config({ maxCount: 2 });
const { TabPane } = Tabs;
const INTERVAL_NUM = 30000;
const TODAY = moment().format('YYYY-MM-DD');
const REALTIME_TAB = '1';
const MAIN_RATE = 'MAIN_RATE';
let timer = null;
/**
 * 子账户页面ListsDetails
 * 【原】6个图表所有获取数据都在该页面获取及处理；现修改为单独模块获取；
 * 除收益率有定时任务，及tab切换全局需要拿到该展出；及交易信号联动数据 需要在全局页面进行获取；盈亏分布拆分后已有内部获取，原数据保留在该页面获取；
 */
export default function ListsDetails(props) {
  const mobxStore = useLocalObservable(() => store);
  const accountState = useReactive({
    info: props.location.state.subAccountInfo, symbol: DEFAUL_INDEX_CODE,
  });
  const [accountCacheState] = useState(JSON.parse(JSON.stringify(mobxStore.accountCache)));
  const [userVip] = useState(JSON.parse(JSON.stringify(mobxStore.userVip)));
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const initType = initDateType(tradeDates, REALTIME_TAB);
  const [atab, setAtab] = useState(String(accountState.info.subAccountId));
  const [tabActive, setTabActive] = useState(MAIN_RATE);
  const [assetData, setassetData] = useState({});
  const [subAssest, setSubAsset] = useState({});
  const [hasSubAccount, setHasSubAccount] = useState(false);
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: {}, newData: {} });
  const [indexList, setindexList] = useState([]);
  const [exeTimes, setexeTimes] = useState([]); // 初始全局时间
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const [tradeTime] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'status', '') === 'trading' ? true : false);
  const [timeCount, setTimeCount] = useState(0); // 图表内部更新初始时间
  const [posCount, setPosCount] = useState(0); // 当前持仓update
  const [countMain, setCountMain] = useState(0); // 收益率更新count
  const [signalCount, setSignalCount] = useState(0); // 信号更新
  const [signalPms, setSignalPms] = useState({});
  const [loadingNew, setLoadingNew] = useState(false)
  const [newTabs, setNewTabs] = useState(initType.tab)
  const [isSubListdetail] = useState(_.get(props, 'location.state.canTrade') === 'no' ? false : true)
  const [exUser, setExUser] = useState(_.get(userVip, 'exData', {}));
  const [isFormal, setIsFormal] = useState(_.get(userVip, 'formalDis', null));// 判断是否是正式版用户，交易员正式版开发全部图表数据
  const [calcModal, setCalcModal] = useState({ show: false, data: null, code: null });
  const [autoModal, setAutoModal] = useState({ show: false, data: null });
  const [inter, setInter] = useState(undefined);
  const [indexValue, setIndexValue] = useState({});
  const [idxParams, setIdxParams] = useState({
    'symbol': accountState.info.indexCode ?? DEFAUL_INDEX_CODE, 'dateType': initType.type, 'date': TODAY
  });
  let isUnmont = false;

  useMount(() => {
    _initGetData();
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    };
  });

  useUnmount(() => {
    setInter(undefined);
    isUnmont = true;
    timer && clearTimeout(timer);
  })

  // 盘中定时更新
  useInterval(() => {
    _cutDateType(newTabs); // 只更新实时图表内容
    _showSubAccountData();
    if (isMarketTrading() === false) {
      setInter(undefined);
    }
  }, inter);

  function _initGetData(reload) {
    accountState.info.contributionTime = [TODAY, TODAY];
    accountState.info.dateType = DATE_TYPE[REALTIME_TAB];
    accountState.info.transactionTime = [TODAY, TODAY];
    if (!accountState.info.subAccountId) {
      accountState.info.subAccountId = accountState.info.id;
    }
    //setLoadingNew(true)
    _cutDateType(newTabs, null, 'init'); // 只更新实时图表内容
    _showSubAccountData();
    _showAllSubAccountHistory(); // 获取收益率时间array，有数据的时间周期
    _getHoldingStocksInfo(); // 获取初始时间内的股票
    // 全局交易状态，并执行页面逻辑; 【原】全局时间为原设计逻辑，现在exeTime为统一的全局交易日期
    const getGlbDate = _.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'date', '')
    setexeTimes(getGlbDate);
    setTimeCount(timeCount + 1); //更新图表内部时间； 只有首次进入时更新...
    // console.log('交易状态', tradeDate, getGlbDate, tradeTime)
    const getSubList = _.get(accountState.info, 'subAccountList', []);
    const isHasSubAccount = _.size(getSubList) > 0 ? true : false;
    setHasSubAccount(isHasSubAccount);

    if (tradeTime) {
      setInter(INTERVAL_NUM);
    }
    if (reload) {
      setPosCount(posCount + 1);
      if (tabActive !== MAIN_RATE) {
        setTabActive(MAIN_RATE);
      }
    }
    if (_.size(exUser) === 0) {
      _checkUserExpire();
    }
    if (mobxStore.userInfo.role === 'fund_manager') {
      _getSubAsset();
    }
  }
  // 获取资产信息
  async function _showSubAccountData() {
    const data = await showSubAccountData({ subAccountId: accountState.info.subAccountId });
    if (validateResponse(data, isUnmont)) {
      setassetData(_.get(data, 'data', {}));
    }
  }
  // 获取子账户有收益率数据的时间区间
  async function _showAllSubAccountHistory() {
    if (accountState.info.subAccountId) {
      const res = await showAllSubAccountHistory(accountState.info.subAccountId);
      if (validateResponse(res, isUnmont)) {
        let getDateArray = _.get(res, 'data', [])
        // 返回区间如不包含今天则添加今天，避免获取问题
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  }
  // 切换【1.收益率】图表时间
  function _cutDateType(e = '', date = null, init = '') {
    //e=11 点击日期点，切换到当日实时数据
    setNewTabs(e === '11' ? REALTIME_TAB : e);
    accountState.info.dateToday = e === '11' ? date : TODAY;
    const new_type = parseInt(e) > 100 ? DATE_TYPE['12'] : DATE_TYPE[e];
    accountState.info.dateType = new_type;
    // 上个接口=ALL直接切换
    if (_.get(idxParams, 'dateType') === 'ALL' && new_type === 'ALL' && init !== 'init') {
      updateMain();
    } else {
      // 实时：开启定时任务 
      setInter(e === REALTIME_TAB && tradeTime ? INTERVAL_NUM : undefined);
      setLoadingNew(true)
      _showSubAccountHistory();
    }
  }
  // 账户收益率获取
  async function _showSubAccountHistory() {
    let params = {
      dateType: accountState.info.dateType,
      subAccountId: accountState.info.subAccountId,
      date: _.get(accountState.info, 'dateToday', TODAY),
    }
    let idxPms = {
      symbol: accountState.symbol,
      dateType: params.dateType,
      date: params.date,
    }
    const res = await showSubAccountHistory(params);
    // 因增加时间对比进行裁剪，正常将不再对指数数据进行缓存，两个数据需要同时获取
    const resIdx = await showIndexKline(idxPms);
    setIdxParams(_.assign(params, idxPms));
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    if (_.get(resIdx, 'message', '') !== 'success') {
      message.info(_.get(resIdx, 'message'))
    }
    if (validateResponse(res, isUnmont) && isValidArray(_.get(res, 'data.pointList', []))) {
      const rateData = handleHistoryRate(res, resIdx, params.dateType);
      setPointDatas({ 'timeList': rateData.timeList, 'datas': rateData.datas, 'newData': rateData.newData });
      setIndexValue(rateData.indexVal);
    } else {
      setPointDatas({ timeList: [], datas: {}, newData: {} });
      setIndexValue({});
    }
    updateMain();
  }

  async function _showIndexKline(idx) {
    let params = {
      symbol: idx ? idx : idxParams.symbol,
      dateType: idxParams.dateType,
      date: idxParams.date
    }
    const res = await showIndexKline(params);
    setIdxParams(_.assign(idxParams, params))
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {});
      setIndexValue(getData);
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    updateMain();
  }
  // 【废弃】迭代后indexList数据很少被使用，当前仅用于避免空股票信息占位使用
  async function _getHoldingStocksInfo(time1, time2) {
    const yestoday = moment().subtract(1, 'day').format('YYYY-MM-DD');
    // console.log('_getHoldingStocksInfo', time1, time2)
    const holdStockRes = await getHoldingStocksInfo({
      subAccountId: accountState.info.subAccountId,
      beginDate: time1 || yestoday,
      endDate: time2 || TODAY
    });
    if (validateResponse(holdStockRes, isUnmont)) {
      const getData = _.get(holdStockRes, 'data', {});
      const stockNameList = Object.keys(getData);
      const newStockList = _.isObject(getData) ? stockNameList.map(keyname => {
        return { title: getData[keyname], value: keyname }
      }) : []
      // console.log('newStockList', newStockList)
      setindexList(newStockList)
    }
  }

  async function _getSubAsset() {
    const data = await showManagerAssets({ subAccountId: accountState.info.subAccountId });
    // console.log('_getSubAsset', data)
    if (validateResponse(data, isUnmont)) {
      setSubAsset(data.data);
    }
  }
  async function _checkUserExpire() {
    const data = await checkUserExpire();
    // console.log('会员过期查询：', data)
    if (validateResponse(data, isUnmont)) {
      let newData = { ...data.data };
      let formalDisable = true;
      if (mobxStore.userInfo.role === 'trader') {
        formalDisable = _.get(newData, 'type', '') === 'official' ? true : false
      }
      setIsFormal(formalDisable)
      setExUser(newData);
    }
  }
  async function _showAutoTradeDetail(id) {
    const data = await showAutoTradeDetail(id);
    //  console.log('查看自动托管记录：', data)
    if (validateResponse(data)) {
      let newData = { ...data.data };
      setAutoModal({ show: true, data: newData })
    }
  }
  async function _showSubAccountStrategy(code, isUpdate) {
    if (code) {
      const data = await showSubAccountStrategy({
        subAccountId: accountState.info.subAccountId,
        symbol: code
      });
      // console.log('查询当前策略：', data)
      if (validateResponse(data)) {
        setCalcModal({ show: true, data: data.data, code: code })
      }
      if (isUpdate) {
        _checkUserExpire();
      }
    }
  }

  function callback(value) {
    if (value === MAIN_RATE) {
      _cutDateType(newTabs);
    }
    if (value !== MAIN_RATE) {//&& tradeTime
      setInter(undefined);
    }
    setTabActive(value);
  };

  function updateMain() {
    setCountMain(_.round(countMain + 0.1, 1));
  }

  //跳转股票页面
  function _goShares() {
    props.history.push({
      pathname: '/home/StockScreening',
      state: { subAccountInfo: _.cloneDeep(accountState.info) }
    });
  }

  const isVisitor = mobxStore.userInfo.role === 'visitor' ? true : false;
  const asset_props = { 'role': mobxStore.userInfo.role, 'assetData': assetData, 'isSubListdetail': isSubListdetail, 'subAccountInfo': accountState.info };
  const main_props = {
    'pageKey': 'lists', 'upcount': countMain, 'role': mobxStore.userInfo.role, 'isTrading': tradeTime, 'subAccountId': accountState.info.subAccountId,
    'datas': pointDatas, 'dailyRevenueRate': _.round(parseFloat(_.get(assetData, 'dailyRevenueRate', 0)), 2),
    'currentTabs': newTabs, 'loading': loadingNew, 'indexValue': indexValue, 'indexSymbol': accountState.symbol,
    'pms': idxParams, 'validDateRange': validDate, 'reloadCount': posCount, 'tradeDates': tradeDates
  };
  const sub_props = {
    'active': tabActive, 'subAccountId': accountState.info.subAccountId, 'indexList': indexList,
    'todayFormat': TODAY, 'id': accountState.info.subAccountId, 'globalDate': exeTimes,
  };
  return (
    <div className="contentView" id="listsDetails">
      <PageHeader
        title={_.get(accountState.info, 'subAccountName', '')}
        onBack={() => props.history?.goBack()}
        style={{ backgroundColor: 'white', marginBottom: 10 }}
      >
        <RecentTabs
          active={atab}
          pageKey='lists'
          items={accountCacheState}
          id={accountState.info.subAccountId}
          onItemChange={(activeKey, itemVal) => {
            setAtab(activeKey);
            accountState.info = itemVal;
            _initGetData(true);
          }}
        />
      </PageHeader>

      <AssetsHeader  // 资产信息
        {...asset_props}
        hasSubAccount={hasSubAccount}
        subAssest={subAssest}
        goShares={_goShares}
      />

      <div className='listTab'>
        <Tabs activeKey={tabActive} onChange={callback} type='card'>
          <TabPane tab={<DetailTabsIcon keys={MAIN_RATE} />} key={MAIN_RATE} disabled={!isFormal}>
            <MainRateCharts
              {...main_props}
              cutDateType={_cutDateType}
              checkDayRate={(key, date) => _cutDateType(key, date)}
              indexChange={(idx) => {
                accountState.symbol = idx;
                _showIndexKline(idx);
              }}
            />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='RATE_K' />} key='RATE_K'>
            <KRateCharts
              pageKey='account'
              subAccountId={accountState.info.subAccountId}
              newProps={props}
              indexSymbol={accountState.symbol}
            />
          </TabPane>
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION2' />} key='CONTRIBUTION2' disabled={!isFormal}>
            <ContributionCharts
              pageKey='account'
              subAccountId={accountState.info.subAccountId || 0}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='REVIEW' />} key='REVIEW'>
            <ReviewCharts
              subAccountId={accountState.info.subAccountId || 0}
              isTrading={mobxStore.inTrading === 'trading' ? true : false}
            />
          </TabPane>}
          <TabPane tab={<DetailTabsIcon keys='DISTRIBUTION' />} key='DISTRIBUTION' disabled={!isFormal}>
            <DisplayCharts
              {...sub_props}
              pageKey='list'
              upTimeCount={timeCount}
            />
          </TabPane>
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='SIGNAL' />} key='SIGNAL' disabled={!isFormal}>
            <SignalCharts
              {...sub_props}
              upcount={signalCount}
              sigPms={signalPms}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='COUNTING' />} key='COUNTING' disabled={!isFormal}>
            <TransCharts  {...sub_props} newProps={props} />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='EARNING' />} key='EARNING' disabled={!isFormal}>
            <CompareCharts
              {...sub_props}
              newProps={props}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='ACC_COM' />} key='ACC_COM' disabled={!isFormal}>
            <CompareChartsTwo
              pageKey='account'
              active={tabActive}
              isTrading={tradeTime}
              accountInfo={{
                dateType: accountState.info.dateType,
                subAccountId: accountState.info.subAccountId,
                date: _.get(accountState.info, 'dateToday', TODAY),
              }}
              idxPms={idxParams}
            />
          </TabPane>}
        </Tabs>
      </div>

      <br />
      <PositionTable  // 当前持仓
        {...asset_props}
        validDateRange={validDate}
        newProps={props}
        exUser={exUser}
        upCount={posCount}
      />
      <br />

      <SubAccountTable  // 子账户列表
        isShow={hasSubAccount}
        subAccountInfo={accountState.info}
      />

      <TradeRecord  // 交易记录
        role={mobxStore.userInfo.role}
        subAccountId={accountState.info.subAccountId}
        strategyType={accountState.info.strategyType}
        tradeStatus={mobxStore.inTrading}
        upCount={timeCount}
        newProps={props}
        onTimeChange={(date) => {
          accountState.info.startAndEndTime = date;
        }}
        onAutoRecord={(id) => {
          if (id) { // 当前版本sholist数据中不包含id.. 【暂时停用功能】
            _showAutoTradeDetail(id);
          }
        }}
        onSignal={(recordVal, dateArray) => {
          document.documentElement.scrollTop = document.body.scrollTop = 180;
          setTabActive('SIGNAL');
          const getSymbol = _.get(recordVal, 'stockCode');
          const getMode = _.get(recordVal, 'tradeMode', 0);
          setSignalPms({
            'selectStocks': getSymbol,
            'traderIndex': 'all',
            'startTime': dateArray[0],
            'endTime': dateArray[1],
            'tradeMode': getMode,
            'stockName': _.get(recordVal, 'stockName', null)
          });
          setSignalCount(prev => _.round(prev + 0.1, 1));
        }}
      />

      <CalModal // 策略服务启动 modal
        visible={_.get(calcModal, 'show')}
        sData={_.get(calcModal, 'data')}
        strategyNumber={{
          sNumber: _.get(exUser, 'strategiesNum'),
          openNumber: _.get(exUser, 'openStrategiesNum'),
        }}
        onCancel={() => setCalcModal({ show: false, data: null })}
        onChange={() => {
          _showSubAccountStrategy(_.get(calcModal, 'code'), 'update');
        }}
      />

      <AutoRecordModal // 自动下单记录 modal
        visible={_.get(autoModal, 'show')}
        datas={[_.get(autoModal, 'data')]}
        onCancel={() => setAutoModal({ show: false, data: null })}
      />
    </div>
  )
}
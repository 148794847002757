import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  PageHeader,
  Typography,
  Button,
  message,
  Modal,
  Image,
  List,
  Avatar
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { observer, inject } from 'mobx-react';
import { showAccount, unbindAccount, getVerifPhoneCode } from '@/api/userSystem';
import { getUserPhone } from '@/api/auto';
import { _validateResponse } from '@/utils/utils';
import PhoneVerify from '@/view/common/PhoneVerify';
import moment from 'moment';
import _ from 'lodash';

const { confirm } = Modal;
const { Text, Title } = Typography;

@inject('store')
@observer
class PaySetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aliStatus: JSON.parse(JSON.stringify(this.props.store.accoutStatus.alipay)) || {},
      visible: false,
      vCount: 0,
      userPhone: {},
    }
    this.userInfo = JSON.parse(JSON.stringify(this.props.store.userInfo))
    // this.routeData = props.location.state;
    this.userid = _.get(this.userInfo, 'id', null)
    this.redirectUrl = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002188652648&state=${this.userid}&scope=auth_user&redirect_uri=https://simx.cfirstquant.com/api/auth/authCallBack`
  }

  componentDidMount() {
    this._getUserPhone();
  }

  _getUserPhone = async () => {
    let phoneRes = await getUserPhone();
    this.setState({
      userPhone: _validateResponse(phoneRes, {})
    })
  }

  goAliBind = async () => {
    const w = window.open('about:blank');
    w.location.href = this.redirectUrl;

    confirm({
      title: '是否绑定完成？',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        this._showAccount()
      },
      onCancel() { },
    });
  }

  unbind = async (values) => {
    const { aliStatus } = this.state;
    let params = {
      id: _.get(aliStatus, 'id'),
      code: _.get(values, 'phoneCode', null),
      phone: _.get(values, 'phone', null),
    }

    if (!params.code) {
      message.info('请输入验证码！')
      return
    }
    if (!params.phone) {
      message.info('请输入手机号！')
      return
    }

    let unbindRes = await unbindAccount(params);
    if (_.get(unbindRes, 'code', '') === '200') {
      message.success('解绑成功！')
      this.setState({ visible: false })
      this._showAccount();
    }
  }

  render() {
    const { aliStatus, visible, userPhone } = this.state;
    // console.log('form', form);
    const data = [
      {
        title: _.get(aliStatus, 'name', '绑定支付宝账户'),
        type: _.get(aliStatus, 'name', null) ? '支付宝账户' : ''
      },
    ];
    const newAvatar = _.get(aliStatus, 'avatar') ?
      <Avatar shape="square" src={<Image src={_.get(aliStatus, 'avatar')} style={{ width: 100, borderRadius: 15 }} />} />
      : null
    const isAlreadyBind = _.get(aliStatus, 'userId');
    return (
      <div>
        <PageHeader
          ghost={false}
          title='支付设置'
          onBack={() => this.userInfo.role === 'trader' ? this.props.history.goBack() : null}
        >

        </PageHeader>

        <Card>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={newAvatar}
                  title={<Title level={4}>{item.title}</Title>}
                  description={item.type}
                />
                <div>
                  <Button
                    type={isAlreadyBind ? 'default' : 'primary'}
                    disabled={this.userid ? false : true}
                    onClick={() => {
                      if (isAlreadyBind) {
                        this.setState({ visible: true, vCount: this.state.vCount + 1 })
                      } else {
                        this.goAliBind()
                      }
                    }}
                  >
                    {isAlreadyBind ? '解除绑定' : '点击绑定'}
                  </Button>
                </div>
              </List.Item>
            )}
          />
        </Card>

        <PhoneVerify
          vCount={this.state.vCount}
          visible={visible}
          pageKey='paysetting'
          userPhone={_.get(userPhone, 'phone', null)}
          phoneHandleCancel={() => this.setState({ visible: false })}
          gobackValues={this.unbind}
          getCode={getVerifPhoneCode}
        />
      </div>
    )
  }
}

export default PaySetting;
import React, { Component } from 'react';
import {
  PageHeader,
  Button,
  message,
  Row,
  Col,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons'
import { showAllManagerSub, joinSubAccountPool, showSubAccountPool } from '@/api/Adm';
import AllSubTable from './Pool/AllSubTable';
import SubPoolTable from './Pool/SubPoolTable';
import _ from 'lodash';

class SubManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allSub: [],
      poolList: [],
    }
    this.routeData = props.location.state;
  }

  componentDidMount() {
    this._showAllManagerSub();
    this._showSubAccountPool();
  }

  _showAllManagerSub = async (txt) => {
    const data = await showAllManagerSub(txt);
    if (_.get(data, 'code', '') === '200') {
      this.setState({
        allSub: _.get(data, 'data', []),
      })
    }
  }

  _showSubAccountPool = async () => {
    const data = await showSubAccountPool();
    if (_.get(data, 'code', '') === '200') {
      this.setState({
        poolList: _.get(data, 'data', []),
      })
    }
  }

  _joinSubAccountPool = async (list) => {
    let params = {
      subs: list,
    }
    const res = await joinSubAccountPool(params);
    if (_.get(res, 'code', '') === '200') {
      message.success('添加成功！')
      this._showSubAccountPool();
    } else {
      message.info('添加失败！')
    }
  }

  render() {
    const { allSub, poolList } = this.state;
    return (
      <div className="contentView" style={{ backgroundColor: 'white' }}>
        <PageHeader
          title={'管理基金经理子账户'}
          style={{ border: ' 1px solid rgb(235, 237, 240)', marginBottom: 10 }}
        >

        </PageHeader>
        <Row gutter={8}>
          <Col span={12}>
            <AllSubTable
              subDatas={allSub}
              poolList={poolList}
              onAdd={(list) => {
                this._joinSubAccountPool(list);
              }}
              onSearch={(txt) => {
                this._showAllManagerSub(txt)
              }}
            />
          </Col>
          <Col span={12}>
            <SubPoolTable
              datas={poolList}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default SubManager;
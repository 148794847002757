import React from 'react';
// import JSEncrypt from 'jsencrypt';
import jsrsasign from 'jsrsasign';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

const createSixteenKey = () => {
  let random = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let str = "";
  for (let i = 0; i < 16; i++) {
    str = str + random.charAt(Math.random() * random.length)
  }
  return str;
}
//排序对象的key,返回指定字符串及对象数据 【例】highLimitPrice=1793.88&isBalance=1&list=[11,10,0]
function sortKeyValue(obj) {
  let sortString = ''; let sortObj = {};
  if (_.isObject(obj) && _.size(obj) > 0) {
    _.sortBy(_.keys(obj), o => o).map((keyname, i) => {
      let vals = obj[keyname];
      let vals_array = _.isArray(vals) ? '[' + vals + ']' : vals; // 数组需要手动增加数组括号
      sortString = sortString + `${i !== 0 ? '&' : ''}${keyname}=${vals_array}`;
      sortObj[keyname] = vals;
    })
  }
  return { string: sortString, objectString: JSON.stringify(sortObj) };
}

const AES_KEY = 'w329lTFi26SNvS8f'; // 当前前后端必须使用16位key值，否则后端无法解密
const CRYPTO_MODE = {
  // iv: iv,
  mode: CryptoJS.mode.ECB,  // mode 与后台一致。有多个模式可选
  padding: CryptoJS.pad.Pkcs7,
}
// 加密
export function cryptoDatas(data) {
  const time_stamp = new Date().getTime();
  let renderSortData = sortKeyValue((_.assign(data, { timeStamp: time_stamp })));
  // let gKey = createSixteenKey();
  let key = CryptoJS.enc.Utf8.parse(AES_KEY); // 密钥：一个常量，前后端协定后一个字符串即可
  let jsonString = CryptoJS.enc.Utf8.parse(renderSortData.objectString);
  //let iv = CryptoJS.enc.Utf8.parse('0123456789ABCDEF'); // 偏移量：一个常量，前后端协定后一个字符串，前后端一致即可

  let encrypted = CryptoJS.AES.encrypt(jsonString, key, CRYPTO_MODE);

  return {
    sign: signature(renderSortData.string),  
    data: encrypted.toString(),
    // org: dataString, //原始拼接字符串,【测试用】
    timeStamp: time_stamp,
  }
}
// 加密单个字符串数值
export function cryptoString(string) {
  let key = CryptoJS.enc.Utf8.parse(AES_KEY);
  let stringVal = CryptoJS.enc.Utf8.parse(string);
  let encrypted = CryptoJS.AES.encrypt(stringVal, key, CRYPTO_MODE);
  return encrypted.toString();
}

const SIGNATURE_PRIVITE_KEY = 'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEAk0MpuLbDZZk8xbl+pVyi8ysRza9onto9NeZBLbh0n7C3pEnGFAB+bD3uvfJzKHJ2J1R0dJKFqdgt5TfJ7AqxKQIDAQABAkAT8lv7Xh4Xa+s0aQzDf0m/IE1YC/Ni2PLDkz2u4LZDTuqZmwpWPusFvfwKgwnKottGv/Vm+STeCd+TaBOCofKBAiEA2DP3WuLj3BC1QHrILIDA6cLj33fsjSViIcFYOExBEvECIQCuXon2pZzBmXqdHjEaG92O/8lQWmMDH+/1jSxIJuIRuQIgbdQYN6dCsSwbpyG4RkMxvI4wS/kTxa/T5end8ttpw0ECICLtvSUzN5twaydeDOf1/QPQKTt3X64Yj7DYfq5VW6PBAiA7qTlq1bAV6gZ/GVr62ZGR83VuX3xV310WuJI4g1j4EA==\n'
// 私钥加签
function signature(signData) {
  let signPrivateKey = "-----BEGIN PRIVATE KEY-----\n" +
    SIGNATURE_PRIVITE_KEY
    + "-----END PRIVATE KEY-----";
  let sig = new jsrsasign.KJUR.crypto.Signature({ "alg": "SHA1withRSA", "prov": "cryptojs/jsrsa", "prvkeypem": signPrivateKey });
  let hashAlg = 'sha1'; // 设置sha1
  let sign = sig.signString(signData, hashAlg); // 加签
  sign = jsrsasign.hex2b64(sign);

  return sign;
}

import React, { useState, memo } from 'react';
import { Row, Col, Card, Typography, Select, Button, List, Input, Collapse, message } from 'antd';
import { showTraderPerformance } from '@/api/workbench';
import { useMount, useUnmount } from 'ahooks';
import { validateResponse } from '@/utils/utils';
import axios from 'axios';
import _ from 'lodash';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { Panel } = Collapse;

const Achieve = ({ assetssArr, assetss }) => {
  const [result, setresult] = useState('all');
  const [tarderPerform, settarderPerform] = useState([]);
  const [down, setdown] = useState([]);
  let isUnmont = false;

  useMount(() => {
    showTraderInit();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function showTraderInit() {
    const data = await showTraderPerformance({ type: 'all' });
    if (validateResponse(data, isUnmont)) {
      if (data.data !== null) {
        settarderPerform(data.data)
      }
    }
  }

  async function showTrader(value) {
    let params = {
      type: result,
      search: value
    }
    const data = await showTraderPerformance(params);
    if (data.code === '200') {
      if (data.data !== null) {
        settarderPerform(data.data)
      }
    }
  }

  async function getLoad() {
    if (!down[0]) {
      let arr = [];
      if (assetss.currentProductResultList.length !== 0) {
        assetss.currentProductResultList.forEach(item => {
          arr.push(item.productName);
        })
      }
      axios.post(global.api + '/fundManager/exportTraderPerformance', {
        productList: arr,
      }, { responseType: "blob" }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = '文件.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(err => {
        console.log(err);
      })
    } else {
      axios.post(global.api + '/fundManager/exportTraderPerformance', {
        productList: down,
      }, { responseType: "blob" }).then(res => {
        if (res.type !== 'application/json') {
          const url = window.URL.createObjectURL(new Blob([res],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = '文件.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          message.error("文件下载失败");
          return;
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  function selectProduct(e) {
    let arr = [];
    if (e !== 'all') {
      arr.push(e);
      setdown(arr);
    } else {
      if (assetss.currentProductResultList.length !== 0) {
        assetss.currentProductResultList.forEach(item => {
          arr.push(item.productName);
        })
      }
      setdown(arr);
    }
  }

  return (
    <Col span={24}>
      <Card>
        <Title level={5}>交易员业绩</Title>
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel header="条件搜索" key="1">
            <Row justify="start" align="middle" gutter={[8, 8]}>
              <Col sm={24} md={12} xl={6} xxl={6}>
                <Select
                  defaultValue="全部"
                  size='small'
                  onChange={setresult}
                >
                  <Option value="all">全部</Option>
                  <Option value="trader">交易员</Option>
                  <Option value="product">产品</Option>
                </Select>
              </Col>

              <Col sm={24} md={12} xl={16} xxl={12}>
                <Search
                  placeholder="请输入交易员/产品"
                  allowClear
                  enterButton
                  style={{ width: 200, marginRight: 10 }}
                  onSearch={showTrader}
                  size='small'
                />
              </Col>

              <Col sm={24} md={12} xl={8} xxl={6}>
                <Select
                  defaultValue="股票"
                  size='small'
                >
                  <Option value="stock">股票</Option>
                  <Option value="fund">基金</Option>
                  <Option value="futures">期货</Option>
                </Select>
              </Col>

            </Row>
            <Row justify="start" align="middle">
              <Col sm={24} md={12} xl={8} xxl={6} style={{ paddingTop: 10 }}>
                <span>产品:</span>
              </Col>

              <Col sm={24} md={12} xl={16} xxl={12}>
                <Select
                  style={{ width: 200, marginTop: 10 }}
                  defaultValue='all'
                  size='small'
                  onChange={selectProduct}
                >
                  <Option value="all">全部</Option>
                  {
                    assetssArr.currentProductResultList.length !== 0 &&
                    assetssArr.currentProductResultList.map((item) => {
                      return (
                        <Option
                          key={item.productId}
                          value={item.productName}
                        >
                          {item.productName}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Col>

              <Col sm={24} md={12} xl={8} xxl={6}>
                <Button
                  type='primary'
                  size='small'
                  style={{ marginTop: 10, marginLeft: 10 }}
                  onClick={getLoad}>
                  导出
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        {
          tarderPerform.length !== 0 &&
          <List
            itemLayout="horizontal"
            dataSource={tarderPerform}
            pagination={{
              pageSize: 6,
            }}
            renderItem={item => (
              <List.Item>{item}</List.Item>
            )}
          />
        }
      </Card>
    </Col>
  )
}

export default memo(Achieve);
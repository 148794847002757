import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Radio, Input, List, Button, Modal, message, Checkbox,InputNumber } from 'antd';
import { getListRiskControl, posMessageRead, delMessage, delBatchMessage, queryContactsRiskList,excuteRiskControl,showSubAccountRules,createTemporaryRulesEasy } from '@/api/workbench';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ShowMessage from './ShowMessage';
import moment from 'moment';

export default function FundManagerNewsRisk(props) {
    const [readList, setreadList] = useState([]);
    const [unReadlist, setunReadlist] = useState([]);
    const [state, setstate] = useState(0);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [showMessageData, setshowMessageData] = useState({});
    const [batchMessage, setbatchMessage] = useState([]);
    const [riskValue, setriskValue] = useState(0);
    const [dealDisabled, setdealDisabled] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [nowItem, setnowItem] = useState();
    useEffect(() => {
        async function _get() {
            const data = await getListRiskControl();

            const arr = data.data;
            let brr = [];
            let crr = [];
            if(arr.length !== 0){
                arr.map(item => {
                    if(item.type === 'execute_risk_control'){
                        item.hasexe = true;
                    }
                    if(item.status === 0){
                        brr.push(item);
                    }else{
                        crr.push(item);
                    }
                    return item;
                })
            }
            setreadList(crr);
            setunReadlist(brr);
        }
        _get();
    }, [])

    async function _get() {
        const data = await getListRiskControl();
        const arr = data.data;
        let brr = [];
        let crr = [];
        if(arr.length !== 0){
            arr.map(item => {
                if(item.type === 'execute_risk_control'){
                    item.hasexe = true;
                }
                if(item.status === 0){
                    brr.push(item);
                }else{
                    crr.push(item);
                }
                return item;
            })
        }
        setreadList(crr);
        setunReadlist(brr);
    }

    async function _messageRead(item, del) {
        if (!del) {
            const data = await posMessageRead({ messageId: item.messageId });
            if (data.code === '200') {
                _get();
            }
        } else {
            item.delete = del;
        }
        setshowMessageData(item);
        setisModalVisible(true);
    }
    async function _messageDel({ messageId }) {
        Modal.confirm({
            title: '是否删除该消息！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await delMessage({ messageId });
                if (data.code === '200') {
                    _get();
                    message.success('删除成功');
                }
            }
        });
    }
    function bathSelectChange(e, item) {
        let arr = JSON.parse(JSON.stringify(batchMessage));
        if (e.target.checked) {
            arr.push(item.messageId);
            setbatchMessage(arr);
        } else {
            let index = arr.indexOf(item.messageId);
            if (index !== -1) {
                arr.splice(index);
            }
            setbatchMessage(arr);
        }
    }
    async function delBathMessage() {
        if (batchMessage.length === 0) return message.error('请选择要删除的消息!');

        Modal.confirm({
            title: '是否批量删除选中的消息！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await delBatchMessage({ messageIdList: batchMessage });
                if (data.code === '200') {
                    _get();
                    message.success('批量删除成功');
                } else {
                    message.error(data.message);
                }
            }
        });
    }

    async function onSearch(value) {
        if (value.length !== 0) {
            const data = await queryContactsRiskList({ sender: value });
            if(data.code === '200') {
                // setlist(data.data);
            }
        }else{
            _get();
        }
    }
    async function perform(item) {
        Modal.confirm({
            title: '是否确认执行预警操作？',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await excuteRiskControl({subAccountId:item.subAccountId,lineType:'1',riskDay:''});
                if(data.code === '200'){
                    if(data.message === 'subAccount already alerts'){
                        message.success("已执行操作，该交易员账户已处于预警期");
                        return;
                    }
                    message.success(data.message);
                }else{
                    message.error(data.message);
                }
            }
        });
    }
    async function dealWith(item) {
        const data = await showSubAccountRules({subAccountId:item.subAccountId});
        if(data.code === '200'){
            setriskValue(data.data.alertLine);
            setdealDisabled(true);
            setnowItem(item);
            const sendTime = item.sendTime;
            const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
            if(nowTime.diff(sendTime,'minutes')>30){
                setisDisabled(true);
                message.info("已超过30分钟，不可修改预警线");
            }
        }else{
            message.error(data.message);
        }
    }
    async function createTemp() {
        if(isDisabled){
            setdealDisabled(false);
            return;
        }
        const data = await createTemporaryRulesEasy({subAccountId:nowItem.subAccountId,alertLine:riskValue});
        if(data.code === '200'){
            message.success(data.message);
        }else{
            message.error(data.message);
        }
    }

    return (
        <div className="contentView">
            <Modal
                title="风控规则"
                visible={dealDisabled}
                onOk={createTemp}
                onCancel={() => {setdealDisabled(false)}}
            >
                <span style={{ marginRight: 20 }}>预警线:</span>
                <InputNumber min={0} value={riskValue} disabled={isDisabled} onChange={setriskValue} step={0.01}/>
                <br/><br/><br/>
                {isDisabled?'':<span style={{ color:'red' }}>注意：此预警线设置后，两小时内有效，过期后自动恢复初始预警线，进入风控管理进行调整</span>}
            </Modal>
            <ShowMessage
                showMessageData={showMessageData}
                isModalVisible={isModalVisible}
                handleCancel={() => setisModalVisible(false)}
                messageDel={() => _messageDel(showMessageData)}
            />
            <Card>
                <Row justify="space-between">
                    <Col><h2>风控通知</h2></Col>
                    <Col>
                        <Col>
                            <Radio.Group size="large" defaultValue={state} onChange={(e) => setstate(e.target.value)}>
                                <Radio.Button value={0}>未读</Radio.Button>
                                <Radio.Button value={1}>已读</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Col>
                    <Col>
                        <Row justify="space-between" align="middle" gutter={36} >
                            <Col>
                                <Input.Search size="large" placeholder="请输入联系人" onSearch={onSearch} />
                            </Col>
                            <Col>
                                {
                                    state === 1 &&
                                    <Button type="primary" onClick={delBathMessage}>批量删除</Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <br /><br />
            <Card>
                {
                    state === 0?
                    // 未读
                    <List
                        itemLayout="horizontal"
                        dataSource={unReadlist}
                        pagination={{
                            defaultPageSize: 10,
                            hideOnSinglePage:true
                        }}
                        renderItem={item => (
                            <List.Item 
                                actions={item.hasexe?
                                    [<Row>
                                        <Button type="link" onClick={() => perform(item)}>执行</Button>
                                        <Button type="link" onClick={() => dealWith(item)}>处理</Button>
                                    </Row>]
                                    :
                                    null
                                }
                            >
                                <List.Item.Meta
                                    onClick={() => _messageRead(item)}
                                    title={
                                        <Row align="middle" gutter={16} >
                                            <Col><h2>{item.sender}</h2></Col>
                                            <Col><span className="colorGrey">{item.sendTime}</span></Col>
                                        </Row>
                                    }
                                    description={
                                        <div style={{ color: '#000' }}>
                                            {item.content}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    :
                    // 已读
                    <List
                        itemLayout="horizontal"
                        dataSource={readList}
                        pagination={{
                            defaultPageSize: 10,
                            hideOnSinglePage:true
                        }}
                        renderItem={item => ( 
                            <List.Item
                                actions={item.hasexe?
                                    [<Row>
                                        <Button type="link" onClick={() => perform(item)}>执行</Button>
                                        <Button type="link" onClick={() => dealWith(item)}>处理</Button>
                                        <Button onClick={() => _messageDel(item)} type="link">删除</Button>
                                    </Row>]
                                    :
                                    [<Button onClick={() => _messageDel(item)} type="link">删除</Button>]
                                }
                            >
                                <List.Item.Meta
                                    avatar={<Checkbox onChange={(e) => bathSelectChange(e, item)} />}
                                    title={
                                        <Row align="middle" gutter={16} onClick={() => _messageRead(item, true)}>
                                            <Col><h2>{item.sender}</h2></Col>
                                            <Col><span className="colorGrey">{item.sendTime}</span></Col>
                                        </Row>
                                    }
                                    description={<div style={{ color: '#000' }}  >{item.content}</div>}
                                />
                            </List.Item>
                        )}
                    />
                }
            </Card>
        </div>
    )
}
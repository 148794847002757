import React from 'react';
import { Table, Button, Tag, Typography } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { singleColumns, colWidth } from '@/utils/utils';
import { renderOrderStatus } from '@/view/common/Components/list_util';
import _ from 'lodash';

const { Text } = Typography;
// 交易记录表格
const RecordTable = ({
  list = [],
  orderKey = '',
  isProduct = false,
  loading = false,

  showBreak,
  showKline
}) => {
  // 区分分页
  const columnsIndex = [
    {
      ...singleColumns(['序号', 'indexFirst', 50]),
      render: (text, record, index) => index + 1,
    },
  ];
  let columns = [
    singleColumns(['委托编号', 'clientOrderId', 130]),
    singleColumns(['子账户', 'name', 130]),
    {
      ...singleColumns(['股票', 'stock', 180]),
      render: (text, record) => <div>
        <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>
        <LineChartOutlined style={{ fontSize: 12, color: '#003e85', marginLeft: 3, cursor: 'pointer' }} onClick={() => showKline(record)} />
      </div>,
    },
    {
      ...singleColumns(['委托时间', 'formatDate', 120]),
      sorter: (a, b) => {
        let aTime = new Date(a.commissionTime).getTime();
        let bTime = new Date(b.commissionTime).getTime();
        return orderKey === 'list' ? a.timeKey - b.timeKey : aTime - bTime;
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
    singleColumns(['委托数量', 'quantity', 80]),
    singleColumns(['成交数量', 'cumQty', 80]),
    singleColumns(['委托均价', 'price', 80]),
    {
      ...singleColumns(['成交均价', 'avgPrice', 80]),
      render: (text) => <Text strong>{_.round(text, 4)}</Text>,
    },
    {
      ...singleColumns(['收益(元)', 'profit', 100], 'updown'),
      sorter: (a, b) => a.profit - b.profit,
      sortDirections: ['descend', 'ascend'],
    },
    {
      ...singleColumns(['买卖方向', 'side', 90]),
      filters: [
        { text: '买入', value: 'BUY' },
        { text: '卖出', value: 'SELL' },
      ],
      onFilter: (value, record) => record?.side?.includes(value),
      render: (text) => text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
    },
    {
      ...singleColumns(['状态', 'orderStatus', 80]),
      render: (text) => renderOrderStatus(text)
    },
    singleColumns(['备注', 'remarks', 130]),
  ];
  if (!isProduct) {
    columns = _.filter(columns, o => o.key !== 'name');
  }
  // 不需要区分分页
  const columnsIndex2 = [
    {
      ...singleColumns(['序号', 'indexFirst', 80]),
      render: (text, record, index) => index + 1,
    },
  ];
  const columnsBreak = [
    {
      ...singleColumns(['是否拆单', 'isBreak', 90]),
      fixed: 'right',
      render: (text, record) => text ? <Button type='link' size='small' onClick={() => showBreak(record)}>
        拆单
      </Button> : ''
    }
  ];

  const expandedRowRender = (fatRecord) => {
    return (
      <Table
        rowKey="clientOrderId"
        dataSource={_.get(fatRecord, 'otherRecord', [])}
        columns={_.concat(columnsIndex2, columns, columnsBreak)}
        scroll={{ x: isProduct ? 1500 : 1400 }}
        showHeader={false}
        pagination={false}
        size='small'
        border={false}
      />
    )
  }

  const newColumns = _.concat(columnsIndex, columns, columnsBreak);
  return <Table
    rowKey="clientOrderId"
    dataSource={list}
    columns={newColumns}
    scroll={{ x: colWidth(newColumns), y: 565 }}
    bordered
    size='small'
    loading={loading}
    expandable={{
      expandedRowRender,
      expandRowByClick: true,
      rowExpandable: (record) => _.size(record.otherRecord) > 0,
    }}
    pagination={false}
  />
}

export default RecordTable;
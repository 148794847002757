import React from 'react';
import { Button, Card, Table, Tag, Modal, message } from 'antd';
import UpdateTraders from '@/view/Home/AccountAdm/ProductDetails/Components/UpdateTraders';
import { delManagerSubAccount } from '@/api/workbench';
import _ from 'lodash';

const MAccountTable = ({
  role,
  hasSubAccount,
  subAccountInfo,
  newProps,
  goBack
}) => {

  const columnsChildren = [
    {
      title: '子账户',
      dataIndex: 'name',
      width: 100,
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{text}</Tag></div>
      }
    },
    {
      title: '资产',
      dataIndex: 'totalAsset',
      sorter: (a, b) => b.totalAsset - a.totalAsset,
      width: 100,
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      }
    },
    {
      title: '净值',
      dataIndex: 'netValue',
      width: 90,
      align: 'center',
      sorter: (a, b) => b.netValue - a.netValue,
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(4)}</Tag></div>
      }
    },
    {
      title: '收益',
      dataIndex: 'totalRevenue',
      sorter: (a, b) => b.totalRevenue - a.totalRevenue,
      width: 90,
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      }
    },
    {
      title: '日收益',
      dataIndex: 'dailyRevenue',
      align: 'center',
      sorter: (a, b) => b.dailyRevenue - a.dailyRevenue,
      width: 90,
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      },
    },
    {
      title: '可用资金',
      dataIndex: 'availableBalance',
      sorter: (a, b) => b.availableBalance - a.availableBalance,
      width: 90,
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      },
    },
    {
      title: '交易员',
      dataIndex: 'traderName',
      align: 'right',
      width: 100,
      render: (text, record, index) => {
        return (
          <><Tag color="blue">{text}</Tag></>
        )
      }
    },
    {
      title: '操作',
      width: 110,
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            {role !== 'fund_manager' && <Button type="link" onClick={() => _seeMDetail(record, true)}>查看</Button>}

            {role === 'fund_manager' && <UpdateTraders
              pageKey={'mAccount'}
              record={record}
              goBack={goBack}
              accInfo={subAccountInfo}
            />}

            {role === 'fund_manager' && <Button type="link" danger onClick={() => _delSubAccount(record)}>删除</Button>}
          </>
        )
      }
    },
  ]

  function _seeMDetail(record, bool) {
    newProps.history.push({
      pathname: '/home/listsDetails',
      state: {
        subAccountInfo: {
          ...record,
          subAccountId: record.id,
          subAccountName: record.name
        },
      }
    });

  }

  async function _delSubAccount(record) {
    Modal.confirm({
      title: '是否删除' + record.name,
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await delManagerSubAccount({ subAccountId: record.id });
        if (data.code === '200') {
          goBack();
          message.success('删除成功');
        } else {
          message.error(data.message);
        }
      }
    });
  }

  return (
    <>
      {_.size(hasSubAccount) > 0 &&
        <Card title="子账户列表" bordered={true} >
          <Table
            rowKey="id"
            dataSource={hasSubAccount}
            columns={columnsChildren}
            scroll={{ x: 750 }}
            bordered
          />
        </Card>}
    </>
  )
}

export default MAccountTable;
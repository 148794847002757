import React, { Component } from 'react';
import { Card, Tabs, PageHeader, Typography, Space, Cascader } from 'antd';
import { getProductDetailss } from '@/api/details';
import { nextTradeDate } from '@/api/stock';
import { observer, inject } from 'mobx-react';
import { PlusOutlined, BlockOutlined } from '@ant-design/icons';
import moment from 'moment';
import PositionTable from './Component/PositionTable';
import AddPage from './Component/AddPage';
// import Traders from './Component/Traders';
import './position.scss';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Text } = Typography;
const TOTAY = moment().format('YYYY-MM-DD');
const CLOSE_TIME = moment(TOTAY + ' 15:00');
@inject('store')
@observer
class PositionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabKey: '1',
      tabChangeCount: 0,
      options: [],
      subId: 0,
      tradeDate: { isToday: false, nextDate: '' }
    }
    this.userInfo = JSON.parse(JSON.stringify(this.props.store.userInfo));
    this.inTrading =_.get(JSON.parse(JSON.stringify(this.props.store.tradingObj)), 'status', '') === 'trading' ? true : false;
    this.products = _.get(props, 'location.state.products', []);
  }

  componentDidMount() {
    // console.log('assetss', this.assetss)
    if (_.size(this.products) > 0) {
      let newOption = this.products.map(n => {
        return { label: _.get(n, 'productName', ''), value: _.get(n, 'productId', 0), isLeaf: false, }
      });
      this.setState({ options: newOption });
    }

    this._nextTradeDate();
  }

  _nextTradeDate = async () => {
    const res = await nextTradeDate();
    if (_.get(res, 'code', '') === '200') {
      let finalObj = { isToday: false, nextDate: _.get(res, 'data.preTradeDay', '') }
      if (_.get(res, 'data.isHoliday', null) === false) {
        const currentTime = moment();
        const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
        if (diffCloseMin < 0) {
          finalObj.isToday = true;
        }
        //console.log('tradeDate', finalObj, diffCloseMin);
      }
      this.setState({ tradeDate: finalObj, tabChangeCount: this.state.tabChangeCount + 1 })
    }
  }

  tabChange = (key) => {
    if (key === '1') {
      this.setState({ tabChangeCount: this.state.tabChangeCount + 1 })
    }
    this.setState({ tabKey: key })
  }

  loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    if (targetOption.value) {
      const res = await getProductDetailss({ params: { productId: targetOption.value } });
      if (_.get(res, 'code', '') === '200') {
        const getAccount = _.get(res, 'data.securitiesAccounts', []);
        if (_.size(getAccount) > 0) {
          let itemArray = [];
          getAccount.map(acc => {
            const getSubAccount = _.get(acc, 'subAccountList', [])
            if (_.size(getSubAccount) > 0) {
              getSubAccount.map(s => {
                itemArray.push({
                  label: _.get(s, 'name', ''),
                  value: _.get(s, 'id'),
                  isLeaf: true,
                })
              })
            }
          })

          targetOption.children = itemArray;
        }
      }
    }

    this.setState({ options: [...this.state.options] })
  }

  onChange = (value, selectedOptions) => {
    // console.log(value, selectedOptions);
    if (_.size(value) > 1) {
      this.setState({
        subId: _.last(value),
        tabChangeCount: this.state.tabChangeCount + 1
      })
    }
  };

  render() {
    const { options, subId, tradeDate, tabChangeCount, tabKey } = this.state;
    const tradingBool = this.inTrading === 'trading' ? true : false;
    return (
      <>
        <PageHeader
          title={'持仓管理'}
          onBack={() => {
            this.props.history.goBack();
          }}
          style={{ backgroundColor: 'white', marginBottom: 10 }}
        >
          <Space>
            <Text strong>账户：</Text>
            <Cascader
              options={options}
              loadData={this.loadData}
              onChange={this.onChange}
              changeOnSelect
              style={{ width: 180 }}
            />
          </Space>
        </PageHeader>

        <Card>
          <Tabs activeKey={tabKey} onChange={this.tabChange} type="card">
            <TabPane tab={<span><BlockOutlined />{'交易限制'}</span>} key="1" disabled={!subId}>
              <PositionTable
                subId={subId}
                trade={tradeDate}
                updateCount={tabChangeCount}
                isTrading={tradingBool}
              />
            </TabPane>
            <TabPane tab={<span><PlusOutlined />{'添加限制'}</span>} key="2" disabled={!subId}>
              <AddPage
                subId={subId}
                trade={tradeDate}
                isTrading={tradingBool}
                updateKey={tabKey}
                goBackTab={(key) => this.tabChange(key)}
              />
            </TabPane>
            {/* <TabPane tab={<span><UserOutlined />{'交易员'}</span>} key="3">
              <Traders />
            </TabPane> */}
          </Tabs>
        </Card>
      </>
    )
  }
}

export default PositionPage;
import React, { useState, useEffect } from 'react';
import {
  Input,
  Table,
  Card,
  Button,
} from 'antd';
import _ from 'lodash';

const { Search } = Input;

const AllSubTable = ({ subDatas, poolList, onAdd, onSearch }) => {
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [searchText, setSearchText] = useState('');

  // useEffect(() => {

  //   return () => { return; }
  // }, [])

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'left',
      key: 'name',
    },
    {
      title: '余额',
      dataIndex: 'balance',
      align: 'left',
      key: 'balance',
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys) => {
      //console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectRowKey(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: _.findIndex(poolList, o => o.subAccountId === record.subAccountId) !== -1,
    }),
  };

  function searchChange(e) {
    setSearchText(e.target.value)
  }

  return (
    <Card
      title={'所有子账户'}
      style={{ minHeight: 450 }}
      extra={<Button
        disabled={_.size(selectRowKey) === 0 ? true : false}
        onClick={() => {
          const addList = selectRowKey.map(k => {
            let findindex = _.findIndex(subDatas, o => o.subAccountId === k);
            return subDatas[findindex]
          })
          onAdd(addList);
          setSelectRowKey([]);
        }}>
        {'添加到分配池'}
      </Button>}
    >
      <div style={{ marginBottom: 10 }}>
        <Search
          value={searchText}
          onChange={searchChange}
          placeholder="请输入账户"
          allowClear
          enterButton
          onSearch={() => onSearch(searchText)}
          style={{ width: 250 }}
        />
      </div>
      <Table
        rowKey={'subAccountId'}
        dataSource={subDatas}
        columns={columns}
        size="middle"
        rowSelection={rowSelection}
        pagination={{
          pageSize: 10
        }}
      />
    </Card>
  )
}

export default AllSubTable;
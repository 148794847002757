import React from 'react';
import { Row, Col, Card, Space, Typography, Divider } from 'antd'; //Button
import { PlusOutlined, BlockOutlined } from '@ant-design/icons';
import StatisticHeader from '@/utils/StatisticHeader';
import { autoCol } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;

const AstHeader = ({ role, assetss, onAdd, onVirtualAdd }) => {
  return (
    <Card>
      <Row className="headView" align='middle'>
        <Col {...autoCol([4])}>
          <StatisticHeader title={'管理的产品数'} value={_.get(assetss, 'totalProduct', '')} />
        </Col>
        <Col {...autoCol([5])}>
          <StatisticHeader title={'产品总金额'} value={_.get(assetss, 'totalAssets', '')} isColor />
        </Col>
        <Col {...autoCol([4])}>
          <StatisticHeader title={'产品总收益'} value={_.get(assetss, 'totalRevenue', '')} isColor />
        </Col>
        <Col {...autoCol([4])}>
          <StatisticHeader title={'产品日收益'} value={_.get(assetss, 'dailyRevenue', '')} isColor />
        </Col>

        <Col {...autoCol([4])}>
          <StatisticHeader title={'空闲资金'} value={_.get(assetss, 'freeBalance', '')} />
        </Col>
        {role !== 'visitor' && <Col {...autoCol([4])}>
          <div className='optionDiv' onClick={onAdd}>
            <Space>
              <Text strong>产品</Text>
              <PlusOutlined />
            </Space>
          </div>
          <Divider style={{ margin: '12px 0' }} />
          <div className='optionDiv' onClick={onVirtualAdd}>
            <Space>
              <Text strong>产品合并</Text>
              <BlockOutlined />
            </Space>
          </div>
          {/* <Button
            type="primary"
            size='large'
            shape='round'
            // style={{ marginTop: 20 }}
            onClick={onAdd}
            icon={<PlusOutlined />}
          >
            添加
          </Button> */}
        </Col>}
      </Row>
    </Card>
  )
}

export default AstHeader;
import React from 'react';
import { Col, Row, Statistic, Card } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

const DeskHeader = ({ accountData }) => {

  function renderValueColor(value) {
    let pureValue = _.replace(value || '', ',', '');
    const floatValue = pureValue ? parseFloat(pureValue) : 0;
    if (floatValue > 0) {
      return 'red'
    } else if (floatValue < 0) {
      return 'green'
    } else {
      return 'black'
    }
  }

  function renderValuePrefix(value) {
    const floatValue = value ? parseFloat(value) : 0;
    if (floatValue > 0) {
      return <ArrowUpOutlined style={{ color: 'red' }} />
    } else if (floatValue < 0) {
      return <ArrowDownOutlined style={{ color: 'green' }} />
    } else {
      return null
    }
  }

  const common_static_style = {
    fontSize: 24, fontWeight: '600'
  }
  const container_col = { xxl: 7, xl: 7, lg: 12, md: 12, sm: 24, flex: 'auto' };

  return (
    <Row gutter={[24, 16]} >
      <Col {...container_col}>
        <Card bordered={true} className="headCard">
          <Row style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Statistic
                title={"总资产"}
                value={accountData.totalAssets}
                valueStyle={common_static_style}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Statistic
                title={"总市值"}
                value={accountData.positionsAssets}
                valueStyle={common_static_style}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title={"可用资金"}
                value={accountData.totalBalance}
                valueStyle={common_static_style}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col {...container_col}>
        <Card bordered={true} className="headCard">
          <Row>
            <Col span={24}>
              <Statistic
                title={"总收益"}
                value={accountData.totalRevenue}
                valueStyle={{ ...common_static_style, color: renderValueColor(accountData.totalRevenue) }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col {...container_col}>
        <Card bordered={true} className="headCard">
          <Row gutter={8}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Statistic
                title={"日收益"}
                value={accountData.dailyRevenue}
                valueStyle={{ ...common_static_style, color: renderValueColor(accountData.dailyRevenue) }}
                prefix={renderValuePrefix(accountData.dailyRevenue)}
              />
            </Col>
            <Col span={24}>
              <Statistic
                title={"日收益率"}
                value={parseFloat(accountData.dailyRevenueRate).toFixed(2) + '%'}
                valueStyle={{ ...common_static_style, color: renderValueColor(accountData.dailyRevenueRate) }}
                prefix={renderValuePrefix(accountData.dailyRevenueRate)}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default DeskHeader;
import axios from '@/utils/axios';


//产品详情-获取账户列表
export function getProductDetailss(data) {
    return axios.get(global.api + '/fundManager/showProductSecuritiesAndSub', data);
}
//产品详情-获取未被证券账户绑定的所有交易员
export function getAllTraders(data) {
    return axios.get(global.api + '/fundManager/getAllTraders', { params: data });
}
// 基金管理人获取
export function getFundManagerAllTraders(params) {
    return axios.get(global.api + `/traderManager/getAllTraders?id=${params.id}&traderId=${params.traderId}`);
}
//产品详情 - 更换交易员
export function updateTrader(data) {
    return axios.post(global.api + '/fundManager/updateTrader', data);
}
//产品详情-添加证券账户
export function addSecuritiesAccount(data) {
    return axios.post(global.api + '/fundManager/addSecuritiesAccount', data);
}
//产品详情-校验子账户名称是否重复
export function isRepeatSub(data) {
    return axios.get(global.api + '/fundManager/isRepeatSub', { params: data });
}
//产品详情-删除子账户
export function delSecuritiesAccount(data) {
    return axios.post(global.api + '/fundManager/deleteSubAccount', data);
}
//获取证券列表
export function getBrokerages(data) {
    return axios.get(global.api + '/product/getBrokerages', data);
}
//校验交易账户是否正确
export function checkTradeAccount(data) {
    return axios.post(global.api + '/product/checkTradeAccount', data);
}
//初始化账户资金
export function initAccount(data) {
    return axios.post(global.api + '/product/initAccount', data);
}
//添加产品
export function addProduct(data) {
    return axios.post(global.api + '/product/addProduct', data);
}
//经理查看子账户（股票）- 展示调仓记录
export function getAdjustmentList(data) {
    return axios.get(global.api + '/subAccount/showWarehouseList', { params: data });
}
//经理查看子账户（股票）- 获取股票资产列表
export function getStockList(data) {
    return axios.get(global.api + '/subAccount/showStockList', { params: data });
}
//经理查看子账户（股票）- 获取配置比例
export function getAssetRate(data) {
    return axios.get(global.api + '/subAccount/showAssetRate', { params: data });
}
//基金经理工作台添加产品 - 校验交易单元是否重复
export function isRepeatTradeUnit(data) {
    return axios.post(global.api + '/product/isRepeatTradeUnit', data);
}
//基金经理工作台添加产品 - 更新产品初始资金
export function initProductOriginAssets(data) {
    return axios.post(global.api + '/product/initProductOriginAssets', data);
}
//基金经理工作台 - 添加证券账户
export function addSecurityAccount(data) {
    return axios.post(global.api + '/product/addSecurityAccount', data);
}
//基金经理工作台 - 证券账户分配子账户
export function distributeSubAccount(data) {
    return axios.post(global.api + '/product/distributeSubAccount', data);
}
//资金管理子产品子账户历史托管记录
export function showProductSecuritiesAndSubHistory(data) {
    return axios.get(global.api + '/fundManager/showProductSecuritiesAndSubHistory', { params: data });
}
//3分钟涨幅超5%
export function getPositionUp5(id) {
    return axios.get(global.api + `/product/getPositionUp5?productId=${id}`);
}

// 虚拟产品
export function addVirtualProduct(params) {
    return axios.post(global.api + '/virtualProduct/addVirtualProduct', params);
}
export function showVirtualProduct() {
    return axios.get(global.api + '/virtualProduct/showVirtualProduct');
}
export function fundAdjustment(params) {
    return axios.post(global.api + '/virtualProduct/fundAdjustment', params);
}
export function showSingleProductHistoryVir(params) {
    return axios.post(global.api + '/virtualProduct/showSingleProductHistory', params);
}
export function deleteVirtualProduct(params) {
    return axios.post(global.api + '/virtualProduct/deleteVirtualProduct', params);
}
//重仓分布档位
export function getHighPositionLevel(data, type) {
    return axios.post(global.api + `/${type === 'vir' ? 'virtualProduct' : 'fundManager'}/getHighPositionLevel`, data);
}
//按日期获取收益率
export function showSubAccountHistory(data) {
    return axios.post(global.api + '/fundManager/showSubAccountHistory', data);
}
export function showProductHistoryIntervalVir(data) {
    return axios.post(global.api + '/virtualProduct/showProductHistoryInterval', data);
}
export function showProductHistoryInterval(data) {
    return axios.post(global.api + '/fundManager/showProductHistoryInterval', data);
}
// 收益率k线
export function indexDailyKline(params) {
    return axios.post(global.api + '/stock/indexDailyKline', params);
}
export function showProductKLine(params) {
    return axios.post(global.api + '/fundManager/showProductKLine', params);
}
export function showSubKLine(params) {
    return axios.post(global.api + '/subAccount/showSubKLine', params);
}
//获取私募基金信息
export function findFund(content = '') {
    return axios.get(global.api + `/stock/findFund?content=${content}`);
}
export function showFundKline(params) {
    return axios.post(global.api + '/fundManager/showFundKline', params);
}
// 产品和虚拟产品的个股盈亏分布
export function showProductProfitAndLoss(params) {
    return axios.post(global.api + '/fundManager/showProductProfitAndLoss', params);
}
export function showProfitAndLossVir(params) {
    return axios.post(global.api + '/virtualProduct/showProfitAndLoss', params);
}
import React, { useState, useEffect } from 'react';
import {
  Tag,
  Table,
  Card,
  Button,
} from 'antd';
import _ from 'lodash';

const SubPoolTable = ({ datas }) => {
  const [selectRowKey, setSelectRowKey] = useState([]);

  // useEffect(() => {

  //   return () => { return; }
  // }, [])

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'left',
      key: 'name',
    },
    {
      title: '已分配账户数量',
      dataIndex: 'number',
      align: 'left',
      key: 'number',
    },
    {
      title: '是否饱和',
      dataIndex: 'isFull',
      align: 'left',
      key: 'isFull',
      render: (text, record) => text === 0 ? '未饱和' : '饱和'
    },
    {
      title: '在管数量上限',
      dataIndex: 'upperLimit',
      align: 'left',
      key: 'upperLimit',
    },
    {
      title: '默认分配金额',
      dataIndex: 'defaultMoney',
      align: 'left',
      key: 'defaultMoney',
    },
  ];

  return (
    <Card
      title={'分配池'}
      style={{ minHeight: 450 }}
    >
      <Table
        rowKey={'subAccountId'}
        dataSource={datas}
        columns={columns}
        size="middle"
        pagination={{
          pageSize: 10
        }}
      />
    </Card>
  )
}

export default SubPoolTable;
import React, { useEffect, useState } from 'react';
import { Card, DatePicker, Row, Col, Spin, Select, Segmented } from 'antd';
// import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import * as echarts from 'echarts';
import { colorArray } from '../../common/colorArray';
import { COMMON_CHART } from '../../common/Components/chartsInfoUtils';
import { showPlatePosition } from '@/api/workbench';
import moment from 'moment';
import _ from 'lodash';

const { RangePicker } = DatePicker;
const { Option } = Select;
const SEG_OPTIONS = ['行业', '板块'];
const OPTION_KEY = {
    '行业': 'industry',
    '板块': 'plate',
}
const tabList = [
    { key: '1', tab: '总仓位' },
    { key: '2', tab: '行业仓位' },
    { key: '3', tab: '个股仓位' },
];
const tabList_4 = [{ key: '4', tab: '仓位统计' }];
// let count_color = 0;
// const COLOR_ARRAY_REPEAT = Array(100).fill('1').map((n, i) => { // 颜色重复循环
//     if (!colorArray[count_color]) {
//         count_color = 0;
//     } else {
//         count_color++;
//     }
//     return colorArray[count_color];
// })

const OPTION_LINE = {
    xAxis: {
        type: 'category',
        name: '时间',
        ...COMMON_CHART.rateX
    },
    yAxis: [
        COMMON_CHART.rateY,
    ],
    grid: {
        top: 85
    },
    series: [],
    legend: {
        selectedMode: 'multiple',
    },
    tooltip: {
        trigger: 'axis',
        showDelay: 0,
        axisPointer: {
            type: 'cross',
            lineStyle: {
                type: 'dashed',
                width: 1
            }
        },
        padding: 5,
    },
};
const OPTION_PIE = {
    legend: {
        type: 'scroll',
        data: []
    },
    dataset: {
        source: []
    },
    xAxis: {
        type: 'category',
        splitLine: {
            show: false
        },
        boundaryGap: false,
        axisTick: {
            show: false
        },
        axisLable: {
            hideOverLap: true,
        }
    },
    yAxis: { gridIndex: 0 },
    grid: { top: '55%' },
    // tooltip: {
    //     show:true,
    //     trigger:'axis',	
    // },
    tooltip: {
        trigger: 'axis',
        showContent: false
    },
    series: []
}

export default function EchartsInfo7(props) {
    // const [activeKey, setactiveKey] = useState('1');
    const [showCash, setShowCash] = useState(true);
    const [dateTime, setdateTime] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
    const [symOption, setSymOption] = useState([]);
    const [topSymbol, setTopSymbol] = useState([]);
    const [serData, setSerData] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [pType, setPType] = useState(SEG_OPTIONS[0]);
    const [option, setoption] = useState(OPTION_PIE);
    const [option2, setoption2] = useState(OPTION_LINE);

    async function _showPlatePosition(type, date) {
        if (props.subAccountId) {
            let params = {
                subAccountId: props.subAccountId,
                beginDate: date ? date[0] : dateTime[0],
                endDate: date ? date[1] : dateTime[1],
                type: type ? OPTION_KEY[type] : OPTION_KEY[pType]
            }
            const res = await showPlatePosition(params);
            if (_.get(res, 'code', '') === '200') {
                const getData = _.get(res, 'data', {}); // {时间(1-1):{板块(电子)：值（1.2）,...},...}
                let timeList = Object.keys(getData).map(n => { // 提取时间
                    return { time: n, timeKey: moment().diff(moment(n), 'd') }
                });
                timeList = _.orderBy(timeList, ['timeKey'], ['desc']); // 时间排序
                let plateObj = {}; // 板块数据
                let seriesArray = []; // 图表数据
                let timeArray = [];
                timeList.map((t, index) => {
                    timeArray.push(t.time);
                    Object.keys(getData[t.time]).map(keyname => {
                        if (keyname in plateObj) {
                            plateObj[keyname][index] = getData[t.time][keyname];
                        } else { // 无板块数据时，创建数据
                            plateObj[keyname] = Array(_.size(timeList)).fill(0); // 创建与时间相同大小的array
                            plateObj[keyname][0] = getData[t.time][keyname]; //赋值第一个数据
                        }
                    })
                })
                Object.keys(plateObj).map(keyname => {
                    seriesArray.push({//connectNulls: true,
                        name: keyname, data: plateObj[keyname], type: 'line', symbol: 'none', maxNum: _.max(plateObj[keyname]),
                    })
                });
                seriesArray = _.orderBy(seriesArray, ['maxNum'], ['desc']); // 根据收益率高的进行排序
                let orderKeysPlate = seriesArray.map(n => n.name);
                setSymOption(orderKeysPlate);
                setTopSymbol(_.slice(orderKeysPlate, 0, 5)) // 默认显示前5数据
                setSerData({
                    time: timeArray,
                    datas: seriesArray
                });
                setUpdateCount(updateCount + 1);
            }
        }
    }

    useEffect(() => {
        let myChart = props.myChart;
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose();//销毁
        }

        myChart = echarts.init(document.getElementById('echart7'));
        myChart.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });

        let newOption = option;
        let newData = _.cloneDeep(props.pieChartData);
        /**
         * 【问题】联动图表依旧存在数据过多时切换数据会使线条和饼图颜色不一致；通过console数据发现并没有发现数据问题。
         * 只是少量数据错误就会没有，暂时只通过筛选掉有效数据偏少的数据进行优化；
         */
        let finalPie = [];
        let finalLegend = [];
        let finalSeries = [];
        let dataSetObj = {}; // name:count 数据名称：个数
        newData.pie.map(n => {
            let invalidDataCount = 0; // 记录‘-’无数据个数
            let totalNum = _.size(n);
            const newItem = _.isArray(n) ? n.map(d => {
                if (d === '-') {
                    invalidDataCount++;
                }
                dataSetObj[n[0]] = invalidDataCount;
                return _.isString(d) ? d : parseFloat(d.toFixed(3))
            }) : []
            if (invalidDataCount < (_.round(totalNum / 3))) { // 无数据小于总数1/3才会显示 
                finalPie.push(newItem);
                dataSetObj[n[0]] = 'show'; //代表下方查看时可以显示数据
            }
        })
        newOption.dataset.source = finalPie;
        let colorCount = 0;
        let colorObj = { '其他': '#f6a100', '现金': '#ffcd00' };
        newData.series.map((s, i) => {
            colorCount = colorCount + 1
            if (colorCount === (_.size(colorArray) - 2)) {
                colorCount = 0;
            }
            if (!colorObj[s.name]) { // 循环colorArray，让pie和series使用同样的颜色
                colorObj[s.name] = colorArray[colorCount]; // 记录name 和 颜色值
            }
            if (dataSetObj[s.name] === 'show' || s.type === 'pie') {  // 按照上方无数据的筛选后，符合条件的数据显示，和pie图的数据
                finalSeries.push({ ...s, itemStyle: { normal: { color: colorObj[s.name] } } })
            }
        });
        let pieIndex = _.findIndex(finalSeries, o => o.type === 'pie');
        // console.log('finalPie', finalPie)
        if (pieIndex !== -1) { // 找到pie的object,修改里面的color属性，保证颜色数据相同
            finalSeries[pieIndex].itemStyle = {
                normal: {
                    color: (colors) => { // 用color里面的dataIndex去遍历循环颜色数组，这样保证每个数据index与color进行对应；否则整个饼图是一个颜色
                        // let colorIndex = colors.dataIndex;
                        let cName = colors.name;
                        return colorObj[cName]; // 通过名称直接拿到颜色值
                    }
                }
            }
        }
        newData.legend.map(n => {
            if (dataSetObj[n.name] === 'show') {
                finalLegend.push(n);
            }
        })
        newOption.series = finalSeries;
        // console.log(newOption)
        newOption.legend.data = finalLegend;
        setoption(newOption);
        myChart.setOption(newOption);
        myChart.hideLoading();
        myChart.resize();
        myChart.on('updateAxisPointer', function (event) {
            const xAxisInfo = event.axesInfo[0];
            if (xAxisInfo) {
                const dimension = xAxisInfo.value + 1;
                myChart.setOption({
                    series: {
                        id: 'pie',
                        label: {
                            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                        },
                        encode: {
                            value: dimension,
                            tooltip: dimension
                        }
                    }
                });
            }
        });
    }, [props.upCount, showCash]);

    useEffect(() => {
        setdateTime(props.time3);
    }, [props.upTimeCount])

    useEffect(() => {
        let myChart = props.myChart;
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose();
        }
        myChart = echarts.init(document.getElementById('echart7_1'));
        let newOption = option2;
        const getSeriesData = _.get(serData, 'datas', []);
        newOption.xAxis.data = _.get(serData, 'time', []);
        newOption.series = _.filter(getSeriesData, o => topSymbol.indexOf(o.name) !== -1);
        setoption2(newOption);
        myChart.setOption(newOption, true);
        myChart.resize();

    }, [updateCount])

    function _cutDateType(key) {
        // setactiveKey(key);
        if (key === '4') {
            _showPlatePosition();
        } 
        if (props.changeTab) props.changeTab(key, dateTime);
    }
    function updateTime(e, dateString) {
        setdateTime(dateString);
        if (_.get(props, 'activeKey', '') === '4') {
            _showPlatePosition(null, dateString);
        } else {
            if (props.changeTab) props.changeTab(_.get(props, 'activeKey'), dateString);
        }
    }

    const onSymbolChange = (value) => {
        setTopSymbol(value);
        setUpdateCount(updateCount + 1);
    };

    const disabledDate = current => {
        const futureDay = current >= moment().endOf('day')
        return futureDay;
    };
    const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, padding: 8 };
    const topChildren = _.size(symOption) > 0 ? symOption.map((n, i) => <Option key={n} value={n}>{n}</Option>) : null;
    const newRangeValue = !dateTime[0] || !dateTime[1] ? null : [moment(dateTime[0]), moment(dateTime[1])];
    const getActiveKey = _.get(props, 'activeKey', '');
    return (
        <div style={{ backgroundColor: '#fff', padding: 5 }}>
            <Card
                tabList={_.get(props, 'pageKey', '') === 'product' ? tabList : _.concat(tabList, tabList_4)}
                activeTabKey={getActiveKey}
                onTabChange={_cutDateType}
                bordered={false}
                tabBarExtraContent={
                    <Row justify='end'>
                        <span style={{ marginRight: 10, marginTop: 5 }}>选择日期:</span>
                        <RangePicker
                            value={newRangeValue}
                            disabledDate={disabledDate}
                            format="YYYY-MM-DD"
                            onCalendarChange={(date, dateStrings, info) => {
                                const calType = _.get(info, 'range', '');
                                if (calType === 'end') {
                                    updateTime(date, dateStrings)
                                }
                            }}
                        />
                    </Row>
                }
            >
                {getActiveKey === '4' ? <Row style={controlBarStyle}>
                    <Col span={20}>
                        <Select
                            mode="multiple"
                            allowClear
                            bordered={false}
                            style={{
                                width: '100%',
                            }}
                            value={topSymbol}
                            onChange={onSymbolChange}
                        >
                            {topChildren}
                        </Select>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <Segmented
                            options={SEG_OPTIONS}
                            value={pType}
                            onChange={(val) => {
                                setPType(val);
                                _showPlatePosition(val, null);
                            }} />
                    </Col>
                </Row> : <Row justify='end' style={{ marginBottom: 10 }}>
                    <Col span={3}>
                        {/* <Button size='small' onClick={() => setShowCash(!showCash)} icon={showCash ? <EyeInvisibleOutlined /> : <EyeOutlined />}>
                            {showCash ? '不看现金' : '显示现金'}
                        </Button> */}
                    </Col>
                </Row>}

                <Spin spinning={_.get(props, 'loading', false)} style={{
                    width: '100%',
                    height: props.height ? props.height : 450
                }}>
                    <div style={{ display: 'flex' }}>
                        <div
                            id="echart7_1"
                            style={{
                                width: '100%',
                                height: 450,
                                display: getActiveKey === '4' ? 'block' : 'none'
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div
                            id="echart7"
                            style={{
                                width: '100%',
                                height: 450,
                                display: getActiveKey === '4' ? 'none' : 'block'
                            }}
                        />
                    </div>
                </Spin>
            </Card>

        </div>
    )
}
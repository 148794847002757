import React, { useState } from 'react';
import {
  Row, Col, Form, Input, Button, Select, message, Tag, PageHeader, DatePicker, List, Typography,
  Checkbox, InputNumber, Space
} from 'antd';
import { addVirtualProduct } from '@/api/details';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { autoCol } from '@/utils/utils';
import { isValidArray, isValidObj } from '@/utils/utils2';
import { useMount, useUnmount } from 'ahooks';
// import moment from 'moment';
import _ from 'lodash';
import './page.scss'

const { Option } = Select;
const { Text } = Typography;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 8 },
};
const PRO_OPTIONS = [['产品组合', '0'], ['子账户组合', '1']].map(arr => <Option key={arr[1]} value={parseInt(arr[1])}>{arr[0]}</Option>);
const ID_STR = 'PA_';
let timer = null;
// 添加虚拟产品表单;
export default function AddVirtualProduct(props) {
  const mobxStore = useLocalObservable(() => store);
  const [allProducts, setAllProduct] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [checks, setChecks] = useState([]);
  const [form] = Form.useForm();
  const modeValue = Form.useWatch('mode', form);

  useMount(() => {
    // console.log(allProducts);
    if (!isValidObj(allProducts)) {
      mobxStore._getProductAccount();
      timer = setTimeout(() => {
        setAllProduct(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
      }, 2000);
    }
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  // 提交添加产品选项
  async function onFinish(values) {
    let params = {
      'name': _.get(values, 'name', ''),
      'mode': _.get(values, 'mode'),
      'interval': _.get(values, 'interval', 0),
      'date': values['date'].format('YYYY-MM-DD')
    }
    if (!isValidArray(checks)) {
      message.info('请选择账户或产品!');
      return
    } else {
      // PA_ 是特定字段，后面加id可快速获取对应的值
      let combind = [];
      checks.map(id => {
        combind.push({
          'totalAsset': _.get(values, `${ID_STR}${id}_asset`),
          'combinationId': id,
          'weight': _.get(values, `${ID_STR}${id}_weight`),
        });
      });
      _.set(params, 'combinations', combind);
    }
    //console.log(params);
    const res = await addVirtualProduct(params);
    if (_.get(res, 'code', '') === '200') {
      message.success('添加成功!');
      form.resetFields();
      setChecks([]);
    } else {
      message.info(_.get(res, 'message', '添加失败！'));
    }
  }
  // 选中产品、账户，check内直接更新对应的id
  function handleCheckChange(v, checked) {
    let temp = _.cloneDeep(checks);
    if (checked) {
      _.pull(temp, v);
    } else {
      temp.push(v);
    }
    setChecks(temp);
  }
  // 根据选择返回产品\账户的options
  function renderOption() {
    const optionObj = { '0': 'product', '1': 'account' };
    return modeValue in optionObj ? _.get(allProducts, optionObj[modeValue]) : [];
  }

  const newOptions = renderOption();
  const gridObj = { gutter: 8, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 };
  const inputNumProps = { 'min': 0, 'step': 1, 'style': { width: '100%' } };
  return (
    <>
      <PageHeader
        title={'添加虚拟产品'}
        onBack={() => props.history?.goBack()}
        style={{ backgroundColor: 'white' }}
      ></PageHeader>

      <div className='virtual-div'>
        <Row gutter={[24, 8]}>
          <Col {...autoCol([17])} className='virtual-leftcol'>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{}}
              {...layout}
            >
              <Form.Item
                name="date"
                label="生效日期"
                rules={[{ required: true, message: '请选择生效日期' }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="类型"
                name="mode"
                rules={[{ required: true, message: '请选择类型' }]}
              >
                <Select placeholder="类型" onChange={(v) => setChecks([])}>
                  {PRO_OPTIONS}
                </Select>
              </Form.Item>
              <Form.Item
                label="名称"
                name='name'
                rules={[{ required: true, message: '请输入名称' }]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
              <Form.Item
                label="重新计算资产周期（天）"
                name='interval'
                rules={[{ required: true, message: '请输入天数' }]}
              >
                <InputNumber placeholder="请输入天数" style={{ width: '100%' }} />
              </Form.Item>

              {isValidArray(checks) && checks.map(id => {
                const checkName = _.chain(newOptions).filter(o => o.value === id).head().get('label').value();
                return <Form.Item
                  key={ID_STR + id}
                  label={<div style={{ paddingRight: 8 }}>{checkName}</div>}
                >
                  <Space>
                    <Form.Item
                      name={`${ID_STR}${id}_asset`}
                      rules={[{ required: true, message: '请输入初始资金' }]}
                    >
                      <InputNumber
                        placeholder="请输入初始资金"
                        {...inputNumProps}
                      />
                    </Form.Item>
                    <Form.Item
                      name={`${ID_STR}${id}_weight`}
                      rules={[{ required: true, message: '请输入权重' }]}
                    >
                      <InputNumber
                        placeholder='请输入权重'
                        {...inputNumProps}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              })}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" block>提交</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col {...autoCol([6])} className='virtual-rightcol'>
            {/* <span>{modeValue}</span> */}
            <List
              grid={gridObj}
              header={<Text>选择</Text>}
              dataSource={newOptions}
              renderItem={(item) => {
                const is_checked = _.includes(checks, item.value);
                return <List.Item>
                  <div className='virtual-check-item' style={is_checked ? { border: `1px solid #0859AB` } : {}}>
                    <Row align='middle'
                      style={{ cursor: 'pointer', paddingTop: 4, paddingBottom: 4 }}
                      onClick={() => handleCheckChange(item.value, is_checked)}
                    >
                      <Col span={4}>
                        {modeValue === 0 ? <Tag color='orange'>产品</Tag> : <Tag color='blue'>账户</Tag>}
                      </Col>
                      <Col span={16} style={{ paddingLeft: 8 }}>
                        <Text>{_.get(item, 'label', '')}</Text>
                      </Col>
                      <Col span={4} style={{ textAlign: 'right' }}>
                        {is_checked && <Checkbox checked />}
                      </Col>
                    </Row>
                  </div>
                </List.Item>
              }}
            />
          </Col>
        </Row>
      </div>

    </>
  )
}
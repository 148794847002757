import React, { useState, useEffect, useRef } from 'react';
import { Steps, Button, message, Form, Select, Input, Popconfirm, Progress, Result, Space } from 'antd';
import { isRepeatAccount, getRegisterCode, isAvailableInviteCode, getCaptcha, first, second } from '@/api/auto';
import { BankOutlined } from '@ant-design/icons';
import logo from '@/images/_logoEN.svg';
import addManager from '@/images/mWechatQrcode2.jpg';
import { useMediaQuery } from 'react-responsive';
import CryptoJS from 'crypto-js';
import { isEmptyStringV2 } from '@/utils/utils';
import { cryptoString } from '@/utils/cryptoUtils';
import VerifyModal from './VerifyModal';
import UUID from 'uuidjs';
import _ from 'lodash';

const { Step } = Steps;
const { Option } = Select;
const NEW_REGISTER_TYPE = {
    0: '基金管理人',
    1: '基金经理',
    2: '基金交易员',
}
const NEW_REGISTER_KEY = {
    0: 'fundManager',
    1: 'trader_manager',
    2: 'trader',
}

export default function Register(props) {
    const [current, setCurrent] = useState(0);
    const [loading, setloading] = useState(false);
    const [popconfirmVisible, setpopconfirmVisible] = useState(false);
    const [percentData, setpercentData] = useState({ num: 0, color: 'red', text: '太短', textColor: 'red' });
    const [disableMsgBtn, setDisableMsgBtn] = useState(false);
    const [isregister, setisregister] = useState(false);
    const [disableTime, setDisableTime] = useState(60);
    const [fundRole, setfundRole] = useState(2);
    const [formUserValues, setformUserValues] = useState({});
    const [firstStep, setfirstStep] = useState({});
    const [inviteID, setInviteID] = useState(0);
    const [formUser] = Form.useForm();
    const [formInfo] = Form.useForm();
    const interVal = useRef(null);
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [captchaVal, setCaptchaVal] = useState(null);
    const [verifyShow, setVerifyShow] = useState(false);
    const [validateObj, setValidateObj] = useState({});

    useEffect(() => {
        if (props.location.state !== undefined) {
            setformUserValues(props.location.state.key);
            if (props.location.state.key.userType === 'fundManager') {
                setfundRole(0);
            }
            setCurrent(1);
        }
        interVal.current = {
            time: 60,
            index: null,
        }
        // async function getToken() {
        //     const data = await getRegisterToken();
        //     if (data.code === '200') {
        //         // setsubToken(data.data.subRegToken);
        //     }
        // }
        // getToken();

        const inviteCodeFromUrl = _.get(props, 'location.search', '');
        if (inviteCodeFromUrl.indexOf('??@') !== -1) {
            let idvalue = _.split(inviteCodeFromUrl, '??@')[1];
            let bytes = CryptoJS.AES.decrypt(idvalue, 'order123');
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
            if (!isEmptyStringV2(originalText)) {
                // message.info('有邀请！！')
                setInviteID(parseInt(originalText));
            }
        }

        return () => {
            clearInterval(interVal.current.index);
        }
    }, [])

    const next = (index) => {
        setCurrent(current + index);
    };
    const prev = () => {
        if (current !== 0) {
            setCurrent(current - 1);
        }
    };
    function _password() {
        const value = formInfo.getFieldValue('password');
        if (value.length !== 0) {
            setpopconfirmVisible(true);
            if (value.length < 6) {
                setpercentData({ num: 33, color: 'red', text: '太短', textColor: 'red' });
            } else if (value.length >= 6 && value.length <= 10) {
                setpercentData({ num: 66, color: '', text: '普通', textColor: '#1890ff' });
            } else if (value.length >= 10) {
                setpercentData({ num: 100, color: '', text: '安全', textColor: '#52c41a' });
            }
        } else {
            setpopconfirmVisible(false);
        }
    }
    // 获取图片验证码
    async function _getCaptcha() {
        const phone = formUser.getFieldValue('phone');
        if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
            setValidateObj({ validateStatus: 'error', help: '请输入手机号！' })
            return //message.error('请输入手机号！');
        }
        const uuid = UUID.generate();
        // const res = await getCaptcha(uuid);
        setCaptchaVal(uuid); // 本地记录图片的token，登录传给后台
        setVerifyShow(true);
    }
    // 获取手机验证码
    async function _getSmsCode(val) {
        if (disableMsgBtn) {
            return message.warning('您点击的太快了！');
        }
        const phone = formUser.getFieldValue('phone');
        if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
            setValidateObj({ validateStatus: 'error', help: '请输入正确的手机号！' })
            return// message.error('请输入正确的手机号！');
        }
        setloading(true);
        let params = {
            phone: phone,
            captchaToken: captchaVal,
            captcha: val,
        }
        const data = await getRegisterCode(params);
        if (data.code === '200') {
            message.success(data.message);
            setDisableMsgBtn(true);
            runDisableTime();
            setVerifyShow(false);
        } else {
            message.error(data.message)
        }
        setloading(false);
    }
    function runDisableTime() {
        interVal.current.index = setInterval(() => {
            if (interVal.current.time <= 1) {
                clearInterval(interVal.current.index);
                setDisableMsgBtn(false);
                interVal.current.time = 60;
                setDisableTime(60);
            }
            interVal.current.time -= 1;
            setDisableTime(t => t - 1);
        }, 1000);
    }
    // 创建账户
    async function onFinish(values) {
        let params = {
            ...values,
            registerType: NEW_REGISTER_KEY[fundRole],
        };

        if (inviteID) {
            params.inviter = inviteID;
        }

        const data = await first(params);
        if (data.code === '200') {
            setisregister(true);
            setfirstStep(data.data);
            next(1);
        } else {
            message.error(data.message);
        }

    }
    // 填写信息
    async function onFinishInfo(values) {
        if (values.password !== values.confirmPassword) return message.error('两次密码输入不一致！');
        let params = {
            ...firstStep,
            ...values,
            password: cryptoString(values.password),
        };
        params.confirmPassword = params.password; // 避免两次加密生成不同密文
        if (!firstStep.userId) {
            params.userId = formUserValues.userId;
            params.userType = formUserValues.userType;
        }
        const data = await second(params);
        if (data.code === '200') {
            message.success('注册成功');
            next(1);
        } else {
            message.error(data.message);
        }

    }

    function _goStoreStratigies() {
        props.history.push('/store/strategies');
    }

    const COMMON_TAB_STYLE = { width: isMobile ? 268 : 400 }
    const COMMON_TAB_2_STYLE = { width: isMobile ? 268 : 600 }
    const COMMON_LOGO_STYLE = { width: isMobile ? 150 : 300, height: isMobile ? 55 : 110 }

    return (
        <div style={{ height: '100%', overflow: 'hidden' }}>
            <img
                src={logo}
                alt=""
                style={{
                    margin: '50px 0 0 50%',
                    transform: 'translateX(-50%)',
                    ...COMMON_LOGO_STYLE
                }}
            />

            <Steps
                type="navigation"
                current={current}
                size={isMobile ? 'small' : 'default'}
                style={{ width: isMobile ? '80%' : '95%', margin: '50px auto 0' }}
            >
                <Step title="创建账户" onClick={prev} />
                <Step title="填写信息" />
                <Step title="完成注册" />
            </Steps>

            {  // 创建账户
                current === 0 &&
                <div style={{ ...COMMON_TAB_STYLE, margin: '50px auto 0' }}>
                    <Form
                        form={formUser}
                        onFinish={onFinish}
                        initialValues={{ registerType: '基金交易员' }}
                    >
                        <Form.Item label="我是 ： " name="registerType" >
                            <Select onChange={e => setfundRole(parseInt(e))} size="large" >
                                {Object.keys(NEW_REGISTER_TYPE).map((keyname, index) => {
                                    return <Option value={keyname} key={keyname}>{NEW_REGISTER_TYPE[keyname]}</Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label=""
                            name='phone'
                            {...validateObj}
                            rules={[
                                { required: true, message: '请输入手机号！', },
                                {
                                    validator: (_, value) => {
                                        if (value) {
                                            if ((/^1[3456789]\d{9}$/.test(value))) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('请输入正确的手机号！'));
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input placeholder="手机号" size="large" />
                        </Form.Item>

                        <Form.Item
                            label=""
                            name='verificationCode'
                            rules={[{ required: true, message: '请输入验证码！', }]}
                        >
                            <Space>
                                <Input
                                    placeholder="验证码"
                                    size="large"
                                    autoComplete={'off'}
                                    style={{ width: 280 }}
                                />
                                <Button
                                    type='primary'
                                    style={{ width: 112 }}
                                    onClick={() => _getCaptcha()}
                                    loading={loading}
                                    size="large"
                                    disabled={disableMsgBtn}
                                >
                                    {disableMsgBtn ? disableTime + '秒' : '获取验证码'}
                                </Button>
                            </Space>
                        </Form.Item>

                        <Form.Item >
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={isregister}
                                style={{ width: '100%', height: 45 }}
                            >
                                注册{NEW_REGISTER_TYPE[fundRole]}
                            </Button>
                        </Form.Item>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Space size='small'>
                                <BankOutlined style={{ marginRight: -15 }} />
                                <Button type="text" onClick={_goStoreStratigies}>策略市场</Button>
                            </Space>
                            <Button type="link" onClick={() => props.history.push('/')}>使用已有账户登录</Button>
                        </div>
                    </Form>

                    <p style={{ textAlign: 'center', margin: '40px 0 20px' }}>COPYRIGHT © 2024 程创（北京）科技有限公司 </p>
                </div>
            }

            {  // 填写信息 
                current === 1 &&
                <div style={{ margin: '100px auto 0', ...COMMON_TAB_2_STYLE }}>
                    <Form
                        form={formInfo}
                        onFinish={onFinishInfo}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 16 }}
                        style={{ width: '100%' }}
                    >
                        <Form.Item
                            label="账户名称"
                            name='account'
                            validateTrigger='onBlur'
                            rules={[{ required: true }, {
                                validator: async (_, value) => {
                                    if (value) {
                                        const data = await isRepeatAccount({ account: value });
                                        if (data.code === '200') {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(data.message));
                                    }
                                }
                            }]}
                        >
                            <Input placeholder="请输入账户名称" size="large" />
                        </Form.Item>

                        <Popconfirm
                            placement="right"
                            icon={<></>}
                            footer={null}
                            visible={popconfirmVisible}
                            autoAdjustOverflow={false}
                            title={() => (
                                <>
                                    <span style={{ color: percentData.textColor }}>强度 ： {percentData.text}</span>
                                    <br />
                                    <Progress percent={percentData.num} strokeColor={percentData.color} showInfo={false} />
                                    <br /> <br />
                                    请至少输入 6 个字符，请不要使用容易被猜到的密码。
                                </>
                            )}>
                            <Form.Item
                                label="密码"
                                name='password'
                                rules={[{ required: true }, {
                                    validator: async (_, value) => {
                                        if (value) {
                                            if ((/^[\w_-]|[^%&',;=?$\x22]{6,16}$/.test(value))) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('请输入6-16位密码！'));
                                        }
                                    }
                                }]}
                            >
                                <Input.Password placeholder="6-16位密码，区别大小写" size="large" onChange={_password} onBlur={() => { setpopconfirmVisible(false) }} autoComplete="new-password" />
                            </Form.Item>
                        </Popconfirm>

                        <Form.Item
                            label="确认密码"
                            name='confirmPassword'
                            rules={[{ required: true }, {
                                validator: async (_, value) => {
                                    if (value) {
                                        if ((/^[\w_-]|[^%&',;=?$\x22]{6,16}$/.test(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('请输入6-16位密码！'));
                                    }
                                }
                            }]}
                        >
                            <Input.Password placeholder="确认密码" size="large" autoComplete="new-password" />
                        </Form.Item>

                        {
                            fundRole === 0 &&
                            <Form.Item
                                label="任职公司"
                                name='company'
                                rules={[{ required: true, message: '请输入所在公司!' }]}
                            >
                                <Input placeholder='请输入所在公司' size="large" />
                            </Form.Item>
                        }

                        {
                            fundRole === 0 &&
                            <Form.Item
                                label="管理人备案登记名称"
                                name='productFileRegistration'
                                rules={[{ required: true, message: '请输入管理人备案登记名称!' }]}
                            >
                                <Input placeholder='请输入管理人备案登记名称' size="large" />
                            </Form.Item>
                        }

                        {
                            fundRole === 0 &&
                            <Form.Item
                                label="管理人备案登记编号"
                                name='productRegistrationNumber'
                                rules={[{ required: true, message: '请输入产品登记编号!' }]}
                            >
                                <Input placeholder='请输入产品登记编号' size="large" />
                            </Form.Item>
                        }

                        <Form.Item
                            label="邀请码"
                            name='inviteCode'
                            validateTrigger='onBlur'
                            rules={fundRole === 0 ? [{ required: true }, {
                                validator: async (_, value) => {
                                    if (value) {
                                        const data = await isAvailableInviteCode({ inviteCode: value });
                                        if (data.code === '200') {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(data.message));
                                    }
                                }
                            }] : null}
                        >
                            <Input placeholder={`请输入邀请码${fundRole === 0 ? '(必填)' : '(选填)'}`} size="large" autoComplete='off' />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{ offset: isMobile ? 0 : 5, span: 16 }}
                            style={{ textAlign: 'center', marginTop: 40 }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                            >
                                注册{NEW_REGISTER_TYPE[fundRole]}
                            </Button>
                        </Form.Item>
                    </Form>

                    <p style={{ textAlign: 'center', margin: '250px 0 20px' }}>COPYRIGHT © 2024 程创（北京）科技有限公司 </p>
                </div>
            }

            {  // 完成注册
                current === 2 &&
                <>
                    <Result
                        status="success"
                        title="你的账户已经注册完成"
                        // subTitle="激活邮件已发送到您的邮箱中，邮件有效期为24小时，请及时登录邮箱，点击邮件中的链接激活账户。"
                        extra={[
                            // <Button type="primary" key="console" href={'abc'} target="_blank" >
                            //     查看邮箱
                            //  </Button>,
                            <Button key="buy" onClick={() => props.history.push('/')}>现在登录</Button>
                        ]}
                    >
                        {fundRole === 2 && <div style={{ width: '100%', padding: 10, textAlign: 'center' }}>
                            <p style={{ fontSize: '16px' }}>请添加以下基金经理微信，为您开通交易服务：</p>
                            <img
                                src={addManager}
                                alt=""
                                style={{
                                    width: 300,
                                    height: 300,
                                    margin: '0 auto'
                                }}
                            />
                        </div>}
                    </Result>

                    <p style={{ textAlign: 'center', margin: '300px 0 20px' }}>COPYRIGHT © 2024 程创（北京）科技有限公司 </p>
                </>
            }

            {/* <div className="steps-action">
                {current < 2 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => next(1)}>
                        下一步
                    </Button>
                )}
                {current > 0 && (
                    <Button type="primary" onClick={() => prev()}>
                        上一步
                    </Button>
                )}
                {current === 2 && (
                    <Button type="primary" onClick={() => message.success('处理完成！')}>
                        处理完成
                    </Button>
                )}
            </div> */}
            <VerifyModal
                visible={verifyShow}
                tokenVal={captchaVal}
                onCancel={() => setVerifyShow(false)}
                onOk={(v) => _getSmsCode(v)}
                onRefresh={() => _getCaptcha()}
            />

        </div >
    )
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Spin, Collapse, Divider, Button, Tooltip, Modal, Table } from 'antd';
import { SyncOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { getAutoTradingStrategies, getAutoTradingInfoPoints } from '@/api/userSystem';
import { kLineHistory, getTradingInfoPoints } from '@/api/workbench';
import { useReactive } from 'ahooks';
import { autoCol, validateResponse } from '@/utils/utils';
import { simpleColumns, isValidArray, isValidObj } from '@/utils/utils2';
import { OPTIONS_KLINE } from '@/view/common/Components/chartsInfoUtils2'
import { handleSingnalItem } from '@/view/common/Components/list_util';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';
import moment from 'moment';
import * as echarts from 'echarts';

const { Option } = Select;
const { Panel } = Collapse;
//const TEMP_RANGE_TIME = [moment().subtract(1, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
const renderBuySellTime = (time) => {
  let finalDate = time ? moment(time).format('YYYY-MM-DD') : '';
  let finalTime = time ? moment(time).format('HH:mm:ss') : '';
  if (finalTime === '09:30:00') {
    finalTime = '09:31:00'
  }
  return `${finalDate} ${finalTime}`;
};
// Each item: open，close，lowest，highest
const SignalCharts = (props) => {
  const [option, setoption] = useState(OPTIONS_KLINE);
  const [selectStocks, setselectStocks] = useState(_.get(props, 'indexList[0].value', ''));
  const [stockIndex, setstockIndex] = useState(null);
  const [tradeMode, setTradeMode] = useState('all');
  const [selectTime, setselectTime] = useState(_.get(props, 'globalDate', []));
  const [sigParams, setSigParams] = useState({});
  const [signalData, setsignalData] = useState([]);
  const [signalVals, setSignalVals] = useState({});
  const [stLoading, setStLoading] = useState(false);
  const [autoList, setAutoList] = useState([]);
  const [update, setUpdate] = useState(0);
  const modalState = useReactive({ show: false, data: [] });

  // 加载不自动获取，否则会与upcount重复执行，导致参数不正确
  // useMount(() => {
  //   _initGet();
  // });

  function _initGet() {
    if (_.size(selectTime) === 2) {
      publicSignal({
        'startTime': selectTime[0],
        'endTime': selectTime[1],
        'traderIndex': 'all',
        'selectStocks': selectStocks,
      });
    }
  }
  // 上层信号更新，获取参数更新
  useEffect(() => {
    if (isValidObj(_.get(props, 'sigPms'))) {
      publicSignal(_.get(props, 'sigPms'));
    }
  }, [props.upcount]);

  // 获取个股交易信号
  async function publicSignal(params) {
    const getIndexList = _.get(props, 'indexList', []);
    const getId = _.get(props, 'subAccountId', 0);
    if (_.size(getIndexList) === 0 || !getId) {
      setSignalVals({});
      setsignalData([]);
      return;
    }

    let allSignal = [];
    let buyList = [];
    let sellList = [];
    let buyIndexList = [];
    let sellIndexList = [];

    const getStock = _.get(params, 'selectStocks');
    const getStockIndex = _.get(params, 'stockIndex');
    let getTime = [_.get(params, 'startTime'), _.get(params, 'endTime')];

    let pm2 = {
      symbol: getStock,
      subAccountId: getId,
      beginDate: getTime[0],
      endDate: getTime[1],
    }
    if (_.get(params, 'traderIndex') !== 'auto') {
      if (_.get(params, 'tradeMode')) {
        pm2.tradeMode = params.tradeMode;
      }
      const data2 = await getTradingInfoPoints(pm2);
      if (data2.code === '200') {
        data2.data.map(item => {
          if (item.side === 1) { // 修改了hh:mm:ss 服务器返回17:15:00 有秒单位，所以判断失效
            buyList.push([renderBuySellTime(item.tradeTime1), item.primaryStockNum, item.avgPrice]);
          } else if (item.side === -1) {
            sellList.push([renderBuySellTime(item.tradeTime1), item.primaryStockNum, item.avgPrice]);
          }
          return item;
        })
      }
    } else {
      if (getStockIndex) { //自动托管的点位值
        pm2.strategiesId = getStockIndex;
        const data = await getAutoTradingInfoPoints(pm2);
        if (validateResponse(data, false)) {
          _.get(data, 'data', []).map(item => {
            if (item.side === 1) { // 修改了hh:mm:ss 服务器返回17:15:00 有秒单位，所以判断失效
              buyList.push([renderBuySellTime(item.tradeTime1), item.primaryStockNum, item.avgPrice]);
            } else if (item.side === -1) {
              sellList.push([renderBuySellTime(item.tradeTime1), item.primaryStockNum, item.avgPrice]);
            }
            return item;
          })
        }
        pm2.tradeMode = 'auto'; // 获取数据后修改参数，保证charts内部拿到参数状态统一
      }
    }
    setSigParams(pm2);
    const res2 = await kLineHistory({
      symbol: getStock,
      beginDate: getTime[0],
      endDate: getTime[1],
      type: 1
    });
    if (validateResponse(res2)) {
      let timeList = [];
      let priceList = [];
      let newValueList = [];
      const getPriceResult = _.get(res2, 'data.priceResult', []);
      getPriceResult.map(array => {
        timeList.push(array[0]);
        priceList.push(parseFloat(array[5]));
        let temp = _.drop(array);
        newValueList.push(temp)
      })
      allSignal = _.concat(allSignal, handleSingnalItem(buyList, timeList, priceList, 'buy'));
      allSignal = _.concat(allSignal, handleSingnalItem(sellList, timeList, priceList, 'sell'));
      allSignal = _.concat(allSignal, handleSingnalItem(buyIndexList, timeList, priceList, 'buyIndex'))
      allSignal = _.concat(allSignal, handleSingnalItem(sellIndexList, timeList, priceList, 'sellIndex'));
      // console.log('allSignal', allSignal)
      let finalData = {
        timeList: timeList,
        values: newValueList,
        cal: {
          high: _.max(priceList),
          low: _.min(priceList),
          startPrice: _.get(res2, 'data.preClose', 0)
        },
      }

      setsignalData(allSignal);
      setSignalVals(finalData);
    } else {
      setsignalData([]);
      setSignalVals({});
    }
    setUpdate(_.round(update + 0.1, 1));
  }

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('list_signal_chart'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);
    if (_.size(signalVals) > 0) {
      const getValues = _.get(signalVals, 'values', []);
      newOption.xAxis.data = _.get(signalVals, 'timeList', []);
      newOption.series[0].data = getValues;
      newOption.series[0].markPoint.data = signalData;
      newOption.series[1].data = calMaLine(getValues);
      modalState.data = signalData.map((n, i) => { return { ...n, key: i } });
      const getHigh = _.get(signalVals, 'cal.high', 0);
      const getLow = _.get(signalVals, 'cal.low', 0);
      const getStart = _.get(signalVals, 'cal.startPrice', 0);
      // console.log('数值', _.get(props, 'signalVals.cal'))
      newOption.yAxis[1].min = _.round((getLow - getStart) / getLow, 2) * 100;
      newOption.yAxis[1].max = _.round((getHigh - getStart) / getHigh, 2) * 100;
    }
    if (_.get(sigParams, 'symbol', null) !== selectStocks) {
      setselectStocks(_.get(sigParams, 'symbol'))
    }
    if (_.get(sigParams, 'tradeMode', '') !== tradeMode) {
      setTradeMode(_.get(sigParams, 'tradeMode'))
    }

    // console.log('更新！', newOption)
    // console.log('props！', props)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);

  async function _getAutoTradingStrategies() {
    if (props.subAccountId) {
      setStLoading(true);

      let params = {
        "symbol": selectStocks, //股票代码
        "subAccountId": props.subAccountId, //子账户id
        "beginDate": moment(selectTime[0]).format('YYYY-MM-DD'), //开始时间
        "endDate": moment(selectTime[1]).format('YYYY-MM-DD') //结束时间
      }
      const data = await getAutoTradingStrategies(params);
      //console.log('所有托管策略信息', data)
      if (_.get(data, 'code', '') === '200') {
        setStLoading(false);
        setAutoList(_.get(data, 'data', []))
      }
    }

  }
  // 计算5分钟移动均线
  function calMaLine(kdata) {
    let final = [];
    if (isValidArray(kdata)) {
      kdata.map((n, i) => {
        if (_.size(n) > 1) { // 有效k线数据=5位
          final[i] = _.chain(kdata)
            .slice(i < 5 ? 0 : i - 4, i + 1) // 截取前5分钟数据，不足5分拿所有
            .map(k => parseFloat(k[1]))
            .sum() // 累加
            .divide(i < 5 ? i + 1 : 5) // 求平均
            .round(2)
            .value();
        }
      })
    }
    return final;
  }
  // 修改参数上层获取； 旧的逻辑直接合并的方法，未做精简处理
  function handleSelectChange(key, value) {
    let params = {
      startTime: selectTime[0],
      endTime: selectTime[1],
      tradeMode: tradeMode,
      stockIndex: stockIndex,
      selectStocks: selectStocks || null
    }

    if (key === 'stock') {
      setselectStocks(value);
      setstockIndex(null);
      setTradeMode(null);
      params.selectStocks = value;
      params.stockIndex = null;
      publicSignal(params);

    } else if (key === 'tradeMode') {
      setTradeMode(value);
      params.tradeMode = value;
    } else if (key === 'stockIndex') {
      setstockIndex(value);
      params.stockIndex = value;
    } else if (key === 'updateTime') {
      setselectTime(value);
      params.startTime = value[0];
      params.endTime = value[1];
    }

    if (params.tradeMode === 'auto') {
      _getAutoTradingStrategies();
    }

    if (params.tradeMode && params.selectStocks) {
      publicSignal(params);
    };
  }

  const renderSelectHeader = () => {
    return (
      <div style={{ backgroundColor: '#f5f5f5', borderRadius: 4 }}>
        <Collapse defaultActiveKey={['1']} ghost>
          <Panel header="条件选择" key="1">
            <Row align='middle'>
              <span style={{ marginRight: 10 }}>股票名称:</span>
              <Select
                onChange={(value) => handleSelectChange('stock', value)}
                value={selectStocks}
                dropdownMatchSelectWidth={false}
                style={{ width: 100 }}
                size='small'
              >
                {
                  props.indexList.length !== 0 &&
                  props.indexList.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        value={item.value}
                      >
                        {item.title}
                      </Option>
                    )
                  })
                }
              </Select>
              <span style={{ marginLeft: 10, marginRight: 10 }}>下单方式:</span>
              <Select
                onChange={(value) => handleSelectChange('tradeMode', value)}
                defaultValue={1}
                style={{ width: 150, marginRight: 10 }}
                value={tradeMode}
                size='small'
              >
                <Option value={1}>手动下单</Option>
                <Option value={2}>{'自动下单(系统)'}</Option>
                <Option value="auto">{'自动下单(托管)'}</Option>
              </Select>
              <span style={{ marginLeft: 10, marginRight: 10 }}>托管策略:</span>
              <Select
                onChange={(value) => handleSelectChange('stockIndex', value)}
                defaultValue={null}
                dropdownMatchSelectWidth={false}
                value={stockIndex}
                style={{ width: 100 }}
                loading={stLoading}
                disabled={tradeMode !== 'auto' ? true : false}
                size='small'
              >
                {_.size(autoList) > 0 && autoList.map((item, index) => {
                  return (
                    <Option
                      key={index}
                      value={item.strategiesId}
                    >
                      {item.strategiesName}
                    </Option>
                  )
                })}
              </Select>
              <span style={{ marginRight: 10, marginLeft: 10 }}>选择日期:</span>
              <NewRangePicker
                dates={selectTime}
                size='small'
                onSelect={(dateString) => {
                  handleSelectChange('updateTime', dateString)
                }}
              />
            </Row>
          </Panel>
        </Collapse>
      </div>
    )
  }

  const columns = [simpleColumns(['时间', 'xAxis']), simpleColumns(['详细', 'value'])];
  return (
    <>
      <Row style={{ backgroundColor: '#fff', padding: 12 }}>
        <Col span={24}>
          <Spin spinning={stLoading}>
            {renderSelectHeader()}
            <Divider />
            <Row justify='space-between'>
              <Col {...autoCol([6])}>
                <div style={{ marginTop: 8, marginBottom: 8, marginLeft: 8 }}>
                  <Tooltip title="点击刷新">
                    <Button
                      shape="circle"
                      icon={<SyncOutlined />}
                      onClick={() => _initGet()}
                      size='small'
                      loading={stLoading}
                    />
                  </Tooltip>
                </div>
              </Col>
              <Col {...autoCol([6])} style={{ textAlign: 'right' }}>
                <Button size='small' type='text' icon={<DoubleLeftOutlined />} onClick={() => modalState.show = true}>
                  {'详情'}
                </Button>
              </Col>
            </Row>

            <div style={{ display: 'flex' }}>
              <div
                id="list_signal_chart"
                style={{ width: '100%', height: 365 }}
              />
            </div>
          </Spin>
        </Col>
      </Row>

      <Modal
        visible={modalState.show}
        footer={null}
        title={null}
        width={650}
        centered
        onCancel={() => modalState.show = false}
      >
        <Table
          rowKey="key"
          dataSource={modalState.data}
          columns={columns}
          size='small'
          pagination={{
            pageSize: 5,
            simple: true,
            showQuickJumper: false
          }}
        />
      </Modal>
    </>
  )
}


export default SignalCharts;
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Radio,
  Card,
  message,
  InputNumber
} from 'antd';
import _ from 'lodash';
const { Paragraph } = Typography;
const title_style = { padding: 10, fontSize: '16px', fontWeight: 600 };

const TIME_RADIO = [
  { label: '一月', value: 1 },
  { label: '半年', value: 6 },
  { label: '一年', value: 12 },
];
const SERVICE_TYPE_RADIO = [
  { label: '服务订单', value: 1 },
  { label: '费用订单', value: 2 },
];

const StepOne = ({
  onChange,
  onTypeChange,

  containerStyle,
  serviceData,
  formValue,
  isPayed,
  orderType,
  unpayFee
}) => {
  const [selected, setSelected] = useState(10);
  const [alertCount, setAlertCount] = useState(1);
  const [payType, setPayType] = useState(1); // 服务类型
  const [priceValue, setPriceValue] = useState(0);

  useEffect(() => {
    setPayType(orderType)
    setPriceValue(unpayFee)
    return () => {
      return;
    }
  }, [orderType])

  function renderServiceCard(data, index) {
    const getDes = _.get(data, 'description')
    const splitDes = getDes ? _.split(getDes, /[\s\n]/) : [];
    const isPayTrial = isPayed && data.type === 'trial' ? true : false
    return (
      <div
        key={'service' + index}
        style={selected === index ? { border: '2px solid #1890ff' } : { border: '2px solid white' }}
        onClick={() => {
          if (isPayTrial && alertCount === 1) { // 已经支付过免费版的用户
            message.info('您已购买过体验版，请选择其他规格支付！');
            setAlertCount(2);
            return
          }

          if (selected !== index && !isPayTrial) {
            setSelected(index)
            onChange('level', {
              index,
              id: data.levelId,
              type: data.type,
              price: _.get(data, 'price')
            })
          }
        }}>
        <Card
          title={_.get(data, 'name', '')}
          headStyle={isPayTrial ? { color: 'gray' } : {}}
          bordered={true}
          extra={
            <div style={{ color: isPayTrial ? 'gray' : 'red' }}>
              ￥<span style={{ fontSize: '20px' }}>{String(_.get(data, 'price') || 0)}</span>
            </div>
          }
        >
          <Paragraph>
            <ul>
              {/* {isPayed && <li>体验时间为一周</li>} */}
              {_.size(splitDes) > 0 && splitDes.map((item, index) => {
                return <li key={'li' + index}>{item}</li>
              })}
            </ul>
          </Paragraph>
        </Card>
      </div>
    )
  }

  function renderTipCard() {
    return (
      <Col span={8}>
        <div style={{ border: '2px solid #f5f5f5' }}>
          <Card
            title={'量化交易系统定制'}
            headStyle={{ color: 'gray' }}
            bordered={true}
          >
            <Paragraph>
              <ul>
                <li>{'我们的策略分析工程师和程序开发工程师为您提供专属量化系统开发服务，如有需求，请与顾问联系'}</li>
              </ul>
            </Paragraph>
          </Card>
        </div>
      </Col>
    )
  }

  function renderRadioCard(label, key, radioArray) {
    const disProps = key === 'buyCount' ? { disabled: _.get(formValue, 'level.type', '') === 'trial' ? true : false } : {}
    return <div style={containerStyle}>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <div style={title_style}>
              {label}
            </div>
          </Col>
          <Col span={18}>
            <Radio.Group
              defaultValue={1}
              buttonStyle="solid"
              onChange={(e) => {
                onChange(key, e.target.value);
                if (key === 'orderType') {
                  setPayType(e.target.value);
                  onTypeChange(e.target.value);
                }
              }}
              {...disProps}
            >
              {radioArray.map((n, i) => (
                <Radio.Button key={n.value + i} value={n.value}>{n.label}</Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>

      </Card>
    </div>
  }

  function renderCustomPrice() {
    return <div style={containerStyle}>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <div style={title_style}>
              {'支付金额'}
            </div>
          </Col>
          <Col span={18}>
            <InputNumber
              prefix="￥"
              size='large'
              min={0.01}
              step={1}
              style={{ width: 280 }}
              value={priceValue}
              onChange={(v) => {
                onChange('orderAmount', v);
                setPriceValue(v)
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  }

  return (
    <>
      {/* {renderRadioCard('服务类型', 'orderType', SERVICE_TYPE_RADIO)} */}

      {payType === 2 ? renderCustomPrice() : renderRadioCard('购买时长', 'buyCount', TIME_RADIO)}

      {payType === 1 && <div style={containerStyle}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <div style={title_style}>
                服务规格
              </div>
            </Col>
            <Col span={18}>
              <Row gutter={[16, 16]}>
                {_.size(serviceData) > 0 && serviceData.map((n, i) => (
                  <Col span={8} key={'list' + i}>
                    {renderServiceCard(n, i)}
                  </Col>
                ))}

                {renderTipCard()}
              </Row>
            </Col>
          </Row>
        </Card>
      </div>}
    </>
  )
}

export default StepOne;
import React, { useState } from 'react';
import { Spin } from 'antd';
import { isValidArray } from '@/utils/utils2';
import { useUpdateEffect } from 'ahooks';
import _ from 'lodash';
import * as echarts from 'echarts';

const LEVELS = [
  { name: '高', color: '#dd0000', key: 'high', index: 0 },
  { name: '中', color: '#ffcd00', key: 'middle', index: 1 },
  { name: '低', color: '#00a388', key: 'down', index: 2 },
];
const PLATES = [
  { name: '北交所', color: '#775fa0', index: 0 },
  { name: '上证主板', color: '#c7161d', index: 1 },
  { name: '深证主板', color: '#dab301', index: 2 },
  { name: '科创板', color: '#aacf53', index: 3 },
  { name: '创业板', color: '#005eab', index: 4 },
];
const PLATE_OPTIONS = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
  },
  legend: {},
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value} %'
    },
    min: 0, max: 110
  },
  xAxis: { type: 'category', data: [] },
  series: PLATES.map(n => {
    return {
      name: n.name, type: 'bar', stack: 'total',
      label: { show: true, formatter: '{c}%' },
      emphasis: { focus: 'series' },
      itemStyle: { color: n.color },
      data: []
    }
  }),
};
const LEVEL_OPTIONS = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  tooltip: { trigger: 'axis' },
  legend: { data: [] },
  xAxis: { type: 'category', data: [] },
  yAxis: { type: 'value' },
  series: LEVELS.map(n => {
    return {
      name: n.name, type: 'bar', data: [],
      itemStyle: { color: n.color }, label: { show: true },
    }
  })
}
// 重仓分布及京沪深统计图表
export default function HighPosCharts(props) {
  const [option, setOption] = useState(LEVEL_OPTIONS);
  const [option2, setOption2] = useState(PLATE_OPTIONS);
  const actives = _.get(props, 'active', '');

  useUpdateEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('high_position_charts'));

    const isLevel = actives === '6' ? true : false;
    let newOption = _.cloneDeep(option);
    let newOption2 = _.cloneDeep(option2);

    const getDatas = _.get(props, 'datas', []);
    const newBase = isLevel ? LEVELS : PLATES;
    let times = []; let sdata = isLevel ? newOption.series : newOption2.series;

    if (isValidArray(getDatas)) {
      getDatas.map((item, i) => {
        const xdate = _.get(item, 'date', '')
        times.push(xdate);
        newBase.map(n => {
          const val = _.round(_.get(item, 'key' in n ? n.key : n.name), 2);
          _.set(sdata[n.index], `data[${i}]`, val);
        });
        if (!isLevel) {
          let pushObj = {
            'name': xdate, 'value': _.get(item, '总个数', 0) + '个', 'xAxis': xdate, 'yAxis': 105,
            'symbol': '', 'symbolSize': 0, 'label': { color: 'black', show: true },
          }
          _.set(sdata[0], `markPoint.data[${i}]`, pushObj);
        }
      });
    };
    if (isLevel) {
      newOption.xAxis.data = times;
      newOption.series = sdata;
      newOption.legend.data = newBase.map(n => n.name);
    } else {
      newOption2.xAxis.data = times;
      newOption2.series = sdata;
      newOption2.legend.data = newBase.map(n => n.name);
    }
    // console.log('newOption', newOption)
    setOption(newOption); setOption2(newOption2);
    myChart.setOption(isLevel ? newOption : newOption2, true);
    myChart.resize();
  }, [props.upCount]);

  const isShow = _.includes(['6', '7'], actives);
  return (
    isShow ? <>
      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="high_position_charts"
            style={{
              width: '99%',
              height: 425
            }}
          />
        </div>
      </Spin>
    </> : <></>

  )
}
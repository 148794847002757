import React, { useState } from 'react';
import { PageHeader, Tabs, Typography, Space, message } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMount, useUnmount, useReactive } from 'ahooks';
import { showVirtualProduct, fundAdjustment, deleteVirtualProduct } from '@/api/details';
import { createDatasTimeKey } from '@/utils/utils2';
import { validateResponse } from '@/utils/utils';
import VirtualTables from './Components/VirtualTables';
import VirtualCards from './Components/VirtualCards';
import _ from 'lodash';
import '../AccountAdm/ProductDetails/product.scss'

const { Text } = Typography;
const { TabPane } = Tabs;
/* 
  产品合并页面;
*/
const VirtualPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [vproducts, setVproducts] = useState(JSON.parse(JSON.stringify(mobxStore.virProducts)));
  const virState = useReactive({ active: '1' });
  let isUnmont = false;

  useMount(() => {
    _showVirtualProduct();
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // 获取产品信息
  async function _showVirtualProduct() {
    const res = await showVirtualProduct();
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', []);
      const orderData = createDatasTimeKey(getData, 'date');
      const roundKeys = ['totalAssets', 'totalRevenue', 'dailyRevenue'];
      // 给资产字段保留2位小数
      let newVdata = orderData.map(n => {
        let floatItm = {};
        roundKeys.map(k => {
          _.set(floatItm, k, _.round(n[k], 2));
        })
        return _.assign(n, floatItm);
      })
      setVproducts(newVdata);
    }
  }
  // 提交修改接口
  async function _fundAdjustment(params) {
    const res = await fundAdjustment(params);
    if (_.get(res, 'code', '') === '200') {
      message.success('调整成功!');
      _showVirtualProduct();
    } else {
      message.info(_.get(res, 'message', '调整失败！'));
    }
  }
  // 删除产品
  async function _deleteVirtualProduct(id) {
    if (id) {
      const res = await deleteVirtualProduct({ 'virtualProductId': id });
      if (_.get(res, 'code', '') === '200') {
        message.success('删除成功!');
        _showVirtualProduct();
      } else {
        message.info(_.get(res, 'message', '删除失败！'));
      }
    }
  }
  // 点击虚拟详情页
  function goVirtualProduct(item) {
    props.history.push({
      'pathname': '/home/vitualProduct',
      'state': { 'item': item, 'allproduct': vproducts }
    });
  }
  // console.log(virState.active, productItems);
  return <div style={{ overflowX: 'hidden' }}>
    <PageHeader
      title={<Space>
        <Text>虚拟产品</Text>
      </Space>}
      style={{ marginBottom: 8, backgroundColor: 'white' }}
    >
    </PageHeader>

    <div className='productTab'>
      <Tabs activeKey={virState.active} type='card' onChange={(value) => virState.active = value}>
        <TabPane tab={<Space size={'small'}>
          <AppstoreOutlined />
          <span>产品</span>
        </Space>} key={'1'}
        >
          <div style={{ minHeight: 550 }}>
            <VirtualCards items={vproducts} goDetails={(item) => goVirtualProduct(item)} />
          </div>
        </TabPane>
        <TabPane tab={<Space size={'small'}>
          <BarsOutlined />
          <span>列表</span>
        </Space>} key='2'
        >
          <div style={{ minHeight: 550 }}>
            <VirtualTables datas={vproducts}
              onEdit={(val) => _fundAdjustment(val)}
              onDelete={(id) => _deleteVirtualProduct(id)}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  </div>
}

export default VirtualPage;
import React from 'react';
import { List, Button, Typography, Space } from 'antd';
import { RightCircleOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;

const SingleList = ({
    page = 1,
    loading = false,
    singleList = [],
    opnList = [],
    type = 1,
    sid = 0,

    pageChange,
    onDetail,
}) => {
    const isSingle = type === 1 ? true : false;
    return (
        <div className='testbackList'>
            <List
                itemLayout="horizontal"
                dataSource={isSingle ? singleList : opnList}
                loading={loading}
                size='small'
                pagination={isSingle ? {
                    current: _.get(page, 'page', 1),
                    total: _.get(page, 'total', 0),
                    pageSize: 5,
                    onChange: e => { pageChange(e) }
                } : {
                    pageSize: 5
                }}
                header={null}
                renderItem={item => {
                    if (isSingle) {
                        return <List.Item>
                            <List.Item.Meta
                                title={`回测-${_.get(item, 'id', '')}`}
                                description={
                                    <Paragraph style={{ marginBottom: 0 }}>
                                        <Text type='secondary'>{moment(_.get(item, 'createTime', '')).format('YYYY-MM-DD HH:mm')}</Text>
                                        <Text type='secondary' style={{ marginLeft: 10 }}>{item.remark || ''}</Text>
                                    </Paragraph>
                                }
                            />
                            <div>
                                <Button type='text' icon={<RightCircleOutlined />} onClick={() => onDetail(item, 1)}>
                                    详情
                                </Button>
                            </div>
                        </List.Item>
                    } else {
                        return <List.Item>
                            <List.Item.Meta
                                title={_.get(item, 'name', '')}
                                description={
                                    <Space>
                                        <Text type='secondary'>{moment(_.get(item, 'createTime', '')).format('YYYY-MM-DD HH:mm')}</Text>
                                        <Text type='secondary'>{_.get(item, 'remark', '')}</Text>
                                        <Text type='secondary'>{_.get(item, 'channel', '')}</Text>
                                    </Space>
                                }
                            />
                            <div>
                                <Button
                                    type='text'
                                    icon={<EditOutlined />}
                                    disabled={!sid}
                                    onClick={() => onDetail(item, 2)}
                                >
                                    去修改
                                </Button>
                            </div>
                        </List.Item>
                    }
                }}
            />
        </div>
    )
}

export default SingleList;
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  Tag,
  PageHeader,
  Descriptions,
} from 'antd';
import moment from 'moment';
import { checkUserBalance } from '@/api/userSystem';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Text } = Typography;

const CheckBalance = (props) => {
  const [balParams] = useState(props.location.state.balance);
  const [loading, setLoading] = useState(false)
  const [checkData, setCheckData] = useState([])
  const [checkState, setCheckState] = useState(true)
  const [balanceInfo, setBalanceInfo] = useState(true)

  useEffect(() => {
    _checkUserBalance()

    return () => { return; }
  }, [])

  async function _checkUserBalance() {
    setLoading(true);

    let params = {
      startDate: _.get(balParams, 'date'),
      memberId: _.get(balParams, 'id'),
    }

    const res = await checkUserBalance(params);
    if (_.get(res, 'code') === '200') {
      const getData = _.get(res, 'data', {})
      const getState = _.get(res, 'data.checkState');
      setCheckState(getState)
      setCheckData(_.get(getData, 'flow', []))
      setBalanceInfo({ balance: _.get(getData, 'balance'), computeBalance: _.get(getData, 'computeBalance') })
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const column = [
    {
      title: '服务名称',
      dataIndex: 'goodsName',
      align: 'left',
      key: 'goodsName',
    },
    {
      title: '支付用户账号',
      dataIndex: 'purchaseUserAccount',
      align: 'left',
      key: 'purchaseUserAccount',
    },
    {
      title: '操作金额',
      dataIndex: 'amount',
      align: 'left',
      key: 'amount',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '当前余额',
      dataIndex: 'currentBalance',
      align: 'left',
      key: 'currentBalance',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '日支付金额',
      dataIndex: 'payAmount',
      align: 'left',
      key: 'payAmount',
      render: (text, record) => _.round(text, 2)
    },
    {
      title: '用户账号',
      dataIndex: 'purchaseUserAccount',
      align: 'left',
      key: 'purchaseUserAccount',
    },
    {
      title: '流水方向',
      dataIndex: 'direction',
      align: 'left',
      key: 'direction',
      render: (text, record) => text === 1 ? '余额增加流水' : '用户提现流水'
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'left',
      key: 'state',
      render: (text, record) => {
        if (text === 1) {
          return <Tag color={'success'}>通过</Tag>
        } else if (text === 0) {
          return <Tag color={'processing'}>审批中</Tag>
        } else if (text === 2) {
          return <Tag color={'error'}>拒绝</Tag>
        }
      }
    },
    {
      title: '操作日期',
      dataIndex: 'operationDate',
      align: 'left',
      key: 'operationDate',
      render: (text, record) => (
        moment(text).format('YYYY-MM-DD HH:mm')
      )
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <PageHeader
        title={_.get(balParams, 'name')}
        subTitle={'查询用户余额'}
        onBack={() => props.history.goBack()}
        style={{ border: ' 1px solid rgb(235, 237, 240)', marginBottom: 10 }}
        tags={<Tag color={checkState ? 'success' : 'error'}>{checkState ? '校验成功' : '校验失败'}</Tag>}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="当前会员余额">{_.get(balanceInfo, 'balance')}</Descriptions.Item>
          <Descriptions.Item label="统计余额">
            <Text strong>{_.get(balanceInfo, 'computeBalance')}</Text>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <div style={{ padding: 10, marginTop: 8 }}>
        <Table
          rowKey={'id'}
          dataSource={checkData}
          columns={column}
          size="middle"
          loading={loading}
        />
      </div>
    </div>
  )
}

export default CheckBalance;
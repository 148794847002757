import React, { useState } from 'react';
import { Col, Row, Descriptions, Tag, Button, Typography, Collapse, Table, Segmented } from 'antd';
import { AccountBookOutlined, AreaChartOutlined, CaretRightOutlined, AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { singleColumns, renderValueColor } from '@/utils/utils';
import QueueAnim from 'rc-queue-anim';
import _ from 'lodash';

const { Text } = Typography;
const { Panel } = Collapse;
const SEG_OPTIONS = [
    {
        value: 'Grid',
        icon: <AppstoreOutlined />,
    },
    {
        value: 'List',
        icon: <BarsOutlined />,
    }
];

const saveType = (data) => {
    try {
        localStorage.setItem('asset_type', JSON.stringify(data));
    } catch (error) { }
}
const getType = () => {
    try {
        let val = localStorage.getItem('asset_type');
        return val ? JSON.parse(val) : null
    } catch (error) { }
}

const AccountCard = ({ accountList, accStastic, onLink }) => {
    const [type, setType] = useState(getType() ?? SEG_OPTIONS[0].value) // 切换功能可全局储存使用习惯
    const columns = [
        { ...singleColumns(['名称', 'subAccountName', 120], 'strong'), },
        {
            ...singleColumns(['类型', 'subAccountType', 80]),
            render: (text) => {
                return text === '1' ? <Tag icon={<AreaChartOutlined />} color={'#3b5999'}>股票</Tag>
                    : <Tag icon={<AreaChartOutlined />} color={'#55acee'}>期货</Tag>
            }
        },
        { ...singleColumns(['总资产', 'totalAsset', 120], 'strong'), },
        { ...singleColumns(['可用资金', 'balance', 100], 'strong'), },
        { ...singleColumns(['总市值', 'position', 100], 'strong'), },
        { ...singleColumns(['可用资金', 'balance', 100]), },
        { ...singleColumns(['净值', 'netValue', 80]), },
        { ...singleColumns(['总收益', 'totalRevenue', 80], 'updown_replace'), },
        { ...singleColumns(['日收益', 'dailyRevenue', 80], 'updown_replace'), },
        {
            ...singleColumns(['操作', 'option', 120]),
            fixed: 'right',
            render: (text, record) => {
                return <Button
                    icon={<AccountBookOutlined />}
                    type='primary'
                    size='small'
                    onClick={() => onLink(record)}
                >
                    查看账户
                </Button>
            }
        },
    ];

    const renderResult = (auto, allShort, result) => {
        // 有托管有限显示托管信息
        if (auto) {
            return auto
        } else if (allShort) {
            return allShort
        } else {
            return _.size(result) > 3 ? result.slice(0, 2) : result
        }
    }
    const new_col = { xxl: 8, xl: 8, lg: 8, md: 12, sm: 24, flex: 'auto' }
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <Segmented
                    options={SEG_OPTIONS}
                    value={type}
                    onChange={(val) => {
                        setType(val);
                        saveType(val);
                    }}
                />
            </div>
            <div className='assetCardDiv'>
                <QueueAnim delay={100}>
                    <Row gutter={[16, 16]} key='a'>
                        {_.size(accountList) > 0 && type === 'Grid' ? accountList.map((item, index) => {
                            const getName = _.get(item, 'subAccountName', '')
                            const limitAccountName = _.size(getName) > 9 ? getName.substring(0, 9) + '...' : getName;
                            const accountType = _.get(item, 'subAccountType');
                            const getResult = _.get(item, 'stockAssetPositionResult', []); // 所有持仓
                            const shortResult = _.size(getResult) > 3 ? getResult.slice(0, 2) : null;
                            const autoResult = _.filter(getResult, o => o.tradeStatus === 1); // 所有托管数据
                            const shortAutoResult = _.size(autoResult) > 3 ? autoResult.slice(0, 2) : null;

                            const newResult = renderResult(shortAutoResult, shortResult, getResult);
                            // const col_offset = item.needOffset ? { ...new_col, ...offset_col } : { ...new_col };
                            return <Col key={'acc' + index} {...new_col}>
                                <div className='assetCard'>
                                    <Descriptions
                                        title={limitAccountName}
                                        column={2}
                                        extra={
                                            <Tag color={accountType === '1' ? '#3b5999' : '#55acee'} icon={<AreaChartOutlined />}>
                                                {accountType === '1' ? '股票' : '期货'}
                                            </Tag>
                                        }
                                    >
                                        <Descriptions.Item label="总资产">
                                            <Text strong>  {_.get(item, 'totalAsset', '')}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="可用资金">
                                            <Text strong>{_.get(item, 'balance', '')}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="总市值">
                                            <Text strong>{_.get(item, 'position', '')}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="净值">
                                            <Text strong>{_.get(item, 'netValue', '')}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="总收益">
                                            <Text strong style={{ color: renderValueColor(_.get(item, 'totalRevenue', ''), 'replace') }}>
                                                {_.get(item, 'totalRevenue', '')}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="日收益">
                                            <Text strong style={{ color: renderValueColor(_.get(item, 'dailyRevenue', ''), 'replace') }}>
                                                {_.get(item, 'dailyRevenue', '')}
                                            </Text>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {_.size(getResult) > 0 && <Collapse
                                        bordered={false}
                                        className="site-collapse-custom-collapse"
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                        defaultActiveKey={['1']}
                                    >
                                        <Panel header="托管信息" key="1" className="site-collapse-custom-panel">
                                            {newResult.map((n, i) => {
                                                const getTradeStatus = _.get(n, 'tradeStatus', 10);
                                                const getInfo = _.get(n, 'tradeInfo', {});
                                                return <Descriptions
                                                    title={<Text strong style={{ fontSize: 14 }}>{_.get(n, 'stockName', '')}</Text>}
                                                    size='small'
                                                    column={2}
                                                    key={'result' + i}
                                                    extra={
                                                        getTradeStatus === 1 ? <span style={{ color: '#8ecdff', fontSize: 12 }}>托管中</span>
                                                            : <span style={{ color: '#b3b3b3', fontSize: 12 }}>未托管</span>
                                                    }
                                                >
                                                    <Descriptions.Item label={'数量'}>{_.get(n, 'stockAmount', '') + '股'}</Descriptions.Item>
                                                    <Descriptions.Item label={'盈利'}>
                                                        <Text style={{ color: renderValueColor(_.get(n, 'totalProfit', '')) }}>
                                                            {_.get(n, 'totalProfit', '')}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    {n.kdj && getTradeStatus === 1 && <Descriptions.Item label={'策略'} span={2}>
                                                        <Tag color='orange'>{_.get(n, 'kdj', '')}</Tag>
                                                    </Descriptions.Item>}
                                                    {_.size(getInfo) > 0 && <Descriptions.Item label={'最近交易'} span={2}>
                                                        <span style={{ marginRight: 5 }}>{getInfo.stockName}</span>
                                                        <span style={{ color: '#cbc500', fontWeight: '600' }}>{getInfo.avgPrice}</span>
                                                        <span style={{ marginRight: 5 }}>{'元'}</span>
                                                        <span style={{ marginRight: 5 }}>{getInfo.side === 'BUY' ? '买入' : '卖出'}</span>
                                                        <span style={{ color: '#cbc500', fontWeight: '600' }}>{getInfo.cumQty}</span>
                                                        <span >{'股'}</span>
                                                    </Descriptions.Item>}
                                                </Descriptions>
                                            })}
                                        </Panel>
                                    </Collapse>}
                                    <div className='acc_btn'>
                                        <Button icon={<AccountBookOutlined />} type='text' block onClick={() => onLink(item)}>
                                            查看账户
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        }) : null}
                    </Row>
                </QueueAnim>
                <QueueAnim delay={100}>
                    {type === 'List' ? <div key='z' className='tableCard'>
                        <Table
                            rowKey="subAccountId"
                            dataSource={accountList}
                            columns={columns}
                            scroll={{ x: 980 }}
                            pagination={{
                                pageSize: 7
                            }}
                        />
                    </div> : null}
                </QueueAnim>
            </div>
        </>
    )
}

export default AccountCard;
import React, { useState, useEffect } from 'react';
import { Card, Alert, Modal, message, Button } from 'antd';
import {
    showAssetsAndRevenue, showAllSubAccount, getManagerSubAccount
} from '@/api/workbench';
import { autoDistributeSub } from '@/api/userSystem';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import AccountTable from './Compents/AccountTable';
import DeskHeader from './Compents/deskHeader';
import addManager from '@/images/mWechatQrcode2.jpg';
import _ from 'lodash';

export default function TradersDesk(props, { history }) {
    const mobxStore = useLocalObservable(() => store);
    const [routeUserInfo] = useState(_.get(mobxStore, 'userInfo', {}));
    const [accountList, setaccountList] = useState([]);
    const [newAccountList, setNewAccountList] = useState([]);
    const [accountData, setaccountData] = useState({});

    const [isModalVisible, setIsModalVisible] = useState(false);

    function handleModal(status) {
        setIsModalVisible(status)
    }

    var _get
    useEffect(() => {
        async function _getData() {
            const data = await showAssetsAndRevenue();
            if (data.code === '200') {
                setaccountData(data.data);
            }
            const data1 = await showAllSubAccount();
            if (data1.code === '200') {
                if (data1.data !== null) {
                    data1.data.map(item => {
                        item.netValue = item.netValue.toFixed(4);
                        return item;
                    })
                }

                // if (!data1.data || _.size(data1.data) === 0) {
                //     const getRole = _.get(routeUserInfo, 'role', '')
                //     if (getRole === 'trader' || getRole === 'trader_manager') {
                //         handleModal(true)
                //     }
                // }

                setaccountList(handleAccountData(data1));
            }
        }
        _getData();

        _get = setInterval(() => {
            showAssetsAndRevenue().then((data) => {
                if (data.code === '200') {
                    setaccountData(data.data);
                }
            });
            showAllSubAccount().then((data1) => {
                if (data1.code === '200') {
                    setaccountList(handleAccountData(data1));
                }
            })
            if (_.get(routeUserInfo, 'role', '') === 'trader_manager') {
                _getAccount();
            }
        }, 10000);

        if (_.get(routeUserInfo, 'role', '') === 'trader_manager') {
            _getAccount();
        }

        return () => { clearInterval(_get); return; }
    }, []);

    async function _getAccount() {
        const data = await getManagerSubAccount();
        if (_.get(data, 'code') === '200') {
            setNewAccountList(_.get(data, 'data', []))
        }
    }

    async function _autoDistributeSub() {
        const data = await autoDistributeSub();
        if (_.get(data, 'code') === '200') {
            const getMsg = _.get(data, 'message', '');
            if (data.data === true) {
                message.success('账户创建成功!');
                window.location.reload();
            } else {
                message.warning(getMsg);
                handleModal(true)
            }
        }
    }


    function handleAccountData(data) {
        return _.get(data, 'data', [])
    }

    return (
        <div className="contentView" >
            {
                _.get(routeUserInfo, 'role', '') === 'trader' && accountList === null &&
                <Alert
                    message="提醒"
                    description={`您还未绑定子账户，请点击右侧进行分配！`}
                    type="warning"
                    showIcon
                    closable
                    action={
                        <Button size="small" danger type='primary' onClick={() => {
                            _autoDistributeSub();
                        }}>
                            点击自动分配基金经理
                        </Button>
                    }
                />
            }
            <Card>
                <DeskHeader
                    accountData={accountData}
                />
                <br /><br /><br />

                <AccountTable
                    newProps={props}
                    role={_.get(routeUserInfo, 'role', '')}
                    newAccountList={newAccountList}
                    accountList={accountList}
                    accountListHome={JSON.parse(JSON.stringify(_.get(mobxStore, 'accList', [])))}
                    goBack={() => _getAccount()}
                />
            </Card>

            <Modal
                title="提醒"
                visible={isModalVisible}
                onOk={() => handleModal(false)}
                onCancel={() => handleModal(false)}
            >
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ fontSize: '16px' }}>请添加以下基金经理微信，为您开通交易服务：</p>
                    <img
                        src={addManager}
                        alt=""
                        style={{
                            width: 300,
                            height: 300,
                            margin: '0 auto'
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}
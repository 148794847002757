import React, { useState } from 'react';
import {
    Row,
    Col,
    Modal,
    Typography,
    Button,
    Space,
    message
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// import CanvasPoster from 'react-canvas-poster';
// import { triggerBase64Download } from 'react-base64-downloader';
import _ from 'lodash';

const { Title, Paragraph, Text } = Typography;
const { confirm } = Modal;

const PosterModal = ({ visible, role, qrLink, onCancel }) => {
    const [poster64, setPoster64] = useState(null)
    const posterData = {
        width: 445,
        height: 667,
        backgroundColor: '#fff',
        debug: true,
        views: [
            {
                type: 'image',
                // url: require('./test.jpg'),
                url: 'https://simx.cfirstquant.com/share_poster.jpg',
                top: 0,
                left: 0,
                width: 445,
                height: 667,
            },
            {
                type: 'qcode',
                text: qrLink,
                top: 480,
                left: 295,
                width: 90,
                height: 90,
            },
        ]
    }

    function posterSuccess(res) {
        //console.log('success', res);
        setPoster64(res)
    }

    return (
        <Modal
            visible={visible}
            onCancel={() => onCancel()}
            width={500}
            footer={null}
            style={{ overflow: 'hidden' }}
        >
            <div className='maskPosterDiv'>
                {/* <Button
                    type='primary'
                    icon={<DownloadOutlined />}
                    style={{ position: 'absolute', top: 43 }}
                    onClick={() => {
                        triggerBase64Download(poster64, '推广海报')
                    }}>
                    点击下载
                </Button> */}
            </div>
            <div className='posterContainer'>
                {/* <CanvasPoster drawData={posterData} success={posterSuccess} /> */}
            </div>
        </Modal>
    )
}

export default PosterModal;
import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  Table,
  message
} from 'antd';
import { showAllLevels, showSubscriptionServices, updateSubscriptionServices } from '@/api/userSystem';
import { showAllStrategiesPools } from '@/api/strategy';
import EditSubscriptModal from './EditSubscriptModal';
import { INDEX_CODE } from '@/utils/indexCode';
import _ from 'lodash';

const _validateResponse = (res, empType) => {
  if (_.get(res, 'code') === '200') {
    return _.get(res, 'data', empType || null)
  } else {
    message.info('获取失败！');
    return empType || null
  }
}

const renderTextValue = (txt) => {
  if (txt === -1) {
    return '不受限制'
  } else {
    return txt
  }
}

const LevelPage = (props) => {
  const [allLevel, setAllLevel] = useState([]);
  const [allPool, setAllPool] = useState([]);
  const [subsData, setSubsData] = useState([]);
  const [visible, setVisible] = useState({ show: false, record: null });
  const [codeList, setCodeList] = useState([]);
  const [codeObj, setCodeObj] = useState({});

  const columns = [
    {
      title: '名称',
      dataIndex: 'levelName',
      key: 'levelName',
    },
    {
      title: '股票池',
      dataIndex: 'stockPool',
      key: 'stockPool',
      render: (text, record) => {
        let splitData = _.split(text, ';');
        return <ul style={{ listStyleType: 'decimal', textAlign: 'left' }}>
          {_.size(splitData) > 0 && splitData.map((item, index) => {
            return <li key={'li' + index}>{codeObj[item] || ''}</li>
          })}
        </ul>
      }
    },
    {
      title: '策略池',
      dataIndex: 'strategiesId',
      key: 'strategiesId',
      render: (text, record) => {
        let findPoolIndex = _.findIndex(allPool, o => o.id === text);
        if (findPoolIndex !== -1) {
          return allPool[findPoolIndex].strategyName || ''
        } else {
          return '无'
        }
      }
    },
    {
      title: '每月可交易股票数量',
      dataIndex: 'stockAmount',
      key: 'stockAmount',
      render: (text, record) => renderTextValue(text)
    },
    {
      title: '每只股票月启动策略总数',
      dataIndex: 'strategiesNum',
      key: 'strategiesNum',
      render: (text, record) => renderTextValue(text)
    },
    {
      title: '回测区间',
      dataIndex: 'interval',
      key: 'interval',
    },
    {
      title: '日回测次数限制',
      dataIndex: 'dayLimit',
      key: 'dayLimit',
    },
    {
      title: '月回测次数限制',
      dataIndex: 'monthLimit',
      key: 'monthLimit',
    },
    {
      title: '每个策略添加规则条数最大值',
      dataIndex: 'ruleNum',
      key: 'ruleNum',
      render: (text, record) => renderTextValue(text)
    },
    {
      title: '操作',
      dataIndex: 'option',
      key: 'option',
      render: (text, record) => {
        return <Button type='link' onClick={() => {
          goNewLevel(record)
        }}>编辑</Button>
      }
    },

  ];
  const columnsSub = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '操作',
      dataIndex: 'option',
      key: 'option',
      render: (text, record) => {
        return <Button type='link' onClick={() => {
          setVisible({ show: true, record: record })
        }}>
          更新服务
        </Button>
      }
    },
  ];

  useEffect(() => {
    //console.log('levelPage更新')
    _getAllLevel();
    _getSubsription();
    _indexCode();
    _showAllStrategiesPools();

    return () => { return; }
  }, [props.location.state])

  async function _indexCode() {
    let code = {};
    let newCodeList = Object.keys(INDEX_CODE).map(n => {
      code[INDEX_CODE[n]] = n;
      return { name: n, value: INDEX_CODE[n] }
    })
    setCodeList(newCodeList);
    setCodeObj(code);
  }

  async function _showAllStrategiesPools() {
    const res = await showAllStrategiesPools();
    if (_.get(res, 'code') === '200') {
      setAllPool(_.get(res, 'data', []))
    }
  }

  async function _getAllLevel() {
    const levRes = await showAllLevels();
    setAllLevel(_validateResponse(levRes, []))
  }
  async function _getSubsription() {
    const subRes = await showSubscriptionServices();
    setSubsData(_validateResponse(subRes, []))
  }
  async function _updateService(value) {
    if (_.get(visible, 'record')) {
      let params = {
        id: _.get(visible, 'record.id'),
        ...value
      }
      const servRes = await updateSubscriptionServices(params);
      if (_.get(servRes, 'code') === '200') {
        message.success(_.get(servRes, 'message', ''))
        setVisible({ show: false, record: null });
        _getSubsription();
      } else {
        message.warning('更新失败！')
      }
    }
  }

  function goNewLevel(record) {
    props.history.push({
      pathname: '/home/newLevelPage',
      state: {
        formValue: record ? record : null,
        cList: codeList || null,
        allPool: allPool
      }
    });
  }

  return (
    <>
      <div style={{ margin: 10 }}>
        <Card title='当前所有level策略' extra={<Button onClick={() => goNewLevel()}>新增策略</Button>}>
          <Table columns={columns} dataSource={allLevel} rowKey={'id'} />
        </Card>
      </div>

      <div style={{ margin: 10 }}>
        <Card title='产品订购服务'>
          <Table columns={columnsSub} dataSource={subsData} rowKey={'id'} />
        </Card>
      </div>

      <EditSubscriptModal
        visible={_.get(visible, 'show')}
        init={_.get(visible, 'record', null)}
        levelData={allLevel}
        onCancel={() => setVisible({ show: false, record: null })}
        onCreate={(value) => {
          //console.log('form', value)
          _updateService(value)
        }}
      />
    </>
  )
}

export default LevelPage;
import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { useReactive, useUpdateEffect, useUnmount } from 'ahooks';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { showSubAccountHistory, showSingleProductHistory, showBatchProductHistory, showBatchSubAccountHistory, showIndexKline } from '@/api/workbench';
import { showSingleProductHistoryVir, showFundKline } from '@/api/details';
import { VSTRING, isValidArray, isValidObj, isValidNumber } from '@/utils/utils2';
import {
  TWO_CHARTS, STATIC_RATE_COLOR, PRE_DAY_CN, isFullTimeRange, calRate, handleIndexValue,
  calMaxMin, calNets, renderSlice, renderFloats, handleRetreat, createBaseLine
} from './Components/chartsInfoUtils';
import { MainSlider, RateDiffModal } from './Components/main_widget';
import { INDEX_CODE_VALUE } from '@/utils/indexCode';
import TwoBars from './Components/TwoBars';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');
const INDEX_COLOR_OBJ = {
  lineStyle: { color: '#B5495B', type: 'dashed', width: 1 },
  itemStyle: { color: '#B5495B' },
};
const GET_TYPE = {
  'account': 'subAccountId', 'product': 'productId',
  'virtual': 'productId', 'fund': 'jid'
};
const PAGEKEY_SHORT = { 'A': 'account', 'P': 'product', 'V': 'virtual' };
let timer = null;
// 创建option
const createAccountState = (alls, virs) => {
  let virObj = {}; let virOptions = [];
  if (isValidArray(virs)) {
    virs.map(itm => {
      virOptions.push({ 'label': itm.name, 'value': VSTRING + itm.id });
      _.set(virObj, VSTRING + itm.id, itm.name);
    })
  }
  return {
    'productObj': _.assign(_.get(alls, 'productObj'), virObj),
    'accObj': _.get(alls, 'accObj'),
    'accountOptions': _.get(alls, 'account'),
    'productOptions': _.get(alls, 'product'),
    'vituralOptions': virOptions
  }
};
//V字符的删减与判断
const trimId = (id) => parseInt(_.trim(id, VSTRING));
const isVid = (id) => _.includes(id, VSTRING);
const isFund = (id) => !isValidNumber(parseInt(id)) && !isVid(id) && id !== 0 ? true : false;
/**
 * 该图表实现为两条指数曲线波幅进行对比显示，故获取暂采用同时获取两次指数数据进行显示;
 */
export default function CompareChartsTwo(props) {
  const mobxStore = useLocalObservable(() => store);
  const [all_product_acc, set_all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [accState, setAccState] = useState(createAccountState(all_product_acc, _.get(props, 'virtualProduct', [])));
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]); // 完整series数据
  const [fullSeriesRetreat, setFullSeriesRetreat] = useState([]); // 完整series数据
  const [rateDatas, setRateDatas] = useState({});
  const [checkboxValues, setCheckBoxValues] = useState([]);
  const [checkboxItem, setCheckBoxItem] = useState([]);
  const compareState = useReactive({
    type: _.get(props, 'idxPms.dateType'), getType: '', account: '', product: [], virtual: [],
    idxSymbol: _.get(props, 'idxPms.symbol'), fund: [], fundNames: {},
    diff_id: '', diffStatus: false, diffShow: false, diffRate: _.get(props, 'idxPms.rate', 0)
  });
  const [option, setoption] = useState(TWO_CHARTS.main);
  const [option2, setoption2] = useState(TWO_CHARTS.retreat);
  const getPageKeys = _.get(props, 'pageKey', '');

  // tab切换获取当前收益率数据
  useEffect(() => {
    if (_.get(props, 'idxPms.rate', 0)) {
      compareState.diffStatus = true;
    };
    _updatePage();
  }, [props.active]);

  function _updatePage() {
    let is_get = false; let is_acc_get = false;
    if (props.active === 'ACC_COM' && getPageKeys === PAGEKEY_SHORT['A']) {
      _getRates(PAGEKEY_SHORT['A']);
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'accObj')) && isValidArray(_.get(accState, 'accountOptions')) ? false : true;
    }
    if (props.active === 'PRO_COM' && _.includes(['product', 'virtual'], getPageKeys)) {
      _getRates(PAGEKEY_SHORT['P']);
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'productObj')) && isValidArray(_.get(accState, 'productOptions')) ? false : true;
    }
    //【230926】账户改为mobx全局获取
    if (is_get && is_acc_get) {
      mobxStore._getProductAccount();
      // 延时获取更新
      timer = setTimeout(() => {
        let newProductAcc = JSON.parse(JSON.stringify(mobxStore.allProductAcc));
        set_all_product_acc(newProductAcc);
        setAccState(createAccountState(newProductAcc, _.get(props, 'virtualProduct', [])));
      }, 2500);
    }
  }

  useUnmount(() => {
    timer && clearTimeout(timer);
  });
  /* 
    获取账户/产品收益率; 获取类型： 单一获取，初始加载，批量获取；
    240320 增加同tamp相同的promise逻辑，增加虚拟账户接口的获取； 
    【tips】虚拟产品id 为Vid,所以处理params获取外，所以虚拟都使用vid，所有逻辑增加对v字符串的判断
  */
  async function _getRates(type = '', gtype = '', id = 0) {
    const isSingle = gtype !== 'batch';
    // promise需要的获取单一产品的方法； 普通产品接口和虚拟产品接口
    const virtualGet = async (id, pms, isVir) => {
      const singleGet = isVir ? await showSingleProductHistoryVir(pms) : await showSingleProductHistory(pms)
      // 返回格式与批量返回相同 {id:{data数据}}
      // 如果是单一获取，则返回数据; 虚拟统一返回虚拟字段
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleGet, 'data', {}), { 'is_virtual': isVir })
          : { [id]: _.get(singleGet, 'data', {}), 'is_virtual': isVir }
      ));
    };
    // 获取单一私募
    const singleGetFund = async (id, pms) => {
      const singleFund = await showFundKline(pms);
      return new Promise(resolve => resolve(
        isSingle ? _.assign(_.get(singleFund, 'data', {}), { 'is_fund': true })
          : { [id]: _.get(singleFund, 'data', {}), 'is_fund': true }));
    };
    // 普通产品promise获取
    const singleGetRate = async (id, pms) => {
      const singleGet = type === PAGEKEY_SHORT['A'] ? await showSubAccountHistory(pms) : await showSingleProductHistory(pms);
      // 返回格式与批量返回相同 {id:{data数据}}
      return new Promise(resolve => resolve({ [id]: _.get(singleGet, 'data', {}) }));
    };
    // 处理接口参数； virtualProduct只在虚拟产品页面才有
    const getVirOptions = _.get(props, 'virtualProduct', []);
    const virIds = getVirOptions.map(itm => VSTRING + itm.id);
    const getParamsFromProps = type === PAGEKEY_SHORT['A'] ? _.get(props, 'accountInfo', {}) : _.get(props, 'productInfo', {});
    if (isValidObj(getParamsFromProps)) {
      const idFromProps = _.get(getParamsFromProps, GET_TYPE[type]);
      let params = {
        'dateType': compareState.type,
        'date': _.get(getParamsFromProps, 'dateToday', TODAY),
        [GET_TYPE[type]]: isVid(idFromProps) ? trimId(idFromProps) : idFromProps
      }
      //id传入判断是否是特殊vid
      let did = idFromProps;
      if (isValidNumber(id)) {
        _.set(params, GET_TYPE[type], id);
        did = id;
      } else if (isVid(id)) {
        _.set(params, GET_TYPE[type], trimId(id));
        did = id;
      };
      // 收益率执行差异，对比带入获取参数
      if (!isFund(did)) {
        if (compareState.diffStatus && compareState.type !== 'TODAY') {
          _.set(params, 'rate', compareState.diffRate);
          if (_.size(compareState.diff_id) > 0) { // id单次进行累加
            compareState.diff_id = compareState.diff_id + ',' + did;
          } else {
            compareState.diff_id = String(did)
          }
        } else {
          compareState.diff_id = '';
          compareState.diffStatus = false;
        };
      };
      // 判断是否批量
      const isNorBatch = gtype === 'batch' ? true : false;
      let needProGet = false; // 是否使用promise进行获取
      let proAll = []; // promise 数组
      // 当前选项中是否包含虚拟账户;或基金账户
      const virArray = isValidArray(checkboxItem) ? checkboxItem.map(c => {
        const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
        return _.includes(virIds, kId);
      }) : [];
      const fundArray = _.concat(checkboxItem.map(c => isFund(c.keyId)), id ? isFund(id) : false);
      //三种情况需要使用promise进行获取
      if (isSingle && _.includes(virIds, id)) {
        // id是虚拟产品
        proAll.push(virtualGet(id, params, true));
        needProGet = true;
      } else if (gtype === 'batch' && _.includes(virArray, true)) {
        // 批量（切换时间区间）；并且账户包含虚拟产品
        checkboxItem.map(c => {
          const kId = isVid(c.keyId) ? c.keyId : parseInt(c.keyId);
          const is_virtual = _.includes(virIds, kId);
          let singlePms = { ...params };
          if (isValidNumber(kId)) {
            _.set(singlePms, GET_TYPE[type], kId);
          } else if (isVid(kId)) {
            _.set(singlePms, GET_TYPE[type], trimId(kId));
          } else if (isFund(c.keyId)) {
            return;
          }
          proAll.push(virtualGet(kId, singlePms, is_virtual));
          needProGet = true;
        });
      } else if (!id && _.includes(virIds, idFromProps) && isSingle) {
        // 首次进入或updatePage，并且当前对比产品id是虚拟产品; 
        //【bug-fix】在single下符合首次加载；今日无数据时，选择其他产品，切换会该方法；重新获取单一虚拟产品；
        proAll.push(virtualGet(idFromProps, params, true));
        needProGet = true;
      }
      if (_.includes(fundArray, true) && gtype === 'batch') {
        const curAllIds = checkboxItem.map(c => c.keyId);
        curAllIds.map(id => {
          if (isFund(id)) {
            let fundPms = {
              'dateType': params.dateType,
              'date': params.date,
              'jjdm': id
            };
            proAll.push(singleGetFund(id, fundPms));
          }
        });
        needProGet = true;
      } else if (isFund(id)) {
        let fundPms = {
          'dateType': params.dateType,
          'date': params.date,
          'jjdm': id
        };
        proAll.push(singleGetFund(id, fundPms));
        params.jid = id;
        needProGet = true;
      };
      // 普通批量时删除虚拟的id
      if (isNorBatch) {
        let newKeyIds = [];
        checkboxItem.map(c => {
          if (!isFund(c.keyId)) {
            newKeyIds.push(c.keyId)
          };
          // 【bug-fix】 批量包含虚拟以及私募，则普通id也进行promise操作
          if (!isFund(c.keyId) && !isVid(c.keyId) && needProGet) {
            let normalParams = {
              'dateType': params.dateType,
              'date': params.date,
              [GET_TYPE[type]]: c.keyId
            };
            proAll.push(singleGetRate(c.keyId, normalParams));
          };
        })
        _.set(params, GET_TYPE[type], _.join(newKeyIds, ','));
      };

      let res = {};
      const isAccGet = type === PAGEKEY_SHORT['A'] ? true : false;
      if (isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchSubAccountHistory(params) : await showSubAccountHistory(params);
      } else if (!isAccGet && !needProGet) {
        res = isNorBatch ? await showBatchProductHistory(params) : await showSingleProductHistory(params);
      }
      // 批量用单一接口获取数据，将使用response的统一格式进行处理
      if (needProGet) {
        const allResult = await Promise.all(proAll);
        let resData = {}
        allResult.map(result => {
          let newResult = {};
          // 对fund数据进行收益率计算
          if (_.get(result, 'is_fund')) {
            let dataSource = result;
            if (isNorBatch) {
              const getId = _.head(_.keys(result));
              dataSource = result[getId];
            };
            let newFundData = {
              'netValueList': _.get(dataSource, 'netValue', []),
              'timeList': _.drop(_.get(dataSource, 'timeList', [])),
            };
            if (isValidArray(newFundData.netValueList)) {
              const getNets = _.get(dataSource, 'netValue', []).map(n => parseFloat(n));
              _.set(newFundData, 'pointList', calRate(getNets, getNets[0]));
              _.set(newFundData, 'netValueList', getNets);
            };
            newResult = isNorBatch ? { [_.head(_.keys(result))]: newFundData } : newFundData;
          } else {
            newResult = result;
          }
          resData = _.assign(resData, newResult);
        });
        res = { 'code': '200', 'data': resData };
      }
      const idxRes = await _handleIndexKline(params.date);
      if (_.get(res, 'code', '') === '200') {
        handleResData(
          res,
          _.assign(params, { 'id': _.get(params, GET_TYPE[type]), 'isBatch': isNorBatch }),
          idxRes
        );
      }
    }
  };
  // 处理指数数据
  async function _handleIndexKline(date) {
    let params = {
      'symbol': compareState.idxSymbol,
      'dateType': compareState.type,
      'date': date,
    }
    const res = await showIndexKline(params);
    return res;
  };
  // 统一处理返回数据
  function handleResData(res, pms, idxRes) {
    const is_batch = _.get(pms, 'isBatch');
    let initTemp = is_batch ? {} : _.cloneDeep(rateDatas);
    const getData = _.get(res, 'data', {});
    // console.log('处理', is_batch, getData)
    // 处理批量数据
    if (is_batch && isValidObj(getData)) {
      _.keys(getData).map(ids => {
        const sub_data = _.get(getData, ids, {});
        if (isValidObj(sub_data) && isValidArray(_.get(sub_data, 'timeList'))) {
          // 虚拟vid重新增加v字符
          const rates = handleRateData(sub_data, pms.dateType, !isFund(ids));
          _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + ids : ids, rates);
        }
      })
    } else {
      // 单独增加的数据进行累加赋值
      if (!isValidObj(getData) || !isValidArray(_.get(getData, 'timeList'))) {
        message.info('暂无数据!');
        return;
      }
      const rates = handleRateData(getData, pms.dateType, !isFund(pms.id));
      _.set(initTemp, _.get(rates, 'is_virtual') ? VSTRING + pms.id : pms.id, rates);
    }
    let temp = sliceUnmatchDates(initTemp, pms.dateType); // 裁剪数据
    // 指数计算；使用main图表计算逻辑，计算指数曲线
    const newTimes = _.get(temp, 'times');
    const newIndexs = handleIndexValue(pms.dateType, newTimes, _.assign(idxRes, { 'lastIndex': temp.dataSize }));
    _.set(temp, 'INDEX', {
      'retreat': handleRetreat(newIndexs.priceValues),
      'vol': newIndexs.vol,
      'margin': newIndexs.margin,
      ...newIndexs,
    });
    // 设置统一时间及slider
    setTimeArr(newTimes.map((n, i) => i));
    setTimeNameArr(newTimes);
    setRateDatas(temp);
    setUpdate(_.round(update + 0.1, 1));
    compareState.getType = pms.dateType;
  }
  // 对完整对象进行处理
  function sliceUnmatchDates(fullData = {}, dateType = '') {
    let final = {};
    let dataSize = 0;
    // 找到最大的时间数据
    let maxSize = 0; let maxTimeList = [];
    _.keys(fullData).map(ids => {
      const get_size = _.get(fullData, `${ids}.timeSize`, -1);
      if (maxSize === 0 || get_size > maxSize) {
        maxSize = get_size;
        dataSize = _.size(_.get(fullData, `${ids}.pointList`, []));
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      };
      // 基金数据为基准，赋值999，产品数据按照基金的时间轴进行计算，计算逻辑可不变
      if (get_size === 999) {
        maxSize = 999;
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      }
    });
    // 再次遍历数据,赋值final
    _.keys(fullData).map(ids => {
      final[ids] = _.cloneDeep(_.get(fullData, ids));
      //小于最大时间数据进行处理
      if (_.get(fullData, `${ids}.timeSize`, 0) !== maxSize) {
        const isValidShort = isValidObj(_.get(fullData, ids)) && 'short_time' in _.get(fullData, ids) ? true : false
        // 短时间轴(含前一交易日);[前一,9-10,9-11...] ; 
        //【bug-fix】:一直使用timeList字段，会在大于2条线对比下，使用修复过的时间进行对比，导致数据前移，对不上；使用short_time初始时间进行计算
        const getShortTime = isValidShort ? _.get(fullData, `${ids}.short_time`, []) : _.get(fullData, `${ids}.timeList`, []);        //【bug-fix】 用长时间轴遍历，短时间轴有数据返回对应自己时间的index值;
        let sindex_array = []; let miss_arr = []; // 记录数组方便找到最小值
        let timeIndex = maxTimeList.map((t, i) => {
          const sindex = getShortTime.indexOf(t);
          let new_sindex = sindex > 0 ? (sindex - 1) : sindex; // 下面提取pointsNet数据是需-1，减掉前一index是正确的数据位
          if (new_sindex > -1) {
            sindex_array.push(new_sindex);
          } else { //打印时用，保存缺失的日期..
            miss_arr.push({ t, i });
          }
          return new_sindex; // 生成长轴时间数组，对应短轴的数据位置；例：[-1,7,8,9...,-2,-2,-2,-2]  -1 =前一，-2为未找到
        });
        // 重新计算短轴时间数据，【bug-fix】短轴时间可早于长轴时间，所以需要重新计算
        let shortNets = timeIndex.map(ti => ti > -1 ? _.get(fullData, `${ids}.pointsNet[${ti}]`) : '-');
        let shortStart = _.min(sindex_array) > 0 ? _.get(fullData, `${ids}.pointsNet[${_.min(sindex_array) - 1}]`) : _.get(fullData, `${ids}.preNet`);
        //【bug-fix】 实时点位不对称，preNet实时为0，所以计算错误；该情况默认start=pointNet第一位净值数据
        if (!shortStart && dateType === 'TODAY') {
          shortStart = _.get(fullData, `${ids}.pointsNet[0]`);
        }
        shortNets[0] = shortStart; // 前一交易日 = shortStart的值
        _.set(final, `${ids}.pointList`, calRate(shortNets, shortStart));
        _.set(final, `${ids}.pointRetreat`, handleRetreat(shortNets));
        _.set(final, `${ids}.timeList`, maxTimeList);
        _.set(final, `${ids}.timeIndex`, timeIndex);
        _.set(final, `${ids}.short_time`, getShortTime);// 获取到的初始时间数据
        _.set(final, `${ids}.short_net`, shortNets);
        _.set(final, `${ids}.miss_arr`, miss_arr);
      }
    });
    final.times = maxTimeList;
    final.dataSize = dataSize;
    return final;
  }
  //处理获取的收益率数据
  function handleRateData(datas = {}, dateType = '', isNor = true) {
    const getPointList = _.get(datas, 'pointList', []);
    const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
    const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
    const getTimeList = _.get(datas, 'timeList', []);
    const isToday = dateType === 'TODAY' ? true : false;
    // 返回的data,object ; 【新】新增虚拟字段
    let dataObj = { 'is_virtual': _.get(datas, 'is_virtual') };
    const points = _.size(getPointList) > 0 ? getPointList.map(n => renderFloats(n, 1)) : [];
    let pointsNet = pointNetList;
    if (isToday) { // 实时收益率先计算净值，然后按照计算回撤
      pointsNet = calNets(points);
    }
    const retreat = handleRetreat(pointsNet); // 回撤数据
    // isNor=是否为普通产品；基金没有前一交易日，已返回第一天作为前一日=0
    dataObj.pointList = isToday ? points : _.concat(isNor ? [0] : [], points);
    dataObj.pointRetreat = isToday ? retreat : _.concat(isNor ? [0] : [], retreat);
    dataObj.timeList = isToday ? getTimeList : _.concat([PRE_DAY_CN], getTimeList);
    dataObj.preNet = isToday ? 0 : preNetVal;
    dataObj.timeSize = isNor ? _.size(getTimeList) : 999;
    _.set(dataObj, 'pointsNet', pointsNet);
    return dataObj;
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const pcdtMap = _.get(accState, 'productObj');
    const accMap = _.get(accState, 'accObj');
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let newSeires = []; let retreatSeires = []; let min = 0; let max = 0;
    let legend = []; let legVals = [];
    // 加载完整生成serise数据
    // console.log(rateDatas)
    // console.log('pcdtMap', pcdtMap)
    if (isValidObj(rateDatas)) {
      let color_count = 0;
      _.keys(rateDatas).map(keyId => {
        if (!_.includes(['dataSize', 'times', 'INDEX'], keyId)) {
          let getName = getPageKeys === PAGEKEY_SHORT['A'] ? _.get(accMap, keyId) : _.get(pcdtMap, keyId);
          if (isFund(keyId)) {
            getName = _.get(compareState.fundNames, keyId);
          }
          let sobj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': getName, 'yAxisIndex': 0, keyId, 'isDot': isFund(keyId),
            'data': _.get(rateDatas, `${keyId}.pointList`, []),
          });
          newSeires.push(sobj);
          let robj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': getName, keyId, 'isDot': isFund(keyId),
            'data': _.get(rateDatas, `${keyId}.pointRetreat`, []),
          });
          retreatSeires.push(robj);
          legend.push({
            'name': getName,
            'fullName': getName,
            'isrdiff': _.includes(compareState.diff_id, String(keyId)) ? true : false,
            keyId
          });
          legVals.push(keyId);
          min = calMaxMin(robj.data, min, 'min');
          max = calMaxMin(robj.data, max, 'max');
          if (color_count >= 9) {
            color_count = 0;
          } else {
            color_count++;
          }
        } else if (keyId === 'INDEX') {
          // const getVol = _.get(rateDatas, 'INDEX.volume', []);
          newSeires.unshift({
            name: INDEX_CODE_VALUE[compareState.idxSymbol],
            is_index: true,
            yAxisIndex: 0,
            type: 'line', symbol: 'none',
            data: _.get(rateDatas, 'INDEX.indexValues', []),
            ...INDEX_COLOR_OBJ
          });

          retreatSeires.unshift({
            name: INDEX_CODE_VALUE[compareState.idxSymbol],
            is_index_retreat: true,
            type: 'line', symbol: 'none', data: _.get(rateDatas, 'INDEX.retreat', []),
            ...INDEX_COLOR_OBJ
          });
          min = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), min, 'min');
          max = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), max, 'max');
        }
      });
    }
    // 赋值slider的滑动数据
    if (_.last(timeArr)) {
      setSliderValue([0, _.last(timeArr)]);
      setSliderValue2([0, _.last(timeArr)]);
    }
    setFullSeries(newSeires);
    setFullSeriesRetreat(retreatSeires);
    // 更新checkbox；默认全选
    setCheckBoxItem(legend);
    setCheckBoxValues(legVals);

    newOption.xAxis.data = _.get(rateDatas, 'times', []); newOption2.xAxis.data = _.get(rateDatas, 'times', []);
    // newOption.legend.data = legArr; newOption2.legend.data = legArr;
    newOption.series = newSeires; newOption2.series = retreatSeires;
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [update]);
  /**
   * slider滑动调用，更新数据；
   * 【逻辑根据平台 MainRateCharts 收益率页面修改，只对收益率进行处理，以下为精简逻辑进行实现
   */
  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFullRange = isFullTimeRange(sliderValue, timeArr);
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let min = 0; let max = 0;
    const indexSeries = _.filter(fullSeries, o => 'is_index' in o);
    const indexRetreat = _.filter(fullSeriesRetreat, o => 'is_index_retreat' in o);
    let filterSeries = _.concat(indexSeries, _.filter(fullSeries, o => _.includes(checkboxValues, o.keyId)));
    let filterRetreat = _.concat(indexRetreat, _.filter(fullSeriesRetreat, o => _.includes(checkboxValues, o.keyId)));
    if (isFullRange) {// 全部时间赋值完整数据
      const fullTimes = _.cloneDeep(timeNameArr);
      newOption.series = _.cloneDeep(filterSeries);
      newOption2.series = filterRetreat.map(r => { // 回撤需要重新计算最大最小值，重新遍历
        min = calMaxMin(r.data, min, 'min');
        max = calMaxMin(r.data, max, 'max');
        return r;
      });
      newOption.xAxis.data = fullTimes;
      newOption2.xAxis.data = fullTimes;
    } else { // 裁切
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let sdata = [];
      filterSeries.map((sir, i) => {
        let sliceRates = []; let sliceOrg = [];
        if ('is_index' in sir) {
          if ('is_vol' in sir) {
            const getVol = _.get(rateDatas, 'INDEX.volume', []);
            const sliceVol = renderSlice(_.concat([0], getVol), sliderValue[0], sliderValue[1]);
            sliceRates = sliceVol;
            sliceOrg = [];
          } else {
            // 处理指数数据
            const indexPrice = _.get(rateDatas, `INDEX.priceValues`, []);
            const slicePriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]);
            const indexStart = _.get(slicePriceOrg, '[0]', 0);
            sliceRates = calRate(slicePriceOrg, indexStart);
            sliceOrg = slicePriceOrg;
          }
        } else {
          //对series进行遍历裁剪
          const pointsData = _.get(rateDatas, `${sir.keyId}.pointList`, []);
          const pointsNet = calNets(pointsData); // 计算净值
          const slicePoints = renderSlice(pointsData, sliderValue[0], sliderValue[1]); //没有开始点位时保留原收益率曲
          const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]);
          // 【bug-fix】如计算时已添加0为第一位，只滑动右侧，开始的计算会出问题，所以slice时start默认支取第0位的数据
          const getStart = _.get(sliceRateOrg, `[0]`);
          sliceRates = isValidNumber(getStart) ? calRate(sliceRateOrg, getStart) : slicePoints; // 重新计算收益率
          sliceOrg = sliceRateOrg;
        }
        sdata.push({ ...sir, data: sliceRates }); // 【bug-fix】第一位无需重新计算0，否则会引起偏移
        // 回撤裁剪
        let fidx = _.findIndex(newOption2.series, o => o.name === sir.name);
        const sliceRetreat = handleRetreat(sliceOrg);
        if (fidx > -1) { // 回撤与series因成交量不对等，需要找到index再赋值
          _.set(newOption2, `series[${fidx}].data`, sliceRetreat); // 【bug-fix】裁切回撤无需头部加 0
        }
        min = calMaxMin(sliceRetreat, min, 'min');
        max = calMaxMin(sliceRetreat, max, 'max');
      });
      newOption.series = sdata;
      newOption.xAxis.data = sliceTimes;
      newOption2.xAxis.data = sliceTimes;
      // 【bug-fix】裁切情况下checkbox变化回测无变化
      newOption2.series.map(sir => {
        if (!_.includes(checkboxValues, sir.keyId) && !_.get(sir, 'is_index_retreat')) {
          _.set(sir, 'data', []);
        }
      });
    }
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [update2]);

  function updatePage() {
    setUpdate2(_.round(update2 + 0.1, 1));
  }
  // slider完成后
  function onSliderFinish(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updatePage();
  }
  function handleClear() {
    setRateDatas({});
    setCheckBoxItem([]);
    setCheckBoxValues([]);
    updatePage();
  }
  // checkbox.group 修改选择项
  const onSubValuesChange = (checkedValues) => {
    setCheckBoxValues(checkedValues);
    updatePage();
  };
  // 删除chekcbox的选项
  function onDelCheckItem(itm) {
    let tempItem = _.cloneDeep(checkboxItem);
    let tempCheck = _.cloneDeep(checkboxValues);
    let tempRate = _.cloneDeep(rateDatas);
    _.pullAllBy(tempItem, [{ 'keyId': itm.keyId }], 'keyId');
    _.pullAll(tempCheck, [itm.keyId]);
    setCheckBoxItem(tempItem);
    setCheckBoxValues(tempCheck);
    setRateDatas(_.omit(tempRate, [itm.keyId]));
    updatePage();
  }
  // type: account/product/virtual
  function accountChange(type, v) {
    // product state字段不同，其他相同
    let finalVal = null;
    if (type === 'fund') {
      compareState['fund'] = v.value;
      compareState.fundNames[v.value] = v.label;
      finalVal = v.value;
    } else {
      compareState[type] = v;
      finalVal = type === PAGEKEY_SHORT['A'] ? v : _.last(v)
    }
    _getRates(type, 'single', finalVal);
  }

  const charts_style = { width: '100%', height: 385 }
  const barsProps = {
    'pageKey': getPageKeys, 'checkVal': checkboxValues, 'checkItems': checkboxItem, 'diffStatus': compareState.diffStatus,
    'typeVal': compareState.type, 'accountOptions': accState, 'idxSymbol': compareState.idxSymbol,
    'accountVal': { 'account': compareState.account, 'product': compareState.product, 'virtual': compareState.virtual, 'fund': compareState.fund },
    'Vstring': VSTRING, 'isRealtime': compareState.type === 'TODAY' ? true : false,
    'isClearDisable': _.size(checkboxItem) === 0 ? true : false,
    'onCheckChange': onSubValuesChange, 'onClear': handleClear
  };
  return (
    <>
      <TwoBars
        {...barsProps}
        onAccountChange={accountChange}
        onTypeChange={(v) => {
          compareState.type = v;
          if (_.size(checkboxItem) >= 1) { // 超过
            _getRates(getPageKeys === PAGEKEY_SHORT['A'] ? 'account' : 'product', 'batch');
          }
        }}
        onIdxChange={(v) => {
          compareState.idxSymbol = v;
          _updatePage();
        }}
        onDelItem={onDelCheckItem}
        onDiffChange={() => {
          if (isValidNumber(compareState.diffRate)) {
            compareState.diffStatus = !compareState.diffStatus;
            _getRates('product', 'batch');
          } else {
            compareState.diffShow = true;
          }
        }}
      />

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div id="compareChartsTwo" style={charts_style} />
        </div>
      </Spin>

      <MainSlider
        isNotToday={compareState.getType !== 'TODAY' ? true : false}
        isFull={isFullTimeRange(sliderValue, timeArr)}
        timeNameArray={timeNameArr}
        timeArray={timeArr}
        empComp={<div style={{ height: 32 }}></div>}
        value={sliderValue}
        svalue={sliderValue2}
        onSliderChange={(v) => setSliderValue2(v)}
        onSliderAfterChange={(v) => onSliderFinish(v)}
        onReset={() => onSliderFinish([0, _.last(timeArr)])}
      />

      <div style={{ display: 'flex' }}>
        <div id="compareChartsTwo_retreat" style={charts_style} />
      </div>

      {getPageKeys !== PAGEKEY_SHORT['A'] && <RateDiffModal
        isShow={compareState.diffShow}
        onCancel={() => compareState.diffShow = false}
        onConfirm={(value) => {
          compareState.diffRate = _.get(value, 'diff') / 100;
          compareState.diffStatus = !compareState.diffStatus;
          _getRates('product', 'batch');
          compareState.diffShow = false
        }}
      />}
    </>
  )
}
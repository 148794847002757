import React, { useState, useEffect } from 'react';
import {
  Tag,
  Table,
} from 'antd';
import moment from 'moment';
import _ from 'lodash';

const InviteTable = ({ datas, loading }) => {
  const columns = [
    {
      title: '受邀人账户',
      dataIndex: 'account',
      align: 'left',
      key: 'account',
      width: 120,
    },
    {
      title: '受邀人手机',
      dataIndex: 'phone',
      align: 'left',
      key: 'phone',
      width: 160,
    },
    {
      title: '受邀日期',
      dataIndex: 'inviteDate',
      align: 'left',
      key: 'inviteDate',
      width: 180,
      render: (text, record) => text ? moment(text).format('YYYY-MM-DD HH:mm') : ''
    }
  ];

  return (
    <Table
      rowKey={'inviteesUserId'}
      loading={loading}
      dataSource={datas}
      columns={columns}
      size="middle"
      scroll={{ x: 480 }}
      pagination={{
        pageSize: 10
      }}
    />
  )
}

export default InviteTable;
import React, { useState } from 'react';
import {
    Descriptions,
    Button,
    Input,
    Modal,
    Form,
    Select,
    message
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { createOption } from '@/api/strategy';
import _ from 'lodash';
import moment from 'moment';

const desc_list = [
    { name: '开始时间', key: 'beginDate', type: '1' },
    { name: '结束时间', key: 'endDate', type: '1' },
    { name: '初始资金', key: 'originAssets', type: '2' },
    { name: '每股买入多少元', key: 'perMoney', type: '2' },
    { name: '一分钟成交量大于昨日平均成交量的x倍', key: 'totalVolumeComparePreAvgVolume', type: '2' },
    { name: '一分钟成交量大于近5日平均成交量的最大值的x倍', key: 'totalVolumeComparePre5DaysMaxAvgVolume', type: '2' },
    { name: '一分钟涨跌幅限制x(小于)', key: 'tempPctChgLimit', type: '2' },
    { name: '当前涨跌幅限制x(大于)', key: 'totalPctChgLimit', type: '2' },
    { name: '昨日涨跌幅限制x(小于)', key: 'prePctChgLimit', type: '2' },
    { name: '前五日最低价到收盘价涨跌幅限制x(小于)', key: 'pre5DaysMaxPctChgLimit', type: '2' },
    { name: '今日开盘涨跌幅限制(小于)', key: 'todayOpenPctChgLimit', type: '2' },
    { name: '一分钟涨跌幅限制x(大于)', key: 'tempPctChgOption1X', type: '2' },
    { name: '一分钟成交量与昨日最大值比X(大于)', key: 'preMaxVolumeOption1X', type: '2' },
    { name: '一分钟成交量与近5日最大值的最大值比X(大于)', key: 'pre5DaysMaxMaxVolumeOption1X', type: '2' },
    { name: '分钟成交量与昨日平均值比Y(大于)', key: 'preAvgVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与昨日最大值比Y(大于)', key: 'preMaxVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与近5日平均值的最大值比Y(大于)', key: 'pre5DaysMaxAvgVolumeOption2Y', type: '2' },
    { name: '一分钟成交量与近5日最大值的最大值比Y(大于)', key: 'pre5DaysMaxMaxVolumeOption2Y', type: '2' },
    { name: '一分钟涨跌幅限制（大于)', key: 'tempPctChgOption2Y', type: '2' },
    { name: '分钟成交量与昨日平均值比Z(大于)', key: 'preAvgVolumeOption3Z', type: '2' },
    { name: '一分钟成交量与昨日最大值比Z(大于)', key: 'pre5DaysMaxMaxVolumeOption3Z', type: '2' },
    { name: '一分钟涨跌幅限制(大于)', key: 'tempPctChgOption3Z', type: '2' },
    { name: '备注', key: 'remark', type: '3' },
]

const form_list = [
    { name: '名称', key: 'name', type: '1' },
    { name: '渠道', key: 'channel', type: '3' },
    { name: '备注', key: 'remark', type: '2' },
];
const renderMoment = (time) => time ? moment(time).format('YYYY-MM-DD') : '';
const BackParams = ({ id = 0, details = {}, onUpdate }) => {
    const [visible, setVisible] = useState(false);
    const [pmForm] = Form.useForm();

    async function handleOk() {
        const values = await pmForm.validateFields();
        let params = {
            ...values,
            option: details
        }
        const data = await createOption(params);
        if (data.code === '200') {
            message.success('保存成功！');
            setVisible(false);
            pmForm.resetFields();
            onUpdate();
        } else {
            message.error(data.message);
        }
    }

    function renderFormType(type) {
        if (type === '1') {
            return <Input />
        } else if (type === '2') {
            return <Input.TextArea allowClear />
        } else if (type === '3') {
            return <Select>
                <Select.Option value="simx">simx</Select.Option>
                <Select.Option value="tamp">tamp</Select.Option>
            </Select>
        }
    }

    const hasEdit = id && _.size(details) > 0 ? true : false;
    return (
        <div className='panalContent'>
            <Descriptions
                title="参数"
                layout="vertical"
                bordered
                size='small'
                column={{ xxl: 3, xl: 3, lg: 2, md: 1, sm: 1 }}
                extra={
                    <Button
                        disabled={!hasEdit}
                        type='link'
                        icon={<SaveOutlined />}
                        onClick={() => {
                            setVisible(true);
                        }}>
                        保存该回测
                    </Button>
                }
            >
                {desc_list.map((n, i) => {
                    const newValue = n.type === '1' ? renderMoment(_.get(details, n.key, '')) : _.get(details, n.key, '')
                    return <Descriptions.Item key={'esc' + i} label={n.name}>{newValue}</Descriptions.Item>
                })}
            </Descriptions>

            <Modal
                title="保存回测"
                width={650}
                maskClosable={false}
                visible={visible}
                onOk={handleOk}
                onCancel={() => {
                    setVisible(false);
                    pmForm.resetFields();
                }}
            >
                <Form
                    form={pmForm}
                >
                    {form_list.map((item, i) => {
                        return <Form.Item
                            key={item.key + i}
                            name={_.get(item, 'key', '')}
                            label={_.get(item, 'name', '')}
                            rules={[{ required: true }]}
                        >
                            {renderFormType(item.type)}
                        </Form.Item>
                    })}
                </Form>
            </Modal>
        </div>
    )
}

export default BackParams;
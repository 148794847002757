import React from 'react';
import {
  Table,
  Collapse,
  Tag,
  Space,
  Button,
  Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import _ from 'lodash';

const { Panel } = Collapse;
const { confirm } = Modal;

const handleDiffTime = (time2) => {
  return moment().diff(moment(time2), 'minute');
}

const OrderTable = ({ orderData, loading, onCloseOrder }) => {
  const columns = [
    {
      title: '订单时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 140,
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '订单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: 140,
    },
    {
      title: '产品名称',
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 120,
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      width: 210,
      render: (text, record) => {
        const splitDes = text ? _.split(text, /[\s\n]/) : [];
        return <ul style={{ listStyleType: 'decimal', textAlign: 'left' }}>
          {_.size(splitDes) > 0 && splitDes.map((item, index) => {
            return <li key={'li' + index}>{item}</li>
          })}
        </ul>
      }
    },
    {
      title: '数量',
      dataIndex: 'buyCount',
      key: 'buyCount',
      width: 80,
    },
    {
      title: '时长',
      dataIndex: 'duration',
      key: 'duration',
      width: 80,
      render: (text, record) => {
        if (text === 'month') {
          return '月'
        } else if (text === 'week') {
          return '周'
        } else {
          return text
        }
      }
    },
    {
      title: '金额/积分',
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      width: 100,
      render: (text, record) =>
        record.payType === 3 ? <Tag color={'blue'}>{`积分兑换:${text}`}</Tag> : _.round(text, 2)
    },
    {
      title: '状态',
      dataIndex: 'option',
      fixed: 'right',
      width: 140,
      key: 'option',
      render: (text, record) => {
        const isExpired = handleDiffTime(record.expiryTime) > 0 ? true : false
        const getStatus = _.get(record, 'orderStatus', 0);
        const closeButton = <Button type='text' danger onClick={() => {
          confirm({
            title: '是否关闭该订单?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: () => {
              onCloseOrder(record.orderNo)
            },
            onCancel() { },
          });

        }}>关闭订单</Button>

        if (getStatus === 0) {
          return <Space>
            <Tag color="processing">{isExpired ? '未支付' : '待支付'}</Tag>
            {isExpired ? null : closeButton}
          </Space>
        } else if (getStatus === 1) {
          return <Tag color="success">完成</Tag>
        } else if (getStatus === 2) {
          return <Tag color="error">关闭</Tag>
        }
      },
    }
  ]

  return (
    // <div style={{ marginTop: 12 }}>
    //   <Collapse>
    //     <Panel
    //       header="订单列表"
    //       key="1"
    //     >

    //     </Panel>
    //   </Collapse>
    // </div>
    <Table
      columns={columns}
      dataSource={orderData}
      scroll={{ x: 1080 }}
      rowKey="id"
      size='small'
      loading={loading}
    />
  )
}

export default OrderTable;
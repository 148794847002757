import React from 'react';
import {
  Modal,
  Alert,
  Table,
  Space,
  Button
} from 'antd';
import moment from 'moment';
import _ from 'lodash';

const UnpayTable = ({ unpayList, visible, onCancel, onUnpayOrder }) => {
  const columns = [
    {
      title: '订单时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '订单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
    },
    {
      title: '产品名称',
      dataIndex: 'goodsName',
      key: 'goodsName',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '数量',
      dataIndex: 'buyCount',
      key: 'buyCount',
    },
    {
      title: '时长',
      dataIndex: 'duration',
      key: 'duration',
      render: (text, record) => {
        if (text === 'month') {
          return '月'
        } else if (text === 'week') {
          return '周'
        } else {
          return text
        }
      }
    },
    {
      title: '金额',
      dataIndex: 'orderAmount',
      key: 'orderAmount',
    },
    {
      title: '状态',
      dataIndex: 'option',
      key: 'option',
      render: (text, record) => {
        return <Space direction='vertical'>
          <Button type='link' onClick={() => {
            onUnpayOrder(record, 2)
          }}>支付宝支付</Button>
          <Button type='link' onClick={() => {
            onUnpayOrder(record, 1)
          }}>微信支付</Button>
        </Space>
      },
    }
  ]
  return (
    <Modal
      title="未支付订单"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      width={820}
    >
      <Alert message="你有未支付订单" type="warning" showIcon />
      <div style={{ marginTop: 8 }}>
        <Table
          columns={columns}
          dataSource={unpayList}
          rowKey="id"
        />
      </div>
    </Modal>
  )
}

export default UnpayTable;
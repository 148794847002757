import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table, Form, DatePicker, message, InputNumber, AutoComplete, Input } from 'antd';
import { getInstrumentOrder, opens, closes, getInstrumentAsset, getTick, getSubAccountCal, showFuturePool } from '@/api/stock';
import moment from 'moment';


export default function ListsDetails(props, { history }) {
    const { RangePicker } = DatePicker;
    const [subAccountInfo] = useState(props.location.state.subAccountInfo);
    const [record] = useState(props.location.state.record);
    const [assetData, setassetData] = useState({});
    const [listData, setlistData] = useState({});
    const [orderList, setorderList] = useState([]);
    const [fundList, setfundList] = useState([]);
    const [leftForm] = Form.useForm();
    const [rightForm] = Form.useForm();
    const [TopForm] = Form.useForm();
    const [askPrice, setaskPrice] = useState(0);
    const [bidPrice, setbidPrice] = useState(0);
    const [currentPage, setcurrentPage] = useState(1);
    const [currentPageBottom, setcurrentPageBottom] = useState(1);
    const [options, setoptions] = useState([]);

    const entrust = [
        {
            title: '序号',
            render: (text, record, index) => { return (currentPageBottom - 1) * 5 + index + 1 },
        },
        {
            title: '期货名',
            dataIndex: 'instrumentId',
        },
        {
            title: '交易所简称',
            dataIndex: 'exchange',
        },
        {
            title: '价格',
            dataIndex: 'price',
        },
        {
            title: '手数',
            dataIndex: 'volume',
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            sorter: (a, b) => {
                let aTime = new Date(a.gmtCreate).getTime();
                let bTime = new Date(b.gmtCreate).getTime();
                return aTime - bTime;
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => {
                return (moment(text).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: '方向',
            dataIndex: 'direction',
            render: (text) => {
                return (text === '0' ? <span style={{ color: 'red' }}>多仓</span> : <span style={{ color: 'green' }}>空仓</span>)
            }
        },
        {
            title: '开平',
            dataIndex: 'offsetFlag',
            render: (text) => {
                return (text === '0' ? <span>开仓</span> : <span>平仓</span>)
            }
        },
        {
            title: '平单盈利',
            dataIndex: 'futureProfit',
        },
    ]
    const fundColumns = [
        {
            title: '序号',
            render: (text, record, index) => { return (currentPage - 1) * 5 + index + 1 },
        },
        {
            title: '期货名',
            dataIndex: 'instrumentId',
        },
        {
            title: '交易所简称',
            dataIndex: 'exchangeId',
        },
        {
            title: '开仓均价',
            dataIndex: 'futureOpenAvgPrice',
            render: (text) => {
                return <span>{parseFloat(text).toFixed(2)}</span>
            }
        },
        {
            title: '方向',
            dataIndex: 'direction',
            render: (text) => {
                return (text === '0' ? <span style={{ color: 'red' }}>{'做多'}</span> : <span style={{ color: 'green' }}>{'做空'}</span>)
            }
        },
        {
            title: '总仓',
            dataIndex: 'totalPosition',
        },
        {
            title: '今仓',
            dataIndex: 'todayPosition',
        },
        {
            title: '昨仓',
            dataIndex: 'yesterdayPosition',
        },
        {
            title: '保证金',
            dataIndex: 'occupyMargin',
            render: (text) => {
                return <span>{parseFloat(text).toFixed(2)}</span>
            }
        },
        {
            title: '浮动盈亏',
            dataIndex: 'floatingProfitAndLoss',
            render: (text) => {
                return (Number(text) > 0 ? <span style={{ color: 'red' }}>{text.toFixed(2)}</span> : Number(text) < 0 ? <span style={{ color: 'green' }}>{text.toFixed(2)}</span> : <span>{text}</span>)
            }
        },
        {
            title: '持仓盈亏',
            dataIndex: 'keepPositionProfitAndLoss',
            render: (text) => {
                return (Number(text) > 0 ? <span style={{ color: 'red' }}>{text.toFixed(2)}</span> : Number(text) < 0 ? <span style={{ color: 'green' }}>{text.toFixed(2)}</span> : <span>{text}</span>)
            }
        },
    ]
    useEffect(() => {
        getlist();
        getMarke();
        async function getMarke() {
            const data = await getTick({ instrumentId: record.code.substring(0, record.code.indexOf('.')) });
            if (data.code === '200') {
                if (data.data === '订阅中 ...') {
                    message.info(data.data);
                } else {
                    setlistData(data.data);
                    if (String(data.data.askPrice1).indexOf('.') === -1) {
                        setaskPrice(data.data.askPrice1);
                    } else {
                        setaskPrice(data.data.askPrice1.toFixed(4));
                    }
                    if (String(data.data.bidPrice1).indexOf('.') === -1) {
                        setbidPrice(data.data.bidPrice1);
                    } else {
                        setbidPrice(data.data.bidPrice1.toFixed(4));
                    }
                }
            } else {
                message.error(data.message);
            }
        }
        async function getlist() {
            const data = await getSubAccountCal({ subAccountId: subAccountInfo.subAccountId });
            if (data.code === '200') {
                setassetData(data.data);
            }
        }
        async function _get() {
            const asset = setInterval(
                async function () {
                    const nextRoutePathName = window.location.pathname;
                    if (!String.prototype.startsWith.call(nextRoutePathName, '/home/FutureTrading')) {
                        clearInterval(asset);
                        return;
                    }
                    const data = await getSubAccountCal({ subAccountId: subAccountInfo.subAccountId });
                    if (data.code === '200') {
                        setassetData(data.data);
                    }
                    const data2 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
                    if (data2.code === '200') {
                        let arr = [];
                        if (data2.data !== null && data2.data.length !== 0) {
                            data2.data.map(item => {
                                if (item.totalPosition !== 0) {
                                    arr.push(item);
                                }
                                return item;
                            })
                        }
                        setfundList(arr);
                    }
                }, 3000);
        }
        async function _getMarket() {
            const stockMarket = setInterval(
                async function () {
                    const nextRoutePathName = window.location.pathname;
                    if (!String.prototype.startsWith.call(nextRoutePathName, '/home/FutureTrading')) {
                        clearInterval(stockMarket);
                        return;
                    }
                    const data = await getTick({ instrumentId: record.code.substring(0, record.code.indexOf('.')) });
                    if (data.code === '200') {
                        if (data.data === '订阅中 ...') {
                            message.info(data.data);
                        } else {
                            setlistData(data.data);
                            // if(record.price===undefined){
                            if (String(data.data.askPrice1).indexOf('.') === -1) {
                                setaskPrice(data.data.askPrice1);
                            } else {
                                setaskPrice(data.data.askPrice1.toFixed(4));
                            }
                            if (String(data.data.bidPrice1).indexOf('.') === -1) {
                                setbidPrice(data.data.bidPrice1);
                            } else {
                                setbidPrice(data.data.bidPrice1.toFixed(4));
                            }
                            // }
                        }
                    } else {
                        message.error(data.message);
                    }
                }, 3000)
        }
        async function _getData() {
            const data1 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data1.code === '200') {
                let arr = [];
                if (data1.data !== null && data1.data.length !== 0) {
                    data1.data.map(item => {
                        if (item.totalPosition !== 0) {
                            arr.push(item);
                        }
                        return item;
                    })
                }
                setfundList(arr);
            } else {
                message.error(data1.message);
            }
            const data2 = await getInstrumentOrder({ subAccountId: subAccountInfo.subAccountId });
            if (data2.code === '200') {
                setorderList(data2.data);
            } else {
                message.error(data2.message);
            }
        }
        async function _getOptions() {
            const data = await showFuturePool({ subAccountId: subAccountInfo.subAccountId });
            if (data.code === '200') {
                let arr = [];
                data.data.map(item => {
                    let b = { value: item.name + '  ' + item.code };
                    arr.push(b);
                    return item;
                })
                setoptions(arr)
            }
        }
        _get();
        _getMarket();
        _getData();
        _getOptions();
    }, [])
    async function selectTime(date, dateString) {
        let params = {
            subAccountId: subAccountInfo.subAccountId,
            beginTime: dateString[0],
            endTime: dateString[1],
        }
        const data = await getInstrumentOrder(params);
        if (data.code === '200') {
            setorderList(data.data);
        } else {
            message.error(data.message);
        }
    }
    async function _getData() {
        const data1 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
        if (data1.code === '200') {
            let arr = [];
            if (data1.data !== null && data1.data.length !== 0) {
                data1.data.map(item => {
                    if (item.totalPosition !== 0) {
                        arr.push(item);
                    }
                    return item;
                })
            }
            setfundList(arr);
        } else {
            message.error(data1.message);
        }
        let params = {
            subAccountId: subAccountInfo.subAccountId,
            beginTime: moment().format('YYYY-MM-DD'),
            endTime: moment().add(1, 'days').format('YYYY-MM-DD'),
        }
        const data2 = await getInstrumentOrder(params);
        if (data2.code === '200') {
            setorderList(data2.data);
        } else {
            message.error(data2.message);
        }
    }
    //开多&&开空
    async function open(value) {
        let params = null;
        let reg = /^[0-9]*[1-9][0-9]*$/;
        const values = await TopForm.validateFields();
        if (values.volume <= 0) {
            return message.error('仓位数不能为0或负数');
        }
        if (!reg.test(values.volume)) return;
        if (value === 1) {
            if (askPrice === null) {
                return message.error('未获取到价格');
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "0",
                direction: "0",
                price: askPrice,
                closeTodayOrYesterday: "2",
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        } else if (value === 2) {
            if (bidPrice === null) {
                return message.error('未获取到价格');
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "0",
                direction: "1",
                closeTodayOrYesterday: "2",
                price: bidPrice,
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        }
        const data = await opens(params);
        if (data.code === '200') {
            message.success(data.message);
            _getData();
        } else {
            message.error(data.message);
        }
    }
    //平多&&平空
    async function close(value) {
        let params = null;
        let flag = true;
        let reg = /^[0-9]*[1-9][0-9]*$/;
        const values = await TopForm.validateFields();
        if (values.volume <= 0) {
            return message.error('仓位数不能为0或负数');
        }
        if (!reg.test(values.volume)) return;
        if (value === 3) {
            if (bidPrice === null) {
                return message.error('未获取到价格');
            }
            const data1 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data1.code === '200' && data1.data.length !== 0) {
                data1.data.map((item => {
                    if (item.instrumentId === record.code.substring(0, record.code.indexOf('.')) && item.direction === '0') {
                        if (item.totalPosition === 0 || values.volume > item.totalPosition) {
                            flag = false;
                            return message.error('仓位数不足');
                        }
                    }
                    return item;
                }))
            } else {
                message.error(data1.message);
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "1",
                direction: "1",
                closeTodayOrYesterday: "0",
                price: bidPrice,
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        } else if (value === 4) {
            if (askPrice === null) {
                return message.error('未获取到价格');
            }
            const data2 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data2.code === '200' && data2.data.length !== 0) {
                data2.data.map((item => {
                    if (item.instrumentId === record.code.substring(0, record.code.indexOf('.')) && item.direction === '1') {
                        if (item.totalPosition === 0 || values.volume > item.totalPosition) {
                            flag = false;
                            return message.error('仓位数不足');
                        }
                    }
                    return item;
                }))
            } else {
                message.error(data2.message);
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "1",
                direction: "0",
                closeTodayOrYesterday: "0",
                price: askPrice,
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        } else if (value === 5) {
            if (askPrice === null) {
                return message.error('未获取到价格');
            }
            const data5 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data5.code === '200' && data5.data.length !== 0) {
                data5.data.map((item => {
                    if (item.instrumentId === record.code.substring(0, record.code.indexOf('.')) && item.direction === '1') {
                        if (item.totalPosition === 0 || values.volume > item.totalPosition) {
                            flag = false;
                            return message.error('仓位数不足');
                        }
                    }
                    return item;
                }))
            } else {
                message.error(data5.message);
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "1",
                direction: "0",
                closeTodayOrYesterday: "1",
                price: askPrice,
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        } else if (value === 6) {
            if (askPrice === null) {
                return message.error('未获取到价格');
            }
            const data6 = await getInstrumentAsset({ subAccountId: subAccountInfo.subAccountId });
            if (data6.code === '200' && data6.data.length !== 0) {
                data6.data.map((item => {
                    if (item.instrumentId === record.code.substring(0, record.code.indexOf('.')) && item.direction === '1') {
                        if (item.totalPosition === 0 || values.volume > item.totalPosition) {
                            flag = false;
                            return message.error('仓位数不足');
                        }
                    }
                    return item;
                }))
            } else {
                message.error(data6.message);
            }
            params = {
                subAccountId: subAccountInfo.subAccountId,
                offset: "1",
                direction: "1",
                closeTodayOrYesterday: "1",
                price: bidPrice,
                instrumentId: record.code.substring(0, record.code.indexOf('.')),
                volume: values.volume,
                exchange: (record.code).substring((record.code).indexOf('.') + 1),
                vtSymbol: record.code,
            }
        }
        if (flag === true) {
            const data = await closes(params);
            if (data.code === '200') {
                message.success(data.message);
                _getData();
            } else {
                message.error(data.message);
            }
        }
    }
    function onSelect(data) {
        let arr = data.split('  ');
        record.name = arr[0];
        record.code = arr[1];
    }
    // function changePrice(value){
    //     if(value !== null){
    //         record.price=value;
    //     }else{
    //         record.price=undefined;
    //     }
    //     setprice(value);
    // }

    return (
        <div className="contentView" id="futureTrading">
            <Card>
                <Row className="headView">
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>总资产</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.totalAssets ? parseFloat(assetData.totalAssets).toFixed(2) : 0}</h2></Col>
                        </Row>
                    </Col>
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>可用资金</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.totalBalance ? parseFloat(assetData.totalBalance).toFixed(2) : 0}</h2></Col>
                        </Row>
                    </Col>
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>总收益</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.totalRevenue === null || parseFloat(assetData.totalRevenue) === 0 ? 0 : parseFloat(assetData.totalRevenue) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.totalRevenue).toFixed(2)}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.totalRevenue).toFixed(2)}</span>}</h2></Col>
                        </Row>
                    </Col>
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>总收益率</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.totalRevenueRate === null || parseFloat(assetData.totalRevenueRate) === 0 ? '0%' : parseFloat(assetData.totalRevenueRate) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.totalRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.totalRevenueRate * 100).toFixed(2) + '%'}</span>}</h2></Col>
                        </Row>
                    </Col>
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>日收益</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.dailyRevenue === null || parseFloat(assetData.dailyRevenue) === 0 ? 0 : parseFloat(assetData.dailyRevenue) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.dailyRevenue).toFixed(2)}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.dailyRevenue).toFixed(2)}</span>}</h2></Col>
                        </Row>
                    </Col>
                    <Col span={4} className="headViewCol">
                        <Row >
                            <Col span={24} className="colorGrey headTitle"><h3>日收益率</h3></Col>
                            <Col span={24} className="headNum" ><h2 className="headCardTetx">{assetData.dailyRevenueRate === null || parseFloat(assetData.dailyRevenueRate) === 0 ? '0%' : parseFloat(assetData.dailyRevenueRate) > 0 ?
                                <span style={{ color: 'red' }}>{parseFloat(assetData.dailyRevenueRate * 100).toFixed(2) + '%'}</span> : <span style={{ color: 'green' }}>{parseFloat(assetData.dailyRevenueRate * 100).toFixed(2) + '%'}</span>}</h2></Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <br />
            <Card>
                <Row>
                    <Col span={16}>
                        <Form form={TopForm} name="control-hooks" layout='inline' initialValues={{ volume: 0, symbol: record.name + '  ' + record.code }}>
                            <Form.Item name='symbol' label="期货代码">
                                <AutoComplete
                                    options={options}
                                    style={{ width: 200 }}
                                    onSelect={onSelect}
                                    placeholder="请输入期货名称或代码"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                            {/* <Form.Item name='price' label="价格">
                                <Row><InputNumber min={0} disabled={true} value={price} onChange={changePrice} style={{width:100}}/></Row>
                            </Form.Item> */}
                            <Form.Item
                                name="volume"
                                label="手数"
                                validateTrigger='onBlur'
                                rules={[{
                                    validator: async (_, value) => {
                                        if (value) {
                                            var reg = /^[0-9]*[1-9][0-9]*$/;
                                            if (reg.test(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(new Error('请输入正整数!'));
                                            }
                                        } else {
                                            return Promise.reject(new Error('请输入仓位数！'));
                                        }
                                    }
                                }]}
                            >
                                <InputNumber min={0} style={{ width: 100 }} />
                            </Form.Item>
                        </Form>
                        <br /><br />
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Card
                                    title={askPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'red', fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { open(1) }}
                                >开多</Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    title={bidPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'green', marginLeft: 15, fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { open(2) }}
                                >开空</Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    title={bidPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'green', marginLeft: 40, fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { close(3) }}
                                >平多</Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    title={askPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'red', marginLeft: 15, fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { close(4) }}
                                >平空</Card>
                            </Col>
                            {/* <Col span={6}>
                                <Card
                                    title={askPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'green', marginLeft: 15, fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { close(5) }}
                                >平空平今</Card>
                            </Col>
                            <Col span={6}>
                                <Card
                                    title={bidPrice}
                                    hoverable={true}
                                    headStyle={{ textAlign: 'center', height: '5%' }}
                                    style={{ width: 150, textAlign: 'center', color: 'red', marginLeft: 15, fontSize: 20, fontWeight: 'bold' }}
                                    onClick={() => { close(6) }}
                                >平多平今</Card>
                            </Col> */}
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col span={13}>
                                <Form form={leftForm} name="control-hooks" >
                                    <Form.Item label="持仓量">
                                        {listData.openInterest}
                                    </Form.Item>
                                    <Form.Item label="今日开盘价">
                                        {String(listData.openPrice).indexOf('e') !== -1 ? 0 : String(listData.openPrice).indexOf('.') !== -1 ? parseFloat(listData.lastPrice).toFixed(4) : listData.lastPrice}
                                    </Form.Item>
                                    <Form.Item label="今日最高价">
                                        {String(listData.highPrice).indexOf('e') !== -1 ? 0 : String(listData.highPrice).indexOf('.') !== -1 ? parseFloat(listData.highPrice).toFixed(4) : listData.highPrice}
                                    </Form.Item>
                                    <Form.Item label="今日最低价">
                                        {String(listData.lowPrice).indexOf('e') !== -1 ? 0 : String(listData.lowPrice).indexOf('.') !== -1 ? parseFloat(listData.lowPrice).toFixed(4) : listData.lowPrice}
                                    </Form.Item>
                                    <Form.Item label="成交金额">
                                        {listData.turnover}
                                    </Form.Item>
                                    <Form.Item label="成交量">
                                        {listData.volume}
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={11}>
                                <Form form={rightForm} name="control-hooks" >
                                    <Form.Item label="昨收盘价格">
                                        {listData.preClosePrice}
                                    </Form.Item>
                                    <Form.Item label="上次结算价格">
                                        {listData.preSettlementPrice}
                                    </Form.Item>
                                    <Form.Item label="最新价">
                                        {String(listData.lastPrice).indexOf('.') === -1 ? listData.lastPrice : parseFloat(listData.lastPrice).toFixed(4)}
                                    </Form.Item>
                                    <Form.Item label="涨停价">
                                        {listData.upperLimit}
                                    </Form.Item>
                                    <Form.Item label="跌停价">
                                        {listData.lowerLimit}
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <br />
            <Card title="当前持仓" bordered={true} >
                <Table
                    rowKey={(record) => { return record.instrumentId + record.direction }}
                    dataSource={fundList}
                    columns={fundColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 5,
                        onChange: e => { setcurrentPage(e) }
                    }}
                />
            </Card>
            <br />
            <Card title="交易记录" bordered={true} extra={
                <Row justify="end" align="middle">
                    <span style={{ marginRight: 10 }}>选择日期:</span>
                    <RangePicker onChange={selectTime} />
                </Row>
            }>
                <Table
                    rowKey="id"
                    dataSource={orderList}
                    columns={entrust}
                    bordered
                    pagination={{
                        pageSize: 5,
                        onChange: e => { setcurrentPageBottom(e) }
                    }}
                />
            </Card>
        </div>
    )
}
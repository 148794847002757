import React, { useState } from 'react';
import { Table, Card, Tag } from 'antd';
import _ from 'lodash';


const SubAccountTable = ({ isShow, subAccountInfo }) => {

  const columnsChildren = [
    {
      title: '子账户',
      dataIndex: 'name',
      width: '16%',
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{text}</Tag></div>
      }
    },
    {
      title: '资产',
      dataIndex: 'totalAsset',
      sorter: (a, b) => b.totalAsset - a.totalAsset,
      width: '12%',
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      }
    },
    {
      title: '净值',
      dataIndex: 'netValue',
      width: '10%',
      align: 'center',
      sorter: (a, b) => b.netValue - a.netValue,
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(4)}</Tag></div>
      }
    },
    {
      title: '收益',
      dataIndex: 'totalRevenue',
      sorter: (a, b) => b.totalRevenue - a.totalRevenue,
      width: '12%',
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      }
    },
    {
      title: '日收益',
      dataIndex: 'dailyRevenue',
      align: 'center',
      sorter: (a, b) => b.dailyRevenue - a.dailyRevenue,
      width: '12%',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      },
    },
    {
      title: '可用资金',
      dataIndex: 'availableBalance',
      sorter: (a, b) => b.availableBalance - a.availableBalance,
      width: '12%',
      align: 'center',
      render: (text, record, index) => {
        return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
      },
    },
    {
      title: '交易员',
      dataIndex: 'traderName',
      align: 'right',
      width: '10%',
      render: (text, record, index) => {
        return (
          <><Tag color="blue">{text}</Tag></>
        )
      }
    },
  ]

  return (
    <>
      {isShow && <Card title="子账户列表" bordered={true} >
        <Table
          rowKey="id"
          dataSource={_.get(subAccountInfo, 'subAccountList', [])}
          columns={columnsChildren}
          bordered
        />
      </Card>}
    </>

  )
}

export default SubAccountTable;
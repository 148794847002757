import React, { useEffect, useState } from 'react';
import { Card, PageHeader, Typography, Col, Row, Radio, Badge, List, Button, Modal, message, Checkbox, Tabs, Space } from 'antd';
import { posMessageRead, delMessage, delBatchMessage, tradersAccept, tradersRefuse } from '@/api/workbench';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { ExclamationCircleOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { showAll, notRead, readAll } from '@/api/workbench';
import _ from 'lodash';

const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const TAB_LIST = [
    { key: 'system_signal', name: '系统通知' },
    { key: 'risk_signal', name: '风控通知' },
    { key: 'trade_signal', name: '交易信号' },
    { key: 'sub_account_manage', name: '邀请信息' },
];
const ACCEPT_STATUS_OBJ = {
    1: '已接受',
    2: '已拒绝',
}

export default function FundManagerNews(props) {
    const mobxStore = useLocalObservable(() => store);
    const [newList, setNewList] = useState([]);
    const [selectKey, setSelectKey] = useState(_.get(props, 'location.state.type', '') === 'risk' ? 'risk_signal' : 'system_signal');
    const [state, setstate] = useState('0');
    const [page, setPage] = useState({ page: 1, totalPage: 1, pageSize: 20 });
    const [loading, setLoading] = useState(false);
    const [batchMessage, setbatchMessage] = useState([]);
    const [newsUnread, setNewUnread] = useState({});

    useEffect(() => {
        _get();
        _notRead();

        return () => { return; }
    }, [])

    async function _get(_type, _state, _page, _pSize) {
        setLoading(true);
        const finalState = _state || state;
        let newPageSize = _pSize || page.pageSize;
        let newPage = _page || _.get(page, 'page');
        if (_type) { // 切换tab将分页设置为第一页
            newPage = 1;
        }
        const data = await showAll({
            type: _type || selectKey,
            status: parseInt(finalState),
            page: newPage,
            pagesize: newPageSize,
        });
        const arr = _.get(data, 'data.message', [])
        setNewList(arr);
        setLoading(false);
        setPage({
            page: _.get(data, 'data.page', 1),
            totalPage: _.get(data, 'data.totalPage', 1),
            pageSize: newPageSize
        })
    }

    async function _notRead() {
        const readRes = await notRead()
        if (_.get(readRes, 'code', '') === '200') {
            const getType = _.get(readRes, 'data', {})
            setNewUnread(getType);
        }
    }

    async function _readAll() {
        const data = await readAll({ messageIds: batchMessage });
        if (data.code === '200') {
            _get();
            _notRead();
            setbatchMessage([])
        }
    }

    async function _messageTradeRead(id) {
        if (id) {
            const data = await posMessageRead({ messageId: id });
            //console.log(data);
            if (data.code === '200') {
                _get();
                message.success('消息已读！');
                _notRead();
            }
        }
    }
    async function _messageDel({ messageId }) {
        Modal.confirm({
            title: '是否删除该消息！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await delMessage({ messageId });
                if (data.code === '200') {
                    _get();
                    message.success('删除成功');
                }
            }
        });
    }
    function bathSelectChange(e, item) {
        let arr = JSON.parse(JSON.stringify(batchMessage));
        if (e.target.checked) {
            arr.push(item.messageId);
            setbatchMessage(arr);
        } else {
            let index = arr.indexOf(item.messageId);
            if (index !== -1) {
                arr.splice(index);
            }
            setbatchMessage(arr);
        }
    }
    async function delBathMessage() {
        if (batchMessage.length === 0) return message.error('请选择要删除的消息!');

        Modal.confirm({
            title: '是否批量删除选中的消息！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await delBatchMessage({ messageIdList: batchMessage });
                if (data.code === '200') {
                    _get();
                    message.success('批量删除成功');
                    setbatchMessage([]);
                    _notRead();
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    // 交易员接受或拒绝基金经理的邀请
    async function _acceptReject(mes, item) {
        console.log(item)
        let params = {
            subAccountId: item.subAccountId,
            messageId: item.messageId,
            sender: item.sender
        }
        if (mes === 'refuse') {
            Modal.confirm({
                title: '是否拒绝该邀请',
                content: '',
                okText: '拒绝',
                cancelText: '取消',
                onOk: async () => {
                    const data = await tradersRefuse(params);
                    if (data.code === '200') {
                        // 当该条消息是未读 接受或者拒绝后 调已读接口
                        if (item.subAccountId && item.status === 0) {
                            const data = await posMessageRead({ messageId: item.messageId });
                            if (data.code === '200') {
                                _get();
                                message.success('拒绝成功');
                            }
                        }
                    } else {
                        message.error(data.message);
                    }

                }
            });
        } else {
            Modal.confirm({
                title: '是否接受该邀请',
                content: '',
                okText: '接受',
                cancelText: '取消',
                onOk: async () => {
                    const data = await tradersAccept(params);
                    if (data.code === '200') {
                        // 当该条消息是未读 接受或者拒绝后 调已读接口
                        if (item.subAccountId && item.status === 0) {
                            const data = await posMessageRead({ messageId: item.messageId });
                            if (data.code === '200') {
                                _get();
                                message.success('接受成功');
                            }
                        }
                    } else {
                        message.error(data.message);
                    }
                }
            });
        }
    }


    function selectAll() {
        let getAll = newList.map(n => n.messageId);
        setbatchMessage(getAll);
    }

    function renderGotItButton(item) {
        if (_.get(item, 'acceptStatus', 0) === 0 && mobxStore.userInfo.role !== 'fund_manager' && _.get(item, 'status', 2) === 0) {
            if (_.get(item, 'type', '') !== 'sub_account_manage') {
                return [
                    <Button
                        type="link"
                        onClick={() => _messageTradeRead(_.get(item, 'messageId', null))}
                        icon={<CheckCircleOutlined />}
                    >
                        知道了
                    </Button>
                ]
            } else {
                return [
                    <Button type="link" onClick={() => _acceptReject('accept', item)} icon={<CheckCircleOutlined />}>接受</Button>,
                    <Button type="link" danger icon={<CloseCircleOutlined />}
                        onClick={() => _acceptReject('refuse', item)} >
                        拒绝
                    </Button>
                ]
            }
        } else {
            return []
        }
    }

    function renderAction(item) {
        const gotBtn = renderGotItButton(item);
        let finalAction = [];
        const getAcceptStatus = _.get(item, 'acceptStatus', 0);
        const delButton = <Button
            onClick={() => _messageDel(item)}
            danger
            icon={<DeleteOutlined />}
            type="link">
            删除
        </Button>

        if (getAcceptStatus !== 0) {
            finalAction = [
                <span className="colorGrey">
                    {ACCEPT_STATUS_OBJ[getAcceptStatus] || ''}
                </span>,
                delButton
            ]
        } else {
            if (_.get(item, 'status', 0) !== 0) {
                finalAction = [delButton];
            }
        }

        return [...gotBtn, ...finalAction];
    }

    function callback(key) {
        //console.log(key);
        setSelectKey(key)
        _get(key);
        setbatchMessage([]);
    }

    const renderList = () => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={newList}
                loading={loading}
                size='small'
                pagination={{
                    current: _.get(page, 'page'),
                    pageSize: _.get(page, 'pageSize'),
                    total: _.get(page, 'totalPage') * _.get(page, 'pageSize'),
                    onChange: (page, pageSize) => {
                        _get(null, null, page, pageSize)
                    }
                }}
                renderItem={item => {
                    const renderTitle = item.type === 'risk_signal' ? '风控通知' : item.sender
                    return <List.Item
                        actions={renderAction(item)}
                    >
                        <List.Item.Meta
                            avatar={state !== 0 ? <Checkbox checked={batchMessage.indexOf(item.messageId) !== -1 ? true : false} onChange={(e) => bathSelectChange(e, item)} /> : null}
                            title={
                                <Row align="middle" gutter={16} >
                                    <Col><Title level={5}>{renderTitle}</Title></Col>
                                    <Col><Text type='secondary'>{item.sendTime}</Text></Col>
                                </Row>
                            }
                            description={
                                <Paragraph style={{ marginBottom: 2 }}>
                                    {item.content}
                                </Paragraph>
                            }
                        />
                    </List.Item>
                }}
            />
        )
    }

    return (
        <>
            <PageHeader
                title={mobxStore.userInfo.role === 'fund_manager' ? <h4>基金经理消息列表</h4> : <h4>账户通知</h4>}
                onBack={() => props.history?.goBack()}
                style={{ backgroundColor: 'white' }}
                extra={[
                    <Radio.Group
                        buttonStyle="solid"
                        optionType="button"
                        defaultValue={state}
                        onChange={(e) => {
                            setstate(e.target.value)
                            _get(null, e.target.value)
                            setbatchMessage([])
                        }}>
                        <Radio.Button value={'0'}>未读</Radio.Button>
                        <Radio.Button value={'1'}>已读</Radio.Button>
                    </Radio.Group>
                ]}
            ></PageHeader>

            <Card
                style={{ marginTop: 10 }}
                bodyStyle={{ paddingTop: 0 }}
            >
                <Row justify='end' style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button
                                type="text"
                                onClick={selectAll}
                                disabled={_.size(batchMessage) === _.size(newList)}
                            >
                                全选
                            </Button>

                            {
                                state === '1' ?
                                    <Button type="primary" onClick={delBathMessage} danger icon={<DeleteOutlined />}>
                                        批量删除
                                    </Button> : <Button
                                        type="primary"
                                        onClick={_readAll}
                                        icon={<CheckCircleOutlined />}
                                        disabled={_.size(batchMessage) === 0}
                                    >
                                        批量已读
                                    </Button>
                            }
                        </Space>

                    </Col>
                </Row>

                <Tabs defaultActiveKey={selectKey} onChange={callback}>
                    {TAB_LIST.map((item, index) => {
                        return <TabPane tab={
                            <Badge offset={[6, -6]} count={_.get(newsUnread, item.key, 0)}>
                                <span>{_.get(item, 'name', '')}</span>
                            </Badge>
                        } key={item.key}>
                            {renderList()}
                        </TabPane>
                    })}
                </Tabs>

            </Card>
        </>

    )
}  
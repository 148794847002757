import axios from '@/utils/axios';

/**
 *  获取订单
 */
export function getOrders() {
  return axios.get(global.api + '/payment/showOrders');
}
/**
 *  查看用户会员是否到期
 */
export function checkUserExpire() {
  return axios.get(global.api + '/account/checkExpire');
}
/**
 *  新订单
 */
export function newOrder(params) {
  return axios.post(global.api + '/payment/createOrder', params);
}
/**
 *  关闭订单
 */
export function closeOrder(id) {
  return axios.get(global.api + '/payment/closeOrder?orderNo=' + id);
}
/**
 *  支付宝
 */
export function goAlipay(id) {
  return axios.get(global.api + '/payment/goAliPay?orderNo=' + id);
}
/**
 *  微信
 */
export function goWechatPay(id) {
  return axios.get(global.api + '/payment/goWxPay?orderNo=' + id);
}
/**
 *  查看是否支付
 */
export function checkIsPaied(id) {
  return axios.get(global.api + '/payment/checkOrderStatus?orderNo=' + id);
}
/**
 *  续费-优惠
 */
export function checkDiscount(id) {
  return axios.get(global.api + '/payment/beforeCreateOrder?levelId=' + id);
}



/**
 *  查看所有策略
 */
export function showAllLevels() {
  return axios.get(global.api + '/subscription/showAllLevels');
}
/**
/**
 *  查看所有订阅服务
 */
export function showSubscriptionServices() {
  return axios.get(global.api + '/subscription/showSubscriptionServices');
}
/**
 *  新增策略
 */
export function addLevels(params) {
  return axios.post(global.api + '/subscription/addLevels', params);
}
/**
 *  更新策略
 */
export function updateLevel(params) {
  return axios.post(global.api + '/subscription/updateLevel', params);
}
/**
 *  更新服务level等级
 */
export function updateSubscriptionServices(params) {
  return axios.post(global.api + '/subscription/updateSubscriptionServices', params);
}
/**
 *  获取当前股票所有策略信息
 */
export function showSubAccountStrategy(params) {
  return axios.post(global.api + '/trader/control/showSubAccountStrategy', params);
}
/**
 *  设置计算功能
 */
export function calculate(params) {
  return axios.post(global.api + '/trader/control/calculate', params);
}
/**
 *  托管记录
 */
export function showAutoTradeRecords(params) {
  return axios.post(global.api + '/subAccount/showAutoTradeRecords', params);
}
/**
 *  自动交易明细
 */
export function showAutoTradeDetail(id) {
  return axios.get(global.api + '/trader/control/showAutoTradeDetail?autoTradeRecordId=' + id);
}
/**
 *  基金经理新角色-图表接口
 */
export function mShowSubAccountHistory(data) {
  return axios.post(global.api + '/traderManager/showParentSubAccountHistory', data);
}
export function mShowContribution(data) {
  return axios.post(global.api + '/traderManager/showSubAccountContribution', data);
}
export function mPersonalDisplayProductLevel(data) {
  return axios.post(global.api + '/traderManager/getPersonalDisplaySubAccountLevel', data);
}
export function mStocksDisplayProductLevel(data) {
  return axios.post(global.api + '/traderManager/getStocksDisplaySubAccountLevel', data);
}
export function mPlateDisplaySubAccountLevel(data) {
  return axios.post(global.api + '/traderManager/getPlateDisplaySubAccountLevel', data);
}
/**
 *  获取所有托管策略信息
 */
export function getAutoTradingStrategies(params) {
  return axios.post(global.api + '/fundManager/getAutoTradingStrategies', params);
}
/**
 *  获取托管交易点位
 */
export function getAutoTradingInfoPoints(params) {
  return axios.post(global.api + '/fundManager/getAutoTradingInfoPoints', params);
}
/**
 *  提现 功能
 */
// admin 执行提现
export function executeTransfer(params) {
  return axios.post(global.api + '/transfer/executeTransfer', params);
}
export function rejectTransfer(params) {
  return axios.post(global.api + '/transfer/rejectTransfer', params);
}
//发起提现
export function startWithdrawal(params) {
  return axios.post(global.api + '/transfer/startWithdrawal', params);
}
export function showAccount(params) {
  return axios.post(global.api + '/auth/showAccount', params);
}
export function unbindAccount(params) {
  return axios.post(global.api + '/auth/unbindAccount', params);
}
export function getVerifPhoneCode(data) {
  return axios.post(global.api + '/fundManager/getCode', { ...data, randomCodeType: 'BIND_THIRD_ACCOUNT' });
}
export function showMemberInfo() {
  return axios.get(global.api + '/transfer/showMemberInfo');
}
export function showAllWithdrawalFlow(id) {
  return axios.get(global.api + '/transfer/showAllWithdrawalFlow' + (id ? `?userId=${id}` : ''));
}
export function showAllIncreaseFlow(params) {
  return axios.post(global.api + '/transfer/showAllIncreaseFlow', params);
}
// 校验余额
export function checkUserBalance(params) {
  return axios.post(global.api + '/transfer/checkUserBalance', params);
}
//自动分配基金经理
export function autoDistributeSub() {
  return axios.get(global.api + '/subAccount/autoDistributeSub');
}
//获取邀请列表
export function showInvitees() {
  return axios.get(global.api + '/account/showInvitees');
}
// 兑换积分
export function exchangeService(params) {
  return axios.post(global.api + '/account/exchangeService', params);
}
// 用户
export function updateUserInfo(params) {
  return axios.post(global.api + '/account/updateUserInfo', params);
}
export function getUserInfo() {
  return axios.get(global.api + '/account/getUserInfo');
}
export function getSignalBills(params) {
  return axios.post(global.api + '/member/getSignalBills',params);
}
export function depositToBalance(params) {
  return axios.post(global.api + '/transfer/depositToBalance',params);
}
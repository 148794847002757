import React, { useState, useEffect } from 'react';
import {
  Form,
  Card,
  Button,
  Select,
  Input,
  InputNumber,
  message
} from 'antd';
import { addLevels, updateLevel } from '@/api/userSystem';
import _ from 'lodash';

const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const NewLevelPage = (props) => {
  const [form] = Form.useForm();
  const [poolList] = useState(_.get(props, 'location.state.allPool', []))
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [codeList, setCodeList] = useState([]);
  const [defaultCode, setDefaultCode] = useState([]);

  useEffect(() => {

    const getFormValue = props.location.state.formValue;
    const getCList = props.location.state.cList;
    if (getFormValue) {
      const splitPoolList = _.split(_.get(getFormValue, 'stockPool', ''), ';')
      let newEdit = {
        ...getFormValue,
        stockPool: splitPoolList
      };
      setDefaultCode(splitPoolList);
      setIsEdit(true);
      setEditValue(newEdit);
      form.setFieldsValue(getFormValue);
    }

    if (getCList) {
      setCodeList(getCList)
    }

    return () => { return; }
  }, [])

  async function submit(value) {
    const res = await addLevels(value);
    if (_.get(res, 'code') === '200') {
      message.success(_.get(res, 'message', ''))
      props.history.goBack()
    } else {
      message.warning('操作失败！')
    }
  }

  async function update(value) {
    let params = {
      id: _.get(editValue, 'id'),
      ...value
    }
    if (params.id) {
      const res = await updateLevel(params);
      if (_.get(res, 'code') === '200') {
        message.success(_.get(res, 'message', ''))
        props.history.goBack()
      } else {
        message.info('操作失败！')
      }
    }
  }

  const onFinish = (values) => {
    let params = {
      ...values,
      stockPool: _.join(_.get(values, 'stockPool', []), ';')
    }
    console.log(isEdit ? '更新' : '新增', params);
    if (isEdit) {
      update(params)
    } else {
      submit(params)
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div style={{ margin: 10 }}>
        <Card title={isEdit ? '编辑level策略' : '新增level策略'}>
          <Form {...layout} form={form} name="new-level" onFinish={onFinish} initialValues={{ stockPool: defaultCode }}>
            <Form.Item name="levelName" label="level名称" rules={[{ required: true }]}>
              <Input style={{ width: 280 }} />
            </Form.Item>
            <Form.Item name="stockPool" label="股票池" rules={[{ required: true, type: 'array' }]}>
              <Select
                style={{ width: 150 }}
                mode="multiple"
                allowClear
              >
                {_.size(codeList) > 0 && codeList.map((item, index) => <Option key={'pool' + index} value={item.value}>{item.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item name="interval" label="回测区间" rules={[{ required: true }]}>
              <Select style={{ width: 150 }}>
                <Option value={'month'}>一月</Option>
                <Option value={'three_month'}>季度</Option>
                <Option value={'year'}>年</Option>
                <Option value={'five_year'}>五年</Option>
                <Option value={'All'}>无限制</Option>
              </Select>
            </Form.Item>
            <Form.Item name="kdjId" label="指标等级id" rules={[{ required: true }]}>
              <Select style={{ width: 150 }}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
              </Select>
            </Form.Item>
            <Form.Item name="trusteeship" label="是否开启托管功能" rules={[{ required: true }]}>
              <Select style={{ width: 150 }}>
                <Option value={0}>否</Option>
                <Option value={1}>是</Option>
              </Select>
            </Form.Item>
            <Form.Item name="strategiesId" label="绑定策略池" rules={[{ required: true }]}>
              <Select style={{ width: 150 }}>
                {poolList.map((item) => (
                  <Option key={item.id} value={item.id}>{item.strategyName}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="stockAmount" label="每月可交易股票数量" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            <Form.Item name="strategiesNum" label="每只股票月启动策略总数" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            <Form.Item name="dayLimit" label="日回测次数限制" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            <Form.Item name="monthLimit" label="月回测次数限制" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>
            <Form.Item name="ruleNum" label="每个策略添加规则条数最大值" rules={[{ required: true }]}>
              <InputNumber />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
              <Button htmlType="button" onClick={onReset} style={{ marginLeft: 8 }}>
                重置
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  )
}

export default NewLevelPage;
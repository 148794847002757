import React, { useState } from 'react';
import {
  Row,
  Col,
  Space,
  Card,
  Button,
  Image,
  Descriptions,
  Tag,
  message,
  Upload,
  Typography,
  Spin,
  Divider,
} from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { IMG_URL, RAMDOM_IMG_URL, UPLOAD_FILE_URL, handleMomentDate, renderDescValue } from '@/utils/utils';
import img_right from '@/images/reward/right.png';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const ROLE_NAME = {
  trader: '交易员',
  trader_manager: '基金经理',
}

const UserCard = ({
  userData,
  exData,
  role,
  userInfo,

  onUpdateAvatar,
  onUpdateName
}) => {
  const [uploading, setUploading] = useState(false);

  const des_status_obj = {
    status: { 0: <Text type='danger' strong>{'到期'}</Text>, 1: <Text type='success' strong>{'服务中'}</Text> },
    trusteeship: { 0: <Text type='danger' strong>{'未开启'}</Text>, 1: <Text type='success' strong>{'开启'}</Text> },
  }
  const desList = [
    { name: '开始时间', valueKey: 'startDate', type: 'date' },
    { name: '结束时间', valueKey: 'expirationDate', type: 'date' },
    { name: '是否到期', valueKey: 'status', type: 'if', span: 2 },
    { name: '开启托管功能', valueKey: 'trusteeship', type: 'if', span: 2 },

  ];
  const desList2 = [
    {
      name: '股票权限',
      col: { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 },
      column: 1,
      itemList: [
        { label: '每月可操作股票', valueKey: 'stockAmount', type: 'show' },
        { label: '当月已操作股票', valueKey: 'monthStockAmount', type: 'show' },
      ]
    },
    {
      name: '策略权限',
      col: { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 },
      column: 1,
      itemList: [
        { label: '月启动策略', valueKey: 'strategiesNum', type: 'show' },
        { label: '当月启动策略', valueKey: 'openStrategiesNum', type: 'show' },
        { label: '规则条数最大值', valueKey: 'ruleNum', type: 'show' },
      ]
    },
    {
      name: '回测权限',
      col: { span: 24 },
      column: { xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 },
      itemList: [
        { label: '日回测次数', valueKey: 'dayLimit', type: 'show' },
        { label: '当日启动次数', valueKey: 'dayTestNum', type: 'show' },
        { label: '月回测次数', valueKey: 'monthLimit', type: 'show' },
        { label: '当月启动回测', valueKey: 'monthTestNum', type: 'show' },
      ]
    },
  ]

  const renderDescSubValue = (v, valueKey) => {
    let final = '';
    if (v.type === 'date') {
      final = <Text>{handleMomentDate(valueKey)}</Text>;
    } else if (v.type === 'if') {
      final = des_status_obj[v.valueKey][valueKey] || '无'
    } else {
      final = <Text strong>{renderDescValue(valueKey)}</Text>
    }
    return final;
  }

  const actions = {
    action: UPLOAD_FILE_URL,
    showUploadList: false,
    beforeUpload: file => {
      let isOversize = true;
      const getSize = _.round(file.size / 1000);
      if (getSize > 5125) {
        message.info('请发送5M以下的图片文件');
        isOversize = false
      }
      return isOversize || Upload.LIST_IGNORE;
    },
    onChange: info => {
      //console.log(info);
      if (info.file.status === 'uploading') {
        setUploading(true);
      }
      if (info.file.status === 'done' && _.get(info, 'file.response.code', '') === '200') {
        setUploading(false);
        onUpdateAvatar(_.get(info, 'file.response.data', null))
      }
    },
  };

  return (
    <Card>
      <div style={{ textAlign: 'center' }}>
        <Spin spinning={uploading}>
          <Space direction='vertical' align='center'>
            <div className='avatarDiv'>
              <Image
                width={100}
                height={85}
                src={_.get(userData, 'avatar', null) ? IMG_URL + userData.avatar : RAMDOM_IMG_URL}
                style={{ borderRadius: 6 }}
              />

              <div className='changeAvatarButton'>
                <Upload {...actions}>
                  <Button
                    icon={<UploadOutlined />}
                    shape='circle'
                    size='small'
                  />
                </Upload>
              </div>
            </div>

            <Paragraph
              editable={{
                icon: <EditOutlined />,
                tooltip: '点击修改名称',
                onChange: (txt) => onUpdateName(txt, null),
              }}
            >
              <Text strong style={{ fontSize: 18 }}>{_.get(userInfo, 'account', '用户名')}</Text>
              <br />
              <Text type='secondary' style={{ fontSize: 14 }}>{'昵称: ' + (_.get(userData, 'name', '') || '')} </Text>
            </Paragraph>

            {ROLE_NAME[role] && <Tag color={'geekblue'}>{ROLE_NAME[role]}</Tag>}
          </Space>
        </Spin>
      </div>

      {/* <Divider>会员权益</Divider> */}

      <Row gutter={[8, 8]} style={{ marginTop: 30 }}>
        <Col span={24}>
          <Space align='center'>
            <Image src={img_right} width={20} preview={false} />
            <Text strong>{'会员权益'}</Text>
          </Space>
          <div style={{ height: 15 }}></div>
          <Descriptions
            //title={<span style={{ fontSize: 15, fontWeight: "bold" }}>{'会员权益'}</span>}
            column={2}
            bordered
            size='small'
            labelStyle={{ fontSize: '15px' }}
          >
            {desList.map((item, index) => {
              let val = renderDescSubValue(item, _.get(exData, item.valueKey, ''));
              return <Descriptions.Item
                key={item.name + index}
                label={item.name}
                labelStyle={{ color: '#919090' }}
                span={item.span || 1}
              >
                {val}
              </Descriptions.Item>
            })}
          </Descriptions>
        </Col>

        <Divider />

        {desList2.map((item, index) => {
          const hasSub = _.get(item, 'itemList', null);
          return <Col key={item.name + index} {...item.col}>
            <Descriptions
              column={item.column}
              size='small'
              title={<span style={{ fontSize: 15, fontWeight: "bold" }}>{item.name}</span>}
              labelStyle={{ fontSize: 15 }}
            >
              {hasSub.map((n, i) => {
                return <Descriptions.Item
                  key={n.label + i}
                  label={n.label}
                  labelStyle={{ color: '#919090' }}
                >
                  {renderDescSubValue(n, _.get(exData, n.valueKey, ''))}
                </Descriptions.Item>
              })}
            </Descriptions>
          </Col>
        })}
      </Row>
    </Card>
  )
}

export default UserCard;
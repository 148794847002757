import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spin, Typography } from 'antd';
import * as echarts from 'echarts';
import { colorArray } from '@/view/common/colorArray';
import { NewRangePicker } from '@/view/common/widgets';
import { showContribution } from '@/api/workbench';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');

const SubContributionCharts = ({
  active = '1',
  id = 0,
  globalDate = [],
  newProps={}
}) => {
  const [updateCount, setUpdateCount] = useState(0);
  const [timeCount, setTimeCount] = useState(0);
  const [profitDatas, setProfitDatas] = useState({ timeList: [], datas: [], nameObj: {} });
  const [loading, setLoading] = useState(false);
  const [currentParams, setCurrentParams] = useState({});
  const [conTimes, setConTimes] = useState([]);
  const [option, setoption] = useState({
    grid: { left: '8%', right: '6%', bottom: '10%', top: '10%' },
    xAxis: {
      type: 'category',
      name: '时间',
      data: [],
      splitLine: {
        show: false
      },
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
        onZero: false,
      },
    },
    yAxis: [
      {
        type: 'value',
        name: '贡献度(%)',
        position: 'left',
        axisLine: {
          show: true,
          symbol: ['none', 'arrow'],
          symbolSize: [8, 8],
          symbolOffset: 7,
        },
        splitArea: {
          show: true
        },
        splitLine: {
          show: false
        },
      }
    ],
    series: [],
    tooltip: {
      trigger: 'axis',
      showDelay: 0,
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      },
      padding: 5,
    },
    legend: {
      type: 'scroll',
      selectedMode: 'multiple',
    },
  });

  useEffect(() => {
    if (active === 'CONTRIBUTION') {
      let timeArray = [];
      if ('beginDate' in currentParams) {
        timeArray = [currentParams.beginDate, currentParams.endDate];
      } else if (globalDate[0] && globalDate[1]) {
        timeArray = globalDate;
      } else {
        timeArray = [TODAY, TODAY];
      }
      setConTimes(timeArray);
      setTimeCount(timeCount + 1);
      _showContribution(timeArray);
    }
  }, [active]);

  async function _showContribution(dates) {
    if (id) {
      setLoading(true)

      let params = {
        productId: id,
        beginDate: dates[0],
        endDate: dates[1],
      }
      setCurrentParams(params);
      const data = await showContribution(params);
      setLoading(false);
      //  console.log('盈亏', data)
      if (_.get(data, 'code', '') === '200') {
        let result = _.get(data, 'data.subAccountHistoryList', []);
        let dataKeys = _.head(result) ? Object.keys(_.head(result)) : [];
        let arr = [];
        if (_.size(dataKeys) > 0) {
          dataKeys.map(keyname => {
            let a = {};
            a.name = keyname;
            a.type = 'line';
            a.data = result.map(r => r[keyname] || 0);
            a.symbol = 'none';
            arr.push(a);
          })
        }
        setProfitDatas({
          timeList: _.get(data, 'data.timeList', []),
          datas: arr,
          nameObj: _.get(data, 'data.subAccountIdNameMap', {})
        });
        setUpdateCount(updateCount + 1);
      }
    }
  }

  function onProfitTimeChange(value) {
    setConTimes(value);
    _showContribution(value);
  }

  useEffect(() => {
    //console.log('个股盈亏chart:', props)
    let myChart = newProps.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('product_contribution'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    /**
     * 选取当天复选账户内，绝对值波动最大的数值（data数组内的数据），选取所有复选账户内波动最大前20个复选账户
     */
    let newOption = _.cloneDeep(option);
    newOption.xAxis.data = _.get(profitDatas, 'timeList', []);
    newOption.series = handleSeriesData();
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [updateCount]);

  function handleSeriesData() {
    let tempData = _.cloneDeep(_.get(profitDatas, 'datas', []));
    let getNameObj = _.get(profitDatas, 'nameObj', {});
    let colorCount = 0;
    let finalData = _.isArray(tempData) && _.size(tempData) > 0 ? tempData.map((child, index) => {
      let newData = child.data;
      let ceilData = newData.map(n => _.round(n, 5));
      if (index > 19) {
        colorCount = colorCount + 1
      }
      if (colorCount === 19) {
        colorCount = 0;
      }
      return {
        ...child,
        name: getNameObj[child.name] || child.name,
        itemStyle: {
          normal: {
            color: index > 19 ? colorArray[colorCount] : colorArray[index],
            lineStyle: {
              color: index > 19 ? colorArray[colorCount] : colorArray[index]
            }
          }
        },
        data: ceilData
      }
    }) : []
    return finalData;
  }

  return (
    <Row style={{ backgroundColor: '#fff', padding: 5 }}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Card
            bordered={false}
            title={<Typography.Text>子账户盈亏分布</Typography.Text>}
            extra={
              <Row justify='end'>
                <span style={{ marginRight: 10, marginTop: 5 }}>选择日期:</span>
                <NewRangePicker
                  dates={conTimes}
                  onSelect={(dateStrings) => onProfitTimeChange(dateStrings)}
                />
              </Row>
            }>
            <div
              id="product_contribution"
              style={{ width: '100%', height: 450 }}
            />
          </Card>
        </Spin>
      </Col>
    </Row>
  )
}

export default SubContributionCharts;
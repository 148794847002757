import React, { useEffect, useState, useRef } from 'react';
import {
  Tabs,
  Card,
  Row,
  Col,
  Space,
  Select,
  Slider,
  Typography,
  Button,
  Tag,
  Checkbox,
  notification,
  Collapse,
  Table,
  DatePicker,
  Switch,
  Divider
} from 'antd';
import { LeftOutlined, RightOutlined, CaretRightOutlined } from '@ant-design/icons';
import { createBasicColums } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';
import * as echarts from 'echarts';
import { useReactive, useUpdateEffect } from 'ahooks';
import { INDEX_OPTIONS, INDEX_CODE_VALUE } from '@/utils/indexCode';
import {
  RATE_KEYS_OBJ, WEEK_LIMIT_NUMBER, TOOLTIP_SHOW_LIST, COMMON_CHART, LIMIT_COL_LIST, CHECK_TAG_ARRAY, SORT_BTN,
  calRate, arraysMinus2, handleRateOption, calNets, calTimeDiff, calMaxMin, renderSlice, handleIndexValue, handleRetreat, handleExtraOption
} from '@/view/common/Components/chartsInfoUtils';

const { TabPane } = Tabs;
const { Text } = Typography;
const { CheckableTag } = Tag;
const SHOW_CARRY = 2; // 展示保留2位小数，仅限于展示；计算时用更高的保留位数
let timer = null;

const renderText = (txt) => txt ?? '';
const isValidRate = (v) => v ? _.round(v, 2) + '%' : '';
let TOOLTIP_SHOW_OBJ_2 = {};
RATE_KEYS_OBJ.all.map(item => {// 用中文名称作为key，keyname作为value，tooltips时可以快速反向查找object
  TOOLTIP_SHOW_OBJ_2[item.name] = item.key;
});
/**
 *  MainRateCharts是EchartsInfo增加回撤部分及代码层面优化的图表组件；
 * 在原本keys等配置参数提取出来后，该图表进一步提取不依赖state的function，全部写入chartsInfoUtils中；
 * 在获取收益率时，将原本在charts里面处理的数据function，提取出来，在获取成功后计算赋值给datas.newData，可直接使用处理好的数据；
 */
export default function EchartsInfo(props) {
  const [pageKeys] = useState(_.get(props, 'pageKey', ''));
  const [activeKey, setActiveKey] = useState('1');
  const [idValue, setIdValue] = useState(_.get(props, 'indexSymbol') ?? '');
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [role] = useState(_.get(props, 'role', ''));
  const [switchAll, setSwitchAll] = useState({ updown_stock: false, updown: false, two_day: false, five_day: false, buy_sell: false, extra_switch: false });
  const [subItems, setSubItems] = useState([]);
  const [subValues, setSubValues] = useState([]);
  const markRef = useRef([]);
  const analysisState = useReactive({ // 立即更新state，全局快速更新
    otherList: [],
    dateType: '',
    points: [], pointsNet: [],
    idx: [], idxNet: [], idxName: '',
    extraList: [],
    lastPoint: 0, lastIdx: 0, lastExtra: 0,
  });
  const [option, setoption] = useState({
    xAxis: {
      name: '时间',
      type: 'category',
      ...COMMON_CHART.rateX
    },
    legend: {
      data: ['收益率']
    },
    yAxis: {
      name: '收益率(%)',
      type: 'value',
      min: -10,
      max: 50,
      splitNumber: 5,
      ...COMMON_CHART.rateY
    },
    series: [{
      ...COMMON_CHART.rateSeries
    }, {
      ...COMMON_CHART.indexSeries,
    }],
    tooltip: {
      ...COMMON_CHART.tooltipCustom,
      formatter: renderFormatter
    },
  });
  const [option2, setoption2] = useState({
    title: { text: '动态回撤' },
    xAxis: {
      name: '时间',
      type: 'category',
      ...COMMON_CHART.rateX
    },
    legend: {
      data: ['回撤', '指数', '超额回撤']
    },
    yAxis: {
      name: '回撤',
      type: 'value',
      min: -10,
      max: 0,
      splitNumber: 5,
      ...COMMON_CHART.rateY
    },
    series: [{
      ...COMMON_CHART.rateRetreat
    }, {
      ...COMMON_CHART.indexSeriesRetreat,
    }, {
      ...COMMON_CHART.extraRate,
      name: '超额回撤',
      symbol: 'none',
      data: []
    }],
    tooltip: { trigger: 'axis' },
  });

  function renderFormatter(params) {
    // 展示时小数点保留两位；【！！仅限展示，计算时按照默认多进位计算】
    const getx = _.get(params, '[0].name', '');
    const times = _.get(props, 'datas.timeList', []);
    const isNotToday = analysisState.dateType !== 'TODAY';
    let finalString = '';
    params.map(n => {
      const ffidx = _.findIndex(times, o => o === n.name); //用time找到index
      const gKey = TOOLTIP_SHOW_OBJ_2[n.seriesName]; // 通过seriesName的中文名称找到key
      const gdata = _.get(analysisState.otherList, `[${ffidx}][${gKey}]`, undefined) // 取值
      const nval = pageKeys === 'lists' ? _.round(gdata, SHOW_CARRY) : undefined; //原始返回数据,value是经过累加等计算值，所以获取原始值
      const renderValue = _.round(n.value, SHOW_CARRY)
      let extraStringValue = ffidx !== -1 && nval !== undefined && nval !== NaN ? '  ( ' + nval + ' )' : '';
      // 几日平均中增加涨停个数字段；中午名找到key,并查找对应数据
      if (RATE_KEYS_OBJ.week.indexOf(gKey) !== -1 && ffidx !== -1 && nval !== undefined) {
        const getExtra = _.get(analysisState.otherList, `[${ffidx}][${WEEK_LIMIT_NUMBER[n.seriesName]}]`, 0)
        extraStringValue = `【${nval} | ${getExtra}个】`
      }
      // 指数和收益率需要显示 点位上原本数据内容
      const curIdx = ffidx + 1;
      const curValp = _.get(analysisState.pointsNet, `[${ffidx}]`, 0); // 收益率数据直接使用ffidx就是对应的数据位，【tips】不是实时收益率数组大小比时间数组少一位
      const curVali = _.get(analysisState.idxNet, `[${curIdx}]`, 0); // 不是TODAY的数据，第一位是【前一交易日】，所以ffidx通过时间查找的index+1才是当前数据
      if (gKey === 'pointList' && ffidx !== -1 && isNotToday) {
        extraStringValue = `(日:${_.round(curValp, SHOW_CARRY)}%)`
      }
      if (n.seriesName === analysisState.idxName && ffidx !== -1 && isNotToday) {
        extraStringValue = `(日:${_.round(curVali, SHOW_CARRY)}%)`
      }
      if (n.seriesName === '超额收益率' && isNotToday) {
        // 括号里是当前点位的超额收益率，不进行累加； 所以需要拿到当天收益率和index进行相减；
        extraStringValue = `(日:${_.round(curValp - curVali, SHOW_CARRY)}%)`
      }
      if (_.includes(n.seriesName, '(超额)')) {
        extraStringValue = '';
      }
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${renderValue}%</span>    
                ${!!isNotToday ? extraStringValue : ''}` // 实时无需展示额外括号数据
        + '<br/>'
    })
    // ！！ 暂时 没和swith开关进行联动，因该function内获取showUpDown数据一直等于false ！！
    let fIndex = _.findIndex(times, o => o === getx); // 验证相同时间的数据
    if (fIndex !== -1 && role !== 'visitor' && pageKeys === 'lists') {
      finalString = finalString + '<br/>' // 仿照<span>配置style，会有显示不全的情况，所以样式暂时只用普通样式。
      TOOLTIP_SHOW_LIST.map(n => {
        const ovalue = _.get(analysisState.otherList, `[${fIndex}][${n.key}]`, '');
        finalString = finalString + `${n.title}: ${ovalue}${_.includes(n.key, 'Rate') && ovalue !== '' ? '%' : ''}` + '<br/>';
      })
    }
    return finalString;
  }
  // 渲染x轴数据
  function renderRateTimes() {
    let newTimeList = _.get(props, 'datas.timeList', []);
    // 除了实时都会进行加0处理，0代表前一交易日开始计算，第二位从newXData数据开始，保证收益率曲线统计的正确性
    if (activeKey !== '1') {
      newTimeList = ['前一交易日', ...newTimeList];
    }
    return newTimeList
  }
  // range范围与最大最小值重合时，返回true，说明当前为全部时间轴
  function isFullTimeRange() {
    const sliderValueOne = _.get(sliderValue, '[0]', 0);
    const sliderValueTwo = _.get(sliderValue, '[1]', 0);
    const timeValueFirst = _.get(timeArr, '[0]', 0);
    const timeValueLast = _.last(timeArr);
    let finalBool = false;
    if (sliderValueOne === timeValueFirst && sliderValueTwo !== 0 && timeValueLast && timeValueLast === sliderValueTwo) {
      finalBool = true;
    }
    return finalBool
  }
  // range 最小值为0时，收盘价无需修改
  function isSliderFirstZero() {
    const sliderValueOne = _.get(sliderValue, '[0]', 0);
    const timeValueFirst = _.get(timeArr, '[0]', 0);
    let finalBool = false;
    if (sliderValueOne === timeValueFirst) {
      finalBool = true;
    }
    return finalBool
  }

  // __________________________________________切换tab和重新加载图表数据时调用____________________________________________
  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart;
    let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
      myChart2.dispose();
    }

    myChart = echarts.init(document.getElementById('echart'));
    myChart2 = echarts.init(document.getElementById('echart_retreat'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    const pmsDateType = _.get(props, 'pms.dateType', '');
    myChart.on('click', 'series.line', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        if (pmsDateType !== 'TODAY') {//跳转至指定日期的分时数据
          props.checkDayRate('11', param.name);
          setActiveKey('1'); // 更新页面active状态；
          return;
        }
        // 点击涨停文字，弹出具体票信息
        const getx = _.get(param, 'data.xAxis', '');
        const filters = _.filter(markRef.current, o => o.xAxis === getx);
        if (_.size(filters) > 0) {
          notification.open({
            key: 'limit_notice',
            message: '涨停票',
            description: <div>
              {filters.map((n, i) => <>
                <Text key={'l' + i} type='secondary'>{`${n.xAxis} : `}</Text>
                <Text strong>{n.value}</Text>
                <Text>{`-${renderText(_.get(n, 'plate'))}-${renderText(_.get(n, 'industry'))}`}</Text>
                <br />
              </>)}
            </div>,
            placement: 'bottom',
          });
        }
      }, 600);
    });

    let newOption = option; let newOption2 = option2;
    let maxValue = 0; let minValue = 0;
    let maxValue2 = 0; let minValue2 = 0;
    let sArray = []; // slider所需的value数据，纯数字，包含slider的最大最小值;

    const newSeriesData = _.get(props, 'datas.newData', {}); // 已处理及计算的所有数据；
    const xdata = _.get(props, 'datas.timeList', []);
    const newTimes = renderRateTimes();
    const pointsData = _.get(newSeriesData, 'pointList', []);
    // console.log('newSeriesData', newSeriesData)

    if (_.size(pointsData) > 0 && _.size(xdata) > 0 && _.size(_.get(props, 'indexValue', {})) > 0) {
      markRef.current = _.get(newSeriesData, 'fullMarks', []);
      analysisState.otherList = _.get(newSeriesData, 'other', []);
      analysisState.pointsNet = _.get(newSeriesData, 'pointsNetList', []);
      analysisState.points = pointsData;
      analysisState.dateType = pmsDateType;
      // 收益率：
      newOption.xAxis.data = newTimes
      newOption.series[0].data = pointsData;
      const getMarks = _.get(newSeriesData, 'limitMarks', []);
      if (_.size(getMarks) > 0 && switchAll['updown_stock']) { // 涨停票显示文字
        newOption.series[0].markPoint.data = getMarks;
      }
      // 多种收益率曲线计算：
      CHECK_TAG_ARRAY.map(c => {
        let isShow = pageKeys === 'lists' && switchAll[c.key] ? true : false;
        const newRateOption = handleRateOption(
          c.keyArray,
          newOption,
          newSeriesData,
          subValues,
          0,
          [0, _.last(timeArr)],
          [maxValue, minValue],
          isShow ? 'full' : 'emp'
        )
        maxValue = newRateOption.max;
        minValue = newRateOption.min;
        newOption = newRateOption.options;
      });
      // 指数：
      //console.log('收盘价/计算后收盘价', indexPreClose, newCloseValue)
      // 处理指数数据；
      const newIndexs = handleIndexValue(pmsDateType, newTimes, { data: _.get(props, 'indexValue') });
      let indexValues = newIndexs.indexValues;
      // let pureValue = newIndexs.pureValue;
      let priceValues = newIndexs.priceValues;
      sArray = newIndexs.stimeArr;
      setTimeArr(sArray);
      setTimeNameArr(newTimes);
      // 赋值slider的滑动数据
      if (_.last(sArray)) {
        setSliderValue([0, _.last(sArray)]);
        setSliderValue2([0, _.last(sArray)]);
      }

      analysisState.idx = indexValues;
      analysisState.idxNet = newIndexs.dayPriceVals;
      newOption.legend.data[1] = INDEX_CODE_VALUE[idValue];
      newOption.series[1].data = indexValues;
      newOption.series[1].name = INDEX_CODE_VALUE[idValue];
      analysisState.idxName = INDEX_CODE_VALUE[idValue];
      newOption.legend.data[1] = '波幅';
      newOption.legend.data[2] = COMMON_CHART.extraRate.name;
      // 所有超额收益率计算
      newOption = handleExtraOption(
        subValues,
        newOption,
        [minValue, maxValue],
        _.get(switchAll, 'extra_switch', false)
      );
      analysisState.extraList = _.get(newOption, 'series[2].data', []);
      // 回撤部分
      const idxRetreat = handleRetreat(priceValues); // 指数计算*100
      const getPointRetreat = _.get(newSeriesData, 'pointRetreat', []);
      const extraRetreat = arraysMinus2(getPointRetreat, idxRetreat);
      newOption2.xAxis.data = newTimes
      newOption2.series[0].data = getPointRetreat;
      newOption2.series[1].data = idxRetreat;
      newOption2.series[2].data = extraRetreat;
      maxValue2 = calMaxMin(_.concat(idxRetreat, getPointRetreat, extraRetreat), maxValue2, 'max');
      minValue2 = calMaxMin(_.concat(idxRetreat, getPointRetreat, extraRetreat), minValue2, 'min');
      newOption2.yAxis.min = minValue2;
      newOption2.yAxis.max = maxValue2;
    }
    // 实时不显示symbol
    if (pmsDateType === 'TODAY') {
      newOption.series[0].symbol = 'none'
    } else {
      newOption.series[0].symbol = 'circle'
    }
    //formatter： 初始option创建时数据取的是初始值
    newOption.tooltip.formatter = renderFormatter; //重新赋值formatter,这样可以获取最新state的数据
    // console.log('newOption', newOption)
    if (_.get(props, 'currentTabs', '1') === '8') {
      // 今年以来进入处理function，处理完进入本地updateCount进行更新裁剪数据
      handleFromYear(newTimes, sArray);
    }
    // 动态记录最后一个点位数值
    analysisState.lastPoint = _.last(_.get(newOption, 'series[0].data', []));
    analysisState.lastIdx = _.last(_.get(newOption, 'series[1].data', []));
    analysisState.lastExtra = _.last(_.get(newOption, 'series[2].data', []));

    setoption(newOption);
    setoption2(newOption2);
    myChart.setOption(newOption, true);
    myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize();
    myChart2.resize();
  }, [props.upcount]);

  // slider 完成后调用，updateCount更新调用
  useUpdateEffect(() => {
    let myChart = props.myChart;
    let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('echart'));
    myChart2 = echarts.init(document.getElementById('echart_retreat'));
    let newOption = option; let newOption2 = option2;

    const pmsDateType = _.get(props, 'pms.dateType', '');
    const isNotToday = pmsDateType !== 'TODAY' ? true : false;
    const newSeriesData = _.get(props, 'datas.newData', {});
    const pointsData = _.get(newSeriesData, 'pointList', []);
    const getMarks = _.get(newSeriesData, 'limitMarks', []);
    const pointsNet = calNets(pointsData); // 收益率计算波幅需按照每个收益率的净值计算 : 1*(1+(净值/100))
    const newTimes = renderRateTimes();
    const getIndexPoint = _.get(props, 'indexValue.pointList', []);
    const indexTimes = _.get(props, 'indexValue.timeList', []);
    const indexPreClose = _.get(props, 'indexValue.preClose', 0);
    const newIndexClose = calTimeDiff(newTimes, indexTimes, getIndexPoint, indexPreClose, isNotToday);
    const isFullRange = isFullTimeRange(); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFisrtZero = isSliderFirstZero(); //sliderValue[0] !== 0时才需要截取数据，右侧滑动时无需重更改startVal值
    const newIndexs = handleIndexValue(pmsDateType, newTimes, { data: _.get(props, 'indexValue') });
    let indexPrice = newIndexs.priceValues; // 只获取价格
    // 根据slider的结果截取数据；
    const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]); // 净值slice
    let sliceIndexPriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]); // 指数价格slice
    const indexStartVal = (isFullRange || isFisrtZero) ? newIndexClose : _.get(sliceIndexPriceOrg, '[0]', 0); //指数收盘价取值
    // 根据截取indexValue及point收益率 数据渲染指数波幅
    let indexRate = calRate(indexPrice, newIndexClose); // 计算完整的指数波幅数据
    let sliceIndexRate = calRate(sliceIndexPriceOrg, indexStartVal); // 指数波幅slice
    let sliceRates = renderSlice(pointsNet, sliderValue[0], sliderValue[1]);
    const idxKey = parseFloat(sliderValue[0]) === 0 ? 1 : 0; // 收益率默认第一位为0，所以slider首位为0的时候要提取第二位数据
    const getStart = parseFloat(_.get(sliceRates, `[${idxKey}]`, 0));
    sliceRates = calRate(sliceRates, getStart);
    // 非实时数据第一位置为0,
    if (isNotToday) {
      indexRate[0] = 0; sliceIndexRate[0] = 0;
      sliceIndexPriceOrg[0] = indexStartVal;
    }
    newOption.series[0].data = isFullRange ? pointsData : sliceRates; // 全时间段无需使用净值数据
    newOption.xAxis.data = isFullRange ? newTimes : renderSlice(newTimes, sliderValue[0], sliderValue[1])
    newOption.series[1].data = isFullRange ? indexRate : sliceIndexRate;

    if (_.size(getMarks) > 0 && switchAll['updown_stock']) {
      newOption.series[0].markPoint.data = getMarks;
    } else {
      newOption.series[0].markPoint.data = [];
    }

    let minVal = 0; let maxVal = 0;
    let typeKey = isFullRange ? 'full' : 'slice'; // 全数据通过key=full，渲染时不做计算处理
    // 涨跌停幅收益率：根据开关生成数据，每次都重新计算option及最大最小值；
    CHECK_TAG_ARRAY.map(c => {
      let isShow = pageKeys === 'lists' && switchAll[c.key] ? true : false;
      const newRateOption = handleRateOption(
        c.keyArray,
        newOption,
        newSeriesData,
        subValues,
        idxKey,
        [sliderValue[0], sliderValue[1]],
        [maxVal, minVal],
        isShow ? typeKey : 'emp'
      )
      maxVal = newRateOption.max;
      minVal = newRateOption.min;
      newOption = newRateOption.options;
    })
    //所以数据都需要增加超额计算，故统一对option进行处理;
    newOption = handleExtraOption(
      subValues,
      newOption,
      [minVal, maxVal],
      _.get(switchAll, 'extra_switch', false)
    );
    // 动态记录最后一个点位数值
    analysisState.lastPoint = _.last(_.get(newOption, 'series[0].data', []));
    analysisState.lastIdx = _.last(_.get(newOption, 'series[1].data', []));
    analysisState.lastExtra = _.last(_.get(newOption, 'series[2].data', []));
    // 回撤部分
    const getPointRetreat = isFullRange ? _.get(newSeriesData, 'pointRetreat') : handleRetreat(sliceRateOrg);
    const idxRetreat = handleRetreat(sliceIndexPriceOrg)
    const extraRetreat = arraysMinus2(getPointRetreat, idxRetreat);
    newOption2.series[0].data = getPointRetreat
    newOption2.series[1].data = idxRetreat
    newOption2.series[2].data = extraRetreat
    newOption2.xAxis.data = isFullRange ? newTimes : renderSlice(newTimes, sliderValue[0], sliderValue[1])
    newOption2.yAxis.min = calMaxMin(_.concat(idxRetreat, getPointRetreat, extraRetreat), 0, 'min');
    newOption2.yAxis.max = calMaxMin(_.concat(idxRetreat, getPointRetreat, extraRetreat), 0, 'max');;
    // console.log('newOption', newOption)
    setoption(newOption);
    setoption2(newOption2);
    myChart.setOption(newOption, true);
    myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize();
    myChart2.resize();
  }, [updateCount])
  // 切换tab
  function _cutDateType(key) {
    const curTabs = _.get(props, 'currentTabs');
    setSliderValue([]);
    // setUpdateCount(updateCount + 1);
    setActiveKey(key);
    if (key === curTabs) return;
    if (props.cutDateType) props.cutDateType(key);
  }
  // 处理今年以来数据及时间
  function handleFromYear(newTime, tarray) {
    const getYear = moment().year();
    let fIndex = -1; // 按顺序找到第一个与今年同年的日期，因不确定今年第一个交易日是哪天
    for (let index = 0; index < _.size(newTime); index++) {
      const sYear = _.split(newTime[index], '-')[0];
      if (getYear === parseInt(sYear)) {
        fIndex = index;
        break;
      }
    }
    // 有第一个值直接更新slider及图表
    if (fIndex !== -1 && newTime[fIndex - 1] !== '前一交易日') {
      setSliderValue([fIndex - 1, _.last(tarray)]);
      setSliderValue2([fIndex - 1, _.last(tarray)]);
      setUpdateCount(updateCount + 1);
    }
  }
  // 分类tag的开关处理
  function handleSubItemChange(checked, keyarray) {
    const keyss = keyarray.map(k => k.key); //拿到当前分类所有key值
    let temp = _.cloneDeep(subItems); //处理checkbox的数组，开启该分类才会显示选项；
    //处理选择项，默认选择分类内的所有key数据
    let tempCheck = _.cloneDeep(subValues);
    if (checked) { //【原】默认选中;【改】为默认不选择
      temp = _.concat(temp, keyarray);
      // tempCheck = _.uniq(_.concat(tempCheck, keyss));
    } else {
      _.remove(temp, o => keyss.indexOf(o.key) !== -1 ? true : false);
      _.pullAll(tempCheck, keyss);
    }
    setSubItems(temp);
    // setSubValues(tempCheck);
    setUpdateCount(updateCount + 1);
  }
  // 收益率显示开关控制
  const onSwitchChange = (checked, key, kArray) => {
    let temp = _.cloneDeep(switchAll);
    temp[key] = checked;
    setSwitchAll(temp);
    if (key === 'updown_stock' || key === 'extra_switch') { //extra_switch 是超额数据计算开关
      setUpdateCount(updateCount + 1);
    } else { // kArray 是该分类的原始配置的完整数组
      handleSubItemChange(checked, kArray);
    }
  }
  // checkbox.group 修改选择项
  const onSubValuesChange = (checkedValues) => {
    setSubValues(checkedValues);
    setUpdateCount(updateCount + 1);
  };
  // 自定义分组修改选择，filterKey数组中包含该key值则筛选出来
  const onSubValuesGroupChange = (key) => {
    if (key === 'all') { // 全选操作
      setSubValues(subItems.map(f => f.key));
    } else if (key === 'clear') { // 清除
      setSubValues([]);
    } else {
      const filterArray = _.filter(subItems, o => _.includes(o.filterKey, key));
      setSubValues(filterArray.map(f => f.key));
    }
    setUpdateCount(updateCount + 1);
  };

  const getPmsDate = _.get(props, 'pms.dateType', ''); //时间周期type
  const getPmsDateVal = _.get(props, 'pms.date', ''); // 日期
  const formatter = (value) => timeNameArr[value] || 'none';
  const getCurTabs = _.get(props, 'currentTabs', '1'); //当前tab，active值；父组件控制active参数
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, paddingLeft: 8, paddingRight: 8 };
  const columns = [...createBasicColums(LIMIT_COL_LIST, [1, 'strong'])];
  const renderPickerDate = getPmsDateVal !== '' ? moment(getPmsDateVal) : null;
  const getValidArray = _.get(props, 'validDateRange', []); // 有数据的时间array
  const disabledDate = (current) => { //getValidArray中未出现的时间数据不显示，区第一位和最后一位作为区间；最后一位获取时处理为当天
    if (_.size(getValidArray) === 0 || !_.last(getValidArray) || !_.head(getValidArray)) {
      return false;
    }
    const tooLate = current.diff(_.last(getValidArray), 'days') > 0;
    const tooEarly = current.diff(_.head(getValidArray), 'days') < 0;
    return !!tooEarly || !!tooLate;
  };
  const validIdx = getValidArray.indexOf(getPmsDateVal); // 当前日期在数组中的index
  const disLeft = validIdx <= 0 ? true : false; // 到数组最左侧禁止点击‘前一日’按钮
  const disRight = validIdx === (_.size(getValidArray) - 1) && validIdx !== -1 ? true : false; // 到数组最右侧禁止点击‘后一日’按钮
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          <Tabs
            //centered
            activeKey={getCurTabs}
            onChange={_cutDateType}
            tabPosition='top'
            tabBarExtraContent={{
              right: <Select size='small' value={idValue} style={{ width: 120 }}
                onChange={(v) => {
                  props.indexChange(v)
                  setIdValue(v);
                }}
                options={role !== 'visitor' ? INDEX_OPTIONS : _.filter(INDEX_OPTIONS, o => o.label === '基础')}
              />
            }}
          >
            <TabPane tab="实时" key='1'></TabPane>
            <TabPane tab="一周" key='2'></TabPane>
            <TabPane tab="一月" key='3'></TabPane>
            <TabPane tab="三月" key='4'></TabPane>
            <TabPane tab="六月" key='5'></TabPane>
            <TabPane tab="今年以来" key='8'></TabPane>
            <TabPane tab="一年" key='6'></TabPane>
            <TabPane tab="三年" key='7'></TabPane>
          </Tabs>
        </Col>
      </Row>

      <Card
        bordered={false}
        bodyStyle={{ padding: props.cardPadding ? props.cardPadding : 10 }}
      >
        <Row style={{ textAlign: 'left', height: 35 }} justify='space-between'>
          <Col span={12}>
            <Space>
              {getCurTabs === '1' && pageKeys === 'lists' && <Button
                size='small'
                icon={<LeftOutlined />}
                type='text'
                disabled={disLeft}
                onClick={() => {
                  props.checkDayRate('11', getValidArray[validIdx - 1])
                }}
              />}
              {getCurTabs === '1' && pageKeys === 'products' && <Text strong style={{ fontSize: 16, marginLeft: 15 }}>{_.get(props, 'pms.date', '')}</Text>}
              {getCurTabs === '1' && pageKeys === 'lists' && <DatePicker
                size='small'
                disabledDate={disabledDate}
                value={renderPickerDate}
                onChange={(date, dateString) => {
                  props.checkDayRate('11', dateString);
                }}
              />}
              {getCurTabs === '1' && pageKeys === 'lists' && <Button
                size='small'
                icon={<RightOutlined />}
                type='text'
                disabled={disRight}
                onClick={() => {
                  props.checkDayRate('11', getValidArray[validIdx + 1])
                }}
              />}
            </Space>
            {getCurTabs === '1' && <Divider type='vertical' style={{ marginLeft: 15, marginRight: 15 }} />}
            <Space size='large'>
              <Text>收益率：<Text strong style={{ color: '#5470c6' }}>{isValidRate(analysisState.lastPoint)}</Text></Text>
              <Text>指数：<Text strong style={{ color: '#B5495B' }}>{isValidRate(analysisState.lastIdx)}</Text></Text>
              <Text>超额：<Text strong style={{ color: '#ffa600' }}>{isValidRate(analysisState.lastExtra)}</Text></Text>
            </Space>
          </Col>
          {pageKeys === 'lists' && role !== 'visitor' && <Col span={12} style={{ textAlign: 'right' }}>
            <Space>
              {CHECK_TAG_ARRAY.map((c) => {
                const getKey = _.get(c, 'key', null);
                if (getKey === 'text_1') {
                  return <Text key={getKey} style={{ marginLeft: 8 }}> 收益率：</Text >;
                } else {
                  return <CheckableTag
                    key={getKey}
                    checked={switchAll[getKey]}
                    onChange={(checked) => onSwitchChange(checked, getKey, _.get(c, 'keyArray', []))}
                  >
                    {c.name}
                  </CheckableTag>
                }
              })}
            </Space>
          </Col>}
        </Row>
        {pageKeys === 'lists' && _.size(subItems) > 0 && <Row gutter={[8, 8]} style={controlBarStyle}>
          <Col span={24} style={{ paddingTop: 6 }}>
            <Space>
              <Text>快捷方式：</Text>
              {switchAll['updown'] || switchAll['two_day'] ? SORT_BTN.map((s, i) => {
                let subBtnProps = { size: 'small', type: 'primary', ghost: true };
                if (s.key === 'all' || s.key === 'clear') {
                  subBtnProps = { size: 'small', type: s.type };
                }
                return <Button key={'sortBtn' + i} {...subBtnProps} onClick={() => onSubValuesGroupChange(s.key)}>
                  {s.title}
                </Button>
              }) : <></>}
              {switchAll['updown'] || switchAll['two_day'] ? <Divider type='vertical' /> : <></>}
              <Switch
                checkedChildren="超额"
                unCheckedChildren="超额"
                checked={_.get(switchAll, 'extra_switch', false)}
                onChange={(checked) => onSwitchChange(checked, 'extra_switch')}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={subValues}
              onChange={onSubValuesChange}
            >
              <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
                {subItems.map((itm, i) => {
                  return <Col span={3} key={i}>
                    <Checkbox value={_.get(itm, 'key', '')}>{_.get(itm, 'name')}</Checkbox>
                  </Col>
                })}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>}
        <div style={{ display: 'flex' }}>
          <div
            id="echart"
            style={{
              width: props.dataSet !== false ? '70%' : '100%',
              height: 450
            }}
          />
        </div>
        <br />
        {getPmsDate !== 'TODAY' && <Row>
          <Col span={6}>
            <Text strong>开始日期: {_.get(timeNameArr, `[${sliderValue[0]}]`, '')}</Text>
          </Col>
          <Col span={12}>
            <Slider
              min={0}
              max={_.last(timeArr) || 0}
              range={{
                draggableTrack: true,
              }}
              tipFormatter={formatter}
              value={sliderValue2}
              onChange={(v) => setSliderValue2(v)}
              onAfterChange={(v) => {
                setSliderValue(v);
                setSliderValue2(v);
                setUpdateCount(updateCount + 1);
              }}
            />
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Text strong>结束日期: {_.get(timeNameArr, `[${sliderValue[1]}]`, '')}</Text>
          </Col>
        </Row>}

        <div style={{ display: 'flex', marginTop: 25, marginBottom: 15 }}>
          <div
            id="echart_retreat"
            style={{
              width: '100%',
              height: 450
            }}
          />
        </div>

        {_.size(markRef.current) > 0 && <>
          <br />
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
          >
            <Collapse.Panel header="涨停票" key="1" className="site-collapse-custom-panel">
              <Table
                rowKey="name"
                dataSource={markRef.current}
                columns={columns}
                bordered
                size='small'
                // scroll={{ y: 1400 }}
                pagination={{ defaultPageSize: 5 }}
              />
            </Collapse.Panel>
          </Collapse>
        </>}
      </Card>
    </div>
  )
}
import React, { useState } from 'react';
import { PageHeader, Tabs, Space } from 'antd';
import { AccountBookOutlined } from '@ant-design/icons';
import { getAccountSubs } from '@/api/workbench';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useMount } from 'ahooks';
import Records from './Fund/Records';
import FundTransfer from './Fund/FundTransfer';
import SecurityTransfer from './Fund/SecurityTransfer';
import _ from 'lodash';
import './fund.scss';

const { TabPane } = Tabs;

export default function FundAdm(props) {
  const mobxStore = useLocalObservable(() => store);
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)));
  const [subList, setsubList] = useState(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
  const admState = useReactive({ active: '1', upcount: 0, selectTxt: '' })

  useMount(() => {
    _getAcc();
  })

  async function _getAcc() {
    const data = await getAccountSubs();
    // console.log('subs', data);
    if (_.get(data, 'code', '') === '200') {
      // 将证券集合数据加工成适配级联选择器的数据 value为key
      let arr = data.data; let secOptions = [];
      arr.map((item, key) => {
        item.label = item.productName
        item.value = item.productId
        item.children = item.securitiesAccountList;
        secOptions.push({
          ...item, children: item.children.map(itm2 => {
            Reflect.deleteProperty(itm2, 'children')
            return { ...itm2, 'label': itm2.securitiesAccountName, 'value': itm2.securitiesAccountId }
          })
        });
        item.children.map((item2, key2) => {
          item2.label = item2.securitiesAccountName
          item2.value = item2.securitiesAccountId;
          item2.children = item2.subAccountList
          item2.children.map((item3, key3) => {
            item3.label = item3.subAccountName;
            item3.value = item3.subAccountId;
            return item3
          })
          return item2
        })
        return item;
      })
      mobxStore._mobxAccountSubs(arr);
      setsubList({ 'full': arr, 'sec': secOptions });
    }
  }

  function _goFundManagerListsDetails(record) {
    record.subAccountName = record.name;
    record.subAccountId = record.id;
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/listsDetails',
      state: { subAccountInfo: record }
    });
  }
  return (
    <Observer>
      {() => (
        <>
          <PageHeader
            title={<Space><AccountBookOutlined /><span>资金管理</span></Space>}
            style={{ backgroundColor: 'white', marginBottom: 10 }}
          ></PageHeader>

          <div className='fundTab'>
            <Tabs activeKey={admState.active} onChange={(v) => admState.active = v} type='card'>
              <TabPane tab='历史托管记录' key="1">
                <Records
                  options={_.get(subList, 'full', [])}
                  goManager={(record) => _goFundManagerListsDetails(record)}
                />
              </TabPane>
              <TabPane tab='账户资金' key="2">
                <FundTransfer
                  options={_.get(subList, 'full', [])}
                  onListUpdate={() => _getAcc()}
                />
              </TabPane>
              <TabPane tab='证券资金' key="3">
                <SecurityTransfer
                  options={_.get(subList, 'sec', [])}
                  userInfo={userInfo}
                  onListUpdate={() => _getAcc()}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Observer>
  )
}
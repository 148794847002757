import React, { useEffect, useState } from 'react';
import { Card, Row, Col, DatePicker, Spin } from 'antd';
import * as echarts from 'echarts';
import { colorArray } from '../../common/colorArray';
import moment from 'moment';
import _ from 'lodash';

const { RangePicker } = DatePicker;
export default function EchartsInfo8(props) {
    const [selectTimes, setSelectTimes] = useState([]);
    const [option, setoption] = useState({
        xAxis: {
            type: 'category',
            name: '时间',
            data: [],
            splitLine: {
                show: false
            },
            boundaryGap: false,
            axisTick: {
                show: false
            },
            axisLine: {
                show: true,
                symbol: ['none', 'arrow'],
                symbolSize: [8, 8],
                symbolOffset: 7,
                onZero: false,
            },
        },
        yAxis: [
            {
                type: 'value',
                name: '贡献度(%)',
                position: 'left',
                axisLine: {
                    show: true,
                    symbol: ['none', 'arrow'],
                    symbolSize: [8, 8],
                    symbolOffset: 7,
                },
                splitArea: {
                    show: true
                },
                splitLine: {
                    show: false
                },
            }
        ],
        series: [],
        tooltip: {
            trigger: 'axis',
            showDelay: 0,
            axisPointer: {
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                }
            },
            padding: 5,
        },
        legend: {
            type: 'scroll',
            selectedMode: 'multiple',
        },
    });
    function updateTime(e, dateStrings, info) {
        setSelectTimes(dateStrings);
        const calType = _.get(info, 'range', '');
        if (calType === 'end') {
            props.onTimeChange(dateStrings);
        }
    }

    function handleSeriesData() {
        let tempData = _.cloneDeep(_.get(props, 'datas.datas', []));
        let getNameObj = _.get(props, 'datas.nameObj', {});
        let colorCount = 0;
        let finalData = _.isArray(tempData) && _.size(tempData) > 0 ? tempData.map((child, index) => {
            let newData = child.data;
            let ceilData = newData.map(n => _.round(n, 5));
            if (index > 19) {
                colorCount = colorCount + 1
            }
            if (colorCount === 19) {
                colorCount = 0;
            }
            return {
                ...child,
                name: getNameObj[child.name] || child.name,
                itemStyle: {
                    normal: {
                        color: index > 19 ? colorArray[colorCount] : colorArray[index],
                        lineStyle: {
                            color: index > 19 ? colorArray[colorCount] : colorArray[index]
                        }
                    }
                },
                data: ceilData
            }
        }) : []
        return finalData;
    }

    useEffect(() => {
        //console.log('个股盈亏chart:', props)
        let myChart = props.myChart;
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose();//销毁
        }

        myChart = echarts.init(document.getElementById('echart8'));
        myChart.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });
        /**
         * 选取当天复选账户内，绝对值波动最大的数值（data数组内的数据），选取所有复选账户内波动最大前20个复选账户
         */
        let newOption = option;
        newOption.xAxis.data = _.get(props, 'datas.timeList', []);
        newOption.series = handleSeriesData();
        setoption(newOption);
        myChart.setOption(newOption, true);
        myChart.hideLoading();
        myChart.resize();

    }, [props.isUpdate])

    useEffect(() => {
        setSelectTimes(props.time2);
    }, [props.upTimeCount])

    const disabledDate = current => {
        const futureDay = current >= moment().endOf('day')
        return futureDay;
    };

    const newRangeValue = !selectTimes[0] || !selectTimes[1] ? null : [moment(selectTimes[0]), moment(selectTimes[1])];

    return (
        <Row style={{ backgroundColor: '#fff', padding: 5 }}>
            <Col span={24}>
                <Spin spinning={_.get(props, 'loading', false)}>
                    <Card bordered={false} title={props.title} extra={
                        <Row justify='end'>
                            <span style={{ marginRight: 10, marginTop: 5 }}>选择日期:</span>
                            <RangePicker
                                value={newRangeValue}
                                format="YYYY-MM-DD"
                                onCalendarChange={updateTime}
                                disabledDate={disabledDate}
                            />
                        </Row>
                    }>
                        <div
                            id="echart8"
                            style={{
                                width: '100%',
                                height: props.height ? props.height : 450
                            }}
                        />
                    </Card>
                </Spin>
            </Col>
        </Row>
    )
}
import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    Form,
    Typography,
    InputNumber,
    Select,
    message,
    Tabs,
    Table,
    Tooltip,
    Radio,
    Space,
    Divider,
} from 'antd';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAutoTradingRule, cancelAutoTrading, setAutoTradingRule } from '@/api/stock';
import { showSubAccountStrategy } from '@/api/strategy';
import { showAutoTradeRecords } from '@/api/userSystem';
import moment from 'moment';
import _ from 'lodash';

const { Text, Title } = Typography;

const renderBuySellRate = (rate) => {
    return rate !== 0 ? rate * 100 : 0
}

const columns = [
    {
        title: '股票代码',
        dataIndex: 'stockCode',
        key: 'stockCode',
        width: 80,
    },
    {
        title: '规则信息',
        dataIndex: 'remarks',
        key: 'remarks',
        width: 150,
        render: (text, record) => {
            return <ul style={{ textAlign: 'left' }}>
                {_.size(text) > 0 && text.map((item, index) => {
                    return <li key={'remarks' + index}>{item}</li>
                })}
            </ul>
        }
    },
    {
        title: '托管方向',
        dataIndex: 'direction',
        key: 'direction',
        width: 80,
        render: (text, record) => (text === 0 ? '关闭' : '开启')
    },
    {
        title: '详情',
        dataIndex: 'tradeType',
        key: 'tradeType',
        align: 'left',
        width: 200,
        render: (text, record) => {
            let textComp = {
                balance: _.get(record, 'balance') || '0',
                amount: _.get(record, 'stockAmount') || '0',
            };
            if (text === 1) {
                textComp.title = '按比例'
                textComp.buy = renderBuySellRate(_.get(record, 'tradeRate'));
                textComp.sell = renderBuySellRate(_.get(record, 'sellRate'));
            } else if (text === 2) {
                textComp.title = '按数量'
                textComp.buy = _.get(record, 'buyAmount') || '0'
                textComp.sell = _.get(record, 'sellAmount') || '0'
            }
            return <Space direction='vertical' size='small'>
                <span>{_.get(textComp, 'title')}</span>
                <div>
                    <span>{'买入: '}<Text strong style={{ color: 'red' }}>{_.get(textComp, 'buy') + (textComp.title === '按比例' ? '%' : '')}</Text></span>
                    <Divider type='vertical' />
                    <span>{'卖出: '} <Text strong style={{ color: 'green' }}>{_.get(textComp, 'sell') + (textComp.title === '按比例' ? '%' : '')}</Text></span>
                </div>
                <div>
                    <span>{'金额: '}<Text strong>{_.get(textComp, 'balance')}</Text></span>
                    <Divider type='vertical' />
                    <span>{'数量: '} <Text strong>{_.get(textComp, 'amount')}</Text></span>
                </div>
            </Space>;
        }
    },
    {
        title: '操作时间',
        dataIndex: 'operationTime',
        key: 'operationTime',
        width: 150,
        render: (text, record) => (moment(text).format('YYYY-MM-DD HH:mm'))
    },
];

export default function AddProductDetails(props) {
    const mobxStore = useLocalObservable(() => store);
    const [hostingVisible, sethostingVisible] = useState(false);
    const [indicators, setindicators] = useState('');
    const [policy, setPolicy] = useState('');
    const [autoRecord, setAutoRecord] = useState([]);
    const [hostingForm] = Form.useForm();
    const { Option } = Select;
    const { TabPane } = Tabs;


    async function _getSubPolicy() {
        const data = await showSubAccountStrategy({
            subAccountId: _.get(props, 'subAccountInfo.subAccountId', ''),
            symbol: _.get(props, 'record.stockCode', ''),
        })
        if (_.get(data, 'code', '') === '200') {
            setPolicy(data.data || [])
        }
    }
    async function _showAutoTradeRecords() {
        const data = await showAutoTradeRecords({
            subAccountId: _.get(props, 'subAccountInfo.subAccountId', ''),
            stockCode: _.get(props, 'record.stockCode', ''),
        })
        if (_.get(data, 'code', '') === '200') {
            setAutoRecord(data.data || [])
        }
    }

    async function hosting(record) {
        _getSubPolicy();

        const data = await getAutoTradingRule({ subAccountId: props.subAccountInfo.subAccountId, stockCode: record.stockCode });
        if (data.code === '200') {
            hostingForm.setFieldsValue(data.data);
            if (data.data !== null) {
                setindicators(data.data.kdj);
            }
        }
        // 获取托管记录
        _showAutoTradeRecords();

        sethostingVisible(true);
    }
    async function cancelHosting(record) {
        Modal.confirm({
            title: '是否确认取消托管？',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await cancelAutoTrading({ subAccountId: props.subAccountInfo.subAccountId, stockCode: record.stockCode });
                if (data.code === '200') {
                    message.success(data.message === 'success' ? '成功' : data.message);
                    props.getPositionReturn();
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    async function handleOk() {
        const values = await hostingForm.validateFields();
        let params = {
            subAccountId: props.subAccountInfo.subAccountId,
            stockCode: props.record.stockCode,
            kdj: values.kdj,
            tradeType: values.tradeType,
            tradeRate: values.tradeRate ? parseFloat(values.tradeRate) : 0,
            balance: values.balance,
            sellRate: values.sellRate ? parseFloat(values.sellRate) : 0,
            sellAmount: values.sellAmount ? parseInt(values.sellAmount) : 0,
            buyAmount: values.buyAmount ? parseInt(values.buyAmount) : 0,
            strategiesId: _.chain(policy).filter(o => o.strategiesName === values.kdj).head().get('id').value()
        }
        const data = await setAutoTradingRule(params);
        if (data.code === '200') {
            message.success(data.message === 'success' ? '成功' : data.message);
            sethostingVisible(false);
            props.getPositionReturn();
        } else {
            message.error(data.message);
        }
    }

    return (
        <Observer>
            {() => (
                <>
                    {props.record.tradeStatus === 0 ?
                        <Button type={props.isTrusteeship ? 'primary' : 'default'}
                            disabled={props.isLock} onClick={() => {
                                if (props.isTrusteeship) {
                                    hosting(props.record)
                                } else {
                                    message.info('您还未开启托管功能，请购买服务可继续使用！')
                                }
                            }}>托管</Button> :
                        <Tooltip title={'当前托管策略：' + _.get(props, 'record.kdj', '')}>
                            <Button type={props.isTrusteeship ? 'primary' : 'default'}
                                disabled={props.isLock || !props.isTrusteeship}
                                onClick={() => { cancelHosting(props.record) }}>
                                取消托管
                            </Button>
                        </Tooltip>
                    }
                    <Modal
                        title="托管"
                        width={650}
                        visible={hostingVisible}
                        onOk={handleOk}
                        onCancel={() => { sethostingVisible(false) }}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="设置" key="1">
                                <Form
                                    form={hostingForm}
                                    initialValues={{ kdj: indicators, tradeRate: 0.1 }}
                                    onValuesChange={(changeValue) => {
                                        const getTradeType = _.get(changeValue, 'tradeType', 0);
                                        if (getTradeType === '1') {
                                            hostingForm.setFieldsValue({ sellAmount: 0, buyAmount: 0 })
                                        } else if (getTradeType === '2') {
                                            hostingForm.setFieldsValue({ sellRate: 0, tradeRate: 0 })
                                        }
                                    }}
                                >
                                    <Form.Item
                                        label="策略名称"
                                        name="kdj"
                                        rules={[{ required: true, message: '请选择技术指标' }]}
                                    >
                                        <Select placeholder="请选择技术指标" onChange={e => {
                                            setindicators(e)
                                        }}>
                                            {_.size(policy) > 0 && policy.map((n, i) => (
                                                <Option key={'po' + i} value={n.strategiesName}>{n.strategiesName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="托管金额"
                                        name="balance"
                                        rules={[{ required: true }, {
                                            validator: async (a, value) => {
                                                const totalBalance = _.get(props, 'assetData.totalBalance', 0);
                                                const newBalance = _.chain(totalBalance).replace(',', '').toNumber().value();
                                                if (value && value > newBalance) {
                                                    return Promise.reject(new Error('可用资金不足！ 当前可用' + newBalance));
                                                }
                                            }
                                        }]}
                                    >
                                        <InputNumber
                                            min={0}
                                            placeholder="托管金额"
                                            style={{ width: 200 }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="tradeType"
                                        label="交易方式"
                                        rules={[{ required: true }]}
                                    >
                                        <Radio.Group>
                                            <Radio value="1">按比例</Radio>
                                            <Radio value="2">按数量</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues.tradeType !== currentValues.tradeType}
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('tradeType') === '1' ? (
                                                <Form.Item
                                                    label="买入比例"
                                                    name="tradeRate"
                                                    validateTrigger='onBlur'
                                                    rules={[{ required: true }, {
                                                        validator: async (_, value) => {
                                                            if (value) {
                                                                if ((/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(value))) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('请输入数字'));
                                                            }
                                                        }
                                                    }]}
                                                >
                                                    <InputNumber
                                                        min={0.1}
                                                        max={1}
                                                        step={0.1}
                                                        placeholder="请输入买入比例"
                                                        style={{ width: 200 }}
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues.tradeType !== currentValues.tradeType}
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('tradeType') === '1' ? (
                                                <Form.Item
                                                    label="卖出比例"
                                                    name="sellRate"
                                                    rules={[{ required: true }]}
                                                >
                                                    <InputNumber
                                                        min={0.1}
                                                        max={1}
                                                        step={0.1}
                                                        placeholder="请输入卖出比例"
                                                        style={{ width: 200 }}
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues.tradeType !== currentValues.tradeType}
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('tradeType') === '2' ? (
                                                <Form.Item
                                                    name="buyAmount"
                                                    label="买入数量"
                                                    rules={[{ required: true }, {
                                                        validator: async (_, value) => {
                                                            if (value) {
                                                                if ((/^(\d{0,15})00$/.test(value))) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('请输入整百数！'));
                                                            }
                                                        }
                                                    }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        step={100}
                                                        placeholder="请输入买入数量"
                                                        style={{ width: 200 }}
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues.tradeType !== currentValues.tradeType}
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('tradeType') === '2' ? (
                                                <Form.Item
                                                    name="sellAmount"
                                                    label="卖出数量"
                                                    rules={[{ required: true }, {
                                                        validator: async (_, value) => {
                                                            if (value) {
                                                                if ((/^(\d{0,15})00$/.test(value))) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('请输入整百数！'));
                                                            }
                                                        }
                                                    }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        step={100}
                                                        placeholder="请输入卖出数量"
                                                        style={{ width: 200 }}
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                </Form>

                                <div style={{ marginBottom: 8 }}>
                                    <Title level={5} style={{ fontSize: 15 }}>策略描述：</Title>
                                </div>
                                <ul>
                                    {indicators !== '' && _.size(policy) > 0 && _.filter(policy, o => o.strategiesName === indicators).map((n, index) => {
                                        let list = _.get(n, 'strategies', [])
                                        return list.map((l, i) => <li key={'rm' + i}><Text type='secondary'>{'· ' + l.remark}</Text></li>)
                                    })}
                                </ul>

                            </TabPane>
                            <TabPane tab="记录" key="2">
                                <Table
                                    columns={columns}
                                    dataSource={autoRecord}
                                    rowKey="id"
                                    scroll={{ x: 700 }}
                                />
                            </TabPane>
                        </Tabs>

                    </Modal>
                </>
            )}
        </Observer >

    )
}
import React, { useState, useEffect } from 'react';
import { Tabs, Button, Tooltip, Space, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import DisplayCalCharts from './Charts2/DisplayCalCharts';
import DisplayMainCharts from './Charts2/DisplayMainCharts';
import DisplaySortCharts from './Charts2/DisplaySortCharts';
import HighPosCharts from './Charts2/HighPosCharts';
import {
  getPersonalDisplayProductLevel, getPlateDisplayProductLevel, getStocksDisplayProductLevel,
  getPersonalDisplaySubAccountLevel, getPlateDisplaySubAccountLevel, getStocksDisplaySubAccountLevel,
  showPlatePosition, showTradeIndexSort, showTradeIndexSortProduct, getHighPositionDistribution, getPositionDistribution
} from '@/api/workbench';
import { createDatasTimeKey } from '@/utils/utils2';
import { NewRangePicker } from '@/view/common/widgets';
import { useReactive } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';

const SEG_OPTIONS = [{ label: '行业', value: 'industry' }, { label: '板块', value: 'plate' }]
const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGES = [moment().subtract(7, 'd').format(FORMAT), TODAY];
const tabList = [
  { key: '1', tab: '总仓位' },
  { key: '2', tab: '行业仓位' },
  { key: '3', tab: '个股仓位' },
];
const tabList_pro = [{ key: '6', tab: '重仓分布' }, { key: '7', tab: '京沪深分布' }];
const tabList_4 = [
  { key: '4', tab: '仓位统计' },
  { key: '5', tab: '风格仓位' },
  { key: '8', tab: '市值统计' },
];
const GET_TYPE = { 'list': 'subAccountId', 'product': 'productId', 'virtual': 'virtualProductId' };
/**
 *  持仓分布图表，获取及处理数据部分拆分为单独组件使用；
 *  根据pageKey区分产品还是子账户数据
 *  【新】将原本Echartsinfo7图表拆分成3个子图表，更方便拓展，该模块负责获取数据，子图表用于基础展示及简单操作
 */
const DisplayCharts = ({
  pageKey = '',
  active = '1',
  id = 0,
  globalDate = [],
  isVir = false,
  virMode = 0,
  // upTimeCount = 0,
}) => {
  const [updateCount, setUpdateCount] = useState(0);
  const [updateCount2, setUpdateCount2] = useState(0);
  const [updateCount3, setUpdateCount3] = useState(0);
  const [updateCount4, setUpdateCount4] = useState(0);
  const [seriesLevel, setSeriesLevel] = useState({ series: [], legend: [], pie: [] });
  const [loading, setLoading] = useState(false);
  const [currentParams, setCurrentParams] = useState({}); //保存最后一次获取参数
  const [ctab, setCtab] = useState('1');
  const [dateTime, setdateTime] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
  const [pType, setPType] = useState(SEG_OPTIONS[0].value);
  const [serData, setSerData] = useState([]);
  const [symOption, setSymOption] = useState([]);
  const [sortDatas, setSortDatas] = useState({});
  const [highDatas, setHightDatas] = useState({});
  const displayState = useReactive({ highLoading: false });
  const is_products = pageKey === 'product' ? true : false;

  useEffect(() => {
    if (active === 'DISTRIBUTION') {
      let timeArray = [];
      if ('beginDate' in currentParams) {
        timeArray = [currentParams.beginDate, currentParams.endDate];
      } else if (globalDate[0] && globalDate[1]) {
        timeArray = globalDate;
      } else {
        timeArray = [TODAY, TODAY];
      }
      if (is_products || isVir) {
        getDisplayLevelProduct('1', timeArray);
      } else {
        getDisplayLevelList('1', timeArray)
      }
      setCtab('1');
    }
  }, [active])
  // 产品获取
  async function getDisplayLevelProduct(key, time) {
    if (id) {
      setLoading(true)
      let params = {
        [GET_TYPE[pageKey]]: id,
        'beginDate': time[0],
        'endDate': time[1]
      };
      if (isVir) {
        _.set(params, 'mode', virMode);
      }
      setCurrentParams(params);
      if (key === '1') {
        const data = await getPersonalDisplayProductLevel(params, isVir ? 'vir' : 'nor');
        handleAccountLevel(data, '1')
      } else if (key === '2') {
        const data = await getPlateDisplayProductLevel(params, isVir ? 'vir' : 'nor');
        handleAccountLevel(data, '2')
      } else if (key === '3') {
        const data = await getStocksDisplayProductLevel(params, isVir ? 'vir' : 'nor');
        handleAccountLevel(data, '3')
      }
      setLoading(false);
      setUpdateCount(updateCount + 1);
    }
  };
  //子账户获取
  async function getDisplayLevelList(key, times) {
    if (id) {
      setLoading(true)
      let params = {
        subAccountId: id,
        beginDate: times[0],
        endDate: times[1]
      }
      setCurrentParams(params);
      if (key === '1') {
        const data = await getPersonalDisplaySubAccountLevel(params);
        handleAccountLevel(data, '1')
      } else if (key === '2') {
        const data = await getPlateDisplaySubAccountLevel(params);
        handleAccountLevel(data, '2')
      } else if (key === '3') {
        const data = await getStocksDisplaySubAccountLevel(params);
        handleAccountLevel(data, '3')
      }
      setLoading(false);
      setUpdateCount(updateCount + 1);
    }
  }
  // tab = 4 板块统计
  async function _showPlatePosition(type, date) {
    if (id) {
      let params = {
        subAccountId: id,
        beginDate: date ? date[0] : dateTime[0],
        endDate: date ? date[1] : dateTime[1],
        type: type ? type : pType
      }
      const res = await showPlatePosition(params);
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', {}); // {时间(1-1):{板块(电子)：值（1.2）,...},...}
        let timeList = Object.keys(getData).map(n => { // 提取时间
          return { time: n, timeKey: moment().diff(moment(n), 'd') }
        });
        timeList = _.orderBy(timeList, ['timeKey'], ['desc']); // 时间排序
        let plateObj = {}; // 板块数据
        let seriesArray = []; // 图表数据
        let timeArray = [];
        timeList.map((t, index) => {
          timeArray.push(t.time);
          Object.keys(getData[t.time]).map(keyname => {
            if (keyname in plateObj) {
              plateObj[keyname][index] = getData[t.time][keyname];
            } else { // 无板块数据时，创建数据
              plateObj[keyname] = Array(_.size(timeList)).fill(0); // 创建与时间相同大小的array
              plateObj[keyname][0] = getData[t.time][keyname]; //赋值第一个数据
            }
          })
        })
        Object.keys(plateObj).map(keyname => {
          seriesArray.push({//connectNulls: true,
            'name': keyname, 'data': plateObj[keyname].map(n => _.round(n, 4)),
            'type': 'line', 'symbol': 'none', 'maxNum': _.max(plateObj[keyname]),
          })
        });
        seriesArray = _.orderBy(seriesArray, ['maxNum'], ['desc']); // 根据收益率高的进行排序
        let orderKeysPlate = seriesArray.map(n => n.name);
        setSymOption(orderKeysPlate);
        setSerData({
          time: timeArray,
          datas: seriesArray
        });
        setUpdateCount2(_.round(updateCount2 + 0.1, 1));
      }
    }
  }
  // 处理数据Main图表获取数据
  function handleAccountLevel(data, type) {
    if (_.get(data, 'code', '') === '200') {
      if (type === '1') {
        let arr = [];
        let brr = [];
        let crr = [{ name: '总仓位' }, { name: '现金' }];
        let a = ['product', ...data.data.timeList];
        let b = ['总仓位', ...data.data.personalPositionList];
        let c = ['现金', ...data.data.moneyList];
        arr.push(a, b, c);
        let obj1 = { name: '总仓位', type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } };
        let obj3 = { name: '现金', type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } };
        let obj2 = {
          type: 'pie', id: 'pie', radius: '30%', center: ['50%', '25%'], emphasis: { focus: 'self' }, label: { formatter: '{b}: {@2012} ({d}%)' },
          encode: { itemName: 'product', value: a[1], tooltip: a[1] }
        };
        brr.push(obj1, obj3, obj2);
        setSeriesLevel({
          series: brr, legend: crr, pie: arr
        })
      } else {
        const plateList = _.get(data, 'data.data.platePositionList', []);
        //返回数据格式:  [{'其他':0.4,'股票名称':0.2},...{item,keyname=名称，value=值}] 数组大小=点位大小=时间array 
        const timeList = _.get(data, 'data.data.timeList', [])
        let a = ['product', ...timeList]
        let arr = [a];
        let brr = [];
        let crr = [];
        let pieObj = {}; //{名称:['名称',1,2,3,4...]} 用名称创建对应array
        plateList.map((item, index) => {
          Object.keys(item).map((keyname, i) => { //!返回数据不是每个点都有数据，所以要将时间index对应起来，没有数据是'-'
            // ['名称',{key:数据对应的index,value:值}]
            if (pieObj[keyname]) { // 有数据则直接push
              pieObj[keyname].push({ key: index, value: item[keyname] });
            } else { // 首次记录该名称数据，创建keyname ['名称',第一位数据]
              pieObj[keyname] = [keyname, { key: index, value: item[keyname] }]
            }
          })
        });
        Object.keys(pieObj).map((key, idx) => {
          crr[idx] = { name: key };
          brr.push({ name: key, type: 'line', symbol: 'none', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' } });
          // 填充空数组,+1第一位是名称
          let sizeArray = Array(_.size(plateList) + 1).fill('-');
          pieObj[key].map((n, i) => {
            if (i === 0) {
              sizeArray[0] = n // 第一位填充名称
            }
            if (_.isObject(n)) { // object代表是数据，key+1填充到空数组的对应位置
              sizeArray[n.key + 1] = n.value;
            }
          })
          arr.push(sizeArray);
        });
        //console.log('创建的数据object', pieObj);
        let obj2 = {
          type: 'pie', id: 'pie', radius: '30%', center: ['50%', '25%'], emphasis: { focus: 'self' }, label: { formatter: '{b}: {@2012} ({d}%)' },
          encode: { itemName: 'product', value: a[1], tooltip: a[1] }
        };
        brr.push(obj2);
        setSeriesLevel({ series: brr, legend: crr, pie: arr })
      }
    } else {
      setSeriesLevel({ series: [], legend: [], pie: [] });
      message.info(_.get(data, 'message', '获取失败！'));
    }
  }
  // tab = 5 风格仓位
  async function _showTradeIndexSort(date = [], tabkey = '') {
    if (id) {
      let params = {
        [GET_TYPE[pageKey]]: id,
        beginDate: date ? date[0] : dateTime[0],
        endDate: date ? date[1] : dateTime[1],
      };
      if (isVir) {
        _.set(params, 'mode', virMode);
      };
      if (tabkey === '8') {
        _.set(params, 'sortType', 'market');
      };
      const res = is_products || isVir
        ? await showTradeIndexSortProduct(params, isVir ? 'vir' : 'nor')
        : await showTradeIndexSort(params, isVir ? 'vir' : 'nor');
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', {});
        let newList = Object.keys(getData).map(date => {
          let pieValue = [];
          Object.keys(getData[date]).map(keyname => {
            pieValue.push({ name: keyname, value: getData[date][keyname] });
          })
          return { time: date, timeKey: moment().diff(moment(date), 'd'), data: pieValue }
        });
        newList = _.orderBy(newList, ['timeKey'], ['desc']);
        // console.log('newList',newList)
        setSortDatas(newList);
        setUpdateCount3(_.round(updateCount3 + 0.1, 1));
      }
    }
  }
  // tab = 6 重仓分布; 产品
  async function _positionDistribution(key, date) {
    if (id) {
      displayState.highLoading = true;
      let params = {
        [GET_TYPE[pageKey]]: id,
        beginDate: date ? date[0] : dateTime[0],
        endDate: date ? date[1] : dateTime[1],
      };
      if (isVir) {
        _.set(params, 'mode', virMode);
      }
      const res = key === '6'
        ? await getHighPositionDistribution(params, isVir ? 'vir' : 'nor')
        : await getPositionDistribution(params, isVir ? 'vir' : 'nor');

      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', {});
        let newList = Object.keys(getData).map(date => {
          return _.assign(getData[date], { 'date': date })
        });
        setHightDatas(createDatasTimeKey(newList, 'date', 'd', 'asc'));
        setUpdateCount4(_.round(updateCount4 + 0.1, 1));
      }
      displayState.highLoading = false;
    }
  }
  // 切换tab并获取相应数据
  function tabChangeUpdate(key, times) {
    const mainArray = ['1', '2', '3'];
    if (_.includes(mainArray, key)) {
      if (is_products || isVir) {
        getDisplayLevelProduct(key, times);
      } else {
        getDisplayLevelList(key, times)
      }
    } else if (key === '4') {
      _showPlatePosition(null, times);
    } else if (_.includes(['5', '8'], key)) {
      _showTradeIndexSort(times, key)
    } else if (_.includes(['6', '7'], key)) {
      _positionDistribution(key, times[0] === times[1] ? RANGES : times);
    }
  }

  const newTabList = is_products || isVir ? _.concat(tabList, [tabList_4[1], tabList_4[2]], tabList_pro) : _.concat(tabList, tabList_4);
  const isSingleDay = dateTime[0] === dateTime[1] ? true : false;
  return (
    <div style={{ backgroundColor: '#fff', padding: 6 }}>
      <Tabs
        activeKey={ctab}
        tabPosition='top'
        tabBarExtraContent={
          <Space>
            {isSingleDay && <Tooltip title='上一日'>
              <Button icon={<LeftOutlined />} size='small' shape='circle' onClick={() => {
                let laseDay = moment(dateTime[0]).subtract(1, 'd').format(FORMAT);
                setdateTime([laseDay, laseDay]);
                tabChangeUpdate(ctab, [laseDay, laseDay]);
              }} />
            </Tooltip>}
            {isSingleDay && <Tooltip title='下一日'>
              <Button icon={<RightOutlined />} disabled={moment().diff(dateTime[0], 'd') <= 0} size='small' shape='circle' onClick={() => {
                let nextDay = moment(dateTime[0]).add(1, 'd').format(FORMAT);
                setdateTime([nextDay, nextDay]);
                tabChangeUpdate(ctab, [nextDay, nextDay]);
              }} />
            </Tooltip>}
            <NewRangePicker
              dates={dateTime}
              onSelect={(dateStrings) => {
                setdateTime(dateStrings);
                tabChangeUpdate(ctab, dateStrings);
              }}
            />
          </Space>
        }
        onChange={(activeKey) => {
          setCtab(activeKey);
          tabChangeUpdate(activeKey, dateTime);
        }}
      >
        {newTabList.map(n => <Tabs.TabPane tab={n.tab} key={n.key}></Tabs.TabPane>)}
      </Tabs>

      <DisplayMainCharts
        active={ctab}
        upCount={updateCount}
        pieChartData={seriesLevel}
        loading={loading}
      />

      <DisplayCalCharts
        active={ctab}
        segOptions={SEG_OPTIONS}
        segType={pType}
        upCount={updateCount2}
        chartsDatas={serData}
        optionVal={symOption}
        onTypeChange={(val) => {
          setPType(val);
          _showPlatePosition(val, null);
        }}
      />

      <DisplaySortCharts
        active={ctab}
        upCount={updateCount3}
        datas={sortDatas}
      />

      <HighPosCharts
        active={ctab}
        upCount={updateCount4}
        datas={highDatas}
        loading={displayState.highLoading}
      />

      <div style={{ height: 35 }}></div>
    </div>
  )
}

export default DisplayCharts;
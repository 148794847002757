import axios from '@/utils/axios';

/**
 *  review账号登陆
 */
export function reviewLogin(data) {
    return axios.post(global.apc + '/api/user/login', data);
}
/**
 *  review 获取已保存的配置
 */
export function reviewGetConfig(data) {
    return axios.get(global.apc + '/api/user/conf/list', data);
}
//访客展示接口
export function visShowTraderTrends(data) {
    return axios.post(global.api + '/visitorFundManager/showTraderTrends', data);
}
export function visShowAssetsAndProducts(data) {
    return axios.get(global.api + '/visitorFundManager/showAssetsAndProducts', data);
}
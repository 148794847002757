import React, { } from 'react';
import {
  Row,
  Col,
  Space,
  Card,
  Button,
  Typography,
  Statistic,
  Divider,
  Tooltip,
} from 'antd';
import {
  PayCircleOutlined, AlipayOutlined, DollarCircleOutlined, RightOutlined, TransactionOutlined,
  WalletOutlined
} from '@ant-design/icons'
import _ from 'lodash';

const { Text } = Typography;
const bar_col_1 = { xxl: 5, xl: 5, lg: 5, md: 12, sm: 12, xs: 12, flex: 'auto' };
const bar_col_2 = { xxl: 4, xl: 4, lg: 4, md: 24, sm: 24, xs: 24, flex: 'auto' };

const MoneyBar = ({
  memberInfo,
  exUser,

  jumpPayment,
  jumpForm,
  jumpLink
}) => {
  const memberInfoList = [
    {
      label: '待支付费用', key: 'fees', extra: <Tooltip title='去支付'>
        <Button
          icon={<PayCircleOutlined />}
          size='small'
          shape='circle'
          type='text'
          style={{ marginLeft: 8 }}
          disabled={_.get(memberInfo, 'fees', 0) === 0}
          onClick={() => jumpPayment(_.get(memberInfo, 'fees', 0))}
        />
      </Tooltip>,
      otherProps: { valueStyle: { color: '#ff4d4f' } }
    },
    {
      label: '可提现余额', key: 'balance', extra: <Tooltip title={'去提现'}>
        <Button
          icon={<WalletOutlined />}
          size='small'
          shape='circle'
          type='text'
          style={{ marginLeft: 8 }}
          disabled={_.get(memberInfo, 'balance', 0) === 0}
          onClick={() => jumpForm('withdraw')}
        />
      </Tooltip>,
      otherProps: {}
    },
    {
      label: '押金', key: 'deposit', extra: <Tooltip title={_.get(exUser, 'status', 0) !== 0 ? '会员期间无法划转' : '押金划转余额'}>
        <Button
          icon={<TransactionOutlined />}
          size='small'
          shape='circle'
          type='text'
          disabled={_.get(exUser, 'status', 0) !== 0 || _.get(memberInfo, 'deposit', 0) === 0}
          style={{ marginLeft: 8 }}
          onClick={() => jumpForm()}
        />
      </Tooltip>,
      otherProps: {}
    },
    { label: '会员月资费', key: 'monthlyPrice', otherProps: {} },
  ]

  return (
    <div className='moneyBar'>
      <Card>
        <div className='decoreateBar'></div>
        <Row gutter={[8, 8]}>
          {memberInfoList.map((item, i) => {
            const getValue = _.get(memberInfo, item.key, 0);
            let newSuffix
            if (item.extra) {
              newSuffix = item.extra
            } else {
              newSuffix = getValue !== 0 ? '元' : ''
            }
            return <Col key={'member' + i} {...bar_col_1}>
              <Statistic
                title={item.label}
                value={getValue}
                suffix={newSuffix}
                {...item.otherProps}
              />
            </Col>
          })}
          <Col {...bar_col_2}>
            <div className='moneyOptionDiv' onClick={() => jumpLink("/home/manager/withdrawal")}>
              <Space>
                <DollarCircleOutlined />
                <Text strong>提现管理</Text>
                <RightOutlined />
              </Space>
            </div>
            <Divider style={{ margin: '12px 0' }} />
            <div className='moneyOptionDiv' onClick={() => jumpLink("/home/manager/paysetting")}>
              <Space>
                <AlipayOutlined />
                <Text strong>支付设置</Text>
                <RightOutlined />
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default MoneyBar;
import React from 'react';
import {
  Table, Button, Tag, Radio, Space, Typography, InputNumber, message, Divider,
  Modal
} from 'antd';
import { EditOutlined, CheckOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { singleColumns } from '@/utils/utils';
import { isValidObj } from '@/utils/utils2';
import { useReactive } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

// 虚拟产品列表； 包含修改产品参数及点击查看功能；
const VirtualTables = ({ datas = [], onEdit, onDelete }) => {
  const virState = useReactive({
    editId: 0, items: {}, editVal: {}, editStatus: {}
  });
  // 提交修改金额
  function confirmEdit() {
    if (!isValidObj(virState.items)) {
      return
    };
    let final = {
      "virtualProductId": _.get(virState.items, 'id'),
      "mode": _.get(virState.items, 'mode'),
    };
    let newItems = [];
    _.get(virState.items, 'combinations').map(n => {
      //【tips】 提交时使用combinationId，id是账户产品的唯一值；
      const getCombId = _.get(n, 'combinationId');
      const getId = _.get(n, 'id');
      //最后的值通过id拿到金额与加减的值即可
      const getMoney = _.get(virState.editVal, getId);
      const getDirection = _.get(virState.editStatus, getId);
      if (getMoney && getDirection) {
        newItems.push({ 'combinationId': getCombId, 'money': getMoney, 'direction': getDirection });
      }
    });
    if (_.size(newItems) === 0) {
      message.info('请填写正确的修改参数！');
      return
    }
    _.set(final, 'items', newItems);
    // console.log('final',final);
    onEdit(final);
    clear();
  };

  function clear() {
    virState.editVal = {};
    virState.editStatus = {};
    virState.editId = 0;
    virState.items = {};
  }

  const columns = [
    {
      ...singleColumns(['名称', 'name']),
      render: (text) => <Text keyboard>{text}</Text>
    },
    singleColumns(['初始资金', 'originAssets']),
    {
      ...singleColumns(['净值', 'netValue']),
      render: (text) => _.round(text, 2)
    },
    {
      ...singleColumns(['类型', 'mode']),
      render: (text) => String(text) === '0' ? <Tag color='orange'>产品</Tag> : <Tag color='blue'>账户</Tag>
    },
    singleColumns(['周期', 'interval']),
    {
      ...singleColumns(['调整日期', 'adjustDate']),
      render: (text) => moment(text).format('YYYY-MM-DD')
    },
    {
      ...singleColumns(['日期', 'formatDate']),
      sorter: (a, b) => a.timeKey - b.timeKey,
    },
    {
      ...singleColumns(['操作', 'option']),
      render: (text, record) => <Button type='link' danger icon={<DeleteOutlined />} size='small'
        onClick={() => {
          Modal.confirm({
            title: '是否删除该产品！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '是',
            cancelText: '否',
            onOk: () => onDelete(_.get(record, 'id')),
            onCancel() { },
          });
        }}>删除</Button>
    },
  ];
  // 合并产品col
  const colCombind = [
    singleColumns(['名称', 'combinationName']),
    {
      ...singleColumns(['金额', 'totalAsset']),
      render: (text, record) => {
        const getId = _.get(record, 'id');
        const newAsset = _.get(virState.editVal, getId) ?? 0;
        const status = _.get(virState.editStatus, getId) ?? 0;
        // 修改金额; 每次点击确定则调用接口
        if (virState.editId === getId) {
          // editVal记录 id:值 的对应关系对象；
          // editStatus记录 radio的id:值对应关系对象；
          return <Space>
            <Text style={{ marginRight: 26 }}>{text}</Text>
            <Divider direction='virtical' />

            <InputNumber
              value={newAsset}
              size='small'
              onChange={(v) => virState.editVal[getId] = v}
            />
            <Radio.Group
              value={status}
              onChange={e => virState.editStatus[getId] = e.target.value}
            >
              <Radio value={1}>增加+</Radio>
              <Radio value={-1}>减少-</Radio>
            </Radio.Group>

            <Divider direction='virtical' />
            <Space size='small' style={{ marginLeft: 12 }}>
              <Button type='link' size='small' onClick={confirmEdit} icon={<CheckOutlined />}>确定</Button>
              <Button type='link' danger size='small' onClick={clear}>取消</Button>
            </Space>
          </Space>
        } else {
          //未点击是显示资产信息
          return <div style={{ cursor: 'pointer' }} onClick={() => {
            // 点击combind账户时更新id，并且赋值item值，保证每次点击编辑是item是当前产品的值；
            virState.editId = getId;
            let findItem = {};
            datas.map(itm => {
              const comb = _.get(itm, 'combinations', []);
              comb.map(n => {
                if (_.get(n, 'id') === getId) { // 根据id查找
                  findItem = itm;
                }
              });
            });
            virState.items = findItem;
          }}>
            <Space size='small'>
              <Text strong>{_.round(text, 2)}</Text>
              <EditOutlined style={{ color: '#bfbfbf' }} />
            </Space>
          </div>
        }
      }
    },
    {
      ...singleColumns(['权重', 'weight']),
      render: (text) => <Space size={2}>
        <Text type='secondary'>权重: </Text>
        <Text strong>{text}</Text>
      </Space>
    },
  ];

  const expandedRowRender = (fatRecord) => {
    return (
      <Table
        rowKey="combinationId"
        dataSource={_.get(fatRecord, 'combinations', [])}
        columns={colCombind}
        pagination={false}
        showHeader={false}
        size='small'
        border={false}
      />
    )
  }

  return (
    <>
      <Table
        rowKey={'id'}
        dataSource={datas}
        columns={columns}
        // scroll={{ y: 220 }}
        size='small'
        pagination={false}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          rowExpandable: (record) => _.size(record.combinations) > 0,
        }}
      />

    </>
  )
}

export default VirtualTables;
import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Segmented,
    Tag,
} from 'antd';
import { autoCol, singleColumns } from '@/utils/utils';
import _ from 'lodash';

const SEG_OPTION = [{ label: '持1', value: 1 }, { label: '持2', value: 2 }, { label: '持3', value: 3 }];

const BackTable = ({ datas = [], updateCount = 0 }) => {
    const [tabType, setTabType] = useState(SEG_OPTION[0].value);
    const [tableDatas, setTableDatas] = useState([]);
    const [tableCount, setTableCount] = useState(0);

    const columns = [
        {
            ...singleColumns(['日期', 'date', 170]),
            render: (text, record) => {
                return _.get(record, 'date', '') + '   ' + _.get(record, 'time', '')
            }
        },
        { ...singleColumns(['价格', 'price', 90]) },
        {
            ...singleColumns(['股票代码', 'code', 140]),
            render: (text, record) => {
                return _.get(record, 'name', '') + ' - ' + _.get(record, 'code', '')
            }
        },
        { ...singleColumns(['数量', 'amount', 90]) },
        {
            ...singleColumns(['买卖方向', 'side', 100]),
            render: (text) => text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
        },
    ];

    useEffect(() => {
        const getList = _.get(datas, `[${tabType}].backTestTradeHistoryInfoList`, []);
        let newDatas = getList.map((n, i) => { return { ...n, id: i } })
        setTableDatas(newDatas);
    }, [updateCount, tableCount])

    return (
        <div className='panalContent'>
            <Row style={{ marginBottom: 20 }} gutter={[8, 8]} justify='space-between'>
                <Col {...autoCol([4])}>
                    <Segmented
                        options={SEG_OPTION}
                        value={tabType}
                        onChange={(v) => {
                            setTabType(v);
                            setTableCount(tableCount + 1)
                        }} />
                </Col>
            </Row>

            <Table
                rowKey={'id'}
                dataSource={tableDatas}
                columns={columns}
                scroll={{ x: 620 }}
                size='small'
                pagination={{
                    pageSize: 10
                }}
            />
        </div>
    )
}

export default BackTable;
import React from 'react';
import { Card, Col, Row, Button, Alert } from 'antd';
import { StockOutlined } from '@ant-design/icons';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';

const LOCK_STATUS = {
  0: '正常',
  1: '处于预警期',
  2: '平仓锁定中'
}

const AssetsHeader = ({ role, subAssest, subAccountInfo, hasSubAccount, assetData, isSubListdetail, goShares }) => {
  const noTradingBtn = role === 'fund_manager' || role === 'visitor' ? true : false;
  const colSpan = !noTradingBtn ?
    [{ xxl: 8, xl: 8, lg: 12, md: 24, sm: 24 }, { xxl: 6, xl: 6, lg: 12, md: 24, sm: 24 }, { xxl: 6, xl: 6, lg: 12, md: 24, sm: 24 }]
    : [{ xxl: 10, xl: 10, lg: 12, md: 24, sm: 24, xs: 24 }, { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24, xs: 24 }, { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24, xs: 24 }];
  const item_col = { xxl: 8, xl: 8, lg: 12, md: 12, sm: 24 };
  const container_array = [
    { xxl: 10, xl: 10, lg: 12, md: 24, sm: 24 },
    { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 },
    { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 },
    { xxl: 8, xl: 8, lg: 12, md: 24, sm: 24 },
  ];
  const HeaderComp = ({ dataSource, firstTitle, type }) => {
    const new_col_array = type === '1' ? container_array : colSpan;
    return <Row gutter={[8, 8]} style={{ minHeight: 206 }}>
      <Col {...new_col_array[0]}>
        <Card bordered={true} className="headCard">
          <Row style={{ marginBottom: 12 }}>
            <Col span={24}>
              <StatisticHeader title={firstTitle} value={_.get(dataSource, 'totalAssets') || '0'} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col {...item_col}>
              <StatisticHeader title={'当前市值'} value={_.get(dataSource, 'positionsAssets')} />
            </Col>
            <Col  {...item_col}>
              <StatisticHeader title={'可用资金'} value={_.get(dataSource, 'totalBalance')} />
            </Col>
            <Col  {...item_col}>
              <StatisticHeader title={'冻结资金'} value={_.get(dataSource, 'totalFrozenBalance') || '0'} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col  {...new_col_array[1]}>
        <Card bordered={true} className="headCard">
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <StatisticHeader title={'总收益'} value={_.get(dataSource, 'totalRevenue')} isColor />
            </Col>
            <Col span={24}>
              <StatisticHeader title={'净值'} value={_.round(_.get(dataSource, 'netValue'), 4)} isColor />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col  {...new_col_array[2]}>
        <Card bordered={true} className="headCard">
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <StatisticHeader title={'日收益'} value={_.get(dataSource, 'dailyRevenue')} isColor isPrefix />
            </Col>
            <Col span={24}>
              <StatisticHeader title={'日收益率'} value={_.round(parseFloat(_.get(dataSource, 'dailyRevenueRate')), 2) + '%'} isColor isPrefix />
            </Col>
          </Row>
        </Card>
      </Col>
      {
        !noTradingBtn && isSubListdetail && type === '2' &&
        <Col xxl={4} xl={4} lg={12} md={12} sm={24} flex="auto">
          <Card bordered={true} className="headCard headCard4">
            <Row justify="center">
              <Button
                type="primary"
                size="large"
                icon={<StockOutlined />}
                onClick={goShares}
                shape='round'
              >
                交易
              </Button>
            </Row>
          </Card>
        </Col>
      }
    </Row>
  }
  return (
    <>
      {_.get(assetData, 'alertStatus') !== 0 && _.get(assetData, 'alertStatus') ? <Alert message={LOCK_STATUS[_.get(assetData, 'alertStatus')] || '锁定中'} type='error' /> : null}
      {hasSubAccount && <HeaderComp dataSource={subAssest} firstTitle={'资产统计'} type='1' />}
      <HeaderComp dataSource={assetData} firstTitle={_.get(subAccountInfo, 'subAccountName', '') + '资产'} type='2' />
    </>
  )
}

export default AssetsHeader;
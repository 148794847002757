import React, { useState } from 'react';
import { Col, Row, Descriptions, Space, Button, Typography, Tag, Divider } from 'antd';
import { SortAscendingOutlined } from '@ant-design/icons';
import { autoCol, RateText, renderUpDownRate2 } from '@/utils/utils';
import { useReactive } from 'ahooks';
import _ from 'lodash';

const { Text } = Typography;
const SORT_KEY = {
  'total': 'totalRevenueRate',
  'day': 'dailyRevenueRate',
};
const SORT_CHANGE_OBJ = { 'up': 'down', 'down': 'up' };// 改变升降序时，需要赋值相反的状态
const SORT_NAME = {
  'total': '总',
  'day': '今',
};
const ProIcon = ({ color }) => <svg t="1710905444530" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4297" width="18" height="18"><path d="M121.904762 330.947048l359.619048 154.575238v451.779047L165.351619 796.94019A73.142857 73.142857 0 0 1 121.904762 730.063238v-399.11619z m780.190476-9.337905v408.454095a73.142857 73.142857 0 0 1-43.446857 66.852572L554.666667 931.888762V481.694476L902.095238 321.584762zM541.696 86.308571l316.952381 140.751239c11.02019 4.87619 20.406857 12.312381 27.599238 21.382095l-371.151238 171.885714-382.537143-164.425143c7.558095-12.483048 18.895238-22.674286 32.792381-28.842666l316.952381-140.751239a73.142857 73.142857 0 0 1 59.392 0z"
  p-id="4298"
  fill={color}></path></svg>;
// 首页卡片展示
const VirtualCards = ({ items, goDetails }) => {
  const [orderItem, setOrderItem] = useState([]);
  const [isOrder, setIsOrder] = useState(false);
  const sortState = useReactive({
    'totalRevenueRate': 'down',
    'dailyRevenueRate': 'down',
    'sortType': '',
  });
  //console.log(items)
  const sortRate = (key) => {
    const sort_full_key = SORT_KEY[key];
    const curSortType = _.get(sortState, sort_full_key);
    sortState[sort_full_key] = SORT_CHANGE_OBJ[curSortType];
    sortState.sortType = key;
    setOrderItem(_.orderBy(items, [sort_full_key], [sortState[sort_full_key] === 'up' ? 'desc' : 'asc']));
    setIsOrder(true);
  };

  const renderSortColor = (type) => {
    const isSortType = sortState[SORT_KEY[type]]
    let updownColor = isSortType === 'up' ? 'red' : 'green';
    return isOrder && type === sortState.sortType ? updownColor : 'black';
  }

  const contentBtnProps = { size: 'small', type: 'text' };
  const newItems = isOrder ? orderItem : items;
  return <Row wrap={true} gutter={[8, 8]}>
    {_.size(items) > 0 && <Col span={24} style={{ textAlign: 'right' }}>
      <Space>
        <Space size='small'>
          {_.keys(SORT_KEY).map((keyname, i) => <Button key={keyname + i} {...contentBtnProps}
            icon={<SortAscendingOutlined style={{ color: renderSortColor(keyname) }} />}
            onClick={() => sortRate(keyname)}>
            {SORT_NAME[keyname]}{sortState[SORT_KEY[keyname]] === 'up' ? '升序' : '降序'}
          </Button>)}
          <Button {...contentBtnProps} disabled={!isOrder} danger onClick={() => {
            setIsOrder(false);
            sortState.sortType = '';
          }}>
            重置
          </Button>
        </Space>
      </Space>
      <Divider style={{ margin: '8px 0' }} />
    </Col>}
    {
      _.size(newItems) > 0
      &&
      newItems.map(item => {
        const getTotalRevenue = _.get(item, 'totalRevenueRate', 0);
        const totalIconColor = renderUpDownRate2(getTotalRevenue);
        return (
          <Col key={item.id} {...autoCol([6])}>
            <div
              className='astsCard-v'
              onClick={() => goDetails(item)}
            >
              <Descriptions
                title={<Text style={{ whiteSpace: 'pre-wrap' }}>{_.get(item, 'name')}</Text>}
                extra={<Space size='small' align='start'>
                  {String(_.get(item, 'mode')) === '0' ? <Tag color='orange'>产品</Tag> : <Tag color='blue'>账户</Tag>}
                  <ProIcon color={totalIconColor.color} />
                </Space>}
                column={{ ...autoCol([2, 1, 1]) }}
                size='small'
              >
                <Descriptions.Item label={<Text type='secondary'>{'总资产'}</Text>}>
                  <Text strong>{_.get(item, 'totalAssets', '')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text type='secondary'>{'最新净值'}</Text>}>
                  <RateText rate={_.get(item, 'netValue', '')} noPrefix carry={4} />
                </Descriptions.Item>
                <Descriptions.Item label={<Text type='secondary'>{'总收益率'}</Text>}>
                  <RateText rate={_.get(item, 'totalRevenueRate', null)} />
                </Descriptions.Item>
                <Descriptions.Item label={<Text type='secondary'>{'日收益率'}</Text>}>
                  <RateText rate={_.get(item, 'dailyRevenueRate', null)} />
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
        )
      })
    }
  </Row>;
}

export default VirtualCards;
import React from 'react';
import { Card, Col, Row, Button, Statistic } from 'antd';
import { DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import _ from 'lodash';

const MAssetsHeader = ({ role, subAssest, subAccountInfo, hasSubAccount, assetData, isSubListdetail, goShares }) => {

  function renderValueColor(value) {
    let pureValue = _.replace(value || '', ',', '');
    const floatValue = pureValue ? parseFloat(pureValue) : 0;
    if (floatValue > 0) {
      return 'red'
    } else if (floatValue < 0) {
      return 'green'
    } else {
      return 'black'
    }
  }

  function renderValuePrefix(value) {
    const floatValue = value ? parseFloat(value) : 0;
    if (floatValue > 0) {
      return <ArrowUpOutlined style={{ color: 'red' }} />
    } else if (floatValue < 0) {
      return <ArrowDownOutlined style={{ color: 'green' }} />
    } else {
      return null
    }
  }

  const colSpan = role !== 'fund_manager' ?
    [{ xxl: 8, xl: 8, lg: 12, md: 24, sm: 24 }, { xxl: 6, xl: 6, lg: 12, md: 24, sm: 24 }, { xxl: 6, xl: 6, lg: 12, md: 24, sm: 24 }]
    : [{ xxl: 10, xl: 10, lg: 12, md: 24, sm: 24 }, { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 }, { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 }];
  const common_static_style = {
    fontSize: 24, fontWeight: '600'
  }
  const item_col = { xxl: 8, xl: 8, lg: 12, md: 12, sm: 24 };
  const container_array = [
    { xxl: 10, xl: 10, lg: 12, md: 24, sm: 24 },
    { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 },
    { xxl: 7, xl: 7, lg: 12, md: 24, sm: 24 },
    { xxl: 8, xl: 8, lg: 12, md: 24, sm: 24 },
  ]
  return (
    <>
      {hasSubAccount && <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
        <Col {...container_array[0]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row style={{ marginBottom: 12 }}>
              <Col span={24}>
                <Statistic
                  title={subAccountInfo.name + '(总资产)'}
                  value={subAssest.totalAssets || '0'}
                  valueStyle={common_static_style}
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col {...item_col}>
                <Statistic
                  title={"当前市值"}
                  value={subAssest.positionsAssets}
                  valueStyle={common_static_style}
                />
              </Col>
              <Col {...item_col}>
                <Statistic
                  title={"可用资金"}
                  value={subAssest.totalBalance}
                  valueStyle={common_static_style}
                />
              </Col>
              <Col {...item_col}>
                <Statistic
                  title={"冻结资金"}
                  value={subAssest.totalFrozenBalance || '0'}
                  valueStyle={common_static_style}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col {...container_array[1]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row>
              <Col span={24} style={{ marginBottom: 10 }}>
                <Statistic
                  title={"总收益"}
                  value={subAssest.totalRevenue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(subAssest.totalRevenue) }}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title={"净值"}
                  value={subAssest.netValue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(subAssest.netValue) }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col {...container_array[2]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row gutter={8}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <Statistic
                  title={"日收益"}
                  value={subAssest.dailyRevenue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(subAssest.dailyRevenue) }}
                  prefix={renderValuePrefix(subAssest.dailyRevenue)}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title={"日收益率"}
                  value={parseFloat(subAssest.dailyRevenueRate).toFixed(2) + '%'}
                  valueStyle={{ ...common_static_style, color: renderValueColor(subAssest.dailyRevenueRate) }}
                  prefix={renderValuePrefix(subAssest.dailyRevenueRate)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>}
      <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
        <Col  {...colSpan[0]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row style={{ marginBottom: 8 }}>
              <Col span={24}>
                <Statistic
                  title={subAccountInfo.name + '(自有资产)'}
                  value={assetData.totalAssets || '0'}
                  valueStyle={common_static_style}
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col {...item_col} flex="auto">
                <Statistic
                  title={"当前市值"}
                  value={assetData.positionsAssets}
                  valueStyle={common_static_style}
                />
              </Col>
              <Col {...item_col}>
                <Statistic
                  title={"可用资金"}
                  value={assetData.totalBalance}
                  valueStyle={common_static_style}
                />
              </Col>
              <Col {...item_col}>
                <Statistic
                  title={"冻结资金"}
                  value={assetData.totalFrozenBalance || '0'}
                  valueStyle={common_static_style}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col  {...colSpan[1]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row>
              <Col span={24} style={{ marginBottom: 10 }}>
                <Statistic
                  title={"总收益"}
                  value={assetData.totalRevenue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(assetData.totalRevenue) }}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title={"净值"}
                  value={assetData.netValue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(assetData.netValue) }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col  {...colSpan[2]} flex="auto">
          <Card bordered={true} className="headCard">
            <Row gutter={[8, 8]}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <Statistic
                  title={"日收益"}
                  value={assetData.dailyRevenue}
                  valueStyle={{ ...common_static_style, color: renderValueColor(assetData.dailyRevenue) }}
                  prefix={renderValuePrefix(assetData.dailyRevenue)}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title={"日收益率"}
                  value={parseFloat(assetData.dailyRevenueRate).toFixed(2) + '%'}
                  valueStyle={{ ...common_static_style, color: renderValueColor(assetData.dailyRevenueRate) }}
                  prefix={renderValuePrefix(assetData.dailyRevenueRate)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {
          role !== 'fund_manager' && !isSubListdetail &&
          <Col xxl={4} xl={4} lg={12} md={12} sm={24} flex="auto">
            <Card bordered={true} className="headCard headCard4">
              <Row justify="center">
                <Button
                  type='link'
                  size="large"
                  onClick={goShares}>
                  查看账户
                </Button>
                <div style={{ color: '#0859AB', paddingTop: 10, marginLeft: -13 }}>
                  <DoubleRightOutlined />
                </div>
              </Row>
            </Card>
          </Col>
        }
      </Row>
    </>
  )
}

export default MAssetsHeader;
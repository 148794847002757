import React, { useEffect, useState } from 'react';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
// import axios from 'axios';
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { Layout, Menu, Button, Space, Badge, Avatar, Dropdown, Image, Row, Col, message } from 'antd';
import { routeConfig } from '@/router/route.config';
import logoCN from "@/images/logoEN.svg";
import {
  showAllSubAccount, showAllFutureSubAccount, notRead, getManagerSubAccount
} from '@/api/workbench';
import { showAccount, checkUserExpire } from '@/api/userSystem';
import {
  UserOutlined, DownOutlined, BellOutlined, MessageOutlined, TeamOutlined, AccountBookOutlined,
  ControlOutlined, UnorderedListOutlined, MenuFoldOutlined, MenuUnfoldOutlined, LineChartOutlined, EllipsisOutlined,
  UserSwitchOutlined, CloudServerOutlined, AlertOutlined, DollarCircleOutlined, FileTextOutlined,
  AlipayOutlined, LogoutOutlined, AreaChartOutlined
} from '@ant-design/icons';
import { useMount } from 'ahooks';
import { isBrowser } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
// import { ChatModeSwitch } from '../common/HomeComp/homeComp';
// import HomeChatPage from './Chat/HomeChatPage';
// import ReconnectingWebSocket from 'reconnecting-websocket';
// import PubSub from 'pubsub-js'
// import moment from 'moment';
import _ from 'lodash';
import '../../colors.less';

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;
// const SOCKET_ADDRESS = 'ws://39.103.209.85:15604/channel';

const traderItem = [
  { key: '1', link: '/home/assets', name: '资产总览', icon: <AccountBookOutlined /> },
  { key: '2', link: '/home/StockScreening', name: '我的股票', icon: <AreaChartOutlined /> },
  { key: '5', link: '/home/reward', name: '个人中心', icon: <UserOutlined /> },
];
const fundManagerItem = [
  { key: '2_12', link: '/home/AssetsShow', pms: { key: "1", from: 'home' }, name: '资产总览', icon: <UnorderedListOutlined /> },
  { key: '2_13', link: '/home/FundAdm', pms: { key: "2" }, name: '资金管理', icon: <AccountBookOutlined /> },
  { key: '2_14', link: '/home/TraderAdm', pms: { key: "3" }, name: '交易员管理', icon: <UserSwitchOutlined /> },
  { key: '2_18', link: '/home/TransAnalyse', pms: { key: "4" }, name: '交易分析', icon: <UserSwitchOutlined /> },
  { key: '2_15', link: '/home/RiskAdm', pms: { key: "5" }, name: '风控管理', icon: <AlertOutlined /> },
  { key: '2_16', link: '/home/FundManagerStockPoll', pms: { key: "6" }, name: '股票管理', icon: <LineChartOutlined /> },
  { key: '2_17', link: '/home/Futuresmanagements', pms: { key: "7" }, name: '期货管理', icon: <LineChartOutlined /> },
  { key: '3', link: '/home/combindProduct', pms: { key: "combindProduct" }, name: '虚拟产品', icon: <CloudServerOutlined /> },
];
const tradeManagerMyItem = [
  { key: '5_1', link: '/home/reward', name: '个人中心', icon: <FileTextOutlined /> },
  { key: '5_2', link: '/home/manager/paysetting', name: '支付设置', icon: <AlipayOutlined /> },
  { key: '5_3', link: '/home/manager/withdrawal', name: '个人中心', icon: <DollarCircleOutlined /> },
];
const adminItem = [
  { key: '6_1', link: '/home/levelPage', name: '策略与服务' },
  { key: '6_2', link: '/home/manager/withdrawal', name: '提现管理' },
  { key: '6_5', link: '/home/openService', name: '开通用户服务' },
  { key: '6_6', link: '/home/submanager', name: '分配账户' },
];

export default function Home(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [collapsed, setcollapsed] = useState(isMobile ? true : false);
  const [menuKey, setmenuKey] = useState(localStorage.getItem('menuKey') || '1');
  const mobxStore = useLocalObservable(() => store);
  const [accountList, setaccountList] = useState([]);
  const [accountFutureList, setaccountFutureList] = useState([]);
  const [newMList, setNewMList] = useState([]);
  const [newsUnread, setNewUnread] = useState(0);
  const [riskUnread, setRiskUnread] = useState(0);

  useEffect(() => {
    // 监听token是否超时，会自动退出到登录
    if (!mobxStore.userLogin) {
      props.history.replace('/login');
    }
  }, [props, mobxStore]);

  useMount(() => {
    _getNews();
    _getData();
    _checkUserExpire();
    if (checkRole('trader_manager') || checkRole('trader')) {
      _showAccount('alipay');
    } else {
      mobxStore._getAllTradeDay();
    }
  });

  // useEffect(() => {
  //     const userInfo = JSON.parse(JSON.stringify(mobxStore.userInfo));
  //     const isLogin = mobxStore.userLogin;

  //     if (!isLogin || !_.get(userInfo, 'imUserId') || !_.get(userInfo, 'imToken')) {
  //         setIsImtoken(false);
  //         return;
  //     }

  //     const socket = new ReconnectingWebSocket(SOCKET_ADDRESS, null, { reconnectInterval: 3000 });
  //     // console.log('socket加载，尽加载一次！(menuKey)', menuKey, initKey);
  //     socket.onopen = () => {
  //         let sParams = {
  //             userId: _.get(userInfo, 'imUserId', ''),
  //             msg: _.get(userInfo, 'imToken', '')
  //         }
  //         console.log('socket连接参数', JSON.stringify(sParams))
  //         socket.send(JSON.stringify(sParams));
  //     };

  //     socket.onmessage = (e) => {

  //         let getData = JSON.parse(e.data);
  //         let newMsg = {
  //             ...getData,
  //             createAt: moment().format('YYYY-MM-DD HH:mm:ss'),
  //             updateAt: moment().unix() // 用于在ChatPage中检查更新
  //         }
  //         const getBoxId = newMsg.mailBoxId;
  //         if (getBoxId) {
  //             const stringBoxId = String(getBoxId);
  //             let tempObj = chatUnreadObj;
  //             tempObj[stringBoxId] = _.get(chatUnreadObj, `${stringBoxId}`) ? chatUnreadObj[stringBoxId] + 1 : 1
  //             setChatUnread(chatUnread + 1);
  //             setChatUnreadObj(tempObj);
  //         }
  //         PubSub.publish('chat_res', JSON.stringify(newMsg));
  //     }

  //     socket.onerror = (e) => {
  //         console.log('socket错误', e.message);
  //         message.error('通信错误！')
  //     }

  //     socket.onclose = (e) => {
  //         console.log('socket关闭', e.code, e.reason);
  //         message.info('通讯关闭')
  //     }

  //     PubSub.subscribe('chat_send', (msg, data) => {
  //         if (msg === 'chat_send') {
  //             debounce(socket.send(data), 300)
  //         }

  //     })

  //     return () => {
  //         return;
  //     }
  // }, [])

  async function _getData() {
    if (checkRole('trader') || checkRole('trader_manager')) {
      const data = await showAllSubAccount();
      if (data.code === '200') {
        setaccountList(data.data);
        mobxStore.changeAccList(data.data)
      }
      const data1 = await showAllFutureSubAccount();
      if (data1.code === '200' && data1.data !== null) {
        setaccountFutureList(data1.data);
      }
    }

    if (checkRole('trader_manager')) {
      const data2 = await getManagerSubAccount();
      if (_.get(data2, 'code') === '200') {
        setNewMList(_.get(data2, 'data', []))
      }
    }
  }

  async function _getNews() {
    const readRes = await notRead()

    if (_.get(readRes, 'code', '') === '200') {
      const getType = _.get(readRes, 'data', {})
      let total = 0;

      Object.keys(getType).map(keyname => {
        total = total + getType[keyname];
      })

      setNewUnread(total);
      setRiskUnread(_.get(readRes, 'data.risk_signal'));
    }
  }

  async function _checkUserExpire() {
    const data = await checkUserExpire();
    if (_.get(data, 'code') === '200') {
      let newData = { ...data.data };
      let formalDisable = true;
      if (mobxStore.userInfo.role === 'trader') {
        formalDisable = _.get(newData, 'status', '') === 1 ? true : false
      }
      mobxStore.changeUserVip({
        exData: newData,
        formalDis: formalDisable
      });
    }
  }

  async function _showAccount(type) {
    const getUserId = _.get(mobxStore, 'userInfo.id', null)
    if (getUserId) {
      const data = await showAccount({
        userId: getUserId,
        accountType: type
      });

      if (_.get(data, 'code', '') === '200') {
        mobxStore.changeAccountStatus(type, data.data)
      }
    }
  }

  function handleClick(e) {
    if (e.key === '2_12') {
      localStorage.setItem('url_string', 'home');
    }
    localStorage.setItem('menuKey', e.key);
    setmenuKey(e.key);
  }
  
  function _toMessage(type) {
    props.history.push({
      pathname: '/home/fundManagerNews',
      state: { type: type }
    });
  }
  // 退出登录，清空全局数据
  function _getOut() {
    mobxStore.cancelLogin();
    mobxStore.changeAccList([]);
    mobxStore.changeAssetsData({});
    mobxStore.changeProducts([]);
    mobxStore.saveAccountCache([]);
    props.history.replace('/login');
  }

  function checkRole(role) {
    return mobxStore.userInfo.role === role ? true : false
  }

  const menu = (
    <Menu onClick={(e) => {
      if (e.key === '1') {
        _getOut();
      }
    }}>
      <Menu.Item key={'1'} danger icon={<LogoutOutlined />}
      >退出登录
      </Menu.Item>
    </Menu>
  );

  const renderLinkItem = (name, link, state) => {
    return <Link to={{ 'pathname': link, state }}>{name}</Link>
  };
  const renderMenuItem = (item) => {
    return <Menu.Item key={item.key} icon={item.icon ?? null}>
      <Link to={item.link}>{item.name}</Link>
    </Menu.Item>
  };

  const iconStyle = { fontSize: 16, color: 'white' };
  const accountName = _.get(mobxStore, 'userInfo.account', 'User');
  const renderAccountName = _.size(accountName) > 7 ? accountName.substring(0, 7) + '...' : accountName;
  const messageComp = <Badge count={newsUnread} style={{ borderColor: '#d9363e' }}>
    <div>
      <MessageOutlined style={{ ...iconStyle, marginRight: -9 }} />
      <Button key="message" type="text" onClick={() => _toMessage('all')} style={{ color: 'white' }}>
        消息列表
      </Button>
    </div>
  </Badge>

  return (
    <Observer>
      {() => (
        <Layout style={{ minHeight: '100vh' }}>
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => {
                //console.log('broken', broken);
                if (isBrowser) {
                  setcollapsed(broken)
                }
              }}
            // onCollapse={(collapsed, type) => {}}
            >
              <div>
                <Avatar src={
                  <Image
                    src={logoCN}
                    style={{ width: 173, height: 49, paddingTop: 20 }}
                    preview={false}
                  />}
                />
              </div>
              <Menu
                theme='dark'
                onClick={handleClick}
                defaultOpenKeys={['1', '2', '3', '5', '6']}
                selectedKeys={[menuKey]}
                mode="inline"
              >
                {
                  checkRole('trader') &&
                  <>
                    {traderItem.map(item => renderMenuItem(item))}
                  </>
                }
                {
                  (checkRole('fund_manager') || checkRole('visitor')) &&
                  <SubMenu key="2" icon={<ControlOutlined />} title={checkRole('visitor') ? '工作台' : "管理人工作台"}>
                    {fundManagerItem.map(item => <Menu.Item key={item.key} icon={item.icon} disabled={checkRole('visitor') && item.key !== '2_12' ? true : false}>
                      {renderLinkItem(item.name, item.link, item.pms)}
                    </Menu.Item>)}
                  </SubMenu>
                }
                {
                  checkRole('trader_manager') &&
                  <>
                    <SubMenu key="2" icon={<ControlOutlined />} title="基金经理工作台">
                      <SubMenu key="3" title="股票资产" icon={<UnorderedListOutlined />}>
                        <Menu.Item key="2_7" icon={<AccountBookOutlined />}>
                          <Link to={"/home/tradersDesk"}>我的股票资产</Link>
                        </Menu.Item>
                        {
                          newMList !== null &&
                          newMList.map((item, key) => {
                            return <Menu.Item key={'3_2' + key} icon={<EllipsisOutlined />}>
                              {renderLinkItem(item.name, '/home/mListsDetails', { subAccountInfo: item, allAccount: accountList })}
                            </Menu.Item>
                          })
                        }
                      </SubMenu>

                      <SubMenu key="4" title="期货资产" icon={<UnorderedListOutlined />}>
                        <Menu.Item key="4_1" icon={<AccountBookOutlined />} >
                          <Link to={"/home/futureAssets"}>我的期货资产</Link>
                        </Menu.Item>
                        {
                          accountFutureList.length !== 0 &&
                          accountFutureList.map((item, key) => {
                            return <Menu.Item key={'4_2' + key} icon={<EllipsisOutlined />}>
                              {renderLinkItem(item.name, '/home/fundManagerListsDetails', { subAccountInfo: item })}
                            </Menu.Item>
                          })
                        }
                      </SubMenu>
                      <Menu.Item key="2_14" icon={<UserSwitchOutlined />}>
                        {renderLinkItem('交易员管理', '/home/TraderAdm', { key: "3" })}
                      </Menu.Item>
                      <Menu.Item key="2_16" icon={<LineChartOutlined />}>
                        {renderLinkItem('股票管理', '/home/FundManagerStockPoll', { key: "4" })}
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu key="5" icon={<TeamOutlined />} title="我的">
                      {tradeManagerMyItem.map(item => renderMenuItem(item))}
                    </SubMenu>
                  </>
                }

                {checkRole('admin') ? <SubMenu key="6" icon={<TeamOutlined />} title="Admin">
                  {adminItem.map(item => {
                    if ('child' in item) {
                      return <SubMenu key={item.key} title={item.name}>
                        {item.child.map(c => renderMenuItem(c))}
                      </SubMenu>

                    } else {
                      return renderMenuItem(item);
                    }
                  })}
                </SubMenu> : null}

              </Menu>
            </Sider>

            <Content>
              {/* {isMobile && } */}
              <Header className="site-layout-background" style={{ padding: 0 }}>
                <Row justify='space-between'>
                  <Col span={2}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                      className: 'trigger',
                      style: { color: 'white' },
                      onClick: () => setcollapsed(!collapsed),
                    })}
                  </Col>
                  <Col xxl={12} xl={12} lg={18} md={22} sm={22} style={{ textAlign: 'right', paddingRight: 25 }}>
                    <Space>
                      <div style={{ width: '100px' }}>
                        {collapsed && isMobile && messageComp}
                        {!isMobile && messageComp}
                      </div>
                      {
                        checkRole('fund_manager') ?
                          <div style={{ width: '120px' }}>
                            <Badge count={riskUnread} style={{ borderColor: '#d9363e' }}>
                              <div>
                                <BellOutlined style={{ ...iconStyle, marginRight: -9 }} />
                                <Button key="riskMessage" type="text" onClick={() => _toMessage('risk')} style={{ color: 'white' }}>
                                  风控通知
                                </Button>
                              </div>
                            </Badge>
                          </div> : <div style={{ width: '100px' }}></div>

                      }

                      <div style={{ lineHeight: '31px' }}>
                        <Space>
                          <div style={{ width: '33px' }}>
                            <UserOutlined style={iconStyle} />
                          </div>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <a style={{ color: 'white' }}>
                              {renderAccountName} <DownOutlined />
                            </a>
                          </Dropdown>
                        </Space>
                      </div>
                    </Space>
                  </Col>
                </Row>

              </Header>
              <Switch>
                {
                  routeConfig[5].routes.map((item, index) => {
                    return <Route key={menuKey + index} path={item.path} component={item.component} />
                  })
                }
                {
                  (checkRole('fund_manager') || checkRole('visitor')) &&
                  <Redirect to={
                    {
                      pathname: "/home/AssetsShow",
                      state: { key: "1", from: 'redirect_home' }
                    }
                  } />
                }
                {checkRole('trader') && <Redirect to='/home/assets' />}
                {checkRole('trader_manager') && <Redirect to='/home/tradersDesk' />}
              </Switch>
            </Content>

            {/* <HomeChatPage
                            mode={chatMode}
                        /> */}
          </Layout>
        </Layout>
      )}
    </Observer>
  )
}
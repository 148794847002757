import React, { Component } from 'react';
import {
  Row,
  PageHeader,
  Button,
  message,
  Statistic
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { observer, inject } from 'mobx-react';
import { showMemberInfo, showAllWithdrawalFlow, showAllIncreaseFlow } from '@/api/userSystem';
import { _validateResponse } from '@/utils/utils';
import RecordTable from './RecordTable';
import moment from 'moment';
import _ from 'lodash';

@inject('store')
@observer
class Withdrawal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accStatus: JSON.parse(JSON.stringify(this.props.store.accoutStatus)) || {},
      memberInfo: {},
      withdrawalData: [],
      flowData: [],
      flowDate: [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      loadingObj: {
        withdrawal: false, record: false
      }
    }
    this.userInfo = JSON.parse(JSON.stringify(this.props.store.userInfo))
    this.userid = _.get(this.userInfo, 'id', null);
    this.role = this.userInfo.role;
    this.isAdmin = this.role === 'admin';
  }

  componentDidMount() {
    if (this.isAdmin) {
      this._showAllWithdrawalFlow();
    } else {
      this._showMemberInfo();
      this._showAllWithdrawalFlow();
      this._showAllIncreaseFlow();
    }

  }
  // 提现余额
  _showMemberInfo = async () => {
    let memRes = await showMemberInfo();
    if (_.get(memRes, 'code', '') === '200') {
      this.setState({
        memberInfo: _.get(memRes, 'data', [])
      })
    }

  }
  // 余额流水
  _showAllIncreaseFlow = async () => {
    const { flowDate } = this.state;
    this.handleLoading('record', true)
    let flowRes = await showAllIncreaseFlow({
      startDate: flowDate[0],
      endDate: flowDate[1],
    });
    if (_.get(flowRes, 'code', '') === '200') {
      this.setState({
        flowData: _.get(flowRes, 'data', [])
      })
    }
    this.handleLoading('record', false)
  }
  // 提现记录
  _showAllWithdrawalFlow = async () => {
    this.handleLoading('withdrawal', true)
    let withRes = await showAllWithdrawalFlow(this.isAdmin ? null : this.userid);
    if (_.get(withRes, 'code', '') === '200') {
      this.setState({
        withdrawalData: _.get(withRes, 'data', []),
      })
    }

    this.handleLoading('withdrawal', false)
  }

  goForm = (balance) => {
    this.props.history.push({
      pathname: '/home/manager/withForm',
      state: {
        accountStatus: this.state.accStatus,
        balance: balance
      }
    });
  }

  handleLoading = (key, bool) => {
    const { loadingObj } = this.state;
    let tempLoading = loadingObj;
    tempLoading[key] = bool;
    this.setState({ loadingObj: tempLoading })
  }

  render() {
    const { memberInfo, flowData, withdrawalData, flowDate, loadingObj } = this.state;
    // console.log('form', form);
    const curBalance = _.round(_.get(memberInfo, 'balance', 0), 2);
    return (
      <div>
        <PageHeader
          ghost={false}
          title='提现设置'
          onBack={() => this.role === 'trader' ? this.props.history.goBack() : null}
          extra={!this.isAdmin ? [
            <Button
              onClick={() => this.goForm(curBalance)}
              type='primary'
              size='large'
              shape='round'
            >
              去提现
            </Button>,
          ] : []}
        >
          {!this.isAdmin && <Row>
            <Statistic
              title="当前提现余额"
              prefix="￥"
              valueStyle={{ fontSize: 29 }}
              value={curBalance}
            />
          </Row>}
        </PageHeader>

        <RecordTable
          newProps={this.props}
          role={this.role}
          flowData={flowData}
          withdrawalData={withdrawalData}
          flowDate={flowDate}
          loading={loadingObj}
          onRefresh={() => {
            this._showAllWithdrawalFlow();
          }}
          onTimeChange={(dateStrings) => {
            this.setState({ flowDate: dateStrings },
              () => this._showAllIncreaseFlow()
            )
          }}
        />
      </div>
    )
  }
}

export default Withdrawal;
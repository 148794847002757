import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, InputNumber } from 'antd';
import _ from 'lodash';
const { Option } = Select;
const { TextArea } = Input;

const EditSubscriptModal = ({ visible, onCreate, onCancel, levelData, init }) => {
  const [form] = Form.useForm();

  useEffect(() => {

    if (visible) {
      form.setFieldsValue(init || {});
    }

  }, [visible])

  return (
    <Modal
      visible={visible}
      title="更新服务"
      okText="确定"
      cancelText="取消"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit_sub_modal"
        initialValues={{
          //modifier: 'public',
        }}
      >
        <Form.Item name="levelId" label='选择levelId' rules={[{ required: true }]}>
          <Select
            // placeholder={'选择levelId'}
            style={{ width: 180 }}
          >
            {_.size(levelData) > 0 && levelData.map((n, i) => {
              return <Option key={'op' + i} value={n.id}>{n.levelName}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item name="price" label="价格" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="point" label="可兑换积分" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="description"
          label="描述"
          rules={[{ required: true }]}
          help={'通过换行区分条目'}
        >
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSubscriptModal;
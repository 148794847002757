import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Typography, notification } from 'antd';
import * as echarts from 'echarts';
import { useReactive, useUpdateEffect, useUnmount } from 'ahooks';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { autoCol } from '@/utils/utils';
import { _getPositions } from './Components/list_util';
import { isValidArray, labelValues } from '@/utils/utils2';
import { INDEX_CODE_VALUE, PLATE_OPTIONS } from '@/utils/indexCode';
import {
  RATE_KEYS_OBJ, WEEK_LIMIT_NUMBER, TOOLTIP_SHOW_LIST, COMMON_CHART, CHECK_TAG_ARRAY, RETREAT_OPTIONS, MAIN_RATE_OPTIONS, TOOLTIP_SHOW_NUM, INTERVAL_KEYS,
  UPDOWN_COLOR, EXTRA_AVG, EXTRA_BAR, STR_PLUS, // 常量
  calRate, handleRateOption, calNets, calTimeDiff, renderSlice, handleIndexValue, handleRetreat, handleExtraOption, handlePlateDatas, // 方法
  renderRateTimes, isFullTimeRange, isSliderFirstZero, handleDaliyRates, renderRetreatOption, createBaseLine, calMaLine
} from './Components/chartsInfoUtils';
import { showIndexKline } from '@/api/workbench';//showIndexSpeed
import MainBar from './Components/MainBar';
import { _showPositionSortFunc } from './Components/list_util';
import { MainRangeTabs, MainSlider, MainLimitTable, ChartTabs, ExtraMonthTable, PositionDrawer, BriefTable, RateDiffModal } from './Components/main_widget';//NoteModal
import moment from 'moment';
import _ from 'lodash';
import './Components/charts.scss'

const { Text } = Typography;
const SHOW_CARRY = 2; // 展示保留小数位数，仅限于展示；计算时用更高的保留位数
let timer = null; let timer2 = null; let timer4 = null;
const renderText = (txt) => txt ?? '';
const isValidRate = (v) => v ? _.round(v, SHOW_CARRY) + '%' : '';
const roundToFix = (v) => _.round(v, SHOW_CARRY + 1).toFixed(SHOW_CARRY);
let TOOLTIP_SHOW_OBJ_2 = {};
RATE_KEYS_OBJ.all.map(item => {// 用中文名称作为key，keyname作为value，tooltips时可以快速反向查找object
  TOOLTIP_SHOW_OBJ_2[item.name] = item.key;
});
//收益率两种区域颜色
const BASE_AREA = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  { offset: 0, color: 'rgba(0,122,175,0.6)' },
  { offset: 1, color: 'rgba(0,122,175,0.2)' }
]);
const CONFIG_AREA = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  { offset: 0, color: 'rgba(0,91,123,0.6)' },
  { offset: 1, color: 'rgba(0,91,123,0.2)' }
]);
const BASE_COLOR = '#007db1';
const CONFIG_COLOR = '#005b7b';

const STR_OTHER = 'other.';
const CHART_TAB_OPTION = [labelValues(['回撤', 'retreat']), labelValues(['超额', 'extra']), labelValues(['收益', 'pointsNetList'])];
const CHART_TAB_ARRAY = CHART_TAB_OPTION.map(n => n.value);
/**
 *  MainRateCharts是EchartsInfo增加回撤部分及代码层面优化的图表组件；
 * 在原本keys等配置参数提取出来后，该图表进一步提取不依赖state的function，全部写入chartsInfoUtils中；
 * 在获取收益率时，将原本在charts里面处理的数据function，提取出来，在获取成功后计算赋值给datas.newData，可直接使用处理好的数据；
 *  * 230513:将多组计算function修改为动态创建series，根据选择的曲线创建数据，取消选择根据特殊字段进行删除; 
 * 230515：原所以字段都和showSubAccountHistory内字段关联，后增加板块和板块平均区间，故获取逻辑需要调整，需在额外增加曲线数据
 */
export default function MainRateCharts(props) {
  const mobxStore = useLocalObservable(() => store);
  const [pageKeys] = useState(_.get(props, 'pageKey', ''));
  const [activeKey, setActiveKey] = useState(_.get(props, 'currentTabs', '1'));
  const [idValue, setIdValue] = useState(_.get(props, 'indexSymbol') ?? '');
  const [plateValue, setPlateValue] = useState([]);
  const [filterPlateData, setFilterPlateData] = useState({});
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]);
  const [sliderDis, setSliderDis] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);
  const [role] = useState(_.get(props, 'role', ''));
  const [subItems, setSubItems] = useState([]);
  const [subValues, setSubValues] = useState([]);
  const markRef = useRef([]);
  const analysisState = useReactive({ // 立即更新state，全局快速更新
    otherList: [], dateType: '', proTools: {},
    points: [], pointsNet: [], idxName: '',
    extraList: [], daliyExtra: [],
    lastPoint: 0, lastIdx: 0, lastExtra: 0,
    speedTimes: [], speedShow: false, sTimes: null, hoverTimes: '' // 变化速率state
  });
  const is_manager = role === 'fund_manager' ? true : false;
  const EMPTY_OPTIONS = {
    ...MAIN_RATE_OPTIONS,
    tooltip: {
      ...COMMON_CHART.tooltipCustom,
      formatter: renderFormatter
    },
  }
  const [option, setoption] = useState(EMPTY_OPTIONS);
  const [option2, setoption2] = useState(RETREAT_OPTIONS);
  const [option4, setoption4] = useState(COMMON_CHART.extraBar);
  const [option5, setoption5] = useState(COMMON_CHART.extraPie);
  const [plateIndex, setPlateIndex] = useState({}); // 板块指数对比数据
  const [plUpdate, setPlUpdate] = useState(0);
  const [daliyRates, setDaliyRates] = useState({});
  const pageState = useReactive({
    upCharts: 'rate', downCharts: 'retreat', extraCal: {}, tabYears: [],
    switchAll: [], posShow: false, posLoading: false, isOverlap: false,
    type: 'ranges', extraMonth: [], extraWeeks: [], // 月/周度超额统计表
    showBrief: false, brLoading: false, diffShow: false, isRangeExtra: false
  });
  const [positionSorts, setPositionSorts] = useState({});
  const [briefPosition, setBriefPositon] = useState([]);
  const [chrtabs, setChrtabs] = useState([CHART_TAB_OPTION[0]]);
  const isCombind = pageKeys === 'combind' ? true : false; // 合并

  function renderFormatter(params) {
    // 展示时小数点保留两位；【！！仅限展示，计算时按照默认多进位计算】
    const getx = _.get(params, '[0].name', '');
    const times = _.get(props, 'datas.timeList', []);
    const isNotToday = analysisState.dateType !== 'TODAY';
    let finalString = '';
    params.map(n => {
      let extraStringValue = '';
      const renderValue = roundToFix(n.value);
      const gKey = TOOLTIP_SHOW_OBJ_2[n.seriesName]; // 通过seriesName的中文名称找到key
      // 产品层面从proTools里面直接找到对应数据
      const cur_net = _.get(analysisState.proTools, `nets.${getx}`, 0);
      const cur_index = _.get(analysisState.proTools, `index.${getx}`, 0);
      if (gKey === 'pointList' && isNotToday) {
        extraStringValue = `(日:${roundToFix(cur_net)}%)`
      }
      if (n.seriesName === analysisState.idxName && isNotToday) {
        extraStringValue = `(日:${roundToFix(cur_index)}%)`
      }
      // 括号里是当前点位的超额收益率，不进行累加； 所以需要拿到当天收益率和index进行相减；
      if (n.seriesName === '超额收益率' && isNotToday) {
        extraStringValue = `(日:${roundToFix(cur_net - cur_index)}%)`
      }

      const ffidx = _.findIndex(times, o => o === n.name); //用time找到index
      const gdata = _.get(analysisState.otherList, `[${ffidx}][${gKey}]`, undefined) // 取值
      const nval = roundToFix(gdata); //原始返回数据,value是经过累加等计算值，所以获取原始值
      // 几日平均中增加涨停个数字段；中午名找到key,并查找对应数据
      if (RATE_KEYS_OBJ.week.indexOf(gKey) !== -1 && ffidx !== -1 && nval !== undefined) {
        const getExtra = _.get(analysisState.otherList, `[${ffidx}][${WEEK_LIMIT_NUMBER[n.seriesName]}]`, 0)
        extraStringValue = `【${nval} | ${getExtra}个】`
      }
      // 增加额外个数显示的判断
      if (gKey in TOOLTIP_SHOW_NUM) {
        extraStringValue = ` (${nval}) (${_.get(analysisState.otherList, `[${ffidx}][${TOOLTIP_SHOW_NUM[gKey]}]`, '')}个)`
      }
      //收益率超额 不显示额外string内容
      const isNotShowExtra = _.includes(n.seriesName, '(超额)') || _.includes(n.seriesName, '(几何)');
      if (isNotShowExtra) {
        extraStringValue = '';
      }
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${renderValue}%</span>    
                  ${!!isNotToday ? extraStringValue : ''}` // 实时无需展示额外括号数据
        + '<br/>';
    });
    // ！！ 暂时 没和swith开关进行联动，因该function内获取showUpDown数据一直等于false ！！
    let timeIndex = _.findIndex(times, o => o === getx); // 验证相同时间的数据
    // 子账户，switchAll有选中进行显示额外tooltip信息
    if (timeIndex !== -1 && role !== 'visitor' && pageKeys === 'lists' && _.size(pageState.switchAll) > 0) {
      finalString = finalString + '<br/>' // 仿照<span>配置style，会有显示不全的情况，所以样式暂时只用普通样式。
      const filterTooltipList = _.filter(TOOLTIP_SHOW_LIST, o => _.includes(pageState.switchAll, o.typeKey))
      filterTooltipList.map(n => {
        const ovalue = _.get(analysisState.otherList, `[${timeIndex}][${n.key}]`, '');
        finalString = finalString + `${n.title}: ${ovalue}${_.includes(n.key, 'Rate') && ovalue !== '' ? '%' : ''}` + '<br/>';
      })
    }
    return finalString;
  }

  useUnmount(() => {
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
    timer4 && clearTimeout(timer4);
  });
  // 两日持仓
  async function _showPositionSort() {
    const isProductPage = _.get(props, 'pageKey') === 'products' ? true : false;
    pageState.posLoading = true;
    const getSort = await _showPositionSortFunc(
      isProductPage ? _.get(props, 'productId') : _.get(props, 'subAccountId'),
      _.get(props, 'pms.date', ''),
      isProductPage,
    );
    setPositionSorts(getSort);
    pageState.posShow = true;
    document.documentElement.scrollTop = document.body.scrollTop = 380;
    pageState.posLoading = false;
  };
  // 板块指数信息
  async function _showPlateKline(code) {
    let tempPlate = _.cloneDeep(plateIndex);
    let params = {
      symbol: code,
      dateType: _.get(props, 'pms.dateType'),
      date: _.get(props, 'pms.date')
    }
    const res = await showIndexKline(params);
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {});
      _.set(tempPlate, params.symbol, getData);
      setPlateIndex(tempPlate);
    }
    setPlUpdate(_.round(plUpdate + 0.3, 1));
  }
  // 收益率在未开盘数据为空时，显示日收益率
  const isValidPoint = (v) => v ? _.round(v, SHOW_CARRY) + '%' : _.get(props, 'dailyRevenueRate', 0) + '%';
  // __________________________________________切换tab和重新加载图表数据时调用____________________________________________
  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart; let myChart2 = props.myChart; let myChart4 = props.myChart; let myChart5 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
      myChart2.dispose();
    }
    myChart = echarts.init(document.getElementById('main_charts'));
    myChart2 = echarts.init(document.getElementById('main_retreat'));
    myChart4 = echarts.init(document.getElementById('main_extra_down'));
    myChart5 = echarts.init(document.getElementById('main_extra_range'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    const pmsDateType = _.get(props, 'pms.dateType', '');
    const isToday = pmsDateType === 'TODAY' ? true : false;
    myChart.on('click', 'series.line', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        if (pmsDateType !== 'TODAY') {//跳转至指定日期的分时数据
          props.checkDayRate('11', param.name);
          setActiveKey('1'); // 更新页面active状态；
          return;
        }
        // 点击涨停文字，弹出具体票信息
        const getx = _.get(param, 'data.xAxis', '');
        const filters = _.filter(markRef.current, o => o.xAxis === getx);
        if (_.size(filters) > 0) {
          notification.open({
            key: 'limit_notice',
            message: '涨停票',
            description: <div>
              {filters.map((n, i) => <>
                <Text key={'l' + i} type='secondary'>{`${n.xAxis} : `}</Text>
                <Text strong>{n.value}</Text>
                <Text>{`-${renderText(_.get(n, 'plate'))}-${renderText(_.get(n, 'industry'))}`}</Text>
                <br />
              </>)}
            </div>,
            placement: 'bottom',
          });
        }
      }, 600);
    });
    //当前收益率拥有的年份
    const getValidDate = _.get(props, 'validDateRange', []);
    let years = [];
    getValidDate.map(y => {
      const this_year = moment(y).year();
      if (!_.includes(years, this_year)) {
        years.push(this_year);
      }
    });
    pageState.tabYears = years.map(y => { return { 'key': y, 'tab': y } });
    // 对比rate之后的收益率
    const isDiffRate = _.get(props, 'pms.rate', false);

    let newOption = option; let newOption2 = option2;
    let newOption4 = _.cloneDeep(option4);
    let newOption5 = pageState.type !== 'ranges' ? _.cloneDeep(EXTRA_BAR) : _.cloneDeep(COMMON_CHART.extraPie);
    let sArray = []; // slider所需的value数据，纯数字，包含slider的最大最小值;

    const newSeriesData = _.get(props, 'datas.newData', {}); // 已处理及计算的所有数据；
    const xdata = _.get(props, 'datas.timeList', []);
    const newTimes = renderRateTimes(_.get(props, 'datas.timeList', []), activeKey);
    //const pointsData = isCombind ? _.get(props, 'datas.pointList') : _.get(newSeriesData, 'pointList', []);
    const pointsData = _.get(newSeriesData, 'pointList', []);
    //console.log('newSeriesData', newSeriesData)

    if (_.size(pointsData) > 0 && _.size(xdata) > 0 && _.size(_.get(props, 'indexValue', {})) > 0) {
      markRef.current = _.get(newSeriesData, 'fullMarks', []);
      analysisState.otherList = _.get(newSeriesData, 'other', []);
      analysisState.pointsNet = _.get(newSeriesData, 'pointsNetList', []);
      analysisState.points = pointsData;
      analysisState.dateType = pmsDateType;
      // 收益率：
      newOption.xAxis.data = newTimes
      newOption.series[0].data = pointsData;
      // 处理指数数据；
      const newIndexs = handleIndexValue(
        pmsDateType,
        newTimes,
        { data: _.get(props, 'indexValue'), lastIndex: _.size(pointsData) - 1 }
      );
      let indexValues = newIndexs.indexValues;
      let priceValues = newIndexs.priceValues;
      sArray = newIndexs.stimeArr;
      setTimeArr(sArray);
      setTimeNameArr(newTimes);
      // 赋值slider的滑动数据
      if (_.last(sArray)) {
        setSliderValue([0, _.last(sArray)]);
        setSliderValue2([0, _.last(sArray)]);
      }
      // tooltips 使用{日期:值}的方式，快速建立每日收益的对应关系，减少findindex查找
      const pnet = _.concat([0], _.get(newSeriesData, 'pointsNetList', []));
      let proTool = { 'index': {}, 'nets': {} };
      newTimes.map((date, i) => {
        _.set(proTool.index, date, newIndexs.dayPriceVals[i]);
        _.set(proTool.nets, date, pnet[i]);
      });
      analysisState.proTools = proTool;
      //const newIndexName = _.get(INDEX_CODE_VALUE, isCombind ? _.get(props, 'indexSymbol') : idValue, '');
      _.set(newOption, 'legend.data[1]', INDEX_CODE_VALUE[idValue]);
      newOption.series[1].data = indexValues;
      newOption.series[1].name = INDEX_CODE_VALUE[idValue];
      analysisState.idxName = INDEX_CODE_VALUE[idValue];
      _.set(newOption, 'legend.data[1]', '波幅');
      // 多种收益率曲线计算：
      newOption = handleRateOption(
        subValues,
        newOption,
        newSeriesData,
        0,
        [0, _.last(timeArr)],
        'full'
      )
      // 所有超额收益率计算
      newOption = handleExtraOption(
        subValues,
        newOption,
        [0, ...analysisState.pointsNet], // 增加几何超额计算，pointNets第一位需要加 0
        newIndexs.dayPriceVals,
        _.includes(pageState.switchAll, 'extra_switch'),
        isToday
      );
      const getExtraPoints = _.get(newOption, 'series[2].data', []);
      analysisState.extraList = getExtraPoints;
      analysisState.daliyExtra = _.get(newOption, 'series[2].data3', []); // 超额统计赋值每日收益
      //【新】板块指数累加
      let filterPlates = {};
      plateValue.map(k => {
        let nindex = _.findIndex(PLATE_OPTIONS, o => o.value === k);
        _.set(filterPlates, k, _.assign(_.get(plateIndex, k, {}), { name: nindex !== -1 ? PLATE_OPTIONS[nindex].label : k }));
      });
      newOption = handlePlateDatas(filterPlates, newOption, 'full', { 'dateType': pmsDateType, 'times': newTimes });
      setFilterPlateData(filterPlates);
      // 回撤部分
      const extraPointGeo = isToday ? _.get(newOption, 'series[2].data', []) : _.get(newOption, 'series[3].data', []);
      const idxRetreat = handleRetreat(priceValues); // 指数计算*100
      const getPointRetreat = _.get(newSeriesData, 'pointRetreat', []);
      //const getPointRetreat = isCombind ? _.get(props, 'datas.pointRetreat') : _.get(newSeriesData, 'pointRetreat', []);
      newOption2 = renderRetreatOption(
        newOption2,
        newOption,
        getPointRetreat,
        idxRetreat,
        extraPointGeo,
        newTimes,
      );
      // 实时不显示symbol
      if (isToday) {
        newOption.series[0].symbol = 'none'
      } else {
        newOption.series[0].symbol = 'circle'
      }
      //formatter： 初始option创建时数据取的是初始值
      newOption.tooltip.formatter = renderFormatter; //重新赋值formatter,这样可以获取最新state的数据
      // 今年以来进入处理function，处理完进入本地updateCount进行更新裁剪数据; 
      //【新】增加年份裁切，使用成立按照对应年份裁切；删除今年以来，与年份功能相同
      const cur_tab = _.get(props, 'currentTabs', '1');
      if (parseInt(cur_tab) > 100) {
        handleFromYear(parseInt(cur_tab), newTimes);
      };
      // 动态记录最后一个点位数值
      analysisState.lastPoint = _.last(_.get(newOption, 'series[0].data', []));
      analysisState.lastIdx = _.last(_.get(newOption, 'series[1].data', []));
      analysisState.lastExtra = _.last(_.get(newOption, 'series[2].data', []));
      setSliderDis(false);
      //【原】超额计算图表，统一在修改effect里面进行显示，故更新数据后切换到回撤图表
      pageState.downCharts = 'retreat';
    } else {
      // 空数据置空series
      newOption.series.map(s => _.set(s, 'data', []));
      newOption2.series.map(s => _.set(s, 'data', []));
      setSliderDis(true);
    };
    // 如带checkbox选项切换，则按照已选择重新选择tab
    let newChrTab = CHART_TAB_OPTION;
    if (_.size(subValues) > 0) {
      const filterArray = _.filter(subItems, o => _.includes(subValues, o.key));
      newChrTab = _.concat(
        CHART_TAB_OPTION,
        filterArray.map(f => labelValues([f.name, STR_OTHER + f.key]))
      );
    };
    setChrtabs(!isToday ? newChrTab : [CHART_TAB_OPTION[0]]);
    // 最后渲染markpoint数据，笔记需要y,x最后的坐标信息
    const getMarks = _.get(newSeriesData, 'limitMarks', []);
    if (_.size(getMarks) > 0 && _.includes(pageState.switchAll, 'updown_stock')) { // 涨停票显示文字
      newOption.series[0].markPoint.data = getMarks;
    };
    newOption.series[0].lineStyle.color = isDiffRate ? CONFIG_COLOR : BASE_COLOR
    newOption.series[0].itemStyle.color = isDiffRate ? CONFIG_COLOR : BASE_COLOR
    newOption.series[0].areaStyle.color = isDiffRate ? CONFIG_AREA : BASE_AREA;

    setoption(newOption); setoption2(newOption2);
    setoption4(newOption4); setoption5(newOption5);
    // 增加trycatch 捕获因切换导致的setOption报错问题；【tips】开发中会发生，服务器未引发过白屏
    try {
      myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
      myChart4.setOption(newOption4, true); myChart5.setOption(newOption5, true);
      myChart.hideLoading();
      myChart.resize(); myChart2.resize();
      myChart4.resize(); myChart5.resize();
      echarts.connect([myChart, myChart2]); // 新增联动
    } catch (error) {
      console.error(error)
    }
  }, [props.upcount, plUpdate]);
  // slider 完成后调用，updateCount更新调用
  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart; let myChart4 = props.myChart; let myChart5 = props.myChart; let myChart6 = props.myChart;
    myChart = echarts.init(document.getElementById('main_charts'));
    myChart2 = echarts.init(document.getElementById('main_retreat'));
    myChart4 = echarts.init(document.getElementById('main_extra_down'));
    myChart5 = echarts.init(document.getElementById('main_extra_range'));
    myChart6 = echarts.init(document.getElementById('main_extra_overlap'));

    let newOption = option; let newOption2 = option2;
    let newOption4 = _.cloneDeep(option4);
    let newOption5 = pageState.type !== 'ranges' ? _.cloneDeep(EXTRA_BAR) : _.cloneDeep(COMMON_CHART.extraPie);
    let newOption6 = _.cloneDeep(EXTRA_BAR);

    const pmsDateType = _.get(props, 'pms.dateType', '');
    const isDiffRate = _.get(props, 'pms.rate', false);
    const isNotToday = pmsDateType !== 'TODAY' ? true : false;
    const newSeriesData = _.get(props, 'datas.newData', {});
    //const pointsData = isCombind ? _.get(props, 'datas.pointList') : _.get(newSeriesData, 'pointList', []);
    const pointsData = _.get(newSeriesData, 'pointList', []);
    const pointsNet = calNets(pointsData); // 收益率计算波幅需按照每个收益率的净值计算 : 1*(1+(净值/100))
    const newTimes = renderRateTimes(_.get(props, 'datas.timeList', []), activeKey);
    const getIndexPoint = _.get(props, 'indexValue.pointList', []);
    const indexTimes = _.get(props, 'indexValue.timeList', []);
    const indexPreClose = _.get(props, 'indexValue.preClose', 0);
    const newIndexClose = calTimeDiff(newTimes, indexTimes, getIndexPoint, indexPreClose, isNotToday);
    const isFullRange = isFullTimeRange(sliderValue, timeArr); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    const isFisrtZero = isSliderFirstZero(sliderValue, timeArr); //sliderValue[0] !== 0时才需要截取数据，右侧滑动时无需重更改startVal值
    const newIndexs = handleIndexValue(
      pmsDateType,
      newTimes,
      { data: _.get(props, 'indexValue'), lastIndex: _.size(pointsData) - 1 }
    );
    const indexPrice = newIndexs.priceValues; // 只获取价格
    const indexNet = newIndexs.dayPriceVals;
    const sliceTimes = isFullRange ? newTimes : renderSlice(newTimes, sliderValue[0], sliderValue[1]);
    // 根据slider的结果截取数据；
    const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]); // 净值slice
    let sliceNetOrg = renderSlice([0, ...analysisState.pointsNet], sliderValue[0], sliderValue[1]); // 初始计算净值slice，需要增加首位0
    let sliceIndexNet = renderSlice(indexNet, sliderValue[0], sliderValue[1]);// 指数净值slice
    let sliceIndexPriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]); // 指数价格slice
    const indexStartVal = (isFullRange || isFisrtZero) ? newIndexClose : _.get(sliceIndexPriceOrg, '[0]', 0); //指数收盘价取值
    // 根据截取indexValue及point收益率 数据渲染指数波幅
    let indexRate = calRate(indexPrice, newIndexClose); // 计算完整的指数波幅数据
    let sliceIndexRate = calRate(sliceIndexPriceOrg, indexStartVal); // 指数波幅slice
    // 【bug-fix】如计算时已添加0为第一位，只滑动右侧，开始的计算会出问题，所以slice时start默认支取第0位的数据
    const getStart = parseFloat(_.get(sliceRateOrg, `[0]`, 0));
    let sliceRates = calRate(sliceRateOrg, getStart);
    // 非实时数据第一位置为0, 【PS】当前计算流程中，赋值第一位0操作可保证计算准确性，必要操作！
    if (isNotToday) {
      indexRate[0] = 0; sliceIndexRate[0] = 0; sliceNetOrg[0] = 0; sliceIndexNet[0] = 0;
      sliceIndexPriceOrg[0] = indexStartVal;
    }
    newOption.series[0].data = isFullRange ? pointsData : sliceRates; // 全时间段无需使用净值数据
    newOption.xAxis.data = sliceTimes;
    newOption.series[1].data = isFullRange ? indexRate : sliceIndexRate;
    // 全数据通过key=full，渲染时不做计算处理
    let typeKey = isFullRange ? 'full' : 'slice';
    // 涨跌停幅收益率：根据开关生成数据，每次都重新计算option及最大最小值；
    newOption = handleRateOption(
      subValues,
      newOption,
      newSeriesData,
      0,
      [sliderValue[0], sliderValue[1]],
      typeKey
    );
    //所以数据都需要增加超额计算，故统一对option进行处理;
    newOption = handleExtraOption(
      subValues,
      newOption,
      sliceNetOrg,
      sliceIndexNet,
      _.includes(pageState.switchAll, 'extra_switch'),
      pmsDateType === 'TODAY' ? true : false
    );
    const getExtraPoints = _.get(newOption, 'series[2].data', []);
    // 动态记录最后一个点位数值
    analysisState.lastPoint = _.last(_.get(newOption, 'series[0].data', []));
    analysisState.lastIdx = _.last(_.get(newOption, 'series[1].data', []));
    analysisState.lastExtra = _.last(getExtraPoints);
    // 板块指数
    newOption = handlePlateDatas(
      filterPlateData,
      newOption,
      typeKey,
      {
        'isFull': (isFullRange || isFisrtZero) ? true : false, 'notToday': isNotToday, 'sliderValue': sliderValue,
        'dateType': pmsDateType, 'times': newTimes
      }
    );
    // markpoint
    const getMarks = _.get(newSeriesData, 'limitMarks', []);
    if (_.size(getMarks) > 0 && _.includes(pageState.switchAll, 'updown_stock')) {
      newOption.series[0].markPoint.data = getMarks;
    }
    // 回撤部分
    const extraPointGeo = isNotToday ? _.get(newOption, 'series[3].data', []) : _.get(newOption, 'series[2].data', []);
    const getPointRetreat = isFullRange ? _.get(newSeriesData, 'pointRetreat') : handleRetreat(sliceRateOrg);
    //const getPointRetreat = isFullRange ? _.get(isCombind ? props.datas : newSeriesData, 'pointRetreat') : handleRetreat(sliceRateOrg, 100);
    const idxRetreat = handleRetreat(sliceIndexPriceOrg)
    newOption2 = renderRetreatOption(
      newOption2,
      newOption,
      getPointRetreat,
      idxRetreat,
      extraPointGeo,
      sliceTimes,
    );
    // 赋值其他图表数据；直接裁剪
    //【新】增加不同收益数据源的切换，今仓，涨停等，与checbox选择进行联动
    if (pageState.downCharts !== 'retreat') {
      // 计算日超额
      let daliy_rate = []; let dname = '';
      let isAccumulate = false;
      if (pageState.downCharts === 'extra') {
        daliy_rate = _.cloneDeep(analysisState.daliyExtra);
        dname = '超额';
      } else {
        let getDataSource = _.get(newSeriesData, pageState.downCharts, []);
        dname = '收益';
        if (_.includes(pageState.downCharts, STR_OTHER)) {
          const okey = _.replace(pageState.downCharts, STR_OTHER, '');
          getDataSource = _.get(newSeriesData, 'other', []).map(n => _.get(n, okey));
          dname = _.chain(subItems).filter(o => o.key === okey).head().get('name').value();
          isAccumulate = _.includes(okey, STR_PLUS);
        };
        daliy_rate = _.concat([0], getDataSource);
      };
      if (_.includes(CHART_TAB_ARRAY, pageState.downCharts)) {
        pageState.isRangeExtra = false;
      };
      const newDaliy = handleDaliyRates(
        isFullRange ? daliy_rate : renderSlice(daliy_rate, sliderValue[0], sliderValue[1]),
        isFullRange ? indexNet : renderSlice(indexNet, sliderValue[0], sliderValue[1]),
        sliceTimes,
        _.get(props, 'tradeDates', []),
        pageState.isRangeExtra,
        isAccumulate
      );
      newOption4.series[0].data = _.get(newDaliy, 'bar4');
      newOption4.series[0].name = dname;
      newOption4.series[1] = createBaseLine('#879494', {
        'name': '日均',
        'data': calMaLine(_.get(newDaliy, 'bar4').map(n => n.value), 5, 'rate')
      });
      newOption4.xAxis.data = sliceTimes;
      newOption4.series[0].label.show = _.size(sliceTimes) > 60 ? false : true; // 时间区间过长可以不显示文字
      // 增加周、月饼图功能；
      let final5 = []; let axis5 = []; let val5 = [];
      if (pageState.type !== 'ranges') {
        _.keys(_.get(newDaliy, pageState.type, {})).map(keys => {
          const gval = _.get(newDaliy, `${pageState.type}.${keys}`, 0);
          let colorIdx = _.findIndex(INTERVAL_KEYS, o => gval > _.get(o, 'min') && gval < _.get(o, 'max'));
          let obj5 = {
            'value': gval,
            'itemStyle': { 'color': _.get(UPDOWN_COLOR, colorIdx !== -1 ? INTERVAL_KEYS[colorIdx]['colorPath'] : 'flat') }
          };
          final5.push(obj5);
          axis5.push(keys);
          val5.push(gval);
        });
        newOption5.series[0].data = final5;
        newOption5.series[1] = createBaseLine('#879494', {
          'name': EXTRA_AVG[pageState.type][0] + '均',
          'data': calMaLine(val5, EXTRA_AVG[pageState.type][1], 'rate')
        });
        newOption5.xAxis.data = axis5;
        newOption5.series[0].label.show = _.size(final5) > 25 ? false : true; // 时间区间过长可以不显示文字
      } else {
        // ranges的时候显示单一饼图
        newOption5.series[0].data = _.get(newDaliy, 'pies');
      };
      setDaliyRates(newDaliy);
      //叠加功能计算
      if (pageState.isOverlap && pageState.type !== 'ranges') {
        const newBatchDaliy = batchCalDaliy(isFullRange, indexNet, sliceTimes);
        let newBar = []; let batchTime = [];
        _.keys(newBatchDaliy).map(keyname => {
          const getItemData = _.get(newBatchDaliy, `${keyname}.${pageState.type}`, []);
          let item = {
            'name': _.chain(subItems).filter(o => o.key === keyname).head().get('name').value(),
            'type': 'bar', 'stack': 'batch', 'data': getItemData,
            'label': { show: false }
          };
          newBar.push(item);
          batchTime = _.get(newBatchDaliy, `${keyname}.rtimes.${pageState.type}`, []);
        });
        newOption6.series = newBar;
        newOption6.xAxis.data = batchTime;
      }
    };

    newOption.series[0].lineStyle.color = isDiffRate ? CONFIG_COLOR : BASE_COLOR
    newOption.series[0].itemStyle.color = isDiffRate ? CONFIG_COLOR : BASE_COLOR
    newOption.series[0].areaStyle.color = isDiffRate ? CONFIG_AREA : BASE_AREA;
    setoption(newOption); setoption2(newOption2);
    setoption4(newOption4); setoption5(newOption5);
    /*
    【bug-fix】: 选择checkbox正常，当subValues为空(取消选择select)后会报错：
    setOption` should not be called during main process.
    解决方法试设置timeout，结束后可以正常执行mychart的逻辑
    */
    if (analysisState.isSwitchCancel) {
      timer2 = setTimeout(() => {
        myChart.setOption(newOption, true);
        myChart.hideLoading();
        myChart.resize();
      }, 200);
    } else {
      myChart.setOption(newOption, true);
      myChart.hideLoading();
      myChart.resize();
    }
    myChart2.setOption(newOption2, true); myChart6.setOption(newOption6, true);
    myChart4.setOption(newOption4, true); myChart5.setOption(newOption5, true);
    myChart2.resize(); myChart4.resize(); myChart5.resize(); myChart6.resize();
  }, [updateCount, pageState.downCharts, pageState.upCharts]);
  // 上层reload重载，加载另外一个产品或子账户，关闭持仓小窗
  useUpdateEffect(() => {
    pageState.showBrief = false;
  }, [props.reloadCount]);
  // 更新useUpdateEffect内charts数据
  const updateThisCharts = () => setUpdateCount(_.round(updateCount + 0.1, 1));
  // 切换tab
  function _cutDateType(key) {
    const curTabs = _.get(props, 'currentTabs');
    setSliderValue([]);
    setPlateValue([]);
    setFilterPlateData({});
    setActiveKey(key);
    pageState.showBrief = false;
    if (key === curTabs) return;
    if (props.cutDateType) props.cutDateType(key);
  }
  // 处理今年以来数据及时间
  function handleFromYear(initYear = 0, newTime = []) {
    // 按顺序找到第一个与今年同年的日期，因不确定今年第一个交易日是哪天
    const sizeTime = _.size(newTime);
    let fIndex = -1; let lastIndex = -1;
    for (let index = 0; index < sizeTime; index++) {
      const sYear = _.split(newTime[index], '-')[0];
      if (initYear === parseInt(sYear)) {
        fIndex = index;
        break;
      }
    };
    // 反向查找当年份最后一个index
    for (let index = sizeTime - 1; index > 0; index--) {
      const sYear = _.split(newTime[index], '-')[0];
      if (initYear === parseInt(sYear)) {
        lastIndex = index;
        break;
      }
    };
    // 有第一个值直接更新slider及图表
    if (fIndex !== -1 && lastIndex !== -1) {
      setSliderValue([fIndex - 1, lastIndex]);
      setSliderValue2([fIndex - 1, lastIndex]);
      updateThisCharts();
    }
  }
  // 收益率显示开关控制； 【新】修改为select数组处理模式
  const onSelectChange = (values) => {
    pageState.switchAll = values;
    //extra_switch 是超额数据计算开关
    if (_.includes(values, 'updown_stock') || _.includes(values, 'extra_switch')) {
      updateThisCharts();
    } else { // 提取所选的keyArray数据
      let selectKeyArray = []; let unselect = [];
      CHECK_TAG_ARRAY.map(n => {
        if (_.includes(values, n.key)) {
          selectKeyArray = _.concat(selectKeyArray, n.keyArray)
        } else {
          unselect = _.concat(unselect, n.keyArray)
        }
      })
      // 分类tag的开关处理;【新】修改为select数组处理模式，直接传入选择及未选择项
      let tempCheck = _.cloneDeep(subValues);
      _.pullAll(tempCheck, unselect.map(k => k.key));
      setSubItems(selectKeyArray);
      updateThisCharts();
    }
    // 取消选择将value置空
    if (!isValidArray(values)) {
      setSubValues([]);
      setChrtabs(CHART_TAB_OPTION);
    }
  }
  // tag开关；【新】当前只有超额的switch用该方法，其他均集成在select里面
  const onCheckTagChange = (checked, keys) => {
    let tempCheck = _.cloneDeep(pageState.switchAll);
    if (checked) {
      tempCheck = _.concat(tempCheck, [keys]);
    } else {
      _.pullAll(tempCheck, [keys]);
    }
    onSelectChange(tempCheck);
  }
  // checkbox.group 修改选择项
  const onSubValuesChange = (checkedValues) => {
    const filterArray = _.filter(subItems, o => _.includes(checkedValues, o.key));
    setChrtabs(_.concat(
      CHART_TAB_OPTION,
      filterArray.map(f => labelValues([f.name, STR_OTHER + f.key]))
    ));
    setSubValues(checkedValues);
    updateThisCharts();
  };
  // 自定义分组修改选择，filterKey数组中包含该key值则筛选出来
  const onSubValuesGroupChange = (key) => {
    let final = []; let finalTab = [];
    if (key === 'all') { // 全选操作
      final = subItems.map(f => f.key);
      finalTab = subItems.map(f => labelValues([f.name, f.key]));
    } else if (key === 'clear') { // 清除
      // setSubValues([]);
    } else {
      const filterArray = _.filter(subItems, o => _.includes(o.filterKey, key));
      final = filterArray.map(f => f.key);
      finalTab = filterArray.map(f => labelValues([f.name, STR_OTHER + f.key]));
    };
    setSubValues(final);
    setChrtabs(_.concat(CHART_TAB_OPTION, finalTab));
    updateThisCharts();
  };
  // 板块切换
  function onPlateChange(v) {
    setPlateValue(v);
    if (_.size(v) < _.size(plateValue)) { // 删除直接更新
      if (isValidArray(v)) {
        let filterPlates = {};
        v.map(k => {
          let nindex = _.findIndex(PLATE_OPTIONS, o => o.value === k);
          _.set(filterPlates, k, _.assign(_.get(plateIndex, k, {}), { name: nindex !== -1 ? PLATE_OPTIONS[nindex].label : k }));
        });
        setFilterPlateData(filterPlates);
      } else {
        setFilterPlateData({});
      }
      updateThisCharts();
      return;
    }
    _showPlateKline(_.last(v));
  }
  // slider完成后
  function onSliderFinish(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updateThisCharts();
  }
  // 持仓小窗全局刷新操作
  async function onBriefSync() {
    const isProductPage = _.get(props, 'pageKey') === 'products' ? true : false;
    pageState.brLoading = true;
    const posInfo = {
      'isProduct': isProductPage, 'productId': _.get(props, 'productId', 0), 'subAccountId': _.get(props, 'subAccountId', 0),
    };
    const posList = await _getPositions(posInfo);
    if (isValidArray(posList)) {
      setBriefPositon(posList);
      mobxStore.savePositionList(posList);
    }
    pageState.brLoading = false;
  };
  // 批量计算daliy数据；符合条件的收益率批量计算统计数据
  function batchCalDaliy(isfull, idxnet, times) {
    const newSeriesData = _.get(props, 'datas.newData', {});
    let finals = {};
    chrtabs.map(itm => {
      if (!_.includes(CHART_TAB_ARRAY, itm.value)) {
        if (_.includes(itm.value, STR_OTHER)) {
          const okey = _.replace(itm.value, STR_OTHER, '');
          let getDataSource = _.get(newSeriesData, 'other', []).map(n => _.get(n, okey));
          let isAccumulate = _.includes(okey, STR_PLUS);
          let daliy_rate = _.concat([0], getDataSource)
          const newDaliy = handleDaliyRates(
            isfull ? daliy_rate : renderSlice(daliy_rate, sliderValue[0], sliderValue[1]),
            isfull ? idxnet : renderSlice(idxnet, sliderValue[0], sliderValue[1]),
            times,
            _.get(props, 'tradeDates', []),
            pageState.isRangeExtra,
            isAccumulate
          );
          let rangeObj = {}; let rtimes = {};
          ['weeks', 'months', 'season'].map(rangeKey => {
            let rangeFinal = []; let rangeTimes = [];
            _.keys(_.get(newDaliy, rangeKey, {})).map(keys => {
              const gval = _.get(newDaliy, `${rangeKey}.${keys}`, 0);
              let colorIdx = _.findIndex(INTERVAL_KEYS, o => gval > _.get(o, 'min') && gval < _.get(o, 'max'));
              let ritem = {
                'value': gval,
                'itemStyle': { 'color': _.get(UPDOWN_COLOR, colorIdx !== -1 ? INTERVAL_KEYS[colorIdx]['colorPath'] : 'flat') }
              };
              rangeFinal.push(ritem);
              rangeTimes.push(keys);
            });
            _.set(rangeObj, rangeKey, rangeFinal);
            _.set(rtimes, rangeKey, rangeTimes);
            _.set(rangeObj, 'rtimes', rtimes);
            // _.set(rangeObj, rangeKey + 'Cal', _.get(newDaliy, rangeKey));
          });
          _.set(finals, okey, rangeObj);
        };
      };
    });
    return finals;
  };

  const getPmsDate = _.get(props, 'pms.dateType', ''); //时间周期type
  const col_left = autoCol([1, 2, 6]);
  const col_right = autoCol([23, 22, 18]);
  const col_right_top = autoCol(pageState.showBrief ? [16, 16, 12] : [23, 22, 18])
  const col_brief = pageState.showBrief ? autoCol([7, 7, 7]) : autoCol([0]);
  const isRetreat = pageState.downCharts === 'retreat' ? true : false;
  const charts_style = { width: '100%', height: 410 };
  const barsProps = {
    'isRealtime': _.get(props, 'currentTabs', '1') === '1' ? true : false, 'isListPage': pageKeys === 'lists' ? true : false, 'isCombind': isCombind,
    'pmsDateValue': _.get(props, 'pms.date', ''), 'validRanges': _.get(props, 'validDateRange', []), 'switchs': pageState.switchAll,
    'checkboxItem': subItems, 'checkboxValues': subValues, 'posLoading': pageState.posLoading, 'showBrief': pageState.showBrief, 'isDiffRate': _.get(props, 'pms.rate', false),
    'rateObj': { 'rate': isValidPoint(analysisState.lastPoint), 'idx': isValidRate(analysisState.lastIdx), 'extra': isValidRate(analysisState.lastExtra) },
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <MainRangeTabs
        active={activeKey}
        idv={idValue}
        plateVal={plateValue}
        isManager={is_manager}
        years={pageState.tabYears}
        onTabChange={_cutDateType}
        onPlateChange={(v) => onPlateChange(v)}
        onIndexChange={(v) => {
          props.indexChange(v)
          setIdValue(v);
        }}
      />

      <div>
        <MainBar
          {...barsProps}
          onSingleDateChange={(dates) => props.checkDayRate('11', dates)}
          tagChange={onCheckTagChange}
          subGroupChange={onSubValuesGroupChange}
          subValueChange={onSubValuesChange}
          selectChange={onSelectChange}
          positionShow={() => _showPositionSort()}
          onBriefChange={(checked) => {
            pageState.showBrief = checked;
            if (checked) {
              //const posList = isHosting ? _.get(props, 'hostPosition', []) : JSON.parse(JSON.stringify(mobxStore.positionList));
              const posList = JSON.parse(JSON.stringify(mobxStore.positionList));
              setBriefPositon(isValidArray(posList) ? posList : []);
            } else {
              setBriefPositon([]);
            }
            updateThisCharts();
          }}
          onDiffChange={() => {
            // 差异配置的开关，如有rate配置值，则点击为取消重新获取操作
            const isDiffRate = _.get(props, 'pms.rate', false);
            if (isDiffRate) { // 取消差异配置
              props.rateDiffChange({ 'diffVal': 0 })
            } else {
              pageState.diffShow = !pageState.diffShow;
            }
          }}
        />

        <Row>
          <Col {...col_left}></Col>
          <Col {...col_right_top}>
            <div style={{ display: pageState.upCharts === 'rate' ? 'flex' : 'none' }}>
              <div id="main_charts" style={charts_style} />
            </div>
          </Col>
          <Col {...col_brief}>
            <BriefTable
              show={pageState.showBrief}
              datas={briefPosition}
              loading={pageState.brLoading}
              onSync={onBriefSync}
            />
          </Col>
        </Row>

        <br />

        <MainSlider
          isNotToday={getPmsDate !== 'TODAY' ? true : false}
          isFull={isFullTimeRange(sliderValue, timeArr)}
          timeNameArray={timeNameArr}
          timeArray={timeArr}
          sDisable={sliderDis}
          value={sliderValue}
          svalue={sliderValue2}
          onSliderChange={(v) => setSliderValue2(v)}
          onSliderAfterChange={(v) => onSliderFinish(v)}
          onReset={() => onSliderFinish([0, _.last(timeArr)])}
        />

        <Row>
          <Col {...col_left} style={{ paddingTop: 18 }}>
            <ChartTabs
              options={chrtabs}
              active={pageState.downCharts}
              onTabChange={(activeKey) => pageState.downCharts = activeKey}
            />
          </Col>
          <Col {...col_right}>
            <div style={{ display: isRetreat ? 'flex' : 'none', marginTop: 25, marginBottom: 15 }}>
              <div id="main_retreat" style={charts_style} />
            </div>
            <div style={{ display: !isRetreat ? 'flex' : 'none', marginTop: 25 }}>
              <div id="main_extra_down" style={{ width: '100%', height: 350 }} />
            </div>
            <div style={{ display: !isRetreat ? 'flex' : 'none', marginBottom: 15 }}>
              <div id="main_extra_range" style={{ width: '100%', height: 275 }} />
            </div>
            <div style={{ display: pageState.isOverlap ? 'flex' : 'none', marginBottom: 15 }}>
              <div id="main_extra_overlap" style={{ width: '100%', height: 535 }} />
            </div>

            <ExtraMonthTable
              isShow={!isRetreat ? true : false}
              isExtra={pageState.isRangeExtra}
              chrtabSize={_.size(chrtabs)}
              isOverlap={pageState.isOverlap}
              noExtra={_.includes(CHART_TAB_ARRAY, pageState.downCharts)}
              extras={daliyRates}
              onTypeChange={(key, v) => {
                pageState[key] = v;
                if (v === 'ranges') {
                  pageState.isOverlap = false;
                };
                updateThisCharts();
              }}
              onExtraChange={(checked) => {
                pageState.isRangeExtra = checked;
                updateThisCharts();
              }}
              onOverlapChange={(checked) => {
                pageState.isOverlap = checked;
                updateThisCharts();
              }}
            />
          </Col>
        </Row>

        <MainLimitTable datas={markRef.current} />
      </div>

      <PositionDrawer
        visible={pageState.posShow}
        datas={positionSorts}
        onClose={() => pageState.posShow = false}
      />

      {pageKeys !== 'lists' && <RateDiffModal
        isShow={pageState.diffShow}
        onCancel={() => pageState.diffShow = false}
        onConfirm={(value) => {
          props.rateDiffChange(_.get(value, 'diff'));
          pageState.diffShow = false
        }}
      />}
    </div>
  )
}
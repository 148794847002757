import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Spin, Row, Tag, Modal, message, Select, Input } from 'antd';
import { Observer } from 'mobx-react';
import AddSubAccount from './AddSubAccount';
import { getSecuritiesTrader, delTradersSubAccount, delPostTrader, sharesselect, setupsharesselect, showFundManagerFuturePool, setFuturePool } from '@/api/workbench';
import AddTrader from './AddTrader';
import _ from 'lodash';
const { Option } = Select;
export default function TraderAdm(props) {
    const { Search } = Input;
    const [listData, setlistData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [spinning, setspinning] = useState(false);
    const [selectlist, setselectlist] = useState([]);
    const [futurePoolList, setfuturePoolList] = useState([]);
    const [idl, setidl] = useState(0);
    const [zdl, setzdl] = useState();
    const [productType, setproductType] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);

    async function showModal(record) {
        if (record.productType === 2) {
            const data = await showFundManagerFuturePool();
            if (data.code === '200') {
                setfuturePoolList(data.data);
            } else {
                message.error(data.message);
            }
        }
        setproductType(record.productType);
        setIsModalVisible(true);
        setzdl(record.subAccId)
    }

    async function handleOk() {
        setIsModalVisible(false);
        if (productType === 1) {
            let params = {
                stockPoolId: selectlist[idl].id,
                subAccountId: zdl,
            }
            const data = await setupsharesselect(params);
            if (data.code === '200') {
                message.success('设置股票池成功');
            } else {
                message.error(data.message);
            }
        } else if (productType === 2) {
            let params = {
                futurePoolId: futurePoolList[idl].id,
                subAccountId: zdl,
            }
            const data = await setFuturePool(params);
            if (data.code === '200') {
                message.success('设置期货池成功');
            } else {
                message.error(data.message);
            }
        }
        _get()
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        select()
    };
    const columns = [
        {
            title: '交易员',
            dataIndex: 'traderName',
            align: 'center',
            width: 200,
            filterDropdown: () => {
                return (
                    <Search
                        value={searchText}
                        onChange={searchChange}
                        placeholder="请输入交易员姓名"
                        allowClear
                        enterButton
                        onSearch={onSearch}
                    />
                );
            },
        },
        {
            title: '产品',
            dataIndex: 'productType',
            width: 250,
            align: 'center',
        },
        {
            title: '证券账户',
            dataIndex: 'securitiesAccount',
            width: 250,
            align: 'center',
        },
        {
            title: '子账户',
            dataIndex: 'subAccountList111',
            width: 250,
            align: 'center',
        },
        {
            title: '账户类型',
            dataIndex: 'accountType',
            width: 200,
            align: 'center',
        },
        {
            title: '交易池',
            dataIndex: 'stockPoolName',
            width: 200,
            align: 'center',
        },
        {
            title: '操作',
            align: 'center',
            render: (text, record, index) => (
                <Row >
                    {
                        record.productNameIdDTOList.length === 0 &&
                        <Button type="link" danger onClick={() => _delTrader(record)}>解绑</Button>
                    }
                    {/* <AddSubAccount record={record} goBack={_get} /> */}
                </Row>
            )
        }
    ]
    async function select() {
        const data = await sharesselect();
        if (data.code === '200') {
            setselectlist(data.data);
        } else {
            message.error(data.message);
        }
    }

    function getlistid(value) {
        let data = Number(value.value);
        setidl(data);
    }

    function searchChange(e) {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        select()
        handleCancel()

        async function _get() {
            setspinning(true);
            const data = await getSecuritiesTrader();
            if (data.code === '200') {
                setlistData(data.data.traderList);
            } else {
                setlistData([])
            }
            setspinning(false);
        }
        _get();
    }, [])

    async function _get() {
        setspinning(true);
        const data = await getSecuritiesTrader();
        if (data.code === '200') {
            setlistData(data.data.traderList);
        } else {
            setlistData([])
        }
        setspinning(false);
    }

    // 删除交易员账户
    async function _delTrader(record) {
        if (record.productNameIdDTOList.length !== 0) return message.error('该交易员名下有子账户!');
        const data = await delPostTrader({ traderId: record.traderId });
        if (data.code === '200') {
            message.success('删除交易员成功');
            _get();
        } else {
            message.error(data.message);
            _get();
        }
    }
    async function onSearch(value) {
        if (value === '') {
            const data = await getSecuritiesTrader();
            if (data.code === '200') {
                setlistData(data.data.traderList);
            }
            return;
        }
        var reg = new RegExp(value);
        var arr = [];
        if (listData.length !== 0) {
            listData.map(item => {
                // if (reg.test(item.traderName)) {
                //     arr.push(item);
                // }
                if (item.traderName && item.traderName?.search(value) !== -1) {
                    arr.push(item);
                }
                return item;
            })
            setSearchData(arr);
        }
    }

    // 删除子账户
    async function _deSubAcc(record) {
        // console.log(3333, record);
        Modal.confirm({
            title: '是否删除该子账户？',
            content: `${record.subAccName}`,
            okText: '确认',
            cancelText: '取消',

            onOk: async () => {
                const data = await delTradersSubAccount({ subAccountId: record.subAccId });
                if (data.code === '200') {
                    message.success('删除成功');
                    _get();
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    // 跳转到基金经理查看子账户
    function _goFundManagerListsDetails(record) {
        record.subAccountName = record.subAccName;
        record.subAccountId = record.subAccId;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        props.history.push({
            pathname: '/home/listsDetails',
            state: { subAccountInfo: record }
        });
    }


    const expandedRowRender = (fatRecord) => {
        const columnsChildren = [
            {
                title: '账户',
                dataIndex: 'test',
                // align: 'center',

                width: 200,
            },
            {
                title: '产品',
                // align: 'center',

                dataIndex: 'productName',
                width: 250,
                render: (text, record, index) => {
                    return <Tag color="blue">{text}</Tag>
                }
            },
            {
                title: '证券账户',
                // align: 'center',

                dataIndex: 'securitiesAccountName',
                width: 250,
                render: (text, record, index) => {
                    return <Tag color="blue">{text}</Tag>
                }
            },

            {
                title: '子账户',
                align: 'center',

                dataIndex: 'subAccName',
                width: 250,
                render: (text, record, index) => {
                    return <Tag color="blue">{text}</Tag>
                }
            },
            {
                title: '账户类型',
                align: 'center',

                dataIndex: 'productType',
                width: 200,
                render: (text, record, index) => {
                    return (
                        <Tag color="blue">
                            {text === 1 ? '股票账户' : text === 2 ? '期货账户' : text === 3 ? '大宗商品账户' : ''}
                        </Tag>
                    )
                }
            },
            {
                title: '交易池',
                align: 'center',

                dataIndex: 'stockPoolName',
                width: 200,
                render: (text) => {
                    return <Tag color="blue">{text}</Tag>

                }
            },
            {
                title: '操作',
                align: 'center',

                render: (record) => {
                    return (
                        <>
                            <Button type="link" onClick={() => _goFundManagerListsDetails(record)} >查看</Button>
                            {/* <Button type="link" danger onClick={() => _deSubAcc(record)} >删除</Button> */}
                            {
                                record.productType === 1 &&
                                <Button type="link" onClick={() => { showModal(record) }}>
                                    添加股票池
                                </Button>
                            }
                            {
                                record.productType === 2 &&
                                <Button type="link" onClick={() => { showModal(record) }}>
                                    添加期货池
                                </Button>
                            }
                            <>

                                <Modal title={`添加${productType === 1 ? '股票池' : '期货池'}`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                                    <span style={{ marginRight: 10 }}>{`请选择${productType === 1 ? '股票池:' : '期货池:'}`}</span>
                                    {
                                        <Select
                                            labelInValue
                                            defaultValue={productType === 1 ? { value: selectlist[0] ? selectlist[0].stockPoolName : null } : { value: futurePoolList[0] ? futurePoolList[0].futuresPoolName : null }}
                                            style={{ width: 200 }}
                                            onChange={getlistid}
                                        >
                                            {
                                                productType === 1 &&
                                                selectlist.map((item, index) => {
                                                    return <Option key={index} >{item.stockPoolName}</Option>
                                                })
                                            }
                                            {
                                                productType === 2 &&
                                                futurePoolList.map((item, index) => {
                                                    return <Option key={index} >{item.futuresPoolName}</Option>
                                                })
                                            }
                                        </Select>
                                    }
                                </Modal>
                            </>
                        </>)
                }
            }
        ]

        return (
            <Table
                rowKey="subAccId"
                showHeader={false}
                dataSource={fatRecord.productNameIdDTOList}
                columns={columnsChildren}
                align='center'
                pagination={false}
            />
        )
    }

    return (
        <Observer>
            {() => (
                <Card>
                    <Spin spinning={spinning}>

                        <Table
                            rowKey="traderId"
                            dataSource={_.size(searchText) > 0 ? searchData : listData}
                            columns={columns}
                            bordered
                            expandable={{ expandedRowRender }}
                        />
                        <AddTrader goBack={_get} />
                    </Spin>
                </Card>
            )}
        </Observer>
    )
}
import React, { useEffect, useState, useRef } from 'react';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { Tabs, Form, Spin, Input, Button, Checkbox, message, Modal, Space } from 'antd';
import logo from '@/images/_logoEN.svg';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getUserCode, userCodeLogin, userLogin } from '@/api/auto'; //getCaptcha
import UUID from 'uuidjs';
import { cryptoString } from '@/utils/cryptoUtils';
import { useMediaQuery } from 'react-responsive';
import { isMobileOnly, isWindows } from 'react-device-detect';
import { useMount } from 'ahooks';
import VerifyModal from './VerifyModal';
import _ from 'lodash';

const { TabPane } = Tabs;

message.config({ maxCount: 2 })

export default function Login(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [spinning, setspinning] = useState(false);
  const [tabskey, settabskey] = useState('1');
  const [loading, setloading] = useState(false);
  const [disableMsgBtn, setDisableMsgBtn] = useState(false);
  const [disableTime, setDisableTime] = useState(60);
  const mobxStore = useLocalObservable(() => store);
  const [formUser] = Form.useForm();
  const [formPhone] = Form.useForm();
  const interVal = useRef(null);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [verifyShow, setVerifyShow] = useState(false);
  const [validateObj, setValidateObj] = useState({});

  useEffect(() => {
    mobxStore.cancelLogin();
    interVal.current = {
      time: 60,
      index: null,
    }

    return () => {
      clearInterval(interVal.current.index);
    }
  }, [mobxStore]);

  useMount(() => {
    const isMobileAlert = (isMobileOnly || !isWindows) ? true : false;
    if (isMobileAlert) {
      Modal.confirm({
        title: '需前往新页面！',
        icon: <ExclamationCircleOutlined />,
        content: '为了移动端更好的浏览体验，请前往移动平台',
        centered: true,
        maskClosable: false,
        onOk: () => window.location.href = 'https://mobile.cfirstquant.com/login?plateform=SIMX',
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  });

  // function _goRegister() {
  //     props.history.push('/register');
  // }

  function _goForgetPassword() {
    props.history.push('/forgetPassword');
  }
  
  function callback(key) {
    settabskey(key);
    if (key === '1') {
      formPhone.resetFields(); // 清除表单数据
    } else {
      formUser.resetFields(); // 清除表单数据
    }
  }

  // 获取图片验证码
  async function _getCaptcha() {
    const phone = formPhone.getFieldValue('phone');
    if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
      setValidateObj({ validateStatus: 'error', help: '请输入手机号！' })
      return //message.error('请输入手机号！');
    } else {
      setValidateObj({ validateStatus: 'success' })
    }
    // 只生成uuid，图片组件会自动执行get；【fix】解决调用接口会产生两个get请求问题
    const uuid = UUID.generate();
    // const res = await getCaptcha(uuid);
    setCaptchaVal(uuid); // 本地记录图片的token，登录传给后台
    setVerifyShow(true);
  }

  async function _getSmsCode(val) {
    if (disableMsgBtn) {
      return message.warning('您点击的太快了！');
    }
    const phone = formPhone.getFieldValue('phone');
    if (phone === '' || !(/^1[3456789]\d{9}$/.test(phone))) {
      setValidateObj({ validateStatus: 'error', help: '请输入正确的手机号！' })
      return //message.error('请输入正确的手机号！');
    }
    setloading(true);
    let params = {
      phone: phone,
      captchaToken: captchaVal,
      captcha: val,
    }
    const data = await getUserCode(params);
    setloading(false);
    if (_.get(data, 'code', '') === '200') {
      message.success(data.message);
      setVerifyShow(false);
      setDisableMsgBtn(true);
      runDisableTime();
    } else {
      message.error(data.message)
    }
  }

  function runDisableTime() {
    interVal.current.index = setInterval(() => {
      if (interVal.current.time <= 1) {
        clearInterval(interVal.current.index);
        setDisableMsgBtn(false);
        interVal.current.time = 60;
        setDisableTime(60);
      }
      interVal.current.time -= 1;
      setDisableTime(t => t - 1);
    }, 1000);
  }
  async function onFinish(values) {
    if (tabskey === '1') {
      let params = {
        account: values.account,
        password: cryptoString(values.password),
        rememberMe: values.rememberPhone ? 1 : 0,
        loginType: 'password'
      }
      setspinning(true);
      const data = await userLogin(params);
      setspinning(false);
      if (_.get(data, 'code', '') === '200') {
        mobxStore.landUserInfo(data.data);
        mobxStore.landLogin(data.data.token);
        if (_.get(data, 'data.imToken', null)) {
          mobxStore.landImLogin(data.data.imToken);
        }
        props.history.push('/home');
      } else if (_.get(data, 'message', '') === '请使用手机验证码登录，以补全信息') {
        Modal.confirm({
          title: '请使用手机验证码登录，以补全信息',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            callback('2');
          }
        });
      } else {
        message.error(data.message);
      }
      return
    } else {
      let params = {
        account: values.phone,
        password: values.phoneCode,
        rememberMe: values.rememberPhone ? 1 : 0,
      }
      setspinning(true);
      const data = await userCodeLogin(params);
      setspinning(false);
      if (data.message === '手机注册成功，跳转用户名密码注册') {
        props.history.push({
          pathname: '/register',
          state: { key: data.data }
        });
      }
      if (_.get(data, 'code', '') === '200' && _.get(data, 'message', '') === 'success') {
        clearInterval(interVal.current.index);
        mobxStore.landUserInfo(data.data);
        mobxStore.landLogin(data.data.token);
        if (_.get(data, 'data.imToken', null)) {
          mobxStore.landImLogin(data.data.imToken);
        }
        props.history.replace('/home');
      } else if (_.get(data, 'message', '') === '验证码错误，请重试') {
        message.error(data.message);
      }
      return
    }
  }
  const COMMON_TAB_STYLE = { width: isMobile ? 268 : 400 }
  const COMMON_LOGO_STYLE = { width: isMobile ? 150 : 300, height: isMobile ? 55 : 110 };
  return (
    <Observer>
      {() => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
          <img
            src={logo}
            alt=""
            style={{ margin: '150px 0 20px', ...COMMON_LOGO_STYLE }}
          />
          <div style={COMMON_TAB_STYLE}>
            <Spin spinning={spinning}>
              <Tabs
                activeKey={tabskey}
                centered
                size="large"
                tabBarGutter={50}
                onChange={callback}
              >
                <TabPane tab="账户密码登录" key="1" style={COMMON_TAB_STYLE}>
                  <Form
                    form={formUser}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      label=""
                      name='account'
                      rules={[{ required: true, message: '请输入账户!', },]}
                    >
                      <Input placeholder="账户" size="large" />
                    </Form.Item>

                    <Form.Item
                      label=""
                      name='password'
                      rules={[{ required: true, message: '请输入密码', },]}
                    >
                      <Input.Password placeholder="密码" size="large" />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Form.Item name="rememberUser" valuePropName="checked">
                        <Checkbox>自动登录</Checkbox>
                      </Form.Item>

                      <Button type="link" style={{ marginBottom: 24 }} onClick={_goForgetPassword}>忘记密码</Button>
                    </div>

                    <Form.Item >
                      <Button type="primary" htmlType="submit" style={{ width: '100%', height: 45 }}>
                        登录
                      </Button>
                    </Form.Item>
                  </Form>
                </TabPane>

                <TabPane tab="手机号登录" key="2" style={COMMON_TAB_STYLE}>
                  <Form
                    form={formPhone}
                    onFinish={onFinish}
                  >
                    <Form.Item
                      label=""
                      name='phone'
                      {...validateObj}
                      rules={[
                        { required: true, message: '请输入手机号！', },
                        {
                          validator: (_, value) => {
                            if (value) {
                              if ((/^1[3456789]\d{9}$/.test(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('请输入正确的手机号！'));
                            }
                          }
                        }
                      ]}
                    >
                      <Input placeholder="手机号" size="large" />
                    </Form.Item>

                    <Form.Item
                      label=""
                      name='phoneCode'
                      rules={[{ required: true, message: '请输入验证码！', }]}
                    >
                      <Space>
                        <Input
                          placeholder="验证码"
                          size="large"
                          autoComplete={'off'}
                          style={{ width: 280 }}
                        />
                        <Button
                          type='primary'
                          style={{ width: 112 }}
                          onClick={() => _getCaptcha()}
                          loading={loading}
                          size="large"
                          disabled={disableMsgBtn}
                        >
                          {disableMsgBtn ? disableTime + '秒' : '获取验证码'}
                        </Button>
                      </Space>
                    </Form.Item>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Form.Item name="rememberPhone" valuePropName="checked">
                        <Checkbox>自动登录</Checkbox>
                      </Form.Item>

                      <Button
                        type="link"
                        style={{ marginBottom: 24 }}
                        onClick={_goForgetPassword}
                      >
                        忘记密码
                      </Button>
                    </div>

                    <Form.Item >
                      <Button type="primary" htmlType="submit" style={{ width: '100%', height: 45 }}>
                        登录
                      </Button>
                    </Form.Item>

                  </Form>
                </TabPane>
              </Tabs>
            </Spin>
          </div>

          <p style={{ textAlign: 'center', margin: '200px 0 20px' }}>COPYRIGHT © 2024 程创（北京）科技有限公司 </p>

          <VerifyModal
            visible={verifyShow}
            tokenVal={captchaVal}
            onCancel={() => setVerifyShow(false)}
            onOk={(v) => _getSmsCode(v)}
            onRefresh={() => _getCaptcha()}
          />

        </div>
      )}
    </Observer>
  )
}

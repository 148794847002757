import React, { useState, memo } from 'react';
import { Row, Col, Card, Typography, Button, DatePicker, Collapse, Select, Input, Form, List, message } from 'antd';
import { showTraderTrends } from '@/api/workbench';
import { visShowTraderTrends } from '@/api/reviewApi';
import { validateResponse } from '@/utils/utils';
import { useMount, useUnmount } from 'ahooks';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const Traders = ({ assetssArr, role }) => {
  const [form] = Form.useForm();
  const [traderName, settraderName] = useState('');
  const [beginData, setbeginData] = useState('');
  const [endData, setendData] = useState('');
  const [list, setlist] = useState([]);
  let isUnmont = false;

  useMount(() => {
    showTrendsInit();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function showTrendsInit() {
    let beginTime = moment().format('YYYY-MM-DD');
    let endTime = moment().format('YYYY-MM-DD');
    setbeginData(beginTime);
    setendData(endTime);
    let params = {
      beginDate: beginTime,
      endDate: endTime,
    }
    const data = role === 'visitor' ? await visShowTraderTrends(params) : await showTraderTrends(params);
    if (validateResponse(data, isUnmont)) {
      setlist(data.data);
    }
  }

  async function showTrends(e, dateString, tradername) {
    const values = await form.validateFields();
    if (!dateString[0]) {
      let params = {
        beginDate: beginData,
        endDate: endData,
        tradeDirection: values.tradeDirection,
        productName: values.productName
      }
      if (tradername === undefined) {
        params.traderName = traderName;
      } else {
        params.traderName = tradername;
      }
      const data = role === 'visitor' ? await visShowTraderTrends(params) : await showTraderTrends(params);
      if (data.code === '200') {
        setlist(data.data);
      } else {
        message.error(data.message);
      }
    } else {
      let beginTime = dateString[0];
      let endTime = dateString[1];
      setbeginData(dateString[0]);
      setendData(dateString[1]);
      let params = {
        beginDate: beginTime,
        endDate: endTime,
        tradeDirection: values.tradeDirection,
        productName: values.productName
      }
      if (tradername === undefined) {
        params.traderName = traderName;
      } else {
        params.traderName = tradername;
      }
      const data = role === 'visitor' ? await visShowTraderTrends(params) : await showTraderTrends(params);
      if (data.code === '200') {
        setlist(data.data);
      } else {
        message.error(data.message);
      }
    }
  }
  function exportToExcel() {
    var data = [];
    list.map(item => {
      var a = {};
      var arr = item.split('，');
      a.name = arr[0].substr(0, arr[0].indexOf('委'));
      // a.name = arr[0].substr(0,arr[0].indexOf('买')) || arr[0].substr(0,arr[0].indexOf('卖'));
      a.direction = arr[0].slice((arr[0].indexOf('了') - 2), arr[0].indexOf('了'));
      a.num = arr[0].slice(arr[0].indexOf('了') + 1, arr[0].indexOf('股') + 1);
      a.ming = arr[0].slice(arr[0].indexOf('股') + 1, arr[0].indexOf('（'));
      a.code = arr[0].slice(arr[0].indexOf('股') + 1).replace(/[^\d]/g, ' ');
      a.entrustPrice = arr[1].slice(arr[1].indexOf(':') + 1);
      a.clinchPrice = arr[3].slice(arr[3].indexOf(':') + 1);
      a.clinchAmount = arr[4].slice(arr[4].indexOf(':') + 1);
      if (arr[0].indexOf('卖出') !== -1) {
        a.realRate = arr[5].substr(arr[5].indexOf('：') + 1);
        a.stockRate = arr[6].substr(arr[6].indexOf('：') + 1);
        a.excessRate = arr[7].substr(arr[7].indexOf('：') + 1);
        a.productName = arr[8].substr(0, arr[8].indexOf('\t') + 1);
        a.securitiesAccountName = arr[8].substr(arr[8].indexOf('\t') + 1, arr[8].indexOf('\t'));
        a.time = arr[8].substr(arr[8].lastIndexOf('\t') + 1);
      } else {
        a.realRate = null;
        a.stockRate = null;
        a.excessRate = null;
        a.productName = arr[5].substr(0, arr[5].indexOf('\t') + 1);
        a.securitiesAccountName = arr[5].substr(arr[5].indexOf('\t') + 1, arr[5].indexOf('\t'));
        a.time = arr[5].substr(arr[5].lastIndexOf('\t') + 1);
      }
      // a.ming = arr[0].slice(arr[0].indexOf('手')+1).replace(/[0-9]/g, '');
      data.push(a);
      return item;
    })
    var option = {};
    let dataTable = [];
    if (data) {
      for (let i in data) {
        if (data) {
          let obj = {
            '姓名': data[i].name,
            '方向': data[i].direction,
            '数量': data[i].num,
            '名称': data[i].ming,
            '代码': data[i].code,
            '委托价': data[i].entrustPrice,
            '成交均价': data[i].clinchPrice,
            '成交金额': data[i].clinchAmount,
            '日内实际收益率': data[i].realRate,
            '日内个股收益率': data[i].stockRate,
            '超额收益率': data[i].excessRate,
            '产品名称': data[i].productName,
            '证券账户名称': data[i].securitiesAccountName,
            '时间': data[i].time
          }
          dataTable.push(obj);
        }
      }
    }
    option.fileName = '交易员动态'  //导出的Excel文件名
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
        sheetHeader: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
      }
    ]
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  return (
    <Card
      style={{ minHeight: 745 }}
    >
      <Row gutter={[8, 8]}>
        <Col span={12}><Title level={5}>交易员动态</Title></Col>
        <Col span={24}>
          <Collapse bordered={false} defaultActiveKey={['1']}>
            <Panel header="条件搜索" key="1">
              <div style={{ textAlign: 'right' }}>
                <Row justify="end" align="middle">
                  <span style={{ marginRight: 10, marginLeft: 20 }}>选择品种:</span>
                  <Select
                    defaultValue="股票"
                    size='small'
                  >
                    <Option value="stock">股票</Option>
                    <Option value="fund">基金</Option>
                    <Option value="futures">期货</Option>
                  </Select>
                  <span style={{ marginRight: 10, marginLeft: 20 }}>选择日期:</span>
                  <RangePicker
                    format="YYYY-MM-DD"
                    defaultValue={[moment(moment()), moment(moment())]}
                    onChange={showTrends}
                    size='small'
                  />
                </Row>
                <Row justify="end" align="middle">
                  <Form size='small' form={form} name="control-hooks" layout='inline' initialValues={{ productName: '', tradeDirection: '' }}>
                    <Form.Item label="选择操作：" name='tradeDirection' style={{ marginRight: 10, marginTop: 10 }}>
                      <Select
                        onChange={showTrends}
                      >
                        <Option value="">全部</Option>
                        <Option value="BUY">买入</Option>
                        <Option value="SELL">卖出</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="产品：" name='productName' style={{ marginRight: 10, marginTop: 10 }}>
                      <Select
                        onChange={showTrends}
                        dropdownMatchSelectWidth={false}
                      >
                        <Option value="">全部</Option>
                        {
                          assetssArr.currentProductResultList.length !== 0 &&
                          assetssArr.currentProductResultList.map((item) => {
                            return (
                              <Option
                                key={item.productId}
                                value={item.productName}
                              >
                                {item.productName}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Form>
                  <span style={{ marginRight: 10, marginTop: 10 }}>交易员:</span>
                  <Search
                    placeholder="请输入交易员"
                    allowClear
                    enterButton
                    style={{ width: 200, marginTop: 10 }}
                    size='small'
                    onSearch={value => {
                      let traderName = value;
                      let dateString = [];
                      showTrends('', dateString, traderName);
                      settraderName(value);
                    }} />
                  <Button
                    type='primary'
                    style={{ marginLeft: 10, marginTop: 10 }}
                    size='small'
                    onClick={exportToExcel}
                  >
                    导出
                  </Button>
                </Row>
              </div>
            </Panel>
          </Collapse>

        </Col>
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={list}
        pagination={{
          defaultPageSize: 7,
          hideOnSinglePage: true
        }}
        renderItem={item => (
          <List.Item>
            {item}
          </List.Item>
        )}
      />
    </Card>
  )
}

export default memo(Traders);
import React, { useState } from 'react';
import { Modal, Button, Space, Input, Image, Form } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

const VerifyModal = ({ visible, tokenVal, onCancel, onOk, onRefresh }) => {
  const [inputVal, setInputVal] = useState('');
  const [status, setStatus] = useState({});
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      centered
      onOk={() => {
        if (inputVal === '') {
          setStatus({ validateStatus: 'error', help: '请输入验证码！' })
        } else {
          setStatus({ validateStatus: 'success' })
          onOk(inputVal);
        }
      }}
    >
      <Form.Item {...status}>
        <Space>
          <Input
            placeholder="图片验证码"
            size="large"
            autoComplete={'off'}
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
          <Image
            width={180}
            height={38}
            src={global.verifyUrl + tokenVal}
            preview={false}
          />
          <Button icon={<RedoOutlined />} type='text' onClick={onRefresh} />
        </Space>
      </Form.Item>
    </Modal>
  )
}

export default VerifyModal;
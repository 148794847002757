import React from 'react';
import {
  Typography, Row, Col, Space, Select, Button, Checkbox, DatePicker, Switch, Divider,
  Tag
} from 'antd';
import { LeftOutlined, RightOutlined, BranchesOutlined } from '@ant-design/icons';
import { CHECK_TAG_ARRAY, SORT_BTN } from './chartsInfoUtils';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const { CheckableTag } = Tag;
const PRO_CHECKS = _.filter(CHECK_TAG_ARRAY, o => _.includes(['updown', 'two_day', 'updown_plus', 'two_day_plus'], o.key));
// 收益率图表操作bar统一组件
const MainBar = ({
  isRealtime = false,
  isListPage = false,
  isCombind = false,
  isDiffRate = false,
  pmsDateValue = '',
  validRanges = [],
  rateObj = {},
  switchs = [],
  checkboxItem = [],
  checkboxValues = [],
  posLoading = false,
  showBrief = false,

  onSingleDateChange,
  tagChange,
  subGroupChange,
  subValueChange,
  selectChange,
  positionShow,
  onBriefChange,
  onDiffChange,
}) => {
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, paddingLeft: 8, paddingRight: 8 };
  const renderPickerDate = pmsDateValue !== '' ? moment(pmsDateValue) : null;
  const isUpdownOrTwoDay = _.includes(switchs, 'updown') || _.includes(switchs, 'two_day') ? true : false;
  const disabledDate = (current) => { //getValidArray中未出现的时间数据不显示，区第一位和最后一位作为区间；最后一位获取时处理为当天
    if (_.size(validRanges) === 0 || !_.last(validRanges) || !_.head(validRanges)) {
      return false;
    }
    const tooLate = current.diff(_.last(validRanges), 'days') > 0;
    const tooEarly = current.diff(_.head(validRanges), 'days') < 0;
    return !!tooEarly || !!tooLate;
  };

  const check_tag_options = isRealtime ? _.concat(CHECK_TAG_ARRAY, [
    { key: 'updown_stock', name: '涨停票' },
  ]) : CHECK_TAG_ARRAY;
  const newCheckTag = isListPage ? check_tag_options : PRO_CHECKS;

  const validIdx = validRanges.indexOf(pmsDateValue); // 当前日期在数组中的index
  const disLeft = validIdx <= 0 ? true : false; // 到数组最左侧禁止点击‘前一日’按钮
  const disRight = validIdx === (_.size(validRanges) - 1) && validIdx !== -1 ? true : false; // 到数组最右侧禁止点击‘后一日’按钮
  // 单日切换按钮
  const SingleDateBtn = ({ dir = '', onpress }) => <Button
    size='small' type='text'
    icon={dir === 'left' ? <LeftOutlined /> : <RightOutlined />}
    disabled={dir === 'left' ? disLeft : disRight}
    onClick={() => onpress(validRanges[dir === 'left' ? validIdx - 1 : validIdx + 1])}
  />;

  return <>
    <Row style={{ textAlign: 'left', height: 35, marginBottom: 6 }} justify='space-between'>
      <Col span={12}>
        {isRealtime && <Space size='small'>
          <SingleDateBtn dir='left' onpress={onSingleDateChange} />
          <DatePicker
            size='small'
            disabledDate={disabledDate}
            value={renderPickerDate}
            onChange={(date, dateString) => {
              onSingleDateChange(dateString);
            }}
          />
          <SingleDateBtn dir='right' onpress={onSingleDateChange} />
        </Space>}
        {isRealtime && <Divider type='vertical' style={{ marginLeft: 12, marginRight: 12 }} />}
        <Space size='small'>
          <Text type='secondary'>收益率</Text>
          <Text strong style={{ color: '#5470c6' }}>{_.get(rateObj, 'rate', '')}</Text>
          <Text type='secondary'>指数</Text>
          <Text strong style={{ color: '#B5495B' }}>{_.get(rateObj, 'idx', '')}</Text>
          <Text type='secondary'>超额</Text>
          <Text strong style={{ color: '#ffa600' }}>{_.get(rateObj, 'extra', '')}</Text>
        </Space>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Space size='small'>
          {!isListPage && !isRealtime && <Button icon={<BranchesOutlined />} size='small'
            type={isDiffRate ? 'primary' : 'default'}
            onClick={onDiffChange}>
            实测差异
          </Button>}
          {isRealtime && !isCombind && <CheckableTag
            checked={showBrief}
            onChange={onBriefChange}
          >
            持仓
          </CheckableTag>}
          <div style={{ padding: isListPage ? 6 : 2, background: '#f9f9f9', borderRadius: 4, textAlign: 'left' }}>
            <Select bordered={false} size='small' mode='tags' style={{ width: 280 }} placeholder='收益率'
              onChange={(values) => selectChange(values)}
            >
              {newCheckTag.map(c => <Option key={c.key} value={c.key}>{c.name}</Option>)}
            </Select>
          </div>
        </Space>
      </Col>
    </Row>
    {_.size(checkboxItem) > 0 && <Row gutter={[8, 8]} style={controlBarStyle}>
      <Col span={18} style={{ paddingTop: 6 }}>
        <Space>
          <Text>快捷方式：</Text>
          {isUpdownOrTwoDay ? SORT_BTN.map((s, i) => {
            let subBtnProps = { size: 'small', type: 'primary', ghost: true };
            if (s.key === 'all' || s.key === 'clear') {
              subBtnProps = { size: 'small', type: s.type };
            }
            return <Button key={'sortBtn' + i} {...subBtnProps} onClick={() => subGroupChange(s.key)}>
              {s.title}
            </Button>
          }) : <></>}
          {isUpdownOrTwoDay ? <Divider type='vertical' /> : <></>}
          <Switch
            checkedChildren="超额"
            unCheckedChildren="超额"
            checked={_.includes(switchs, 'extra_switch')}
            onChange={(checked) => tagChange(checked, 'extra_switch')}
          />
        </Space>
      </Col>
      <Col span={6} style={{ textAlign: 'right', paddingTop: 6 }}>
        {_.includes(switchs, 'two_day') && isRealtime && <Button size='small' type='primary' ghost onClick={positionShow} loading={posLoading}>
          两日持仓票
        </Button>}
      </Col>
      <Col span={24}>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={checkboxValues}
          onChange={subValueChange}
        >
          <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
            {checkboxItem.map((itm, i) => {
              return <Col span={3} key={i}>
                <Checkbox value={_.get(itm, 'key', '')}>{_.get(itm, 'name')}</Checkbox>
              </Col>
            })}
          </Row>
        </Checkbox.Group>
      </Col>
    </Row>}
  </>
}

export default MainBar;
import { COMMON_CHART } from '@/view/common/Components/chartsInfoUtils';
import _ from 'lodash';

const upColor = '#ec0000';
const upBorderColor = '#ec0000';
const downColor = '#00da3c';
const downBorderColor = '#00da3c';
const COMPARE_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: function (params) {
      //console.log(params)
      let maArray = [];
      let maString = '';
      let k_data = _.get(params, '[0]', {});
      if (_.size(params) > 1) {
        maArray = _.drop(params);
        maArray.map(n => {
          maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
        })
      }

      return '开盘: ' + (k_data.value[1] || '') + '<br>' +
        '收盘: ' + (k_data.value[2] || '') + '<br>' +
        '最高: ' + (k_data.value[3] || '') + '<br>' +
        '最低: ' + (k_data.value[4] || '') + '<br>' + maString
    }
  },
  legend: {
    // data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
    data: ['分钟K', '超额收益率']
  },
  grid: {
    left: '10%',
    right: '10%',
    bottom: '15%'
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: [{
    name: '股价',
    scale: true,
    splitArea: {
      show: true
    }
  }, {
    name: '超额收益率(%)',
    axisLine: {
      show: true,
      symbol: ['none', 'arrow'],
      symbolSize: [8, 8],
      symbolOffset: 7,
    },
    splitArea: {
      show: true
    },
    splitLine: {
      show: false
    }
  }],
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100
    },
    {
      show: true,
      type: 'slider',
      top: '90%',
      start: 0,
      end: 100
    }
  ],
  series: [
    {
      name: '分钟K',
      type: 'candlestick',
      data: [],
      yAxisIndex: 0,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      }
    },
    {
      name: '超额收益率',
      type: 'line',
      yAxisIndex: 1,
      data: [],
      smooth: true,
      connectNulls: true,
      symbol: 'none',
    },
  ]
}

const COUNTING_OPTIONS = {
  xAxis: {
    type: 'category',
    data: [],
    splitLine: {
      show: false
    },
    boundaryGap: false,
    axisTick: {
      show: false
    },
  },
  yAxis: [
    {
      type: 'value',
      name: '交易次数/次',
      position: 'left',
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
      },
      splitArea: {
        show: true
      },
      splitLine: {
        show: false
      },
      minInterval: 1,
    },
    {
      type: 'value',
      name: '股票数量/个',
      position: 'right',
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
      },
      splitArea: {
        show: true
      },
      splitLine: {
        show: false
      },
      minInterval: 1,
    }
  ],
  series: [
    {
      name: '交易次数',
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'none',
      yAxisIndex: 0,
    },
    {
      name: '股票数量',
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'none',
      yAxisIndex: 1,
    },
  ],
  tooltip: {
    trigger: 'axis',
    showDelay: 0,
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  legend: {
    type: 'scroll',
    data: ['交易次数', '股票数量'],
    selectedMode: 'multiple',
  },
}

const CONTRIBUTION_OPTIONS = {
  grid: { left: '8%', right: '6%', bottom: '10%', top: '10%' },
  xAxis: {
    type: 'category',
    name: '时间',
    ...COMMON_CHART.rateX
  },
  yAxis: [
    COMMON_CHART.rateY,
  ],
  series: [],
  tooltip: {
    trigger: 'axis',
    showDelay: 0,
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  legend: { selectedMode: 'multiple' },
}

export {
  COMPARE_OPTION,
  COUNTING_OPTIONS,
  CONTRIBUTION_OPTIONS
}
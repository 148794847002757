import React, { useState, useRef } from 'react';
import { Card, Modal, message, Tabs, Alert } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useInterval, useMount, useUnmount, useReactive, useSize } from 'ahooks';
import { getProductDetailss, delSecuritiesAccount, getPositionUp5 } from '@/api/details';
import { showSingleProductHistory, getfundManagerAssets, getCommission, showIndexKline, showAllProductHistory } from '@/api/workbench';
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import { STRATEGY_TYPE, DATE_TYPE, handleHistoryRate, initDateType } from '@/view/common/Components/list_util';
import { isValidArray, isValidNumber, createDatasTimeKey, isMarketTrading } from '@/utils/utils2';
import { validateResponse } from '@/utils/utils';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import Marquee from 'react-fast-marquee';
import MainRateCharts from '@/view/common/MainRateCharts';
import AccountTable from './Components/AccountTable';
import AddAccountModal from './Components/AddAccoutModal';
import CommissionModal from './Components/CommissionModal';
import DisplayCharts from '@/view/common/DisplayCharts';
import SubContributionCharts from './Components/SubContributionCharts';
import CompareChartsTwo from '@/view/common/CompareChartsTwo';
import AssetsHeader from './Components/AssetsHeader';
import TradeRecord from '../ListsDetails/TradeRecord';
import PositionTable from '../ListsDetails/Components/PositionTable';
import LevelCharts from './Components/LevelCharts';
import KRateCharts from '@/view/common/KRateCharts';
import ContributionCharts from '../ListsDetails/Components/ContributionCharts';
import ExtraCompare from './Components/ExtraCompare';
import { RecentTabs, DetailTabsIcon, NewPageHeader } from './de_widget';
import moment from 'moment';
import _ from 'lodash';
import './product.scss'

const { TabPane } = Tabs;
const INTERVAL_NUM = 60000;
const TODAY = moment().format('YYYY-MM-DD');
const REALTIME_TAB = '1';
const MAIN_RATE = 'MAIN_RATE';
let timer = null;
/**
 * 产品页面ProductDetails
 * 收益率有定时任务在该页面获取；其他拆分为模块内加载获取
 *  * 231008：增加账户层面的持仓及交易记录
 */
export default function ProductDetails(props) {
  const mobxStore = useLocalObservable(() => store);
  const productParams = useReactive({
    pms: props.location.state.paramsData, riseList: [], symbol: DEFAUL_INDEX_CODE,
  });// 当前产品信息所用的数据，默认加载路由中的数据
  const [allProducts] = useState(JSON.parse(JSON.stringify(mobxStore.allProducts)));
  const [userRole] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.userInfo)), 'role', ''));
  const [vproducts] = useState(JSON.parse(JSON.stringify(mobxStore.virProducts)));
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const initType = initDateType(tradeDates, REALTIME_TAB);
  const initTrading = isMarketTrading(initType.isTradeDay);
  const [ptab, setPtab] = useState(String(productParams.pms.productId));
  const [tabActive, setTabActive] = useState(MAIN_RATE);
  const [listData, setlistData] = useState({});
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: [], newData: {} });
  const [glbDate, setGlbDate] = useState([]);
  const [allassets, setallassets] = useState({});
  const [count7time, setCount7Time] = useState(0); //7 时间更新
  const [countMain, setCountMain] = useState(0); // 收益率图表更新
  const [newTabs, setNewTabs] = useState(initType.tab)
  const [newLoading, setNewLoading] = useState(false)
  const [tradeTime] = useState(initTrading);
  const [commisVisible, setCommisVisible] = useState({ show: false, data: [] });
  const [indexValue, setIndexValue] = useState({});
  const [idxParams, setIdxParams] = useState({
    'symbol': DEFAUL_INDEX_CODE, 'dateType': initType.type, 'date': TODAY
  });
  const [inter, setInter] = useState(initTrading ? INTERVAL_NUM : undefined);
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const [posCount, setPosCount] = useState(0); // 当前持仓及交易记录update
  const sizeRef = useRef(null);
  const divSize = useSize(sizeRef);
  const isVisitor = userRole === 'visitor' ? true : false;
  let isUnmont = false;

  useMount(() => {
    // 全局交易状态，并执行页面逻辑
    const getGlbDate = _.get(JSON.parse(JSON.stringify(mobxStore.tradingObj)), 'date', '')
    setGlbDate(getGlbDate); // 初始化配置全局日期
    setCount7Time(count7time + 1);

    _initGetDatas();
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    };
  });

  useUnmount(() => {
    isUnmont = true;
    setInter(undefined);
    timer && clearTimeout(timer);
  });
  // 盘中定时更新
  useInterval(() => {
    _cutDateType(newTabs);
    _getAsset();
    _getPositionUp5();
    if (isMarketTrading() === false) {
      setInter(undefined);
    }
  }, inter);

  function _initGetDatas(reload) {
    // 初始赋值全局数据
    setallassets(productParams.pms);
    productParams.pms.dateType = 'TODAY';
    productParams.pms.dateToday = TODAY;
    productParams.pms.contributionTime = [TODAY, moment().add('days', 1).format('YYYY-MM-DD')];
    productParams.pms.strategyType = STRATEGY_TYPE[productParams.pms.strategyType] || '';

    _get();
    _cutDateType(newTabs, null, 'init');
    _getAsset();
    _showAllProductHistory();
    _getPositionUp5();

    if (reload) {
      setPosCount(posCount + 1); // 重载或初次加载更新，组件内部会重新获取数据
      if (tabActive !== MAIN_RATE) {
        setTabActive(MAIN_RATE);
      }
    }
  }

  // 获取子账户有收益率数据的时间区间
  async function _showAllProductHistory() {
    if (productParams.pms.productId) {
      const res = await showAllProductHistory(productParams.pms.productId);
      if (validateResponse(res, isUnmont)) {
        let getDateArray = _.get(res, 'data', [])
        // 返回区间如不包含今天则添加今天，避免获取问题
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  }
  // 每3分钟涨幅5%的票；[定时任务]
  async function _getPositionUp5() {
    if (productParams.pms.productId) {
      const res = await getPositionUp5(productParams.pms.productId);
      productParams.riseList = createDatasTimeKey(_.get(res, 'data', []), 'time', 's');
    }
  }
  // 收益率获取
  async function _showSingleProductHistory(params) {
    let pms = {
      productId: params.productId,
      dateType: params.dateType,
      date: _.get(params, 'dateToday'),
    }
    let idxPms = {
      symbol: productParams.symbol,
      dateType: pms.dateType,
      date: pms.date,
    };
    if (('diffVal' in params) && isValidNumber(params.diffVal)) {      // 百分比转化为小数；
      _.set(pms, 'rate', _.get(params, 'diffVal') / 100)
    }
    const res = await showSingleProductHistory(pms);
    // 因增加时间对比进行裁剪，正常将不再对指数数据进行缓存，两个数据需要同时获取
    const resIdx = await showIndexKline(idxPms);
    setIdxParams(_.assign(pms, idxPms));
    setNewLoading(false);
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    if (_.get(resIdx, 'message', '') !== 'success') {
      message.info(_.get(resIdx, 'message'))
    }
    const getPoints = _.get(res, 'data.pointList', []);
    if (validateResponse(res, isUnmont) && isValidArray(getPoints)) {
      const rateData = handleHistoryRate(res, resIdx, params.dateType);
      setPointDatas({ 'timeList': rateData.timeList, 'datas': rateData.datas, 'newData': rateData.newData });
      setIndexValue(rateData.indexVal);
    } else {
      setPointDatas({ timeList: [], datas: {}, newData: {} });
      setIndexValue({});
    }
    updateMain();
  }
  // 【原】获取指数数据，因对比只能做成同步，该function只有切换时使用，并执行缓存数据
  async function _showIndexKline(idx) {
    let params = {
      symbol: idx ? idx : idxParams.symbol,
      dateType: idxParams.dateType,
      date: idxParams.date
    }
    const res = await showIndexKline(params);
    setIdxParams(_.assign(idxParams, params))
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {});
      setIndexValue(getData);
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    updateMain();
  }
  //实时更新产品资产、收益等信息
  async function _getAsset() {
    let params = { isShow: false, pageNum: 1, pageSize: 10, productId: productParams.pms.productId }
    const data = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    if (validateResponse(data, isUnmont) && _.size(_.get(data, 'data.currentProductResultList', [])) === 1) {
      setallassets(_.head(_.get(data, 'data.currentProductResultList', [])));
    }
  }
  //求证券账户表格的总收益和今日收益
  async function _get() {
    const data = await getProductDetailss({ params: { productId: productParams.pms.productId } });
    if (validateResponse(data, isUnmont)) {
      let subList = [];
      let securitiesAccounts = _.get(data, 'data.securitiesAccounts', []);
      securitiesAccounts.map(item => {
        let totalRevenue = 0;
        let dailyRevenue = 0;
        item.subAccountList.map(subs => {
          totalRevenue += subs.totalRevenue;
          dailyRevenue += subs.dailyRevenue;
          subList.push(_.assign(subs, {
            'subAccountId': _.get(subs, 'id'),
            'subAccountName': _.get(subs, 'name'),
          }));
          return subs;
        })
        item.totalRevenue = totalRevenue;
        item.dailyRevenue = dailyRevenue;
        return item;
      })
      setlistData(_.assign({ 'table': securitiesAccounts }, _.get(data, 'data', {})));
      mobxStore.saveAccountCache(subList);
    }
  }

  async function _delSubAccount(record) {
    Modal.confirm({
      title: record.name,
      content: '是否删除该子账户',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await delSecuritiesAccount({ subAccountId: record.id });
        if (_.get(data, 'code', '') === '200') {
          _get();
          message.success('删除成功');
        } else {
          message.error(data.message);
        }
      }
    });
  }

  async function _getCommission(id) {
    if (id) {
      const res = await getCommission(id);
      if (_.get(res, 'code', '') === '200') {
        setCommisVisible({
          show: true,
          data: _.get(res, 'data', [])
        })
      }
    }
  }
  // 跳转到基金经理查看子账户
  function _goFundManagerListsDetails(record) {
    // 查找strategyType，子账户内部使用
    let findex = _.findIndex(_.get(listData, 'table', []), o => o.id === _.get(record, 'securitiesAccountId', 0));
    record.subAccountName = record.name;
    record.subAccountId = record.id;
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    const hasChildrenAccount = _.get(record, 'hasCid', '') === 1 ? true : false;
    if (record.assetsType === '1' && !hasChildrenAccount) {
      const newInfo = { ...record, strategyType: findex !== -1 ? _.get(listData, `table[${findex}].strategyType`) : 0 };
      props.history.push({
        pathname: '/home/listsDetails',
        state: { subAccountInfo: newInfo }
      });
    } else if (hasChildrenAccount) {
      props.history.push({
        pathname: '/home/mListsDetails',
        state: { subAccountInfo: { ...record, strategyType: findex !== -1 ? _.get(listData, `table[${findex}].strategyType`) : 0 } }
      });
    }
  }

  // 切换图表时间
  async function _cutDateType(e = '', date = null, init = '') {
    setNewTabs(e === '11' ? REALTIME_TAB : e);
    productParams.pms.dateToday = e === '11' ? date : TODAY;
    // 实时：开启定时任务
    setInter(e === REALTIME_TAB && tradeTime ? INTERVAL_NUM : undefined);
    const new_type = parseInt(e) > 100 ? DATE_TYPE['12'] : DATE_TYPE[e];
    productParams.pms.dateType = new_type;
    // 上个接口=ALL直接切换
    if (_.get(idxParams, 'dateType') === 'ALL' && new_type === 'ALL' && init !== 'init') {
      updateMain();
    } else {
      _showSingleProductHistory(productParams.pms);
    }
  };

  function updateMain() {
    setCountMain(_.round(countMain + 0.1, 1));
  }

  const main_props = {
    'pageKey': 'products', 'upcount': countMain, 'role': mobxStore.userInfo.role, 'isTrading': tradeTime,
    'datas': pointDatas, 'dailyRevenueRate': _.round(parseFloat(_.get(allassets, 'dailyRevenueRate', 0)), 2),
    'currentTabs': newTabs, 'loading': newLoading, 'indexValue': indexValue, 'indexSymbol': productParams.symbol,
    'pms': idxParams, 'validDateRange': validDate, 'productId': productParams.pms.productId, 'reloadCount': posCount,
    'tradeDates': tradeDates
  };
  const sub_props = { 'active': tabActive, 'id': productParams.pms.productId, 'globalDate': glbDate };
  const pos_res_pros = {
    'pageKey': 'products', 'role': mobxStore.userInfo.role, 'productId': productParams.pms.productId, 'upCount': posCount,
    'newProps': props,
  };
  const header_props = {
    'title': _.get(productParams.pms, 'productName', '-'), 'subTitle': _.get(productParams.pms, 'strategyType', '-'),
    'screenWidth': _.get(divSize, 'width', 0), 'listData': listData, 'isTradeDay': initType.isTradeDay
  };
  const twoProps = {
    'pageKey': 'product', 'active': tabActive, 'isTrading': tradeTime, 'idxPms': idxParams, 'virtualProduct': vproducts,
    'productInfo': { productId: productParams.pms.productId, dateType: productParams.pms.dateType, date: _.get(productParams.pms, 'dateToday') }
  };
  return (
    <>
      <NewPageHeader {...header_props}
        onBack={() => props.history?.goBack()}
        onJump={(item) => _goFundManagerListsDetails(item)}
      >
        <RecentTabs
          active={ptab}
          pageKey='products'
          items={allProducts}
          id={productParams.pms.productId}
          onItemChange={(activeKey, itemVal) => { // 点击切换产品，重新赋值productParams数据，并重载页面
            setPtab(activeKey);
            productParams.pms = itemVal;
            _initGetDatas(true);
          }}
        />
      </NewPageHeader>

      <div className="ProductDetails contentView" id="productDetails">

        <AssetsHeader assets={allassets} pmData={productParams.pms} />

        <br />
        <div className='productTab'>
          <Tabs activeKey={tabActive} type='card' onChange={(value) => {
            if (value === MAIN_RATE) {
              _cutDateType(newTabs);
            } else {
              setInter(undefined);
            }
            setTabActive(value);
          }}>
            <TabPane tab={<DetailTabsIcon keys={MAIN_RATE} />} key={MAIN_RATE}>
              <MainRateCharts
                {...main_props}
                cutDateType={_cutDateType}
                checkDayRate={(key, date) => _cutDateType(key, date)}
                indexChange={(idx) => {
                  productParams.symbol = idx;
                  _showIndexKline(idx);
                }}
                rateDiffChange={(diffVal) => _showSingleProductHistory(_.assign(productParams.pms, { diffVal }))}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='RATE_K' />} key='RATE_K'>
              <KRateCharts {...pos_res_pros}
                pageKey='product'
                active={tabActive}
                indexSymbol={productParams.symbol}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION' />} key='CONTRIBUTION'>
              <SubContributionCharts {...sub_props} newProps={props} />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION2' />} key='CONTRIBUTION2'>
              <ContributionCharts
                pageKey='product'
                productId={productParams.pms.productId}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='DISTRIBUTION' />} key='DISTRIBUTION'>
              <DisplayCharts
                pageKey='product'
                upTimeCount={count7time}
                {...sub_props}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='PRO_LEVEL' />} key='PRO_LEVEL'>
              <LevelCharts {...sub_props} />
            </TabPane>
            {!isVisitor && <TabPane tab={<DetailTabsIcon keys='PRO_COM' />} key='PRO_COM'>
              <CompareChartsTwo {...twoProps} />
            </TabPane>}
            <TabPane tab={<DetailTabsIcon keys='EXTRA_COM' />} key='EXTRA_COM'>
              <ExtraCompare
                {...twoProps}
              />
            </TabPane>
          </Tabs>
        </div>

        <Card>
          <AccountTable
            role={userRole}
            listData={listData}
            getCommission={(id) => _getCommission(id)}
            goBack={_get}
            delSubAccount={(record) => _delSubAccount(record)}
            goFundManagerListsDetails={(record) => _goFundManagerListsDetails(record)}
          />

          {!isVisitor && <AddAccountModal paramsData={productParams.pms} />}
        </Card>

        <br />
        {/* 
          新增产品层面持仓和交易，两个组件可通用但保持以子账户代码优先
       */}
        {isValidArray(productParams.riseList) && <Alert banner
          message={<Marquee pauseOnHover gradient={false} speed={35}>
            {productParams.riseList.map((r, ri) => <span key={r.name + ri} style={{ marginRight: 16 }}>{`${r.name} ${r.formatDate}`}</span>)}
            <span style={{ marginRight: 16 }}>||</span>
          </Marquee>}
        />}
        <PositionTable
          {...pos_res_pros}
          rises={_.uniq(productParams.riseList.map(n => n.name))}
        />
        <br />
        <TradeRecord
          {...pos_res_pros}
          tradeStatus={tradeTime}
          visitor={isVisitor}
          onTimeChange={(date) => { }} //子账户需输出时间，产品不需要，空操作
        />

        <CommissionModal
          visible={_.get(commisVisible, 'show', false)}
          datas={_.get(commisVisible, 'data', [])}
          onCancel={() => {
            setCommisVisible({ visible: false, data: [] })
          }}
        />
      </div>
    </>
  )
}
import React, { useState } from 'react';
import {
  Row, Col, Card, Typography, Button, Table, Dropdown, Menu, Space, Tag,
  message, Input, Segmented, Spin, Badge
} from 'antd';
import { FileExcelOutlined, SyncOutlined, LineChartOutlined } from '@ant-design/icons';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { showPositionReturn, getAllPosition } from '@/api/workbench';
import { useMount, useUpdateEffect, useReactive, useUnmount } from 'ahooks';
import { showList } from '@/api/stock';
import { useMediaQuery } from 'react-responsive'
import { singleColumns, autoCol, validateResponse, RateText } from '@/utils/utils';
import { isValidArray, isValidString, createDatasTimeKey, labelValues } from '@/utils/utils2';
import { KlineDrawer } from '@/view/common/widgets';
import AddHosting from './AddHosting';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Search } = Input;
const MS_FORMAT = 'YY-MM-DD HH:mm:ss:SSS'; const DAY_FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(DAY_FORMAT);
const TO_YES_PRE_DAY = [TODAY, moment(TODAY).subtract(1, 'd').format(DAY_FORMAT), moment(TODAY).subtract(2, 'd').format(DAY_FORMAT)]; // 今、昨、前
/**
 *  持仓列表组件，产品&子账户通用模块
 */
const PositionTable = ({
  pageKey = '',
  role = '',
  productId = 0,
  subAccountInfo = {},
  isSubListdetail = false,
  validDateRange = [],
  rises = [],
  newProps = {},
  assetData = {},
  exUser = {},
  upCount = 0,

  // onSubStrategy,
}) => {
  const mobxStore = useLocalObservable(() => store);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [fundList, setfundList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const noTradingBtn = role === 'fund_manager' || role === 'visitor' ? true : false;
  const [exLoading, setExLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [segVal, setSegVal] = useState('all');
  const modalState = useReactive({ dshow: { show: false, record: {} } });
  const isProductPage = pageKey === 'products' ? true : false;
  let isUnmont = false;

  useMount(() => {
    getPositionDatas();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  // 上层更新重载
  useUpdateEffect(() => {
    getPositionDatas();
  }, [upCount]);

  //买入卖出按钮
  function renderBuySellBtn(record, type) {
    const is_buy = type === 'buy' ? true : false;
    return <Button type='primary' danger={is_buy ? true : false} disabled={record.tradeStatus !== 0} onClick={() => {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      newProps.history.push({
        pathname: '/home/Trading',
        state: {
          'subAccountInfo': _.cloneDeep(subAccountInfo),
          'active': is_buy ? '1' : '2',
          'record': record
        }
      });
    }}>{is_buy ? '买入' : '卖出'}</Button>
  }

  const fundColumns = [
    {
      ...singleColumns(['序号', 'order', 50]), //0
      render: (text, record, index) => index + 1,
    },
    {
      ...singleColumns(['股票', 'stock', 200]), //1
      render: (text, record) => <div>
        <Badge dot={_.includes(rises, _.get(record, 'stockName'))}>
          <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>
        </Badge>
        <LineChartOutlined style={{ fontSize: 12, color: '#003e85', marginLeft: 4, cursor: 'pointer' }}
          onClick={() => {
            modalState.dshow = { show: true, record: record };
          }}
        />
      </div>,
    },
    {
      ...singleColumns(['板块', 'plate', 150]),//2
      sorter: (a, b) => a.plateKey - b.plateKey,
      render: (text) => text ? <Tag>{text}</Tag> : ''
    },
    {
      ...singleColumns(['总市值', 'totalMarketValue', 120]),//3
      sorter: (a, b) => a.totalMarketValue - b.totalMarketValue,
      render: (text, record) => <RateText rate={text} rType='replace' noPrefix restStyle={{ fontWeight: 400, color: 'black' }} />
    },
    { //4
      ...singleColumns(['今日涨跌幅', 'isHighLimit', 115, 'left', ['descend', 'pctChg']]),
      render: (text, record) => {
        const pctVal = _.get(record, 'pctChg', 0);
        return <Space>
          <RateText rate={pctVal} />
          {text && role !== 'visitor' ? <Tag color={'red'}>{'涨停'}</Tag> : <></>}
        </Space>
      }
    },
    {
      ...singleColumns(['涨速', 'speedIncrease', 95, 'left']),//5
      sorter: (a, b) => a.speedIncrease - b.speedIncrease,
      render: (text) => <RateText rate={text} />
    },
    singleColumns(['总收益', 'totalProfit', 110], 'updown'),//6
    singleColumns(['总收益率', 'totalRevenue', 100, 'left', ['descend', 'totalRevenue'], 'rate'], 'updown'), //7
    singleColumns(['股票数量', 'stockAmount', 95]),//8
    singleColumns(['可交易数量', 'availableStockAmount', 110]),//9
    singleColumns(['成本价', 'costPrice', 120]),//10
    {
      ...singleColumns(['买入时间', 'formatDate', 120, 'left', ['descend', 'orderKey']]),//11
      fixed: !noTradingBtn && isSubListdetail ? '' : 'right',
    },
    { //12
      title: '操作',
      fixed: isMobile ? '' : 'right',
      width: 290,
      render: (record) => {
        return <Space>
          {renderBuySellBtn(record, 'buy')}
          {renderBuySellBtn(record, 'sell')}
          <AddHosting
            record={record}
            subAccountInfo={subAccountInfo}
            getPositionReturn={getPositionDatas}
            assetData={assetData}
            isLock={_.get(assetData, 'alertStatus') !== 0 ? true : false}
            isTrusteeship={_.get(exUser, 'trusteeship') === 1 ? true : false}
          />
        </Space>
      }
    }
  ];

  const pro_col = [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11].map(idx => fundColumns[idx]);
  const acc_col = _.concat([0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11], role === 'trader' ? [12] : []).map(idx => fundColumns[idx]);

  // 获取持仓数据
  async function getPositionDatas() {
    setLoading(true);
    const openMoment = moment(TODAY + ' 09:00:00');
    const res = isProductPage ? await getAllPosition({ productId }) : await showPositionReturn({ subAccountId: subAccountInfo.subAccountId });
    if (validateResponse(res, isUnmont)) {
      const getData = _.get(res, 'data', []);
      let newData = []; let plateMap = new Map(); let count = 1;
      let plateSingleMap = new Map();
      newData = isValidArray(getData) ? getData.map(n => {
        const getPlate = _.get(n, 'plate', null);
        const plateArr = _.split(getPlate, ','); // 板块拆分为数组
        let newPlateKey = 0;
        if (getPlate && !plateMap.has(getPlate)) {
          plateMap.set(getPlate, count);
          newPlateKey = count;
          count++;
        } else if (getPlate && plateMap.has(getPlate)) {
          newPlateKey = plateMap.get(getPlate);
        };
        // 多个板块逗号拆分，map内不重复的所有板块
        if (isValidArray(plateArr)) {
          plateArr.map(a => {
            if (!plateSingleMap.has(a)) {
              plateSingleMap.set(a, count);
            }
          })
        }
        const buyDate_moment = moment(_.get(n, 'buyDate', ''));
        return {
          ...n,
          'plateKey': newPlateKey,
          'plate_arr': plateArr,
          'formatDate': buyDate_moment.format(MS_FORMAT),
          'totalRevenue': _.round(_.get(n, 'totalRevenue', 0) * 100, 2),
          'formatDate_day': buyDate_moment.format(DAY_FORMAT), // 区分天
          'orderKey': openMoment.diff(buyDate_moment, 'millisecond') // 排序用
        }
      }) : [];
      let optionsPlate = []; // 将map转化成options的数组
      Array.from(plateSingleMap)?.map(arr => {
        const plItem = _.head(arr);
        if (isValidString(plItem)) {
          optionsPlate.push(labelValues([plItem, plItem]));
        }
      })
      setfundList(newData);
      setFilterList(newData);
      setSegVal('all');
      mobxStore.savePositionList(newData);
    }
    setLoading(false);
  }
  // 导出所有持仓：调用交易记录接口，获取当天的交易记录，showList接口
  async function exportExcelToday() {
    const res = await showList({
      subAccountId: _.get(subAccountInfo, 'subAccountId'),
      beginTime: TODAY,
      endTime: moment().add(1, 'd').format(DAY_FORMAT),
      state: true
    });
    if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
      const getList = createDatasTimeKey(_.get(res, 'data.result', []), 'commissionTime', 'fms');
      exportExcel(
        'today',
        _.filter(getList, o => parseFloat(_.get(o, 'quantity', 0)) > 0 && _.get(o, 'side', '') === 'BUY')
      )
    }
  }
  // 导出全部持仓
  function exportExcel(type, list) {
    let option = {}; let datas = [];

    const todayDescList = type === 'today' ? _.uniqBy(list, 'stockCode') : _.orderBy(fundList, ['orderKey'], ['desc']);
    if (_.size(todayDescList) === 0) {
      message.info('当前无持仓！');
      setExLoading(false);
      return
    }
    todayDescList.map(n => {
      datas.push({ '代码': _.get(n, 'stockCode', ''), '名称': _.get(n, 'stockName', ''), '时间': _.get(n, 'formatDate', '') })
    });
    // console.log('todayDescList', todayDescList)
    const fileNameObj = { 'today': `${TODAY} SIMX 持仓`, 'all': `${TODAY} SIMX 全部持仓` }
    option.fileName = fileNameObj[type] || 'file';
    option.datas = [
      {
        sheetData: datas,
        sheetName: 'sheet',
        sheetFilter: ['代码', '名称', '时间'],
        sheetHeader: ['代码', '名称', '时间'],
      }
    ]
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    setExLoading(false);
  }
  // 搜索股票
  const onSearchStock = (value) => {
    let filters = isValidString(value)
      ? _.filter(fundList, o => _.includes(o.stockName, value) || _.includes(o.stockCode, value))
      : [];
    if (_.size(filters) > 0) {
      setFilterList(filters);
    }
    if (!isValidString(value)) {
      setFilterList(fundList);
      setSearchVal('');
    }
  }
  // 用效日期（交易日）的最新三天作为今昨前的时间判断
  const new_pre_days = isValidArray(validDateRange) && _.size(validDateRange) > 2 ? _.reverse(_.takeRight(validDateRange, 3)) : TO_YES_PRE_DAY;
  const seg_options = [
    labelValues(['全', 'all']),
    labelValues(['今', new_pre_days[0]]), labelValues(['昨', new_pre_days[1]]), labelValues(['前', new_pre_days[2]]),
    labelValues(['其他', 'else']),
  ];
  // 筛选
  const onSegChange = (v) => {
    setSegVal(v);
    if (v === 'all') {
      setFilterList(fundList);
    } else if (v === 'else') {
      setFilterList(_.filter(fundList, o => !_.includes(new_pre_days, o.formatDate_day)));
    } else {
      setFilterList(_.filter(fundList, o => o.formatDate_day === v));
    }
  }
  // 导出menu
  const menu = (
    <Menu
      triggerSubMenuAction='click'
      onClick={(e) => {
        setExLoading(true);
        if (e.key === 'today') {
          exportExcelToday();
        } else if (e.key === 'all') {
          exportExcel('all');
        }
      }}>
      <Menu.Item key={'today'}>导出今日</Menu.Item>
      <Menu.Item key={'all'}>导出全部</Menu.Item>
    </Menu>
  );
  const searchInputProps = { value: searchVal, size: 'small', placeholder: '搜索股票名称或代码', style: { width: 185 } };
  const searchUpDownProps = { size: 'small', type: 'text', danger: true };
  const newCol = isProductPage ? pro_col : acc_col;
  return (
    <Card bordered={true} size='small'  // className='positionCards'
    >
      <Row justify='space-between' className='sellHeader' align='middle'>
        <Col {...autoCol([6])}>
          <Space>
            <Text style={{ fontSize: 15 }} strong>当前持仓</Text>
            <Spin spinning={loading} size='small' />
          </Space>
        </Col>
        <Col {...autoCol([12])} style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button size='small' icon={<SyncOutlined />} type='text' onClick={getPositionDatas} />
            {role === 'fund_manager' && <Segmented size='small' value={segVal} options={seg_options}
              onChange={(v) => onSegChange(v)}
            />}
            <Search {...searchInputProps} onChange={(e) => setSearchVal(e.target.value)} onSearch={onSearchStock} />
            {isValidString(searchVal) ?
              <Button {...searchUpDownProps} onClick={() => onSearchStock()}>清除</Button>
              : <div style={{ width: 44, height: 24 }}></div>}
            {role === 'fund_manager' ? <Dropdown overlay={menu} trigger={['click']}>
              <Button loading={exLoading} icon={<FileExcelOutlined />} size='small' disabled={_.size(fundList) === 0}>
                导出
              </Button>
            </Dropdown> : <></>}
          </Space>
        </Col>
      </Row>

      <Table
        rowKey="stockCode"
        dataSource={filterList}
        columns={newCol}
        bordered
        size='small'
        scroll={{ x: _.sum(newCol.map(n => n.width)), y: 680 }}
        pagination={false}
      />

      <KlineDrawer
        pageKey='position'
        show={modalState.dshow.show}
        value={modalState.dshow.record}
        lists={fundList}
        newProps={newProps}
        onClose={() => modalState.dshow = { show: false, record: {} }}
      />
    </Card>
  )
}

export default PositionTable;
import React, { useState } from 'react';
import { Modal, Table, message, } from 'antd';
import moment from 'moment';
import _ from 'lodash';


const AutoRecordModal = ({ visible, onCancel, datas }) => {
  const columns = [
    {
      title: '时间',
      dataIndex: 'date',
      key: 'date',
      with: 80,
      render: (text, record) => (moment(text).format('YYYY-MM-DD HH:mm:ss'))
    },
    {
      title: '买入命中比例',
      dataIndex: 'buyScore',
      key: 'buyScore',
      with: 60,
    },
    {
      title: '卖出命中比例',
      dataIndex: 'sellScore',
      key: 'sellScore',
      with: 60,
    },
    {
      title: '策略名称',
      dataIndex: 'strategiesName',
      key: 'strategiesName',
      with: 60,
    },
    {
      title: '是否开启计算',
      dataIndex: 'calculateStatus',
      key: 'calculateStatus',
      with: 60,
      render: (text, record) => (text === 0 ? '未开启' : '开启')
    },
    {
      title: '操作时间',
      dataIndex: 'operationTime',
      key: 'operationTime',
      with: 80,
      render: (text, record) => (moment(text).format('YYYY-MM-DD HH:mm:ss'))
    },
    {
      title: '规则明细',
      dataIndex: 'remarks',
      key: 'remarks',
      with: 190,
      render: (text, record) => {
        return <ul style={{ textAlign: 'left', listStyleType: 'none' }}>
          {_.isArray(text) && _.size(text) > 0 && text.map((item, index) => {
            return <li key={'remarks' + index} style={{ marginBottom: 6 }}>{item}</li>
          })}
        </ul>
      }
    },
  ];

  return (
    <Modal
      title='自动交易明细'
      visible={visible}
      onCancel={onCancel}
      width={870}
    >
      <Table
        rowKey="id"
        dataSource={datas}
        columns={columns}
        bordered
        scroll={{ x: 900 }}
        footer={null}
      />
    </Modal>
  )
}

export default AutoRecordModal;
import React, { useState } from 'react';
import {
    Modal,
    List,
    Button,
    Typography,
    Tag,
} from 'antd';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Text } = Typography;

const SelectAccModal = ({
    visible,
    account,
    showObj = { title: '名称', btn: '点击' },
    value = {},
    stockName = null,

    onCancel,
    onSelect
}) => {
    return (
        <Modal
            title={_.get(showObj, 'title', '')}
            visible={visible}
            onCancel={onCancel}
            maskClosable={false}
            width={610}
            footer={null}
        >
            {stockName && <div>
                <span style={{ marginRight: 10 }}>跟投股票: </span><Tag color={'#f50'}>{stockName}</Tag>
            </div>}
            <List
                bordered={false}
                dataSource={account}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                // type='text'
                                shape='circle'
                                size='small'
                                disabled={_.get(value, 'subAccountId', null) === item.subAccountId}
                                onClick={() => {
                                    onSelect(item)
                                }}
                                icon={_.get(value, 'subAccountId', null) === item.subAccountId ? <CheckOutlined /> : <RightOutlined />}
                            />
                        ]} //{_.get(showObj, 'btn', '')}
                    >
                        <Text>{_.get(item, 'subAccountName', '')}</Text>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default SelectAccModal;
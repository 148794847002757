import React, { useState } from 'react';
import {
  Typography,
  Table,
  Row,
  Col,
  DatePicker,
  Tag,
} from 'antd';
import moment from 'moment';
import _ from 'lodash';

const { RangePicker } = DatePicker;
const { Text, Paragraph } = Typography;

const BillsTable = ({ bills = [], billsToday, billDate, onTimeChange }) => {

  const columns = [
    {
      title: '账单金额',
      dataIndex: 'amount',
      align: 'left',
      key: 'amount',
      width: 90,
      render: (text) => text + '元'
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      align: 'left',
      key: 'remarks',
      width: 140,
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'left',
      key: 'state',
      width: 100,
      render: (text, record) => text === 0 ? <Tag color='blue'>入账中</Tag> : <Tag color='success'>已入账</Tag>
    },
    {
      title: '股票代码',
      dataIndex: 'stockCode',
      align: 'left',
      key: 'stockCode',
      width: 80,
    },
    {
      title: '命中比例',
      dataIndex: 'rate',
      align: 'left',
      key: 'rate',
      width: 140,
      render: (text, record) => {
        return <ul>
          <li>买入命中比例: {_.get(record, 'buyScore', '')}</li>
          <li>卖出命中比例: {_.get(record, 'sellScore', '')}</li>
        </ul>
      }
    },
    {
      title: '交易方向',
      dataIndex: 'direction',
      align: 'left',
      key: 'direction',
      width: 80,
      render: (text, record) => text === -1 ? <Tag color='green'>卖</Tag> : <Tag color='red'>买</Tag>

    },
    {
      title: '推荐委托价格',
      dataIndex: 'price',
      align: 'left',
      key: 'price',
      width: 80,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'left',
      key: 'createTime',
      width: 120,
      render: (text, record) => (
        moment(text).format('YYYY-MM-DD HH:mm')
      )
    },
  ];

  const newRangeValue = !billDate[0] || !billDate[1] ? null : [moment(billDate[0]), moment(billDate[1])];

  return <>
    <Row gutter={[16, 16]} justify='space-between' align='center' style={{ marginBottom: 14 }}>
      <Col span={8}>
        <Paragraph>
          今日累计账单金额：<Text strong style={{ fontSize: 17 }}>
            {_.get(billsToday, 'totalAmount', '')} 元
          </Text>
        </Paragraph>
      </Col>
      <Col span={8} style={{ textAlign: 'right' }}>
        <RangePicker
          value={newRangeValue}
          format="YYYY-MM-DD"
          onCalendarChange={(date, dateStrings, info) => {
            const calType = _.get(info, 'range', '');
            if (calType === 'end' && (dateStrings[0] !== billDate[0] || dateStrings[1] !== billDate[1])) {
              onTimeChange(dateStrings);
            }
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Table
          rowKey={'id'}
          dataSource={bills}
          columns={columns}
          size="middle"
          scroll={{ x: 1000 }}
        // loading={_.get(loading, 'record', false)}
        />
      </Col>
    </Row>
  </>
}

export default BillsTable;
import React, { useState } from 'react';
import { PageHeader, Tabs, message } from 'antd';
// import { CaretRightOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMount, useUnmount, useReactive } from 'ahooks';
import { showSingleProductHistoryVir } from '@/api/details';
import { showIndexKline } from '@/api/workbench';
import { VSTRING, isValidArray, isValidNumber } from '@/utils/utils2';
import { validateResponse } from '@/utils/utils';
import { INDEX_CODE_NAME } from '@/utils/indexCode';
import { DATE_TYPE, handleHistoryRate } from '@/view/common/Components/list_util';
import MainRateCharts from '@/view/common/MainRateCharts';
import CompareChartsTwo from '@/view/common/CompareChartsTwo';
import LevelCharts from '../AccountAdm/ProductDetails/Components/LevelCharts';
import ContributionCharts from '../AccountAdm/ListsDetails/Components/ContributionCharts';
import AssetsHeader from '@/view/Home/AccountAdm/ProductDetails/Components/AssetsHeader';
import DisplayCharts from '@/view/common/DisplayCharts';
import { DetailTabsIcon } from '../AccountAdm/ProductDetails/de_widget';
import moment from 'moment';
import _ from 'lodash';
import '../AccountAdm/ProductDetails/product.scss'

const { TabPane } = Tabs;
const MAIN_RATE = 'MAIN_RATE';
const REALTIME_TAB = '9';
const TODAY = moment().format('YYYY-MM-DD');
let timer = null;
/* 
  虚拟产品详情页面
*/
const VirtualProduct = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const routeProduct = _.get(props, 'location.state.item', {});
  const allVirProducts = _.get(props, 'location.state.allproduct', []);
  const [indexValue, setIndexValue] = useState({});
  const [pointDatas, setPointDatas] = useState({});
  const [countMain, setCountMain] = useState(0);
  const [idxParams, setIdxParams] = useState({});
  const [tradeTime] = useState(mobxStore.inTrading === 'trading' ? true : false);
  const [validDate, setValidDate] = useState([]);
  const conState = useReactive({
    active: MAIN_RATE, symbol: INDEX_CODE_NAME['中证1000'], dateType: _.get(DATE_TYPE, REALTIME_TAB),
    date: TODAY, mainTab: REALTIME_TAB
  });
  let isUnmont = false;

  useMount(() => {
    _showSingleProductHistory();
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    };
  });

  useUnmount(() => {
    isUnmont = true;
    timer && clearTimeout(timer);
  });
  // 获取产品历史数据
  async function _showSingleProductHistory(diffVal = 0) {
    let pms = {
      productId: _.get(routeProduct, 'id'),
      dateType: conState.dateType,
      date: conState.date,
    }
    let idxPms = {
      symbol: conState.symbol,
      dateType: conState.dateType,
      date: conState.date,
    };
    if (isValidNumber(diffVal)) {     // 百分比转化为小数；
      _.set(pms, 'rate', diffVal / 100);
    }
    const res = await showSingleProductHistoryVir(pms);
    const resIdx = await showIndexKline(idxPms);
    setIdxParams(_.assign(pms, idxPms));
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    if (_.get(resIdx, 'message', '') !== 'success') {
      message.info(_.get(resIdx, 'message'))
    }
    const getPoints = _.get(res, 'data.pointList', []);
    if (validateResponse(res, isUnmont) && isValidArray(getPoints)) {
      const rateData = handleHistoryRate(res, resIdx, pms.dateType);
      setPointDatas({ 'timeList': rateData.timeList, 'datas': rateData.datas, 'newData': rateData.newData });
      setIndexValue(rateData.indexVal);
      if (pms.dateType === 'ALL') {
        setValidDate(rateData.timeList);
      }
    } else {
      setPointDatas({ timeList: [], datas: {}, newData: {} });
      setIndexValue({});
    }
    setCountMain(countMain + 1);
  };
  // 指数获取
  async function _showIndexKline() {
    let params = {
      symbol: conState.symbol,
      dateType: conState.dateType,
      date: conState.date
    }
    const res = await showIndexKline(params);
    setIdxParams(_.assign(idxParams, params))
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {});
      setIndexValue(getData);
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    setCountMain(countMain + 1);
  }

  function onDateTypeChange(key, date) {
    const new_type = parseInt(key) > 100 ? DATE_TYPE['12'] : _.get(DATE_TYPE, key);
    conState.dateType = new_type;
    conState.mainTab = key === '11' ? REALTIME_TAB : key;
    if (date) {
      conState.date = date;
    }
    if (_.get(idxParams, 'dateType') === 'ALL' && new_type === 'ALL') {
      setCountMain(countMain + 1);
    } else {
      _showSingleProductHistory();
    }
  };

  const main_props = {
    'upcount': countMain, 'isTrading': tradeTime, 'datas': pointDatas, 'pms': idxParams,
    'dailyRevenueRate': _.round(parseFloat(_.get(routeProduct, 'dailyRevenueRate', 0)), 2),
    'currentTabs': conState.mainTab, 'indexValue': indexValue, 'indexSymbol': conState.symbol,
    'validDateRange': validDate, 'tradeDates': tradeDates
  };
  const base_props = {
    'pageKey': 'virtual', 'virtualProductId': _.get(routeProduct, 'id'), 'id': _.get(routeProduct, 'id'),
    'isVir': true, 'virMode': _.get(routeProduct, 'mode')
  };
  return <div style={{ overflowX: 'hidden' }}>
    <PageHeader
      title={_.get(routeProduct, 'name', '-')}
      subTitle={String(_.get(routeProduct, 'mode')) === '0' ? '产品' : '账户'}
      onBack={() => props.history?.goBack()}
      style={{ backgroundColor: 'white' }}
    >
    </PageHeader>

    <div className="ProductDetails contentView" id="virtualProduct">
      <AssetsHeader assets={routeProduct} pmData={routeProduct} />
      <div style={{ height: 16 }}></div>

      <div className='productTab' style={{ marginTop: 12 }}>
        <Tabs activeKey={conState.active} type='card' onChange={(value) => conState.active = value}>
          <TabPane tab={<DetailTabsIcon keys={MAIN_RATE} />} key={MAIN_RATE}>
            <MainRateCharts
              pageKey='combind'
              {...main_props}
              cutDateType={onDateTypeChange}
              checkDayRate={onDateTypeChange}
              indexChange={(idx) => {
                conState.symbol = idx;
                _showIndexKline();
              }}
              rateDiffChange={(diffVal) => _showSingleProductHistory(diffVal)}
            />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='PRO_COM' />} key='PRO_COM'>
            <CompareChartsTwo
              pageKey='virtual'
              active={conState.active}
              isTrading={tradeTime}
              virtualProduct={allVirProducts}
              productInfo={{
                'productId': VSTRING + _.get(routeProduct, 'id'),
                'dateType': conState.dateType,
                'date': conState.date,
              }}
              idxPms={idxParams}
            />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='PRO_LEVEL' />} key='PRO_LEVEL'>
            <LevelCharts {...base_props} />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION2' />} key='CONTRIBUTION2'>
            <ContributionCharts {...base_props} />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='DISTRIBUTION' />} key='DISTRIBUTION'>
            <DisplayCharts {...base_props}
              active={conState.active}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
}

export default VirtualProduct;
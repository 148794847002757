import React from 'react';
import { Table, Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash'

const CommissionModal = ({ visible, onCancel, datas }) => {

  const columns = [
    {
      title: '账户',
      align: 'center',
      dataIndex: 'date',
      render: (text, record, index) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: '当日手续费',
      align: 'center',
      dataIndex: 'dailyCommission',
      render: (text, record, index) => _.round(text, 2),
    },
    {
      title: '当日累计总手续费',
      align: 'center',
      dataIndex: 'totalCommission',
      render: (text, record, index) => _.round(text, 2),
    },
  ];

  return <Modal
    title='手续费'
    visible={visible}
    footer={null}
    onCancel={onCancel}
    width={680}
  >
    <Table
      rowKey="date"
      dataSource={datas}
      columns={columns}
      size='small'
      pagination={{
        pageSize: 10
      }}
    />
  </Modal>
}

export default CommissionModal;
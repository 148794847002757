import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Radio,
  Space,
  Table,
  Typography
} from 'antd';
import _ from 'lodash';
import wechat from "@/images/wechat_pay.png";
import alipay from "@/images/alipay.png";
const { Paragraph } = Typography;

const columns = [
  {
    title: '订单号',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: '产品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    render: (text, record) => {
      const splitDes = text ? _.split(text, /[\s\n]/) : [];
      return <ul style={{ listStyleType: 'decimal', textAlign: 'left' }}>
        {_.size(splitDes) > 0 && splitDes.map((item, index) => {
          return <li key={'li' + index}>{item}</li>
        })}
      </ul>
    }
  },
  {
    title: '数量',
    dataIndex: 'buyCount',
    key: 'buyCount',
  },
  {
    title: '时长',
    dataIndex: 'duration',
    key: 'duration',
    render: (text, record) => {
      if (text === 'month') {
        return '月'
      } else if (text === 'week') {
        return '周'
      } else {
        return text
      }
    }
  },
  {
    title: '金额',
    dataIndex: 'orderAmount',
    key: 'orderAmount',
    render: (text, record) => (_.round(text, 2))
  },
];

const StepTwo = ({ tData, onChange, containerStyle }) => {
  const [selected, setSelected] = useState(10)

  function renderRadio(name, index) {
    return (
      <Row justify='space-between'
        style={{
          padding: 20,
          border: '2px solid #f5f5f5',
          height: '85px',
          lineHeight: '43px'
        }}
        onClick={() => {
          if (selected !== index) {
            setSelected(index);
            onChange('pay', index);
          }
        }}>
        <Col span={8}>
          <Space>
            <Radio checked={selected === index ? true : false} on />
            <span style={{ fontSize: '17px', fontWeight: 600 }}>{name}</span>
          </Space>
        </Col>
        <Col span={10}>
          <img
            src={index === 1 ? wechat : alipay}
            alt=""
            style={{
              width: 75,
              marginTop: index === 1 ? -10 : 0,
              float: 'right'
            }}
          />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <div style={containerStyle}>
        <Card>
          <Table
            columns={columns}
            dataSource={tData}
            rowKey="orderNo"
          />
        </Card>
      </div>

      <div style={containerStyle}>
        <Card title='支付方式' bordered>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              {renderRadio('微信', 1)}
            </Col>
            <Col span={8}>
              {renderRadio('支付宝', 2)}
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}

export default StepTwo;
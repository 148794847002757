import axios from '@/utils/axios';

 export function findUser(txt) {
  return axios.get(global.api + '/account/findUser?content=' + txt);
}

export function openService(data) {
  return axios.post(global.api + '/payment/openService', data);
}
// 自动分配基金经理
export function showAllManagerSub(txt) {
  return axios.get(global.api + '/system/admin/showAllManagerSub?name=' + (txt ? txt : ''));
}
export function showSubAccountPool() {
  return axios.get(global.api + '/system/admin/showSubAccountPool');
}
export function joinSubAccountPool(data) {
  return axios.post(global.api + '/system/admin/joinSubAccountPool', data);
}
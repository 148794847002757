import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Select, Spin, Tooltip, Button, Space, message } from 'antd';
import { kLineHistory, excessRateOfReturnHistory } from '@/api/workbench';
import { SyncOutlined } from '@ant-design/icons'
import * as echarts from 'echarts';
import { NewRangePicker } from '@/view/common/widgets';
import { COMPARE_OPTION } from '../list_utils';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;

const CompareCharts = ({
  indexList = [],
  subAccountId = 0,
  active = '1',
  globalDate = [],
  todayFormat = '',
  newProps = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [compareDatas, setCompareDatas] = useState([]);
  const [currentParams, setCurrentParams] = useState({});
  const [update, setUpdate] = useState(0);
  const [selectTime, setselectTime] = useState([]);
  const [selectStocks, setselectStocks] = useState(_.get(indexList, '[0].value', ''));
  const [option, setoption] = useState(COMPARE_OPTION);

  useEffect(() => {
    // console.log('更新', active)
    if (active === 'EARNING') {
      let timeArray = [];
      let stocks = 'symbol' in currentParams ? currentParams.symbol : _.get(indexList, '[0].value', null)
      if ('startTime' in currentParams) {
        timeArray = [currentParams.startTime, currentParams.endTime];
      } else if (globalDate[0] && globalDate[1]) {
        timeArray = globalDate;
      } else {
        timeArray = [todayFormat, todayFormat];
      }

      dailyEarning(stocks, timeArray)
    }
  }, [active])
  //个股盈亏和个股收益曲线比较
  async function dailyEarning(value, timeValue) {
    if (!value || !subAccountId) {
      return;
    }
    setLoading(true)
    let params = {
      "subAccountId": subAccountId,
      "startTime": timeValue[0],
      "endTime": timeValue[1],
      "symbol": value
    };
    setCurrentParams(params);
    const res5 = await excessRateOfReturnHistory(params);
    setLoading(false);
    if (!res5) {
      return;
    } else {
      const data6 = await kLineHistory({
        symbol: params.symbol,
        beginDate: params.startTime,
        endDate: params.endTime,
        type: 2
      });

      if (_.get(data6, 'code', '') === '200') {
        let exData = _.get(res5, 'data', {});
        const getTime5 = _.get(exData, 'excessTimeList', []);
        const getData = _.get(exData, `excessList`, []);
        const getPriceResult = _.get(data6, 'data.priceResult', []);
        let timeList = getPriceResult.map(array => array[0]);
        let timeList5 = getTime5.map(n => n + ':00'); // 收益时间没有秒单位；添加方便下方indexOf查找
        let newValueList = [];
        let exeRate = [];
        newValueList = getPriceResult.map((array) => {
          let temp = _.slice(array, 1, _.size(array))
          return temp;
        })

        if (_.isArray(timeList5) > 0 && _.size(timeList5) > 0) {
          let cIndex = -1;
          timeList.map((item) => {
            const diffs = moment().diff(moment(item), 'm');
            let findIndex = timeList5.indexOf(item);
            if (findIndex !== -1) {
              if (diffs === 0) { // 记录当前时间，之后所有数据要用前一位数据占位
                cIndex = findIndex;
              }
              let targetValue = getData[findIndex];
              exeRate.push(targetValue);
            } else {
              if (cIndex !== -1 && getData[cIndex]) {
                exeRate.push(getData[cIndex]);
              } else {
                exeRate.push(0)
              }
            }
          })
        }
        // 避免中间单独一位为【0】的空数据
        exeRate = exeRate.map((n, i) => {
          if (i !== 0 && i !== (_.size(exeRate) - 1) && n === 0 && exeRate[i - 1] !== 0 && exeRate[i + 1] !== 0) {
            return exeRate[i - 1];
          }
          return n;
        })
        let finalData = {
          timeList: timeList,
          values: newValueList,
          rate: exeRate,
          cal: {
            high: _.max(exeRate),
            low: _.min(exeRate),
            startPrice: _.get(newValueList, '[0][0]', 0)
          }
        }
        setCompareDatas(finalData);
        setUpdate(update + 1);
      } else {
        message.warning(_.get(data6, 'message', '无数据！'))
      }
    }
  }

  function renderHighAndLow(price, type, defNumber) {
    if (price === 0) {
      return defNumber
    }
    let finalValue
    if (type === 'high') {
      finalValue = _.ceil(price < 0 ? price * 0.9 : price * 1.1, 2)
    } else {
      finalValue = _.ceil(price < 0 ? price * 1.1 : price * 0.9, 2)
    }
    return finalValue
  }

  useEffect(() => {
    //console.log('5chart', props)
    let myChart = newProps.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('list_compare_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);
    // newOption.xAxis.data = props.chartsXData5;
    const k_line_data = _.get(compareDatas, 'values', []) || []
    newOption.xAxis.data = _.get(compareDatas, 'timeList', []);
    newOption.series[0].data = k_line_data;
    newOption.series[1].data = _.get(compareDatas, 'rate', []);

    let k_other_value = { highPrice: [], lowPrice: [] };
    _.size(k_line_data) > 0 && k_line_data.map(n => {
      k_other_value.highPrice.push(parseFloat(n[2]))
      k_other_value.lowPrice.push(parseFloat(n[3]))
    })
    const minPrice = _.min(k_other_value.lowPrice);
    const maxPrice = _.max(k_other_value.highPrice);
    const getHigh = _.get(compareDatas, 'cal.high', 0);
    const getLow = _.get(compareDatas, 'cal.low', 0);

    newOption.yAxis[0].min = _.ceil(minPrice * 0.99, 2);
    newOption.yAxis[0].max = _.ceil(maxPrice * 1.01, 2);
    newOption.yAxis[1].min = renderHighAndLow(getLow, 'low', -1)
    newOption.yAxis[1].max = renderHighAndLow(getHigh, 'high', 1)

    //console.log('chart5-option', newOption)
    // console.log('计算', _.get(props, 'compareDatas', {}))
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update])

  return (
    <Row style={{ backgroundColor: '#fff', padding: 2 }}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Card bordered={false} title='个股盈亏和个股收益曲线比较' extra={
            <Row justify='end'>
              <Space>
                <span>股票:</span>
                <Select
                  onChange={(value) => {
                    setselectStocks(value);
                    const findIndex = _.findIndex(indexList || [], o => o.value === value);
                    dailyEarning(indexList[findIndex].value, selectTime);
                  }}
                  value={selectStocks}
                  dropdownMatchSelectWidth={false}
                  style={{ width: 100 }}
                >
                  {
                    indexList.length !== 0 &&
                    indexList.map((item, index) => {
                      return (
                        <Option
                          key={index}
                          value={item.value}
                        >
                          {item.title}
                        </Option>
                      )
                    })
                  }
                </Select>
                <span>选择日期:</span>
                <NewRangePicker
                  dates={selectTime}
                  onSelect={(dateString) => {
                    setselectTime(dateString);
                    dailyEarning(selectStocks, dateString);
                  }}
                />
              </Space>
            </Row>
          }>
            <div style={{ marginTop: 8, marginBottom: 8, marginLeft: 8 }}>
              <Tooltip title="点击刷新">
                <Button
                  shape="circle"
                  icon={<SyncOutlined />}
                  onClick={() => dailyEarning(selectStocks, selectTime)}
                  size='small'
                  loading={loading}
                />
              </Tooltip>
            </div>
            <div
              id="list_compare_charts"
              style={{ width: '100%', height: 380 }}
            />
          </Card>
        </Spin>
      </Col>
    </Row>
  )
};

export default CompareCharts;
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Typography,
  Space,
  message
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Title, Paragraph, Text } = Typography;
const { confirm } = Modal;

const InterestModal = ({ visible, role, onCancel }) => {
  // const [point, setPoint] = useState(0);
  // const [serviceIndex, setServiceIndex] = useState(null);

  // function confirmExchange() {
  //   let params = {
  //     costPoint: point || 0,
  //     serviceIndex: serviceIndex,
  //   }
  //   confirm({
  //     title: '是否确定兑换积分？',
  //     icon: <ExclamationCircleOutlined />,
  //     content: ``,
  //     onOk: () => {
  //       onConfirm(params);
  //     },
  //     onCancel: () => { }
  //   })
  // }

  const showList = {
    trader: [
      { label: '推广收益：', value: '交易员订阅费用的20%' },
      { label: '平台奖励：', value: '我的(直属)基金经理商品推广佣金的20%' },
      { label: '课程权益：', value: '正式版购买6个月或者高级版购买3个月 ，送Python课(2180元)/量化策略课(2180元)' },
      { label: '交易金权益：', value: '正式版购买6个月或者高级版购买3个月，送1万元实盘交易金(购买者获得50%的收益分成)' },
    ],
    trader_manager: [
      { label: '推广收益：', value: '固定底薪,交易员订阅费用的20%' },
      {
        label: '平台奖励：', value: <Paragraph>
          直属团队补贴,我的(直属)基金经理推广佣金的30%
          <br />
          非直属团队补贴非直属基金经理推广佣金的20%`
        </Paragraph>
      },
      { label: '课程权益：', value: 'AQF培训课程返佣（8%-12%）' },
      { label: '交易金权益：', value: '高级版购买一年，送10万实盘交易资金' },
    ],
  }

  return (
    <Modal
      title={'晋升为基金经理权益'}
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
    >
      {showList[role] ? showList[role].map((n, i) => {
        return <Typography key={'show' + i}>
          <Title level={5}>{n.label}</Title>
          {_.isString(n.value) ? <Paragraph>
            {n.value}
          </Paragraph> : n.value}
        </Typography>
      }) : <></>}
    </Modal>
  )
}

export default InterestModal;